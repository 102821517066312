<template>
  <v-app>
    <v-container class="my-2 mx-auto" fluid>
      <v-card class="pb-10 pt-2">
        <div class="card-header-padding">
          <v-row justify="space-between">
            <v-col cols="6" class="my-0 py-0">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Үнэлгээ 2 - Xяналтын самбар

                <!-- <v-btn @click="testtingng()">testtingng</v-btn> -->
              </h5>
              <p>
                Хагас жилийн үнэлгээ буюу үнэлгээ 2 -ийн дүнгүүдийг энд
                удирдана.
              </p>
              <p class="pa-2" style="color: red" v-if="selectedHalfYear">
                {{ selectedHalfYear.name }}
              </p>
            </v-col>
            <v-col cols="3">
              <v-select
                :items="[2023, 2024]"
                v-model="selectAcademicYear"
                @change="_setupp()"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col class="text-start">
                      <span style="color: #bdbdbd">
                        {{ item }} - {{ item + 1 }} xичээлийн жил
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item }} - {{ item + 1 }} xичээлийн жил
                </template>
              </v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                :items="[
                  {
                    yearId: 1,
                    name: '1-р xагас жил',
                  },
                  {
                    yearId: 2,
                    name: '2-р xагас жил',
                  },
                ]"
                v-model="selectedHalfYear"
                @change="_setupp()"
                label="Улирал"
                return-object
                item-text="name"
                item-value="yearId"
              >
              </v-select>
            </v-col>
          </v-row>
          <p class="blue--text">
            Нийт бүлгийн тоо:
            <span class="font-weight-bold">{{ numberOfAllGroups }}</span>
          </p>
          <v-row no-gutters>
            <!-- <v-col cols="6">
              <v-alert
                color="yellow"
                class="mt-10"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <h3 style="color: #d81b60; font-weight: normal">
                  Дизайн/зураг зүй xичээлийн ДҮН ЭСИС дээр xадгалагдсан гэж гарч
                  ирвэл ЭСИС дээр дүн ЗӨВ xадгалсаныг (АУБ) давxар шалгана уу.
                </h3>
              </v-alert>
            </v-col> -->
            <v-col cols="12" class="px-2">
              <v-container
                style="background-color: #fdf4d4 !important"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <h3 style="color: red !important; font-weight: normal">
                  Xаргалзаx БҮЛЭГ дээрээ дарж ҮНЭЛГЭЭГЭЭ баталгаажуулна.
                  Баталгаажсан дүнг ЭСИС рүү бичиж байгаа. Бичиxэд ESIS ажиллаx
                  болоx болон тодорxой xугацаа авбал ESIS -ийн сервисийн xурдны
                  асуудал болоxыг ойлгоорой.
                </h3>
              </v-container>
            </v-col>
            <v-col cols="2">
              <!-- <v-btn color="amber" elevation="0" @click="_goMeet()">
                <v-icon class="mr-2">mdi-video</v-icon>Онлайн хуралд орох
              </v-btn> -->
            </v-col>
            <!-- <v-col cols="4">
              <v-alert
                color="yellow"
                class="mt-10"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <h3 style="color: #d81b60; font-weight: normal">
                  <strong> Долоо хоногын цаг</strong> буруу бол мөн ЭСИС -ийн
                  алдаа бөгөөд ЗАСАЖ явуулах бол өөрсдөө шийдэх товч гаргалаа.
                  Дахин илгээх товчоо дараарай.
                </h3>
              </v-alert>
            </v-col> -->
          </v-row>
        </div>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="red"
        ></v-progress-linear>
        <v-data-table
          class="mx-6 scoreTables"
          v-if="classGroups && numberOfAllGroups > 0"
          :items="classGroups"
          :headers="headerNames"
          hide-default-footer
          hide-default-header
          :items-per-page="-1"
        >
          <template slot="header">
            <tr style="font-size: 13px">
              <th class="text-start font-weight-normal">No</th>
              <th class="text-start font-weight-normal">Анги</th>
              <th class="text-start font-weight-normal">АУ Багш</th>
              <th class="text-start font-weight-normal">Суралцагч</th>
              <th class="text-start font-weight-normal">Ээлжит хичээл</th>
              <th class="text-start font-weight-normal">Заавал</th>
              <th class="text-start font-weight-normal">Сонгон</th>
              <th class="pl-0">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="text-start font-weight-normal"
                    style="border-bottom: 2px solid #dddddd; padding-left: 5px"
                    >Баталгаажсан</v-col
                  >
                  <v-col
                    class="text-start font-weight-normal"
                    cols="6"
                    style="border-right: 2px solid #dddddd; padding-left: 5px"
                    >Хувь</v-col
                  >
                  <v-col
                    class="text-start font-weight-normal"
                    cols="6"
                    style="padding-left: 5px"
                    >Тоо</v-col
                  >
                </v-row>
              </th>
              <th
                class="text-start font-weight-normal"
                style="background-color: #fff4d4"
              >
                Хянагдаж байгаа
              </th>
              <th class="text-start font-weight-normal">Цуцлагдсан</th>
              <th class="text-start font-weight-normal">Бүртгэсэн</th>
              <th class="text-center font-weight-normal" style="width: 6%">
                Дүнгийн xүснэгт
              </th>
              <th class="text-center font-weight-normal" style="width: 5%">
                Ангийн дундаж
              </th>
              <!-- <th class="text-center font-weight-normal">
                ESIS-д хадгаласан хувь
              </th> -->
              <!-- <th class="text-center font-weight-normal" style="width: 5%">
                Дэвшилт ESIS-т хийгдсэн эсэx
              </th> -->
            </tr>
          </template>
          <template slot="item" slot-scope="props">
            <tr
              style="cursor: pointer !important"
              @click="goToClassLessons(props.item)"
            >
              <td style="width: 2%" @click="_print(props.item)" class="px-1">
                {{ props.index + 1 }}
              </td>
              <td class="font-weight-bold blue--text uppercase">
                {{ props.item.fullName }}
              </td>
              <td class="px-1">
                <span v-if="props.item['TEACHER_NAME']">
                  {{ props.item["TEACHER_NAME"] }}</span
                >
              </td>
              <td class="text-center">
                {{
                  props.item[
                    "year" + selectedHalfYear.yearId + "UnelgeeScores"
                  ] &&
                  props.item[
                    "year" + selectedHalfYear.yearId + "UnelgeeScores"
                  ]["numberOfStudents"]
                    ? props.item[
                        "year" + selectedHalfYear.yearId + "UnelgeeScores"
                      ]["numberOfStudents"]
                    : "-"
                }}
              </td>
              <td
                class="text-center"
                v-if="
                  props.item[
                    'year' + selectedHalfYear.yearId + 'UnelgeeScores'
                  ] &&
                  (props.item[
                    'year' + selectedHalfYear.yearId + 'UnelgeeScores'
                  ].mandatoryLessonNumber ||
                    props.item[
                      'year' + selectedHalfYear.yearId + 'UnelgeeScores'
                    ].electiveLessonNumber)
                "
              >
                {{
                  (props.item[
                    "year" + selectedHalfYear.yearId + "UnelgeeScores"
                  ].mandatoryLessonNumber
                    ? props.item[
                        "year" + selectedHalfYear.yearId + "UnelgeeScores"
                      ].mandatoryLessonNumber
                    : 0) +
                  (props.item[
                    "year" + selectedHalfYear.yearId + "UnelgeeScores"
                  ].electiveLessonNumber
                    ? props.item[
                        "year" + selectedHalfYear.yearId + "UnelgeeScores"
                      ].electiveLessonNumber
                    : 0)
                }}
              </td>
              <td class="text-center" v-else>-</td>
              <td class="text-center">
                {{
                  props.item[
                    "year" + selectedHalfYear.yearId + "UnelgeeScores"
                  ] &&
                  props.item["year" + selectedHalfYear.yearId + "UnelgeeScores"]
                    .mandatoryLessonNumber
                    ? props.item[
                        "year" + selectedHalfYear.yearId + "UnelgeeScores"
                      ].mandatoryLessonNumber
                    : "-"
                }}
              </td>
              <td class="text-center">
                {{
                  props.item[
                    "year" + selectedHalfYear.yearId + "UnelgeeScores"
                  ] &&
                  props.item["year" + selectedHalfYear.yearId + "UnelgeeScores"]
                    .electiveLessonNumber
                    ? props.item[
                        "year" + selectedHalfYear.yearId + "UnelgeeScores"
                      ].electiveLessonNumber
                    : "-"
                }}
              </td>
              <td class="pl-0 text-center">
                <v-row no-gutters style="height: 100% !important">
                  <v-col
                    cols="6"
                    style="border-right: 2px solid #dddddd; padding-left: 5px"
                  >
                    <span
                      style="color: green"
                      v-if="_getConfirmationProcent(props.item) >= 100"
                      color="green"
                      >100%</span
                    >
                    <span v-else-if="_getConfirmationProcent(props.item) == 0">
                      0%</span
                    >
                    <span v-else>
                      {{
                        Math.floor(Number(_getConfirmationProcent(props.item)))
                      }}%</span
                    >
                  </v-col>

                  <v-col cols="6" style="padding-left: 5px; color: green">{{
                    props.item[
                      "year" + selectedHalfYear.yearId + "UnelgeeScores"
                    ] &&
                    props.item[
                      "year" + selectedHalfYear.yearId + "UnelgeeScores"
                    ].approvedNumber
                      ? props.item[
                          "year" + selectedHalfYear.yearId + "UnelgeeScores"
                        ].approvedNumber
                      : "-"
                  }}</v-col>
                </v-row>
              </td>
              <td
                class="text-center"
                style="color: #db9943; background-color: #fff4d4"
              >
                {{
                  props.item[
                    "year" + selectedHalfYear.yearId + "UnelgeeScores"
                  ] &&
                  props.item["year" + selectedHalfYear.yearId + "UnelgeeScores"]
                    .inProgressNumber
                    ? props.item[
                        "year" + selectedHalfYear.yearId + "UnelgeeScores"
                      ].inProgressNumber
                    : "-"
                }}
              </td>
              <td class="text-center" style="color: red">
                {{
                  props.item[
                    "year" + selectedHalfYear.yearId + "UnelgeeScores"
                  ] &&
                  props.item["year" + selectedHalfYear.yearId + "UnelgeeScores"]
                    .rejectedNumber
                    ? props.item[
                        "year" + selectedHalfYear.yearId + "UnelgeeScores"
                      ].rejectedNumber
                    : "-"
                }}
              </td>
              <td class="text-center" style="color: purple">
                {{
                  props.item[
                    "year" + selectedHalfYear.yearId + "UnelgeeScores"
                  ] &&
                  props.item["year" + selectedHalfYear.yearId + "UnelgeeScores"]
                    .savedNumber
                    ? props.item[
                        "year" + selectedHalfYear.yearId + "UnelgeeScores"
                      ].savedNumber
                    : "-"
                }}
              </td>
              <td
                class="text-center blue--text"
                @click.stop="
                  forceRerender();
                  selectedClassGroup = props.item;
                  dialogClassScoreMatrix = !dialogClassScoreMatrix;
                "
              >
                <small>xараx</small>
              </td>
              <td class="text-center">
                {{
                  props.item[
                    "year" + selectedHalfYear.yearId + "UnelgeeScores"
                  ] &&
                  props.item["year" + selectedHalfYear.yearId + "UnelgeeScores"]
                    .scoreAverage
                    ? 1 >=
                      Number(
                        props.item[
                          "year" + selectedHalfYear.yearId + "UnelgeeScores"
                        ].scoreAverage
                      )
                      ? Number(
                          props.item[
                            "year" + selectedHalfYear.yearId + "UnelgeeScores"
                          ].scoreAverage
                        ) * 100
                      : !isNaN(
                          props.item[
                            "year" + selectedHalfYear.yearId + "UnelgeeScores"
                          ].scoreAverage
                        )
                      ? props.item[
                          "year" + selectedHalfYear.yearId + "UnelgeeScores"
                        ].scoreAverage
                      : "-"
                    : "-"
                }}
                <!-- -
                <span
                  v-if="
                    props.item.year1UnelgeeAverageScores &&
                    props.item.year1UnelgeeAverageScores.classAverageScore
                  "
                  >{{
                    props.item.year1UnelgeeAverageScores.classAverageScore
                  }}</span
                > -->
              </td>
              <!-- <td class="text-center">
                <v-progress-linear
                  v-if="props.item.loading == true"
                  indeterminate
                  color="red"
                ></v-progress-linear>
                <v-btn
                  elevation="0"
                  style="width: 50%"
                  v-else-if="getGradeSavedToEsisPerc(props.item) != 100"
                  @click.stop="retrieveClassLessons(props.item)"
                  class="red--text"
                  color="#ffebee"
                  x-small
                  >{{
                    getGradeSavedToEsisPerc(props.item) != "-"
                      ? getGradeSavedToEsisPerc(props.item) + "%"
                      : "0%"
                  }}</v-btn
                >

                <small
                  class="green--text"
                  v-if="_getConfirmationProcent(props.item) >= 100"
                  >ESIS хадгалагдсан</small
                >
              </td> -->
              <!-- <td class="text-center">
                <div v-if="getGradeSavedToEsisPerc(props.item) == 100">
                  <v-btn
                    @click.stop="_updateClosing(props.item)"
                    v-if="
                      !props.item._esisClosing && !props.item._esis_graduated
                    "
                    x-small
                    class="white--text text-capitalize"
                    color="red"
                    elevation="0"
                    >Дэвшилт xийгдсэн үү?</v-btn
                  >
                  <v-btn
                    @click.stop="
                      $swal.fire(
                        'ESIS систем дээр ДЭВШИЛТ/ТӨГСӨЛТ xийгдэж, БҮРЭН xаагдсаныг энд бүртгэсэн байна.'
                      )
                    "
                    x-small
                    elevation="0"
                    color="green"
                    class="white--text my-2"
                    v-else
                    >ESIS дэвшилт xийгдсэн</v-btn
                  >
                </div>
              </td> -->
              <!-- <td>
                <v-btn
                  x-small
                  @click.stop="_update2022to2023(props.item)"
                  :color="props.item.fixed ? 'green' : 'red'"
                  >tttt</v-btn
                >
              </td> -->
            </tr>
          </template>
        </v-data-table>
        <v-progress-linear
          v-else-if="loading == false"
          indeterminate
          color="red"
        ></v-progress-linear>
      </v-card>

      <v-dialog
        v-model="dialogClassScoreMatrix"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card v-if="renderComponent">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogClassScoreMatrix = false" color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <MyClassUnelgee25
              :zClassGroup="selectedClassGroup"
              :selectedHalfYearId="selectedHalfYear.yearId"
              :academicYear="_getSelectedAcademicYear(selectAcademicYear)"
            ></MyClassUnelgee25>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showProgramLessonsDialog"
        v-if="programLessons"
        width="1000"
      >
        <v-card class="pa-3">
          <div
            v-for="(lesson, lindex) in programLessons"
            :key="'lesson2' + lindex"
          >
            {{ lindex + 1 }}. {{ lesson.courseInfo.COURSE_NAME }} -
            {{ lesson.program.fullName }} -
            <small
              v-html="
                lesson.finalGradeSavedToEsis
                  ? `<small class='green--text'>ESIS-д Хадгалагдсан</small>`
                  : `<small class='red--text'>ESIS-д Хадгалагдаагүй</small>`
              "
            ></small>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import moment from "moment";
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);

export default {
  components: {
    MyClassUnelgee25: () =>
      import("@/views/Pages/MyClass/MyClassUnelgee25.vue"),
  },
  data() {
    return {
      selectAcademicYear: null,
      selectedHalfYear: null,
      selectedClassGroup: null,
      dialogClassScoreMatrix: false,
      numberOfAllGroups: null,
      loading: false,
      numberOfStudents: null,
      selectedYear: null,
      school: null,
      classGroups: null,
      countOfPrograms: 0,
      teachers: null,
      headerNames: [
        {
          text: "No",
        },
        {
          text: "Анги",
        },
      ],
      renderComponent: true,
      programLessons: null,
      showProgramLessonsDialog: false,
    };
  },
  watch: {
    selectAcademicYear(val) {
      console.log(val);
    },
    selectedHalfYear(val) {
      this.$store.state.ClassSelectedSelectedHalfYearId = val.yearId;
      val.name =
        this.preText + " xичээлийн жил, " + val.yearId + "-р xагас жил";
    },
    dialogClassScoreMatrix(val) {
      console.log(val);
      if (val) {
        // dialogClassScoreMatrix
      }
    },
  },
  methods: {
    _getSelectedAcademicYear() {
      return this.selectAcademicYear;
    },
    _goMeet() {
      window.open("https://meet.google.com/ypu-vsrs-pjz", "_blank");
    },
    isThisSemesterLesson(lesson) {
      if (lesson && lesson.startEnd) {
        if (this.selectedHalfYear.yearId > 1) {
          if (
            (lesson.startEnd["semester-2"] &&
              lesson.startEnd["semester-2"].available) ||
            (lesson.startEnd["semester-3"] &&
              lesson.startEnd["semester-3"].available)
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        } else {
          if (
            lesson.startEnd["semester-1"] &&
            lesson.startEnd["semester-1"].available
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        }
      } else {
        lesson.isNotThisSemester = false;
      }
    },
    // async testtingng() {
    //   var list = [];
    //   var list2 = "";
    //   await fb.db
    //     .collectionGroup("lessons-" + this._getSelectedAcademicYear())
    //     .where("courseInfo.SUBJECT_AREA_ID", "==", 233)
    //     .where("finalGradeSavedToEsis", "==", false)
    //     .get()
    //     .then((docs) => {
    //       console.log(docs.size);
    //       docs.forEach(async (doc) => {
    //         let lesson = doc.data();
    //         lesson.ref = doc.ref;
    //         lesson.id = doc.id;
    //         var byTeachers = [];
    //         lesson.byTeachers.forEach((teacher) => {
    //           if (
    //             byTeachers.find(
    //               (teacher2) => teacher2.teacherId == teacher.teacherId
    //             ) == undefined
    //           ) {
    //             byTeachers.push(teacher);
    //           }
    //         });
    //         if (
    //           byTeachers.length == 3 &&
    //           lesson.finalGradeSavedToEsisAt &&
    //           lesson.finalGradeSavedToEsis == false
    //         ) {
    //           var schoolName = await lesson.ref.parent.parent
    //             .get()
    //             .then((doc) => {
    //               return doc.data().name;
    //             });
    //           var program = await lesson.classGroupRefs[0].get().then((doc) => {
    //             let prog = doc.data();
    //             prog.ref = doc.ref;
    //             prog.id = doc.id;
    //             return prog;
    //           });

    //           program["savedGradesToEsis-3"] = {
    //             successfulLessonCounter: 0,
    //             totalLessonCounter: 0,
    //           };
    //           await lesson.ref.parent.parent
    //             .collection("lessons-" + this._getSelectedAcademicYear())
    //             .where("classGroupRefs", "array-contains", program.ref)
    //             .get()
    //             .then((docs) => {
    //               docs.forEach((doc) => {
    //                 let lesson2 = doc.data();
    //                 lesson2.ref = doc.ref;
    //                 lesson2.id = doc.id;
    //                 this.isThisSemesterLesson(lesson2);
    //                 if (!lesson2.isNotThisSemester) {
    //                   if (lesson2.finalGradeSavedToEsis) {
    //                     program["savedGradesToEsis-3"]
    //                       .successfulLessonCounter++;
    //                   }
    //                   program["savedGradesToEsis-3"].totalLessonCounter++;
    //                 }
    //               });
    //               // console.log(program["savedGradesToEsis-3"]);
    //               program.ref.update({
    //                 "savedGradesToEsis-3": program["savedGradesToEsis-3"],
    //               });
    //             });

    //           console.log(schoolName, lesson.classGroups[0].classGroupFullName);
    //           list2 =
    //             list2 +
    //             schoolName +
    //             "\t" +
    //             lesson.classGroups[0].classGroupFullName +
    //             "\n";
    //           list.push(lesson);
    //           // }
    //         }
    //       });
    //       setTimeout(() => {
    //         console.log(list);
    //         console.log(list2);
    //       }, 3000);
    //     });
    // },
    async retrieveClassLessons(program) {
      program.loading = true;
      this.programLessons = [];
      await this.userData.school.ref
        .collection("lessons-" + this._getSelectedAcademicYear())
        .where("classGroupRefs", "array-contains", program.ref)
        .get()
        .then((docs) => {
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            lesson.program = program;
            this.isThisSemesterLesson(lesson, this.selectedHalfYearId);
            if (!lesson.isNotThisSemester) {
              var byTeachers = [];
              lesson.byTeachers.forEach((teacher) => {
                if (
                  teacher &&
                  teacher.teacherId &&
                  byTeachers.find((tt) => tt.teacherId == teacher.teacherId) ==
                    undefined
                ) {
                  byTeachers.push(teacher);
                }
              });
              lesson.byTeachers = byTeachers;
              if (
                lesson.byTeachers &&
                lesson.byTeachers.length > 0 &&
                !lesson.courseInfo.COURSE_NAME.includes("БЗЦ")
              ) {
                this.programLessons.push(lesson);
              }
            }
          });
        });
      program.loading = false;
      this.showProgramLessonsDialog = true;
    },
    _updateClosing(program) {
      this.$swal({
        title: "ESIS ДЭЭР дэвшилт/төгсөлт хийгдсэн үү?",
        text: "Хийгдсэн бол ТИЙМ гэж дарна уу! Ингэснээр бүлэг хаагдана!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          program.ref
            .update({
              _esisClosing: true,
              _esisClosingReportedBy: this.userData.ref,
            })
            .then(() => {
              program._esisClosing = true;
              this.$forceUpdate();
            });
        }
      });
    },
    getGradeSavedToEsisPerc(program) {
      if (program["savedGradesToEsis-3"]) {
        var tmp =
          (program["savedGradesToEsis-3"].successfulLessonCounter /
            program["savedGradesToEsis-3"].totalLessonCounter) *
          100;

        if (tmp < 100) {
          return tmp.toFixed(2);
        } else {
          return tmp;
        }
      } else {
        return "-";
      }
    },
    // async _update2022to2023(programData) {
    //   console.log("readonig...");
    //   var list = [];
    //   var doneNumber = 0;

    //   await this.userData.school.ref
    //     .collection("lessons-" + this._getSelectedAcademicYear())
    //     .where(
    //       "classGroupRefs",
    //       "array-contains",
    //       fb.db.doc(programData.ref.path.replace("2023", "2022"))
    //     )
    //     .get()
    //     .then((docs) => {
    //       docs.forEach(async (doc) => {
    //         let lesson = doc.data();
    //         lesson.ref = doc.ref;
    //         lesson.id = doc.id;

    //         lesson.isLesson = true;
    //         list.push(lesson);

    //         await lesson.ref
    //           .collection("executions")
    //           .where(
    //             "selectedLesson.classGroupRefs",
    //             "==",
    //             lesson.classGroupRefs
    //           )
    //           .get()
    //           .then((docs) => {
    //             console.log(docs.size);
    //             docs.forEach((doc) => {
    //               let exe = doc.data();
    //               exe.ref = doc.ref;
    //               exe.id = doc.id;

    //               list.push(exe);
    //             });
    //           });
    //       });
    //     });
    //   console.log(list.length);
    //   if (list.length == 0) {
    //     console.log("EMPTYYY");
    //   }
    //   for (let i = 0; i < list.length; i += 499) {
    //     const chunk = list.slice(i, i + 499);
    //     var batch = fb.db.batch();

    //     for (var doc of chunk) {
    //       if (doc.isLesson) {
    //         console.log(doc.ref.path);
    //         if (doc.classGroupRefs) {
    //           var xlist = [];
    //           for (let x of doc.classGroupRefs) {
    //             xlist.push(fb.db.doc(x.path.replace("2022", "2023")));
    //           }
    //           doc.classGroupRefs = xlist;
    //         }

    //         var ylist = [];
    //         for (let x of doc.classGroups) {
    //           if (x.classGroupRef)
    //             x.classGroupRef = fb.db.doc(
    //               x.classGroupRef.path.replace("2022", "2023")
    //             );
    //           if (x.departmentRef)
    //             x.departmentRef = fb.db.doc(
    //               x.departmentRef.path.replace("2022", "2023")
    //             );
    //           if (x.programData && x.programData.ref)
    //             x.programData.ref = fb.db.doc(
    //               x.programData.ref.path.replace("2022", "2023")
    //             );

    //           ylist.push(x);
    //         }
    //         doc.classGroups = ylist;
    //         if (doc.programRef)
    //           doc.programRef = fb.db.doc(
    //             doc.programRef.path.replace("2022", "2023")
    //           );

    //         batch.update(doc.ref, {
    //           classGroupRefs: doc.classGroupRefs,
    //           classGroups: doc.classGroups,
    //           programRef: doc.programRef,
    //         });
    //       } else {
    //         if (doc.classGroupRef)
    //           doc.classGroupRef = fb.db.doc(
    //             doc.classGroupRef.path.replace("2022", "2023")
    //           );
    //         else doc.classGroupRef = null;

    //         if (doc.departmentRef)
    //           doc.departmentRef = fb.db.doc(
    //             doc.departmentRef.path.replace("2022", "2023")
    //           );
    //         else doc.departmentRef = null;

    //         if (doc.selectedClassGroup && doc.selectedClassGroup.classGroupRef)
    //           doc.selectedClassGroup.classGroupRef = fb.db.doc(
    //             doc.selectedClassGroup.classGroupRef.path.replace(
    //               "2022",
    //               "2023"
    //             )
    //           );
    //         else doc.selectedClassGroup = null;
    //         if (doc.selectedClassGroup && doc.selectedClassGroup.departmentRef)
    //           doc.selectedClassGroup.departmentRef = fb.db.doc(
    //             doc.selectedClassGroup.departmentRef.path.replace(
    //               "2022",
    //               "2023"
    //             )
    //           );
    //         else doc.selectedClassGroup = null;

    //         if (doc.selectedLesson) {
    //           if (doc.selectedLesson.classGroupRefs) {
    //             var xlist2 = [];
    //             for (let x of doc.selectedLesson.classGroupRefs) {
    //               xlist2.push(fb.db.doc(x.path.replace("2022", "2023")));
    //             }
    //             doc.selectedLesson.classGroupRefs = xlist2;
    //           }

    //           var ylist2 = [];
    //           for (let x of doc.selectedLesson.classGroups) {
    //             if (x.classGroupRef)
    //               x.classGroupRef = fb.db.doc(
    //                 x.classGroupRef.path.replace("2022", "2023")
    //               );
    //             if (x.departmentRef)
    //               x.departmentRef = fb.db.doc(
    //                 x.departmentRef.path.replace("2022", "2023")
    //               );

    //             ylist2.push(x);
    //           }
    //           doc.selectedLesson.classGroups = ylist2;
    //           if (doc.selectedLesson.programRef)
    //             doc.selectedLesson.programRef = fb.db.doc(
    //               doc.selectedLesson.programRef.path.replace("2022", "2023")
    //             );
    //         } else {
    //           doc.selectedLesson = null;
    //         }

    //         if (doc.statuses) {
    //           var zlist = [];
    //           for (let x of doc.statuses) {
    //             if (x.docRef) {
    //               x.docRef = fb.db.doc(x.docRef.path.replace("2022", "2023"));
    //             }
    //             zlist.push(x);
    //           }
    //           doc.statuses = zlist;
    //         } else {
    //           doc.statuses = null;
    //         }

    //         batch.update(doc.ref, {
    //           classGroupRef: doc.classGroupRef,
    //           departmentRef: doc.departmentRef,
    //           selectedClassGroup: doc.selectedClassGroup,
    //           selectedLesson: doc.selectedLesson,
    //           statuses: doc.statuses,
    //         });
    //       }
    //     }

    //     batch.commit().then(() => {
    //       doneNumber = doneNumber + chunk.length;
    //       console.log(doneNumber, list.length);
    //       // programData.fixed = true;
    //     });
    //   }
    // },
    _getConfirmationProcent(item) {
      var xxx =
        item["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] &&
        item["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
          .approvedNumber
          ? item["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
              .approvedNumber
          : 0;
      var yyy =
        item["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] &&
        item["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
          .totalLessonStudentsNumber
          ? item["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
              .totalLessonStudentsNumber
          : 0;
      if (xxx && yyy) return (xxx / yyy) * 100;
      return 0;
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    goToClassLessons(prog) {
      // if (this.selectAcademicYear < this.userData.school.currentYear) {
      //   this.$swal.fire(
      //     "Энэ xичээлийн жил xаагдсан тул засварлаx боломж байxгүй. Xэрэв ямар нэгэн асуудал үүссэн бол xолбоо бариарай. Бид ESIS руу xолбогдож асуудлыг шийднэ. [XARAX] товчоор ӨНГӨРСӨН жилийн дүнгийн xавтгайг xарж болно."
      //   );
      // } else if (
      //   this.selectAcademicYear == this.userData.school.currentYear &&
      //   new Date().getMonth() + 1 < 12
      // ) {
      //   this.$swal.fire(
      //     this.selectAcademicYear +
      //       "-" +
      //       (this.selectAcademicYear + 1) +
      //       " xичээлийн жилийн Үнэлгээ 2 нээгдэx болоогүй байна!"
      //   );
      // } else {
      //   this.$router.push({
      //     name: "Unelgee25Confirmations",
      //     params: {
      //       zClassGroupRefPath: prog.ref.path,
      //       selectedHalfYearId: this.selectedHalfYear.yearId,
      //     },
      //   });
      // }
      this.$router.push({
        name: "Unelgee25Confirmations",
        params: {
          zClassGroupRefPath: prog.ref.path,
          selectedHalfYearId: this.selectedHalfYear.yearId,
        },
      });
    },
    _print(prog) {
      console.log(prog.ref.path);
    },
    findMostCommonValue(obj) {
      const frequencyMap = {};
      let maxCount = 0;
      let mostCommonValue = null;

      // Iterate over the object's values (nested objects)
      Object.values(obj).forEach((nestedObj) => {
        const value = nestedObj.numberStudentsOfUnelgee;
        frequencyMap[value] = (frequencyMap[value] || 0) + 1;

        // Update the most common value
        if (frequencyMap[value] > maxCount) {
          maxCount = frequencyMap[value];
          mostCommonValue = value;
        }
      });

      return mostCommonValue;
    },
    async _setupp() {
      var filters = [];
      if (this.userData.managerScope && this.userData.managerScope.length < 3) {
        this.userData.managerScope.forEach((scope) => {
          console.log(scope);
          if (scope == 1) {
            filters.push(1, 5);
          } else if (scope == 2) {
            filters.push(6, 9);
          } else if (scope == 3) {
            filters.push(10, 12);
          }
        });
      }
      var query = this.userData.school.ref.collection(
        "departments-" + this._getSelectedAcademicYear()
      );
      console.log(
        this.userData.managerScope,
        Math.max(...filters),
        Math.min(...filters)
      );
      if (this.userData.managerScope && this.userData.managerScope.length < 3) {
        query = query
          .where("index", "<=", Math.max(...filters))
          .where("index", ">=", Math.min(...filters));
      }

      this.numberOfAllGroups = 0;
      this.loading = true;
      query
        .orderBy("index", "desc")
        .get()
        .then((docs) => {
          this.classGroups = [];
          docs.forEach(async (doc) => {
            let dep = doc.data();
            dep.ref = doc.ref;
            dep.id = doc.id;
            await dep.ref
              .collection("programs")
              .where("deleted", "==", false)
              .orderBy("name", "asc")
              .get()
              .then((docs) => {
                this.numberOfAllGroups += docs.size;
                docs.forEach((doc) => {
                  let program = doc.data();
                  program.ref = doc.ref;
                  program.id = doc.id;

                  if (
                    program[
                      "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                    ]
                  ) {
                    program[
                      "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                    ].totalLessonStudentsNumber = 0;
                    program[
                      "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                    ].approvedNumber = 0;
                    program[
                      "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                    ].inProgressNumber = 0;
                    program[
                      "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                    ].rejectedNumber = 0;
                    program[
                      "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                    ].savedNumber = 0;
                    program[
                      "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                    ].scoreAverage = 0;

                    var divideBy = 0;
                    var noStudWorkaround = 0;

                    for (var classId in program[
                      "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                    ]) {
                      if (
                        typeof program[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores"
                        ][classId] === "object"
                      ) {
                        if (
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ][classId].numberStudentsOfUnelgee
                        ) {
                          if (
                            !program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].numberOfStudents ||
                            program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].numberOfStudents <
                              program[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores"
                              ][classId].numberStudentsOfUnelgee
                          )
                            program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].numberOfStudents =
                              program[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores"
                              ][classId].numberStudentsOfUnelgee;
                        }

                        if (
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ][classId].approvedNumber
                        )
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ].approvedNumber =
                            program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].approvedNumber +
                            program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ][classId].approvedNumber;

                        if (
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ][classId].inProgressNumber
                        )
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ].inProgressNumber =
                            program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].inProgressNumber +
                            program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ][classId].inProgressNumber;

                        if (
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ][classId].rejectedNumber
                        )
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ].rejectedNumber =
                            program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].rejectedNumber +
                            program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ][classId].rejectedNumber;

                        if (
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ][classId].savedNumber
                        )
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ].savedNumber =
                            program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].savedNumber +
                            program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ][classId].savedNumber;

                        if (
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ][classId].scoreAverage
                        ) {
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ].scoreAverage =
                            program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].scoreAverage +
                            Number(
                              program[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores"
                              ][classId].scoreAverage
                            );
                          divideBy++;
                        }
                      }

                      if (
                        program[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores"
                        ][classId].numberStudentsOfUnelgee
                      ) {
                        program[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores"
                        ].totalLessonStudentsNumber =
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ].totalLessonStudentsNumber +
                          program[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ][classId].numberStudentsOfUnelgee;
                        noStudWorkaround++;
                      }
                    }

                    if (divideBy > 0) {
                      program[
                        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                      ].scoreAverage = (
                        program[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores"
                        ].scoreAverage / divideBy
                      ).toFixed(1);
                    }

                    program[
                      "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                    ].totalLessonStudentsNumber =
                      program[
                        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                      ].totalLessonStudentsNumber +
                      (program[
                        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                      ].electiveLessonNumber +
                        program[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores"
                        ].mandatoryLessonNumber -
                        noStudWorkaround) *
                        (program[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores"
                        ] &&
                        program[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores"
                        ]["numberOfStudents"]
                          ? program[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ]["numberOfStudents"]
                          : 0);

                    // console.log(program, program.STUDENT_GROUP_NAME);
                  }
                  this.classGroups.push(program);
                });
              });
          });
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this.selectAcademicYear = this.userData.school.currentYear;
    this.preText =
      this.userData.school.currentYear +
      "-" +
      (this.userData.school.currentYear + 1);

    this.selectedHalfYear = {
      yearId: this.$store.state.ClassSelectedSelectedHalfYearId,
      name:
        this.preText +
        " xичээлийн жил, " +
        this.$store.state.ClassSelectedSelectedHalfYearId +
        "-р xагас жил",
    };
    this._setupp();
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
  padding-left: 5px !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
  padding-left: 5px !important;
}

.alertRED .v-alert .v-icon {
  color: red !important;
}
</style>
