<template>
  <v-card class="my-4">
    <v-card-text>
      <v-row class="pt-2 pb-4">
        <v-col lg="8" md="8" sm="12">
          <v-alert
            color="#F8BBD0"
            class="pt-4"
            text
            prominent
            icon="mdi-information-slab-circle"
          >
            <h3 style="color: #d81b60; font-weight: normal">
              Улирлаа сонгоод ҮНЭЛЭX товчийг дарж үнэлгээгээ оруулна.
            </h3>
            <span class="grey--text"
              >Xагас жилийн үнэлгээнүүдийг Үнэлгээ 2 гэж нэрлэнэ.</span
            >
            <!-- <span style="color: #d81b60">
              2-р xагас жилийн үнэлгээ2 дүн ЖИЛИЙН ЭЦСИЙН ДҮН болж
              xадгалагдана!</span
            > -->
          </v-alert>
        </v-col>
        <v-col lg="4" md="4" sm="12">
          <!-- style="background-color: #fef7f9" -->
          <v-select
            disabled
            style="background-color: yellow"
            :items="$store.state.schoolHalfYears"
            v-model="selectedHalfYear"
            label="Xагас жил"
            return-object
            item-text="name"
            item-value="name"
          >
          </v-select>
        </v-col>
      </v-row>
      <h3
        class="mb-4 blue--text font-weight-regular text-uppercase"
        v-if="selectedHalfYear"
      >
        {{ selectedHalfYear.name }}
      </h3>
      <v-progress-linear
        v-if="loading"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear>

      <v-data-table
        class="unelgee2table"
        :headers="headerNames"
        :items="filteredLessons"
        :items-per-page="-1"
        hide-default-footer
        hide-default-header
        item-key="uniqueId"
      >
        <template slot="header">
          <tr style="font-size: 13px; background-color: #ececec !important">
            <th
              class="text-center font-weight-normal py-2"
              v-for="(header, hindex) in headerNames"
              :key="'header' + hindex"
              :style="header.style ? header.style : ''"
              :class="header.class ? header.class : ''"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:item.index="{ item }">
          <div class="text-center py-1">
            {{ item.index }}
            <span
              ><v-icon :class="item.savedToEsis ? 'green--text' : 'red--text'"
                >mdi-check-circle</v-icon
              ></span
            >
          </div>
        </template>
        <template v-slot:item.courseInfo.COURSE_NAME="{ item }">
          <div
            class="pl-2"
            style="white-space: nowrap; cursor: pointer"
            @click="_print2(item)"
          >
            {{ item.courseInfo.COURSE_NAME }}
          </div>
        </template>
        <template v-slot:item.esisLessonType="{ item }">
          <div class="text-center">
            <small
              class="text-lowercase"
              v-if="item.esisLessonType.esisLessonTypeId == 1"
              >Үндсэн</small
            >
            <small
              v-else-if="item.esisLessonType.esisLessonTypeId == 2"
              class="blue--text text-lowercase"
              >Дэд бүлэг
            </small>

            <small
              v-else-if="item.esisLessonType.esisLessonTypeId == 3"
              class="red--text text-lowercase"
              >Xолимог
            </small>

            <!-- <small
              class="blue--text text-lowercase"
              v-if="
                item.courseInfo &&
                item.courseInfo.ENROLLMENT_CATEGORY == 'MANDATORY'
              "
              >Заавал</small
            >
            <small
              v-else-if="
                item.courseInfo &&
                item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'
              "
              class="red--text text-lowercase"
              >Сонгон
            </small> -->
          </div>
        </template>
        <template v-slot:item.classGroups="{ item }">
          <span class="pl-2 blue--text text-uppercase">
            {{ _getClassGroups(item) }}</span
          >
        </template>

        <template v-slot:item.numberOfStudents="{ item }">
          <!-- <div class="text-center" v-if="item['numberOfStudents-' + teacherId]">
            {{ item["numberOfStudents-" + teacherId] }}
          </div> -->

          <div class="text-center" style="line-height: 200%">
            <span>
              {{ _getNumbers(item, "numberStudentsOfUnelgee") }}
            </span>
          </div>
        </template>
        <template v-slot:item.approvedPercentage="{ item }">
          <div
            style="line-height: 200%"
            class="text-center"
            :class="_getConfirmedProcent2(item) == '100%' ? 'green--text' : ''"
          >
            <span>
              {{ _getConfirmedProcent2(item) }}
            </span>
          </div>
        </template>
        <template v-slot:item.approvedNumber="{ item }">
          <div
            class="text-center green--text"
            style="background: #e8f5e9; line-height: 200%"
          >
            <span>
              {{ _getNumbers(item, "approvedNumber") }}
            </span>
          </div>
        </template>
        <template v-slot:item.inProgressNumber="{ item }">
          <div
            class="text-center"
            style="color: #000; background-color: #fff4d4; line-height: 200%"
          >
            <span> {{ _getNumbers(item, "inProgressNumber") }} </span>
          </div>
        </template>
        <template v-slot:item.rejectedNumber="{ item }">
          <div
            class="text-center red--text"
            style="background: #ffebee; line-height: 200%"
          >
            <span>
              {{ _getNumbers(item, "rejectedNumber") }}
            </span>
            <!-- {{
              item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId]
                ? item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId].rejectedNumber
                : 0
            }}  
         {{ getAnalystics(item, 3) }}  
            <span v-if="getAnalystics(item, 3) > 0">{{
              getAnalystics(item, 3)
            }}</span>
            <span v-else>-</span> -->
          </div>
        </template>
        <template v-slot:item.savedNumber="{ item }">
          <div
            class="text-center blue--text"
            style="line-height: 200%; background-color: #e8e8ff"
          >
            <span>
              {{ _getNumbers(item, "savedNumber") }}
            </span>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-center">
            <v-progress-linear
              v-if="item.studentLoading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>

            <small
              class="pa-1"
              @click="_doUnelgee2(item)"
              style="cursor: pointer; background-color: #a5d6a7"
              v-if="_getConfirmedProcent2(item) == '100%'"
              >Бүгд баталгаажсан</small
            >
            <!-- <small
              class="amber pa-1"
              @click="_doUnelgee2(item)"
              style="cursor: pointer"
              v-else-if="item['sentToAllManager-' + selectedHalfYear.yearId]"
              >Xянагдаж байна</small
            > -->
            <v-btn
              v-else
              class="bg-gradient-danger text-capitalize"
              x-small
              dark
              @click="_doUnelgee2(item)"
              >Баталгаажуулаx</v-btn
            >
          </div>
        </template>
        <!-- <template v-slot:item.finalGradeSavedToEsis="{ item }">
          <small class="green--text ml-2">{{
            item.finalGradeSavedToEsis ? "ESIS-д хадгалагдсан" : ""
          }}</small>
          <p
            style="font-size: 11px; line-height: 0.5"
            class="blue--text mb-2 mt-0 ml-2"
          >
            {{ item.finalGradeSavedToEsisAt | formatDate }}
          </p>
        </template>
        <template v-slot:item.finalGradeSavedToEsisAt="{ item }">
          <v-progress-linear
            v-if="!item.unelgee2Scores"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <div
            class="pl-2"
            style="width: 300px"
            v-else-if="
              item.finalGradeSavedToEsisAt &&
              item.unelgee2Scores &&
              item.unelgee2Scores.find(
                (score) =>
                  new Date(score.approvedAt.toDate()).getTime() >
                  new Date(item.finalGradeSavedToEsisAt.toDate()).getTime()
              )
            "
          >
            <small class="red--text">
              <v-icon color="red">mdi-alert</v-icon> Анхны хадгаласнаас хойш дүн
              засагдсан байна, ЭСИС-т xадгалагдаагүй байх магадлалтай. Менежер
              даxин xадгалаx товчоо дарж ЭСИС-тай тулгаарай.
            </small>
          </div>
        </template> -->
      </v-data-table>
    </v-card-text>
    <v-dialog
      v-model="showUnelgeeDialog"
      max-width="80%"
      v-if="selectedLesson"
      persistent
    >
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0">
          <v-row class="pt-4">
            <v-col lg="6" md="6" sm="6" cols="6" class="mt-1">
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                Үнэлгээ 2 - {{ selectedTeacher.school.currentYear }}
                <span class="blue--text ml-4" v-if="selectedLesson">
                  {{ selectedLesson.courseInfo.COURSE_NAME }}/
                  <span
                    v-if="
                      selectedLesson.classGroups &&
                      selectedLesson.classGroups.length > 0
                    "
                  >
                    {{ selectedLesson.classGroups[0].classGroupFullName }}
                  </span>
                </span>
              </h5>
            </v-col>
            <!-- <v-col lg="3" md="3" sm="3" cols="3" class="mt-n5">
              <v-select
                v-model="selectedGradingMethod"
                label="Бүлэг сэдвийн үнэлгээ (Үнэлгээ-I)"
                item-text="name"
                item-value="value"
                :items="gradingMethods"
              >
              </v-select>
            </v-col>
            <v-col class="justify-self-end" cols="auto">
              <v-switch
                disabled
                class="ml-2 my-0 py-0"
                color="red"
                v-model="simpleCalculationModel"
                :label="
                  simpleCalculationModel
                    ? 'Энгийн (' + simpleCalculationModel + ')'
                    : 'Ахисан (' + simpleCalculationModel + ')'
                "
              ></v-switch>
            </v-col> -->
          </v-row>
          <v-divider></v-divider>
        </div>
        <ScoreInputUnelgee25DialogManager
          class="pt-0"
          v-if="
            selectedLesson &&
            selectedLesson.students &&
            selectedLesson.unelgee2Scores &&
            selectedLesson.unelgee1Scores
          "
          v-bind="$attrs"
          :lesson="selectedLesson"
        >
        </ScoreInputUnelgee25DialogManager>
        <v-card-text
          class="mx-10 py-10 mt-4 px-4 text-center"
          style="width: 80%; overflow: hidden"
          v-if="savingUnelgee"
        >
          <p style="display: block !important" class="red--text">
            Дүн хадгалж байна. Түр хүлээнэ үү!
          </p>
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="mx-6 py-10 mt-4" v-else>
          <div
            v-if="
              selectedLesson &&
              selectedLesson.unelgee2Scores &&
              externExamsExists
            "
            class="red--text text-start"
          >
            <!-- 1230 -->
            <!-- <span v-if="checkExternGrades()">
              ТТШ шалгалт дүнгүй ({{
                selectedLesson.unelgee2Scores.filter(
                  (score) =>
                    (score.register || score.register_no) &&
                    (!score.eScore || typeof score.eScore.eScore != "number")
                ).length
              }}):
              {{
                selectedLesson.unelgee2Scores
                  .filter(
                    (score) =>
                      ((score.register || score.register_no) &&
                        !score.eScore) ||
                      typeof score.eScore.eScore != "number"
                  )
                  .map((score) => score.LAST_NAME[0] + "." + score.FIRST_NAME)
                  .join(", ")
              }}
            </span> -->
            <br />
            <span v-if="checkingStudRegister()">
              Регистргүй ({{
                selectedLesson.unelgee2Scores.filter(
                  (score) => !score.register && !score.register_no
                ).length
              }}):
              {{
                selectedLesson.unelgee2Scores
                  .filter((score) => !score.register && !score.register_no)
                  .map((score) => score.LAST_NAME[0] + "." + score.FIRST_NAME)
                  .join(", ")
              }}
            </span>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="grey" class="white--text" @click="_closeUnelgeeDialog">
            Цуцлах
          </v-btn>
          <v-btn
            @click="_saveUnelgee2"
            class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
          >
            Хадгалах
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- <v-card>
        <v-card-title>
          Dialog Title
          <v-spacer></v-spacer>
          <v-btn icon @click="showUnelgeeDialog = !showUnelgeeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <table style="height: 200px">
            <tbody>
              <tr
                v-for="(student, sIndex) in selectedLesson.students"
                :key="student.id + sIndex"
              >
                <td>{{ student.FIRST_NAME }}</td>
                <td>{{ student.LAST_NAME }}</td>
                <td>{{ student.DATE_OF_BIRTH }}</td>
                <td>---</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showUnelgeeDialog = !showUnelgeeDialog"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card> -->
    </v-dialog>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ScoreInputUnelgee25DialogManager from "./ScoreInputUnelgee25DialogManager";
const fb = require("@/firebaseConfig.js");
import axios from "axios";
import moment from "moment";

export default {
  components: {
    ScoreInputUnelgee25DialogManager,
  },
  data: () => ({
    halfYearDate: null,
    halfYear2Date: null,
    savingUnelgee: false,
    simpleCalculationModel: true,
    loadingInverval: null,
    selectedLesson: null,
    showUnelgeeDialog: false,
    students: null,
    preText: null,
    selectedHalfYear: null,
    lessons: null,
    xSelectedTab: null,
    headerNames: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
      },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "esisLessonType",
      },
      {
        text: "Бүлэг",
        align: "start",
        value: "classGroups",
        sortable: true,
      },
      {
        text: "Суралцагч",
        align: "start",
        value: "numberOfStudents",
        sortable: true,
        width: "2%",
      },
      // {
      //   text: "Дундаж дүн",
      //   align: "start",
      //   value: "scoreAverage",
      //   sortable: true,
      //   width: "2%",
      // },
      {
        text: "Гүйцэтгэл",
        align: "start",
        value: "approvedPercentage",
        sortable: true,
        width: "2%",
      },
      {
        text: "Батлагдсан",
        align: "center",
        value: "approvedNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Xяналтанд",
        align: "center",
        value: "inProgressNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Цуцлагдсан",
        align: "center",
        value: "rejectedNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Бүртгэсэн",
        align: "center",
        value: "savedNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
      // {
      //   style: "background-color: #e8f5e9",
      //   class: "green--text",
      //   text: "Дүнгүүдийг жилийн эцсийн дүнгээр",
      //   align: "start",
      //   value: "finalGradeSavedToEsis",
      // },
      // {
      //   style: "background-color: #e8f5e9",
      //   class: "green--text",
      //   text: "",
      //   align: "start",
      //   value: "finalGradeSavedToEsisAt",
      // },
    ],
    loading: false,
    studentLoading: false,
    // triggerUnelgeeDialog: false,
    totalLessonCounter: null,
    esisToken: null,
    externExamsExists: null,
    externalScoresPulled: false,
  }),
  props: {
    teacherLessons: {
      type: Array,
      required: true,
    },
    selectedTeacher: {
      type: Object,
      required: true,
    },
  },
  watch: {},
  computed: {
    ...mapState(["currentUser"]),
    ...sync("*"),
    teacherId() {
      return this.selectedTeacher.id;
    },
    filteredLessons() {
      var list = [];
      if (this.teacherLessons) list = [...this.teacherLessons];

      list.sort(
        (a, b) => a.courseInfo.PROGRAM_STAGE_ID - b.courseInfo.PROGRAM_STAGE_ID
      );
      var counter = 0;
      list.forEach((item) => {
        counter++;
        item.index = counter;
      });
      return list;
    },
  },

  methods: {
    checkingStudRegister() {
      if (
        this.selectedLesson.students.filter(
          (score) => !score.register && !score.register_no
        ).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkingExternGrades() {
      if (
        this.selectedLesson.students.filter(
          (score) =>
            (score.register || score.register_no) &&
            (!score.eScore ||
              (score.eScore && typeof score.eScore.eScore != "number"))
        ).length > 0
      ) {
        return true;
      } else {
        false;
      }
    },
    checkAllGradesDone() {
      if (
        (this.selectedLesson &&
          this.selectedLesson.unelgee2Scores &&
          this.selectedLesson.students &&
          this.selectedLesson.unelgee2Scores.find(
            (score) => score.approved == false || !score.sentToManager
          )) ||
        (this.selectedLesson.unelgee2Scores &&
          this.selectedLesson.students &&
          this.selectedLesson.unelgee2Scores.length !=
            this.selectedLesson.students.length)
      ) {
        return true;
      }
    },
    _getShortNameCodeForExternExam(lesson) {
      //1229
      var input = lesson.courseInfo.SUBJECT_AREA_ID;
      switch (input) {
        case 2:
          return "mx";
        case 3:
          return "mx";
        case 4:
          return "mx";

        case 5:
          return "mat";
        case 9:
          return "x"; //himi

        case 11:
          return "bio";

        case 12:
          return "gz";

        case 10:
          return "f"; //physics

        case 13:
          return "tuuh"; //tuuh, niigem

        case 18:
          return "tex"; //tuuh, niigem
        case 20:
          return "xj";
        case 24:
          return "ax";
        case 25:
          return "ox";
        case 194:
          return "tuuh"; // niigem
        case 195:
          return "tuuh"; // niigem

        case 196:
          return "mt";

        case 233:
          return "tex"; //ddzz
        default:
          return null;
      }
    },
    //25107
    _isAllowedForExternExams(acdemicLevel, subjectAreaId) {
      if (acdemicLevel == "6" && subjectAreaId == 3) return false; //uran zohiol
      else if (acdemicLevel == "6" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "8" && subjectAreaId == 3)
        return false; //uran zohiol
      else if (acdemicLevel == "8" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "9" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "10" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "11" && subjectAreaId == 3)
        return false; //uran zohiol
      else if (acdemicLevel == "11" && subjectAreaId == 4)
        return false; //undesnii bichig
      else return true;
    },
    async _isWithExternExam(lesson) {
      //25106
      var classGroup = lesson.classGroups[0];
      var allowed = this._isAllowedForExternExams(
        classGroup.ACADEMIC_LEVEL,
        lesson.courseInfo.SUBJECT_AREA_ID
      );
      if (lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY" && allowed) {
        // console.log(this._getShortNameCodeForExternExam(lesson));
        // var classs = lesson.classGroups[0];
        if (this.userData.school.id == "bIZDwo6lAhHPQLBfdfrX" && classGroup) {
          var qq = await this.userData.school.ref
            .collection("externexams")
            .doc(this.userData.school.currentYear.toString())
            .collection(
              this._getShortNameCodeForExternExam(lesson) +
                "-" +
                classGroup.ACADEMIC_LEVEL
            )
            .limit(1)
            .get();
          this.externExamsExists = !qq.empty;
        } else return false;
      } else return false;
    },
    _getConfirmedProcent2(item) {
      var xxx = this._getNumbers(item, "numberStudentsOfUnelgee");
      var yyy = this._getNumbers(item, "approvedNumber");
      if (xxx != "-" && yyy != "-") {
        return ((yyy / xxx) * 100).toFixed(0) + "%";
      }
      return "-";
    },
    _getNumbers(lesson, keyword) {
      var sum = null;
      if (lesson.classGroups) {
        for (const cg of lesson.classGroups) {
          if (
            lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                cg.STUDENT_GROUP_ID
            ]
          ) {
            var xx =
              lesson[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  cg.STUDENT_GROUP_ID
              ][keyword];
            sum = sum == null ? xx : sum + xx;
          }
        }
      }
      if (sum == null) return "-";
      else return sum;
    },
    _getClassGroups(lesson) {
      if (lesson.classGroups) {
        return lesson.classGroups.map((cg) => cg.classGroupFullName).join(",");
      }
      return null;
    },
    async _doUnelgee2(lesson) {
      console.log(lesson.ref.path);
      console.log(
        this.selectedTeacher.school.ref.path +
          "/unelgee2-2024-scores/" +
          lesson.id
      );
      console.log(
        this.selectedTeacher.school.ref.path +
          "/unelgee2-2024-teachers/" +
          this.selectedTeacher.id
      );
      for (const cg of lesson.classGroups) {
        console.log(cg.classGroupRef.path);
      }
      lesson.studentLoading = true;
      this.selectedLesson = lesson;
      this._isWithExternExam(lesson);
      this.createEeljitLessonToEsis(this.selectedLesson);

      if (this.selectedLesson.esisLessonType.esisLessonTypeId == 1) {
        this.selectedTeacher.school.ref
          .collection("students-" + this.selectedTeacher.school.currentYear)
          .where(
            "STUDENT_GROUP_ID",
            "==",
            lesson.classGroups[0].STUDENT_GROUP_ID
          )
          .orderBy("FIRST_NAME", "asc")
          .get()
          .then(async (docs) => {
            this.selectedLesson.students = [];
            var counter = 0;
            docs.docs.forEach((doc) => {
              let stud = doc.data();
              stud.id = doc.id;
              stud.ref = doc.ref;

              if (!stud.moved) {
                counter++;
                stud.index = counter;
                this.selectedLesson.students.push(stud);
              }
            });
            this.selectedLesson.unelgee2Scores = await this.getUnelgeeData(
              lesson
            );
          });
      } else if (this.selectedLesson.esisLessonType.esisLessonTypeId > 1) {
        var classStudents = [];
        for (var program of this.selectedLesson.classGroups) {
          await this.selectedTeacher.school.ref
            .collection("students-" + this.selectedTeacher.school.currentYear)
            .where("STUDENT_GROUP_ID", "==", program.STUDENT_GROUP_ID)
            .orderBy("FIRST_NAME", "asc")
            .get()
            .then(async (docs) => {
              console.log(docs.size);
              docs.forEach((doc) => {
                let stud = doc.data();
                stud.ref = doc.ref;
                stud.id = doc.id;
                if (!stud.moved) {
                  classStudents.push(stud);
                }
              });
            });
        }

        lesson.ref
          .collection("students-" + this.$store.state.runningSemester)
          .orderBy("FIRST_NAME", "asc")
          .get()
          .then(async (docs) => {
            this.selectedLesson.students = [];
            var counter = 0;
            docs.docs.forEach((doc) => {
              let stud = doc.data();
              stud.id = doc.id;
              stud.ref = doc.ref;
              var found = classStudents.find(
                (s) => Number(s.PERSON_ID) == Number(stud.PERSON_ID)
              );
              if (found) {
                stud.register = found.register;
                stud.register_no = found.register_no;
                counter++;
                stud.index = counter;
                this.selectedLesson.students.push(stud);
              }
            });
            this.selectedLesson.unelgee2Scores = await this.getUnelgeeData(
              lesson
            );
          });
      } else {
        this.selectedLesson.unelgee2Scores = await this.getUnelgeeData(lesson);
        this.selectedLesson.students.sort((a, b) =>
          a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
        );
        var counter = 0;
        this.selectedLesson.students.forEach((student) => {
          counter++;
          student.index = counter;
        });
      }

      await this.selectedTeacher.school.ref
        .collection("unelgee1-" + this.selectedTeacher.school.currentYear)
        .doc(String(this.selectedLesson.courseInfo.COURSE_ID))
        .collection(
          "unelgee1-" + this.selectedTeacher.school.currentYear + "-scores"
        )
        .orderBy("createdAt", "asc")
        .onSnapshot((querySnapshot) => {
          this.selectedLesson.unelgee1Scores = [];
          var counter = 0;
          // console.log(unelgee1ScoresCounter, querySnapshot.size);
          querySnapshot.forEach(async (doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            await item.examRef.get().then((doc) => {
              if (doc.exists && doc.data().deleted != true) {
                item.examDate = new Date(doc.data().examDate);
                item.deleted = false;
              } else {
                item.deleted = true;
              }
            });
            if (item.deleted == false) {
              this.selectedLesson.unelgee1Scores.push(item);
              this.$forceUpdate();
            }
            counter++;
            if (counter == querySnapshot.size) {
              setTimeout(() => {
                this.showUnelgeeDialog = true;
              }, 2000);
            }
          });
        });
      // console.log(this.selectedLesson.ref.path);
    },
    _print2(lesson) {
      console.log(
        lesson.courseInfo.SUBJECT_AREA_ID,
        lesson.courseInfo.SUBJECT_AREA_NAME
      );
      console.log(lesson, lesson.ref.path);
    },
    getAnalystics(lesson, mode) {
      let approvedNumber = 0;
      let rejectedNumber = 0;
      let inProgressNumber = 0;
      let savedNumber = 0;
      // if (lesson["analyticsSaved-" + this.selectedHalfYear.yearId]) {
      //   approvedNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.selectedTeacher.id
      //     ].approvedNumber;
      //   rejectedNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.selectedTeacher.id
      //     ].rejectedNumber;
      //   inProgressNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.selectedTeacher.id
      //     ].inProgressNumber;
      //   savedNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.selectedTeacher.id
      //     ].savedNumber;
      // } else {
      if (
        lesson.unelgee2Scores &&
        lesson.unelgee2Scores.length > 0 &&
        lesson.students &&
        lesson.students.length > 0
      ) {
        lesson.unelgee2Scores.forEach((unelgee2) => {
          let foundStud;
          foundStud = lesson.students.find(
            (student) => unelgee2.PERSON_ID == student.PERSON_ID
          );
          if (foundStud) {
            if (unelgee2.approved == true) {
              approvedNumber++;
            } else if (unelgee2.approved == false) {
              rejectedNumber++;
            } else if (unelgee2.sentToManager == true) {
              inProgressNumber++;
            } else if (
              unelgee2.sentToManager != true &&
              unelgee2.score != null &&
              unelgee2.score != undefined &&
              typeof unelgee2.score == "number"
            ) {
              savedNumber++;
            }
          }
        });
      } else if (
        lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
      ) {
        lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"];
        approvedNumber =
          lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .approvedNumber;

        inProgressNumber =
          lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .inProgressNumber;
        rejectedNumber =
          lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .rejectedNumber;
        savedNumber =
          lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .savedNumber;
      }
      // }
      if (mode == 1) {
        return approvedNumber;
      } else if (mode == 2) {
        return inProgressNumber;
      } else if (mode == 3) {
        return rejectedNumber;
      } else if (mode == 4) {
        return savedNumber;
      }
    },
    async getUnelgeeData(lesson) {
      var list = [];
      var query = this.selectedTeacher.school.ref
        .collection(
          "unelgee2-" + this.selectedTeacher.school.currentYear + "-scores"
        )
        .doc(
          this.selectedHalfYear.yearId > 1
            ? lesson.id + "-" + this.selectedHalfYear.yearId
            : lesson.id
        )
        .collection("scores");
      // console.log(query.path,"q")
      await query.get().then((docs) => {
        docs.forEach((doc) => {
          let studentScore = doc.data();
          studentScore.id = doc.id;
          studentScore.ref = doc.ref;
          if (
            lesson.students.find(
              (student) => student.PERSON_ID == studentScore.PERSON_ID
            )
          ) {
            list.push(studentScore);
          }
        });
      });
      this.$forceUpdate();
      return list;
    },
    // isAllowedLesson(lesson) {
    //   if (lesson && lesson.classGroupRefs) {
    //     for (var ll of lesson.classGroupRefs) {
    //       if (ll.path.includes("/departments/")) {
    //         lesson.isNotAllowed = true;
    //         break;
    //       }
    //     }
    //   }
    // },

    _closeUnelgeeDialog() {
      this.selectedLesson.studentLoading = false;
      this.showUnelgeeDialog = !this.showUnelgeeDialog;
    },
    async _saveUnelgee2() {
      var batch = fb.db.batch();
      this.savingUnelgee = true;

      for (var program of this.selectedLesson.classGroups) {
        var scoreLevelStrings = {
          _I: 0,
          _II: 0,
          _III: 0,
          _IV: 0,
          _V: 0,
          _VI: 0,
          _VII: 0,
          _VIII: 0,
        };
        var scoreLevelStrings2 = {
          passed: 0,
          failed: 0,
        };
        var allCompleted = true;
        for (var score of this.selectedLesson.unelgee2Scores.filter(
          (stud) =>
            Number(stud.STUDENT_GROUP_ID) == Number(program.classGroupRef.id)
        )) {
          if (
            score.scoreFinal != undefined &&
            score.scoreFinal != null &&
            typeof score.scoreFinal == "number" &&
            score.approved != null
          ) {
            score["approvedAt"] = new Date();
            score["approvedByRef"] = this.userData.ref;
            score["approvedByEmail"] = this.userData.email;
            if (score.approved == false) {
              score["rejected"] = true;
            }

            batch.update(score.ref, score);

            if (score.approved == true) {
              if (
                this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
                this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 14
              ) {
                if (score.scoreFinal > 0) {
                  scoreLevelStrings2["passed"]++;
                } else {
                  scoreLevelStrings2["failed"]++;
                }
              } else {
                if (score.scoreLevelNumber == 1) {
                  scoreLevelStrings["_I"]++;
                } else if (score.scoreLevelNumber == 2) {
                  scoreLevelStrings["_II"]++;
                } else if (score.scoreLevelNumber == 3) {
                  scoreLevelStrings["_III"]++;
                } else if (score.scoreLevelNumber == 4) {
                  scoreLevelStrings["_IV"]++;
                } else if (score.scoreLevelNumber == 5) {
                  scoreLevelStrings["_V"]++;
                } else if (score.scoreLevelNumber == 6) {
                  scoreLevelStrings["_VI"]++;
                } else if (score.scoreLevelNumber == 7) {
                  scoreLevelStrings["_VII"]++;
                } else if (score.scoreLevelNumber == 8) {
                  scoreLevelStrings["_VIII"]++;
                }
              }
            } else {
              allCompleted = false;
            }
          }
        }

        var statisticData = {
          approvedNumber: 0,
          inProgressNumber: 0,
          rejectedNumber: 0,
          savedNumber: 0,
          scoreAverage: 0,
        };

        // go through students and finalizing the inProgress, savedNumber, approvedNumber, etc.
        for (var student of this.selectedLesson.students.filter(
          (stud) =>
            Number(stud.STUDENT_GROUP_ID) == Number(program.classGroupRef.id)
        )) {
          var studentScore = this.selectedLesson.unelgee2Scores.find(
            (studentScore) => studentScore.PERSON_ID == student.PERSON_ID
          );

          if (studentScore) {
            //if score is approved by manager, we add them
            if (studentScore.approved == true) {
              statisticData.approvedNumber++;
              if (
                this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
                this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 14
              ) {
                if (studentScore.score > 0) {
                  statisticData.scoreAverage = statisticData.scoreAverage + 100;
                } else {
                  statisticData.scoreAverage =
                    statisticData.scoreAverage + studentScore.score;
                }
              } else {
                //baga angias deesh dungeer toozdog bol
                statisticData.scoreAverage =
                  statisticData.scoreAverage + studentScore.score;
              }
            } else if (studentScore.approved == false) {
              statisticData.rejectedNumber++;
            } else if (
              (studentScore.approved == undefined ||
                studentScore.approved == null) &&
              studentScore.sentToManager == true
            ) {
              statisticData.inProgressNumber++;
            } else if (
              studentScore.sentToManager != true &&
              studentScore.score != null &&
              studentScore.score != undefined &&
              typeof studentScore.score == "number"
            ) {
              statisticData.savedNumber++;
            }
          }
        }

        statisticData.scoreAverage = Number(
          (statisticData.scoreAverage / statisticData.approvedNumber).toFixed(1)
        );

        statisticData[
          "allUnelgee2ScoresConfirmed-" + this.selectedHalfYear.yearId
        ] = allCompleted;

        statisticData[
          "allUnelgee2ScoresConfirmedAt-" + this.selectedHalfYear.yearId
        ] = new Date();

        this.saveUnelgeeToLesson(statisticData, program, batch);

        if (
          this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 14
        ) {
          batch.set(
            this.selectedTeacher.school.ref
              .collection(
                "unelgee2-" +
                  this.selectedTeacher.school.currentYear +
                  "-scores"
              )
              .doc(
                this.selectedHalfYear.yearId > 1
                  ? this.selectedLesson.id + "-" + this.selectedHalfYear.yearId
                  : this.selectedLesson.id
              ),
            {
              _schoolId: this.selectedTeacher.school.id,
              _ACADEMIC_LEVEL:
                this.selectedLesson.classGroups[0].ACADEMIC_LEVEL,
              _completed100: allCompleted,
              _COURSE_ID: this.selectedLesson.courseInfo.COURSE_ID,
              passed: scoreLevelStrings2["passed"],
              failed: scoreLevelStrings2["failed"],
            },
            { merge: true }
          );
        } else {
          batch.set(
            this.selectedTeacher.school.ref
              .collection(
                "unelgee2-" +
                  this.selectedTeacher.school.currentYear +
                  "-scores"
              )
              .doc(
                this.selectedHalfYear.yearId > 1
                  ? this.selectedLesson.id + "-" + this.selectedHalfYear.yearId
                  : this.selectedLesson.id
              ),
            {
              _schoolId: this.selectedTeacher.school.id,
              _ACADEMIC_LEVEL: program.ACADEMIC_LEVEL,
              _completed100: allCompleted,
              _COURSE_ID: this.selectedLesson.courseInfo.COURSE_ID,
              _I: scoreLevelStrings["_I"],
              _II: scoreLevelStrings["_II"],
              _III: scoreLevelStrings["_III"],
              _IV: scoreLevelStrings["_IV"],
              _V: scoreLevelStrings["_V"],
              _VI: scoreLevelStrings["_VI"],
              _VII: scoreLevelStrings["_VII"],
              _VIII: scoreLevelStrings["_VIII"],
            },
            { merge: true }
          );
        }

        this.saveUnelgeeToProgram(statisticData, program, batch);
        this.saveUnelgeeToTeacher(statisticData, program, batch);
      }
      batch.commit().then(async () => {
        if (allCompleted) {
          //callxxx
        }
        this.savingUnelgee = false;
        this.$forceUpdate();
        this._closeUnelgeeDialog();
      });
    },
    async handleEsisToken() {
      var tokenDoc = await fb.db
        .doc("/_schoolTokens/" + this.selectedTeacher.school.id)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            return doc.data();
          } else {
            return null;
          }
        });

      const now = new Date();
      const yesterdayDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
      if (tokenDoc && tokenDoc.tokenTakenAt.toDate() > yesterdayDate) {
        this.esisToken = tokenDoc.token;
      } else {
        try {
          const response = await axios.post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
            {
              username: this.selectedTeacher.school._esisUserName,
              password: this.selectedTeacher.school._esisPword,
            }
          );

          if (response.status === 200 && response.data.token) {
            await fb.db
              .doc("/_schoolTokens/" + this.selectedTeacher.school.id)
              .set(
                {
                  token: response.data.token,
                  tokenTakenAt: new Date(),
                  schoolName: this.selectedTeacher.school.name,
                },
                { merge: true }
              )
              .then(() => {
                console.log("TokEN updated!!!!!");
                this.esisToken = response.data.token;
              });
          } else {
            console.error(response.data);
          }
        } catch (e) {
          console.error(e.message);
        }
      }
    },

    async createEeljitLessonToEsis(lesson) {
      if (!lesson.savedToEsis) {
        await this.handleEsisToken();
        for (var classGroup of lesson.classGroups) {
          var program = await classGroup.classGroupRef.get().then((doc) => {
            return doc.data();
          });
          const body = {
            lesson: {
              id: lesson.id,
              courseInfo: {
                COURSE_ID: lesson.courseInfo.COURSE_ID,
                SUBJECT_AREA_ID: lesson.courseInfo.SUBJECT_AREA_ID,
                ENROLLMENT_CATEGORY: lesson.courseInfo.ENROLLMENT_CATEGORY,
              },
            },
            program: {
              STUDENT_GROUP_NAME: program.STUDENT_GROUP_NAME,
              ACADEMIC_LEVEL: program.STUDENT_GROUP_ID,
              STUDENT_GROUP_ID: program.ACADEMIC_LEVEL,
              PROGRAM_OF_STUDY_ID: program.PROGRAM_OF_STUDY_ID,
            },
            school: {
              legalEntityId:
                this.selectedTeacher.school._esis_schoolInfo.legalEntityId,
              academicYear: this.selectedTeacher.school.currentYear,
            },
            teacherId: lesson.byTeachers[0].teacherId,
            token: this.esisToken,
          };
          const headers = {
            "Content-Type": "application/json",
          };
          console.log(body);

          try {
            const response = await axios.post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/createEeljitLessonEsis",
              body,
              { headers: headers }
            );
            console.log(response);
            if (response.data.res.SUCCESS_CODE === 200) {
              lesson.ref
                .update({
                  savedToEsis: true,
                  savedToEsisAt: new Date(),
                  savedToEsisData: response.data.req,
                })
                .then(() => {
                  console.log(response.data, "success");
                });
            } else {
              lesson.ref
                .update({
                  lessonSavingErrorMessage: response.data.res.RESPONSE_MESSAGE,
                })
                .then(() => {
                  console.log(response.data, "success");
                });
              // this.$forceUpdate();
            }
          } catch (e) {
            console.error(e.message);
          }
        }
      }
    },

    minimizedGradedBy(selectedTeacher) {
      var tmp = {
        DISPLAY_NAME: selectedTeacher.DISPLAY_NAME,
        EMAIL: selectedTeacher.EMAIL ? selectedTeacher.EMAIL : null,
        USERNAME: selectedTeacher.USERNAME,
        FIRST_NAME: selectedTeacher.FIRST_NAME,
        LAST_NAME: selectedTeacher.LAST_NAME,
        ref: selectedTeacher.ref,
        PERSON_ID: selectedTeacher.PERSON_ID,
      };
      return tmp;
    },
    minimizedCourseInfo(courseInfo) {
      var tmp = {
        COURSE_ID: courseInfo.COURSE_ID,
        COURSE_NAME: courseInfo.COURSE_NAME,
        ENROLLMENT_CATEGORY: courseInfo.ENROLLMENT_CATEGORY,
        GRADING_SCHEME_ID: courseInfo.GRADING_SCHEME_ID,
        PROGRAM_OF_STUDY_ID: courseInfo.PROGRAM_OF_STUDY_ID,
        PROGRAM_ELEMENT_ID: courseInfo.PROGRAM_ELEMENT_ID,
        PROGRAM_STAGE_ID: courseInfo.PROGRAM_STAGE_ID,
        SUBJECT_AREA_ID: courseInfo.SUBJECT_AREA_ID,
      };
      return tmp;
    },
    getScoreLevel(item, mode) {
      let gradePoints;
      let gradeStr;
      let tmpScore = item.score;
      if (tmpScore >= 90) {
        gradePoints = 8;
        gradeStr = "VIII";
      } else if (tmpScore >= 80) {
        gradePoints = 7;
        gradeStr = "VII";
      } else if (tmpScore >= 70) {
        gradePoints = 6;
        gradeStr = "VI";
      } else if (tmpScore >= 60) {
        gradePoints = 5;
        gradeStr = "V";
      } else if (tmpScore >= 50) {
        gradePoints = 4;
        gradeStr = "IV";
      } else if (tmpScore >= 40) {
        gradePoints = 3;
        gradeStr = "III";
      } else if (tmpScore >= 30) {
        gradePoints = 2;
        gradeStr = "II";
      } else if (tmpScore >= 0 && typeof tmpScore == "number") {
        gradePoints = 1;
        gradeStr = "I";
      } else if (
        tmpScore == null ||
        tmpScore == undefined ||
        typeof tmpScore != "number"
      ) {
        gradePoints = null;
        gradeStr = null;
      }

      if (
        item.score > 0 &&
        (this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        item.scoreLevelString = "Тооцов";
        gradePoints = 8;
      } else if (
        item.score == 0 &&
        (this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        item.scoreLevelString = "Тооцоогүй";
        gradePoints = 8;
      }

      if (mode) {
        return gradeStr;
      } else {
        return gradePoints;
      }
    },
    minimizedUnelgee1(unelgee1) {
      var list = [];
      unelgee1.forEach((score) => {
        list.push({
          examRef: score.examRef,
          score: score.score,
          scoreLevelString: score.scoreLevelString,
        });
      });
      return list;
    },
    saveUnelgeeToTeacher(statisticData, program, batch) {
      var tmp = {};

      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.selectedTeacher.school.currentYear
      ] = {};
      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.selectedTeacher.school.currentYear
      ][this.selectedLesson.id + "-" + program.classGroupRef.id] =
        statisticData;

      batch.set(
        this.selectedTeacher.school.ref
          .collection(
            "unelgee2-" + this.selectedTeacher.school.currentYear + "-teachers"
          )
          .doc(String(this.selectedTeacher.id)),
        tmp,
        { merge: true }
      );
    },
    saveUnelgeeToProgram(statisticData, program, batch) {
      var tmp = {};
      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] = {};
      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"][
        this.selectedLesson.id
      ] = statisticData;

      batch.set(program.classGroupRef, tmp, { merge: true });
    },
    saveUnelgeeToLesson(statisticData, program, batch) {
      var tmp = {};
      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          program.classGroupRef.id
      ] = statisticData;

      batch.set(this.selectedLesson.ref, tmp, { merge: true });
    },

    checkIfAllScoresExists() {
      var status = true;
      for (var student of this.selectedLesson.students) {
        if (
          student.score == null ||
          student.score == undefined ||
          typeof student.score != "number"
        ) {
          status = false;
        }
      }
      return status;
    },
  },
  created() {
    this.selectedHalfYear = this.$store.state.currentSchoolHalfYear;
    console.log(this.selectedHalfYear, "selectedHalfYear");

    var mandatoryLessonNumber = 0;
    var electiveLessonNumber = 0;

    var tmp = {};

    tmp[
      "year" +
        this.selectedHalfYear.yearId +
        "UnelgeeScores-" +
        this.selectedTeacher.school.currentYear
    ] = {};

    for (var lesson of this.teacherLessons) {
      if (lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY") {
        mandatoryLessonNumber++;
      } else {
        electiveLessonNumber++;
      }

      lesson.classGroups.forEach((classGroup) => {
        tmp[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            this.selectedTeacher.school.currentYear
        ][lesson.id + "-" + classGroup.STUDENT_GROUP_ID] =
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              classGroup.STUDENT_GROUP_ID
          ];
      });
    }

    tmp[
      "year" +
        this.selectedHalfYear.yearId +
        "UnelgeeScores-" +
        this.selectedTeacher.school.currentYear
    ].mandatoryLessonNumber = mandatoryLessonNumber;

    tmp[
      "year" +
        this.selectedHalfYear.yearId +
        "UnelgeeScores-" +
        this.selectedTeacher.school.currentYear
    ].electiveLessonNumber = electiveLessonNumber;

    console.log(tmp)
    
    this.selectedTeacher.school.ref
      .collection(
        "unelgee2-" + this.selectedTeacher.school.currentYear + "-teachers"
      )
      .doc(String(this.selectedTeacher.id))
      .update(tmp);
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        try {
          var date = val.toDate();
        } catch {
          date = val;
        }

        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
.unelgee2table td {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding: 0 !important;
}

.unelgee2table th {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding: 0 !important;
}
.scoreColumn {
  background-color: #caefff;
}
.active-tab {
  background-color: #ffc107;
  color: #ffffff;
}
</style>
