<template>
  <section class="my-0 pa-0">
    <v-card class="mx-10 px-10">
      <div class="card-header-padding pt-10 px-3">
        <v-row>
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Үнэлгээ 2 - Хагас жилийн үнэлгээ ({{
                  userData.school.currentYear
                }})
                <span class="blue--text">{{
                  userData["STUDENT_GROUP_NAME"]
                }}</span>
              </h5>
              <p v-if="students2">
                <span v-if="eeljitLessons">
                  Ээлжит хичээлийн тоо:
                  <span class="blue--text font-weight-bold">{{
                    eeljitLessons.length
                  }}</span></span
                >
              </p>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-col cols="1">
            <v-checkbox
              :disabled="disableLeaderboard"
              v-model="showLeaderBoard"
              :label="showLeaderBoard ? 'Ахисан' : 'Энгийн'"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-select
              style="background-color: yellow"
              :items="[
                {
                  yearId: 1,
                  name: preText + ' xичээлийн жил, 1-р xагас жил',
                },
                {
                  yearId: 2,
                  name: preText + ' xичээлийн жил, 2-р xагас жил',
                },
              ]"
              v-model="selectedHalfYear"
              label="Xагас жил"
              return-object
              item-text="name"
              item-value="yearId"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
      <div class="table-container" v-if="loading == false">
        <h4 class="text-typo mb-4" v-if="zaavalLessons">
          Заавал судлаx xичээлүүд (<span class="blue--text">{{
            zaavalLessons.length
          }}</span
          >)
        </h4>

        <table v-if="zaavalLessons && zaavalLessons.length > 0">
          <thead>
            <tr>
              <th class="text-center" style="width: 1%">No.</th>
              <th>Xичээлийн нэр</th>
              <th>Багшийн нэр</th>
              <th
                @click="_showLessonInfo(eLesson)"
                style="max-width: 10%"
                class="text-center"
              >
                Төрөл
              </th>
              <th class="text-center" style="width: 10%">Дүн (%)</th>
              <th class="text-center" style="width: 5%">Түвшин</th>

              <!-- <th
                v-for="(scoreLevel, sindex) in scoreLevels"
                :key="'scoreLevel-header-' + sindex"
              >
                {{ scoreLevel.name }}
              </th> -->
            </tr>
          </thead>
          <tbody v-if="zaavalLessons && zaavalLessons.length > 0">
            <tr v-for="(eLesson, sIndex) in zaavalLessons" :key="eLesson.id">
              <td class="fixed-column text-center">{{ sIndex + 1 }}</td>
              <td class="fixed-column" style="width: 25%">
                {{ eLesson.courseInfo.SUBJECT_AREA_NAME }}
              </td>
              <td style="width: 25%">
                <template>
                  <small
                    class="blue--text"
                    v-for="(teacher, teacherIndex) in eLesson.byTeachers"
                    :key="teacherIndex"
                    ><span v-if="teacherIndex > 0">, </span
                    >{{ teacher.teacherDisplayName }}</small
                  >
                </template>
              </td>
              <td style="width: 15%" class="text-center">
                <small>{{ eLesson.courseInfo.ENROLLMENT_CATEGORY_NAME }}</small>
              </td>
              <td
                class="text-center"
                :style="
                  'background-color:' +
                  eLesson.backgroundColor +
                  '!important;' +
                  'color:' +
                  eLesson.textColor
                "
              >
                <div v-if="_getScore(userData, eLesson).length > 0">
                  <div
                    v-for="(unelgee2, uindex) in _getScore(userData, eLesson)"
                    :key="'score' + uindex"
                  >
                    <small
                      v-if="typeof unelgee2.scoreFinal == 'number'"
                      :class="
                        unelgee2.lessonGroup && unelgee2.scoreFinal < 10
                          ? 'ml-2'
                          : ''
                      "
                      >{{ unelgee2.scoreFinal }}</small
                    >

                    <span
                      v-else
                      :class="unelgee2.lessonGroup ? 'ml-2 mr-1' : ''"
                      >-</span
                    >
                  </div>
                </div>
                <div v-else>-</div>
              </td>
              <td class="text-center blue--text">
                <div v-if="_getScore(userData, eLesson).length > 0">
                  <div
                    v-for="(unelgee2, uindex) in _getScore(userData, eLesson)"
                    :key="'score' + uindex"
                  >
                    <small
                      v-if="typeof unelgee2.scoreFinal == 'number'"
                      :class="
                        unelgee2.lessonGroup && unelgee2.scoreFinal < 10
                          ? 'ml-2'
                          : ''
                      "
                      >{{ unelgee2.scoreLevelString }}</small
                    >

                    <span
                      v-else
                      :class="unelgee2.lessonGroup ? 'ml-2 mr-1' : ''"
                      >-</span
                    >
                  </div>
                </div>
                <div v-else>-</div>
              </td>
            </tr>
          </tbody>
        </table>
        <v-progress-linear
          v-else
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
      </div>
      <div class="table-container">
        <h4 v-if="selectiveLessons" class="text-typo mt-10 mb-4">
          <span class="blue--text">Сонгон</span> судлаx xичээлүүд (<span
            class="blue--text"
            >{{ selectiveLessons.length }}</span
          >)
        </h4>
        <table v-if="selectiveLessons && selectiveLessons.length > 0">
          <thead>
            <tr>
              <th class="text-center" style="width: 1%">No.</th>
              <th>Xичээлийн нэр</th>
              <th>Багшийн нэр</th>
              <th
                @click="_showLessonInfo(eLesson)"
                style="max-width: 10%"
                class="text-center"
              >
                Төрөл
              </th>
              <th class="text-center" style="width: 10%">Дүн (%)</th>
              <th class="text-center" style="width: 5%">Түвшин</th>

              <!-- <th
                v-for="(scoreLevel, sindex) in scoreLevels"
                :key="'scoreLevel-header-' + sindex"
              >
                {{ scoreLevel.name }}
              </th> -->
            </tr>
          </thead>
          <tbody v-if="selectiveLessons && selectiveLessons.length > 0">
            <tr v-for="(eLesson, sIndex) in selectiveLessons" :key="eLesson.id">
              <td class="fixed-column text-center">{{ sIndex + 1 }}</td>
              <td class="fixed-column" style="width: 25%">
                {{ eLesson.courseInfo.SUBJECT_AREA_NAME }}
              </td>
              <td style="width: 25%">
                <template>
                  <small
                    class="blue--text"
                    v-for="(teacher, teacherIndex) in eLesson.byTeachers"
                    :key="teacherIndex"
                    ><span v-if="teacherIndex > 0">, </span
                    >{{ teacher.teacherDisplayName }}</small
                  >
                </template>
              </td>
              <td style="width: 15%" class="text-center">
                <small>{{ eLesson.courseInfo.ENROLLMENT_CATEGORY_NAME }}</small>
              </td>
              <td class="text-center">
                <div v-if="_getScore(userData, eLesson).length > 0">
                  <div
                    v-for="(unelgee2, uindex) in _getScore(userData, eLesson)"
                    :key="'score' + uindex"
                  >
                    <small
                      v-if="typeof unelgee2.scoreFinal == 'number'"
                      :class="
                        unelgee2.lessonGroup && unelgee2.scoreFinal < 10
                          ? 'ml-2'
                          : ''
                      "
                      >{{ unelgee2.scoreFinal }}</small
                    >

                    <span
                      v-else
                      :class="unelgee2.lessonGroup ? 'ml-2 mr-1' : ''"
                      >-</span
                    >
                  </div>
                </div>
                <div v-else>-</div>
              </td>
              <td
                class="text-center blue--text"
                :style="
                  'background-color:' + eLesson.backgroundColor + '!important'
                "
                style="color: white"
              >
                <div v-if="_getScore(userData, eLesson).length > 0">
                  <div
                    v-for="(unelgee2, uindex) in _getScore(userData, eLesson)"
                    :key="'score' + uindex"
                  >
                    <small
                      v-if="typeof unelgee2.scoreFinal == 'number'"
                      :class="
                        unelgee2.lessonGroup && unelgee2.scoreFinal < 10
                          ? 'ml-2'
                          : ''
                      "
                      >{{ unelgee2.scoreLevelString }}</small
                    >

                    <span
                      v-else
                      :class="unelgee2.lessonGroup ? 'ml-2 mr-1' : ''"
                      >-</span
                    >
                  </div>
                </div>
                <div v-else>-</div>
              </td>
            </tr>
          </tbody>
        </table>
        <v-progress-linear
          v-else-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
      </div>
    </v-card>
  </section>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { sync } from "vuex-pathify";

export default {
  components: {},
  data: () => ({
    eeljitLessons: null,
    selectedTab: 0,
    selectedStudent: null,

    currentClassGroup: null,
    loading: false,
    myClass: null,
    myDepartment: null,
    selectedDepartment: null,
    selectedClassGroup: null,
    departments: null,
    selected: [],
    excelLoading: false,
    myStudents: null,
    scoreLevels: [
      {
        name: "I",
        level: 1,
      },
      {
        name: "II",
        level: 2,
      },
      {
        name: "III",
        level: 3,
      },
      {
        name: "IV",
        level: 4,
      },
      {
        name: "V",
        level: 5,
      },
      {
        name: "VI",
        level: 6,
      },
      {
        name: "VII",
        level: 7,
      },
      {
        name: "VIII",
        level: 8,
      },
    ],
    finishedLoading: false,
    alreadySetup: false,
    selectedHalfYear: null,
    preText: null,
    showingOnlyApproved: false,
    showLeaderBoard: false,
    disableLeaderboard: true,
  }),
  props: {
    zSchool: {
      type: Object,
    },
    zClassGroup: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
    zaavalLessons() {
      if (this.eeljitLessons) {
        return this.eeljitLessons.filter(
          (ll) => ll.courseInfo.ENROLLMENT_CATEGORY != "ELECTIVE"
        );
      } else return [];
    },
    selectiveLessons() {
      if (this.eeljitLessons)
        return this.eeljitLessons.filter(
          (ll) => ll.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
        );
      else return [];
    },
    students2() {
      var list = [];
      if (this.myStudents != null) {
        var counter = 0;
        for (var item of this.myStudents) {
          if (!item.moved) {
            counter++;
            item.index = counter;
            list.push(item);
          }
        }
      }
      return list;
    },
  },
  created() {
    if (!this.school) {
      this.school = this.userData.school;
    } else {
      this.school = this.zSchool;
    }
    if (this.userData.school && this.userData.school.hasLeaderboard) {
      this.disableLeaderboard = false;
    }

    this.preText =
      this.userData.school.currentYear +
      "-" +
      (this.userData.school.currentYear + 1);
    this.selectedHalfYear = {
      yearId: 2,
      name: this.preText + " xичээлийн жил, " + 1 + "-р xагас жил",
    };

    console.log(this.school);
  },
  watch: {
    selectedHalfYear(val) {
      val.name =
        this.preText + " xичээлийн жил, " + val.yearId + "-р xагас жил";
      this._setupp();
    },
    showLeaderBoard() {
      this._setupp();
    },
    // finishedLoading(val) {
    //   if (val == true) {
    //     if (this.alreadySetup == false) this.calculateScore();
    //   }
    // },
  },
  methods: {
    _setupp() {
      this.loading = true;
      this.$forceUpdate()
      if (this.showLeaderBoard) {
        this.currentClassGroup = this.zClassGroup;
        this.school.ref
          .collection("students-" + this.school.currentYear)
          .orderBy("firstName", "asc")
          .where("STUDENT_GROUP_ID", "==", this.userData["STUDENT_GROUP_ID"])
          .onSnapshot((querySnapshot) => {
            this.myStudents = [];
            querySnapshot.forEach((doc) => {
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              item["DATE_OF_BIRTH"] = item["DATE_OF_BIRTH"]
                ? item["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "")
                : null;
              item["_I"] = 0;
              item["_II"] = 0;
              item["_III"] = 0;
              item["_IV"] = 0;
              item["_V"] = 0;
              item["_VI"] = 0;
              item["_VII"] = 0;
              item["_VIII"] = 0;

              this.myStudents.push(item);
            });
          });
      }
      var classGroupRef =
        this.userData["classGroup-" + this.userData.school.currentYear];
      this.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("classGroupRefs", "array-contains", classGroupRef)
        .onSnapshot((docs) => {
          this.eeljitLessons = [];
          var loadingCounter = 0;
          docs.forEach(async (doc) => {
            let eeljitLesson = doc.data();
            eeljitLesson.id = doc.id;
            eeljitLesson.ref = doc.ref;

            if (this.showLeaderBoard) {
              if (
                eeljitLesson &&
                eeljitLesson.byTeachers &&
                eeljitLesson.byTeachers.length > 0
              ) {
                if (eeljitLesson.esisLessonType.esisLessonTypeId == 1) {
                  var query = await this.userData.school.ref
                    .collection(
                      "unelgee2-" + this.userData.school.currentYear + "-scores"
                    )
                    .doc(
                      this.selectedHalfYear.yearId > 1
                        ? eeljitLesson.id + "-" + this.selectedHalfYear.yearId
                        : eeljitLesson.id
                    )
                    .collection("scores");

                  // if (this.showingOnlyApproved) {
                  //   query = query.where("approved", "==", true);
                  // }

                  query
                    .orderBy("scoreFinal", "desc")
                    .get()
                    .then((docs) => {
                      var counter = 0;
                      eeljitLesson.allScores = [];
                      eeljitLesson["_I"] = 0;
                      eeljitLesson["_II"] = 0;
                      eeljitLesson["_III"] = 0;
                      eeljitLesson["_IV"] = 0;
                      eeljitLesson["_V"] = 0;
                      eeljitLesson["_VI"] = 0;
                      eeljitLesson["_VII"] = 0;
                      eeljitLesson["_VIII"] = 0;
                      eeljitLesson["_students" + "_I"] = [];
                      eeljitLesson["_students" + "_II"] = [];
                      eeljitLesson["_students" + "_III"] = [];
                      eeljitLesson["_students" + "_IV"] = [];
                      eeljitLesson["_students" + "_V"] = [];
                      eeljitLesson["_students" + "_VI"] = [];
                      eeljitLesson["_students" + "_VII"] = [];
                      eeljitLesson["_students" + "_VIII"] = [];

                      docs.forEach(async (doc) => {
                        let score = doc.data();
                        score.ref = doc.ref;
                        score.id = doc.id;

                        var student = await this.userData.school.ref
                          .collection(
                            "students-" + this.userData.school.currentYear
                          )
                          .doc(String(score.PERSON_ID))
                          .get();

                        if (student.exists && student.data().moved != true) {
                          if (score.PERSON_ID == this.userData.PERSON_ID) {
                            if (
                              counter < Math.ceil(this.myStudents.length / 4)
                            ) {
                              eeljitLesson.backgroundColor = "#A5D6A7";
                              eeljitLesson.textColor = "black";
                            } else if (
                              counter >
                              this.myStudents.length -
                                Math.ceil(this.myStudents.length / 4)
                            ) {
                              eeljitLesson.backgroundColor = "#ff9595";
                              eeljitLesson.textColor = "white";
                            }
                          }
                          eeljitLesson.allScores.push(score);
                          this.$forceUpdate();
                        }
                        loadingCounter++;
                      });
                    });
                } else {
                  var query2 = await this.userData.school.ref
                    .collection(
                      "unelgee2-" + this.userData.school.currentYear + "-scores"
                    )
                    .doc(
                      this.selectedHalfYear.yearId > 1
                        ? eeljitLesson.id + "-" + this.selectedHalfYear.yearId
                        : eeljitLesson.id
                    )
                    .collection("scores");

                  // if (this.showingOnlyApproved) {
                  //   query2 = query2.where("approved", "==", true);
                  // }

                  query2
                    .orderBy("scoreFinal", "desc")
                    .get()
                    .then((docs) => {
                      eeljitLesson.allScores = [];
                      var counter = 0;
                      docs.forEach(async (doc) => {
                        let score = doc.data();
                        score.ref = doc.ref;
                        score.id = doc.id;

                        if (score.lessonGroupRef) {
                          await score.lessonGroupRef.get().then((doc) => {
                            if (doc.exists) {
                              let lessonGroup = doc.data();
                              lessonGroup.id = doc.id;
                              lessonGroup.ref = doc.ref;
                              if (
                                lessonGroup.ref.parent.parent.id ==
                                eeljitLesson.id
                              ) {
                                score.lessonGroup = lessonGroup;
                              } else {
                                score.lessonGroup = null;
                              }
                            }
                          });
                          var studentExistsInGroup;
                          if (
                            score.lessonGroup &&
                            score.lessonGroup.allStudentsSelected
                          ) {
                            studentExistsInGroup = {
                              exists: true,
                            };
                          } else if (score.lessonGroup) {
                            studentExistsInGroup = await score.lessonGroup.ref
                              .collection("students")
                              .doc(String(score.PERSON_ID))
                              .get();
                          }

                          if (
                            score.lessonGroup &&
                            studentExistsInGroup.exists
                          ) {
                            if (score.PERSON_ID == this.userData.PERSON_ID) {
                              if (
                                counter < Math.ceil(this.myStudents.length / 4)
                              ) {
                                eeljitLesson.backgroundColor = "#A5D6A7";
                                eeljitLesson.textColor = "black";
                              } else if (
                                counter >
                                this.myStudents.length -
                                  Math.ceil(this.myStudents.length / 4)
                              ) {
                                eeljitLesson.backgroundColor = "#ff9595";
                                eeljitLesson.textColor = "white";
                              }
                            }
                            eeljitLesson.allScores.push(score);
                            counter++;
                            this.$forceUpdate();
                          }
                        }
                      });
                    });
                }
                var xx = this.eeljitLessons.find(
                  (ll) => ll.id == eeljitLesson.id
                );
                if (!xx) {
                  this.eeljitLessons.push(eeljitLesson);
                }
                loadingCounter++;
              } else {
                loadingCounter++;
              }
            } else {
              if (
                eeljitLesson &&
                eeljitLesson.byTeachers &&
                eeljitLesson.byTeachers.length > 0
              ) {
                if (eeljitLesson.esisLessonType.esisLessonTypeId == 1) {
                  query = await this.userData.school.ref
                    .collection(
                      "unelgee2-" + this.userData.school.currentYear + "-scores"
                    )
                    .doc(
                      this.selectedHalfYear.yearId > 1
                        ? eeljitLesson.id + "-" + this.selectedHalfYear.yearId
                        : eeljitLesson.id
                    )
                    .collection("scores")
                    .where("FIRST_NAME", "==", this.userData["FIRST_NAME"])
                    .where("LAST_NAME", "==", this.userData["LAST_NAME"])
                    // if (this.showingOnlyApproved) {
                    //   query = query.where("approved", "==", true);
                    // }
                    .get()
                    .then((docs) => {
                      eeljitLesson.allScores = [];
                      eeljitLesson["_I"] = 0;
                      eeljitLesson["_II"] = 0;
                      eeljitLesson["_III"] = 0;
                      eeljitLesson["_IV"] = 0;
                      eeljitLesson["_V"] = 0;
                      eeljitLesson["_VI"] = 0;
                      eeljitLesson["_VII"] = 0;
                      eeljitLesson["_VIII"] = 0;
                      eeljitLesson["_students" + "_I"] = [];
                      eeljitLesson["_students" + "_II"] = [];
                      eeljitLesson["_students" + "_III"] = [];
                      eeljitLesson["_students" + "_IV"] = [];
                      eeljitLesson["_students" + "_V"] = [];
                      eeljitLesson["_students" + "_VI"] = [];
                      eeljitLesson["_students" + "_VII"] = [];
                      eeljitLesson["_students" + "_VIII"] = [];
                      docs.forEach(async (doc) => {
                        let score = doc.data();
                        score.ref = doc.ref;
                        score.id = doc.id;

                        var student = await this.userData.school.ref
                          .collection(
                            "students-" + this.userData.school.currentYear
                          )
                          .doc(String(score.PERSON_ID))
                          .get();

                        if (student.exists && student.data().moved != true) {
                          eeljitLesson.allScores.push(score);
                          this.$forceUpdate();
                        }
                        loadingCounter++;
                      });
                    });
                } else {
                  query2 = await this.userData.school.ref
                    .collection(
                      "unelgee2-" + this.userData.school.currentYear + "-scores"
                    )
                    .doc(
                      this.selectedHalfYear.yearId > 1
                        ? eeljitLesson.id + "-" + this.selectedHalfYear.yearId
                        : eeljitLesson.id
                    )
                    .collection("scores")
                    .where("FIRST_NAME", "==", this.userData["FIRST_NAME"])
                    .where("LAST_NAME", "==", this.userData["LAST_NAME"]);

                  // if (this.showingOnlyApproved) {
                  //   query2 = query2.where("approved", "==", true);
                  // }

                  query2
                    .orderBy("scoreFinal", "desc")
                    .get()
                    .then((docs) => {
                      eeljitLesson.allScores = [];
                      docs.forEach(async (doc) => {
                        let score = doc.data();
                        score.ref = doc.ref;
                        score.id = doc.id;

                        if (score.lessonGroupRef) {
                          await score.lessonGroupRef.get().then((doc) => {
                            if (doc.exists) {
                              let lessonGroup = doc.data();
                              lessonGroup.id = doc.id;
                              lessonGroup.ref = doc.ref;
                              if (
                                lessonGroup.ref.parent.parent.id ==
                                eeljitLesson.id
                              ) {
                                score.lessonGroup = lessonGroup;
                              } else {
                                score.lessonGroup = null;
                              }
                            }
                          });
                          var studentExistsInGroup;
                          if (
                            score.lessonGroup &&
                            score.lessonGroup.allStudentsSelected
                          ) {
                            studentExistsInGroup = {
                              exists: true,
                            };
                          } else if (score.lessonGroup) {
                            studentExistsInGroup = await score.lessonGroup.ref
                              .collection("students")
                              .doc(String(score.PERSON_ID))
                              .get();
                          }

                          if (
                            score.lessonGroup &&
                            studentExistsInGroup.exists
                          ) {
                            eeljitLesson.allScores.push(score);
                            this.$forceUpdate();
                          }
                        }
                      });
                    });
                }
                xx = this.eeljitLessons.find((ll) => ll.id == eeljitLesson.id);
                if (!xx) {
                  this.eeljitLessons.push(eeljitLesson);
                }
                loadingCounter++;
              } else {
                loadingCounter++;
              }
            }

            if (loadingCounter == docs.size) {
              this.loading = false;
            }
          });
        });
    },
    _showLessonInfo(eLesson) {
      var xx = null;
      for (const tt of eLesson.byTeachers) {
        xx = xx ? xx + ", " + tt.teacherDisplayName : tt.teacherDisplayName;
      }
      this.$swal({
        title: eLesson.courseInfo.COURSE_NAME,
        text: "Багш: " + xx,
      });
    },
    calculateScore() {
      this.alreadySetup = true;
      this.eeljitLessons.forEach((eLesson) => {
        this.students2.forEach((student) => {
          var scores = eLesson.allScores.filter(
            (score) => score.PERSON_ID == student.PERSON_ID
          );
          scores.forEach((score) => {
            if (score.scoreLevelNumber == 1) {
              eLesson["_I"]++;
              student["_I"]++;
              eLesson["_students" + "_I"].push(student);
            } else if (score.scoreLevelNumber == 2) {
              eLesson["_II"]++;
              student["_II"]++;
              eLesson["_students" + "_II"].push(student);
            } else if (score.scoreLevelNumber == 3) {
              eLesson["_III"]++;
              student["_III"]++;
              eLesson["_students" + "_III"].push(student);
            } else if (score.scoreLevelNumber == 4) {
              eLesson["_IV"]++;
              student["_IV"]++;
              eLesson["_students" + "_IV"].push(student);
            } else if (score.scoreLevelNumber == 5) {
              eLesson["_V"]++;
              student["_V"]++;
              eLesson["_students" + "_V"].push(student);
            } else if (score.scoreLevelNumber == 6) {
              eLesson["_VI"]++;
              student["_VI"]++;
              eLesson["_students" + "_VII"].push(student);
            } else if (score.scoreLevelNumber == 7) {
              eLesson["_VII"]++;
              student["_VII"]++;
              eLesson["_students" + "_VII"].push(student);
            } else if (score.scoreLevelNumber == 8) {
              eLesson["_VIII"]++;
              student["_VIII"]++;
              eLesson["_students" + "_VIII"].push(student);
            }
          });
        });
      });
    },
    _getScore(student, eLesson) {
      if (eLesson.allScores) {
        if (this.showingOnlyApproved) {
          var scores = eLesson.allScores.filter(
            (score) =>
              score.PERSON_ID == student.PERSON_ID && score.approved == true
          );
        } else {
          scores = eLesson.allScores.filter(
            (score) => score.PERSON_ID == student.PERSON_ID
          );
        }
        return scores;
      } else {
        return [];
      }
    },

    _getShortenName(text) {
      const words = text.split(/\s+/);
      if (words.length == 1) {
        return words[0].substring(0, 3).toUpperCase();
      } else {
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase();
        });
        const capitalizedText = capitalizedWords.join("");
        return capitalizedText;
      }
    },

    _print(item) {
      console.log(item.ref.path);
    },

    _detail() {},
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
<style>
.table-container {
  overflow-x: auto;
  max-width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 1px 4px;
  text-align: left;
  border: 1px solid #ddd;
  /* Change this to your desired border style */
}

.fixed-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
  /* Change this to your desired background color */
}
</style>
