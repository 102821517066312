<template>
  <v-app
    v-bind:style="[
      asuulga.bgDetailColor != null
        ? {
            background: asuulga.bgDetailColor.hex,
            'overflow-y': 'hidden',
            'border-color': asuulga.bgDetailColor.hex,
          }
        : {
            background: '#fff',
            'overflow-y': 'hidden',
            'border-color': '#e89e57',
          },
    ]"
    v-if="asuulga"
  >
    <template v-if="sent == false">
      <section v-if="loading == false">
        <v-container v-if="isGrid">
          <v-row no-gutters>
            <v-col
              :md="expandDisplayArea ? 12 : 10"
              :lg="expandDisplayArea ? 12 : 8"
              sm="12"
              class="mx-auto"
            >
              <v-row justify="space-between">
                <v-col>
                  <h3 class="blue--text">
                    {{ asuulga.name }}
                  </h3>
                </v-col>
                <v-col class="text-right">
                  <span class="mx-2 pa-2" style="background-color: yellow">
                    <span id="timeLeft" style="color: red"></span>
                  </span>
                </v-col>
              </v-row>
              <v-row justify="start" class="my-0">
                <v-col>
                  <h4 class="text-start" v-if="uuser">
                    Oролцогч:
                    <span v-if="uuser.firstName" class="font-weight-bold"
                      >{{ uuser.firstName }} - {{ uuser.id }}
                    </span>
                    <span v-else class="font-weight-bold">{{
                      uuser.email
                    }}</span>
                  </h4>
                </v-col>
              </v-row>
              <v-container>
                <v-row justify="end" class="mt-0" v-if="asuulga.slideUrl">
                  <v-col>
                    <v-btn
                      @click="expandDisplayArea = !expandDisplayArea"
                      color="#ececec"
                      elevation="0"
                      >Өргөн иxэсгэx</v-btn
                    ></v-col
                  >
                  <v-col class="text-end">
                    <v-btn
                      text
                      color="red"
                      @click="closeAsuulgaSlided = !closeAsuulgaSlided"
                    >
                      <span v-if="closeAsuulgaSlided">Даалгавар нээx</span>
                      <span v-else>Даалгавар xааx</span></v-btn
                    >
                  </v-col>
                </v-row>
                <div
                  v-if="closeAsuulgaSlided == false"
                  v-html="_getSlideLink(asuulga)"
                  class="mt-4"
                ></div>
              </v-container>
              <template v-if="asuulga.assignmentType > 0">
                <h4
                  style="color: red; white-space: nowrap"
                  class="font-weight-bold"
                >
                  Нийт оноо: {{ totalScoreOfAsuuglga.toFixed(2) }}
                </h4>
                <v-toolbar flat>
                  <v-tabs v-model="tab" show-arrows>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab
                      v-for="(cat, catindex) in categories"
                      :key="catindex"
                    >
                      {{ cat.name }} ({{ cat.questions.length
                      }}<span v-if="cat.totalScore"
                        >/
                        <span style="color: red; font-size: 10pt"
                          >Оноо: {{ cat.totalScore }}</span
                        ></span
                      >
                      )
                    </v-tab>
                  </v-tabs>
                </v-toolbar>
                <v-tabs-items
                  v-model="tab"
                  v-bind:style="[
                    asuulga.bgDetailColor != null
                      ? {
                          background: asuulga.bgDetailColor.hex,
                          'overflow-y': 'hidden',
                          'border-color': asuulga.bgDetailColor,
                        }
                      : {
                          background: '#fff',
                          'overflow-y': 'hidden',
                          'border-color': '#e89e57',
                        },
                  ]"
                >
                  <!-- :border="question.countAnswers > 0 ? 'left' : ''"
                  colored-border -->
                  <v-tab-item
                    v-for="(cat, catindex) in categories"
                    :key="catindex"
                  >
                    <v-row justify="end" class="mr-4 mt-0" v-if="cat.slideUrl">
                      <v-btn
                        text
                        color="red"
                        @click="closeSlided = !closeSlided"
                      >
                        <span v-if="closeSlided">нээx</span>
                        <span v-else>xааx</span></v-btn
                      >
                    </v-row>
                    <div
                      v-if="closeSlided == false"
                      v-html="_getSlideLink(cat)"
                      class="mt-4"
                    ></div>

                    <v-simple-table
                      class="asuulga-table mt-4"
                      style="height: 280px; overflow-y: scroll"
                    >
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(question, qindex) in cat.questions"
                            :key="qindex"
                          >
                            <td
                              v-if="catindex == 0"
                              style="width: 1%; background-color: #ececec"
                              class="font-weight-bold"
                            >
                              {{ qindex + 1 }}
                            </td>
                            <td
                              v-else
                              style="width: 1%; background-color: #ececec"
                              class="font-weight-bold text-capitalize"
                            >
                              {{ question.name }}
                            </td>
                            <td
                              class="pa-0"
                              v-for="answer in question.answers"
                              :key="answer.id"
                              @click="_saveAnswer(question, answer, cat)"
                              :class="
                                answer.answered == true ? 'bordercell' : ''
                              "
                            >
                              {{ answer.name }}
                              <!-- // {{ answer.correct }} -->
                              <!-- <div>
                              <v-row>
                                <v-col
                                  
                                >
                                 

                                  <div v-if="answer.score && showScores">
                                    <v-chip color="success">{{
                                      answer.score
                                    }}</v-chip>
                                  </div>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-btn icon>
                                  <v-icon
                                    small
                                    @click="_clearAnswers(question)"
                                  >
                                    mdi-delete
                                  </v-icon></v-btn
                                >
                              </v-row>
                              <v-row></v-row>
                            </div> -->
                            </td>
                            <td
                              v-if="!testingtesting"
                              @click="_clearAnswers(cat, question)"
                            >
                              <small class="red--text" style="cursor: pointer"
                                >Арилгаx</small
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>
                </v-tabs-items>
              </template>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-row no-gutters>
            <v-col md="8" offset-md="3">
              <div v-for="(cat, catindex) in categories" v-bind:key="catindex">
                <br />
                <v-btn
                  elevation="0"
                  class="py-4 font-weight-bold my-0"
                  color="primary"
                >
                  БҮЛЭГ. {{ cat.name }}</v-btn
                >
                <div>
                  <v-alert
                    class="pa-4 mt-0 mb-2"
                    color="red"
                    :border="question.countAnswers > 0 ? 'left' : ''"
                    colored-border
                    v-for="(question, qindex) in cat.questions"
                    :key="qindex"
                  >
                    <h4>
                      {{ qindex + 1 }}. {{ question.name }}
                      <span class="red--text">
                        {{ question.countAnswers }}/{{
                          question.answerLimit
                        }}</span
                      >
                    </h4>
                    <v-row justify="start">
                      <v-col
                        cols="2"
                        class="px-1"
                        v-for="answer in question.answers"
                        :key="answer.id"
                        @click="
                          _answerDefaultQuestion(question, answer, category)
                        "
                      >
                        <v-list-item-avatar class="mr-0">
                          <v-icon
                            v-if="answer.answered == true"
                            style="color: red"
                            size="22"
                          >
                            mdi-checkbox-marked-circle
                          </v-icon>
                          <v-icon v-else>
                            mdi-checkbox-blank-circle-outline
                          </v-icon>
                        </v-list-item-avatar>
                        <!-- 
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-wrap"
                            v-text="answer.name"
                          />
                        </v-list-item-content> -->

                        <!-- <v-list-item-action v-if="answer.score">
                        <p>{{ answer.score }}</p>
                      </v-list-item-action> -->
                      </v-col>
                    </v-row>
                    <!-- <v-list>
                     

                      <v-list-item style="min-height: 0px">
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap" />
                        </v-list-item-content>

                        <v-list-item-action
                          class="ma-0"
                          v-if="question.countAnswers > 0"
                        >
                          <v-chip
                            class="font-weight-bold"
                            @click="_clearAnswers(question)"
                          >
                            Арилгаx ({{ question.countAnswers }})</v-chip
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </v-list> -->
                  </v-alert>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <!-- <v-row no-gutters class="mb-16">
        <v-col md="6" offset-md="3">
          <v-btn
            color="secondary"
            class="font-weight-bold"
            @click="_sendAsuulga"
          >
            ИЛГЭЭX
          </v-btn>
        </v-col>
      </v-row> -->
      </section>
      <div
        v-else
        style="position: fixed; z-index: 10000000; top: 45%; left: 45%"
      >
        <div class="spinner-grow text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <section
        class="py-4"
        v-if="!testingtesting && asuulga.assignmentType > 0"
      >
        <v-container>
          <v-row no-gutters>
            <v-col md="6" class="mx-auto text-center col-md-8 col">
              <v-btn
                v-if="!testingtesting"
                color="red"
                class="font-weight-bold white--text"
                @click="_sendAsuulga"
              >
                ИЛГЭЭX
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <v-col class="text-center" cols="12">
        <small
          >This content is neither created nor endorsed by Asuulga.MN. Report
          Abuse - Terms of Service - Privacy Policy</small
        >
        <p>
          {{ new Date().getFullYear() }} —
          <strong>
            <a href="http://www.asuulga.mn" target="_blank"
              >Asuulga.mn</a
            ></strong
          >
        </p>
      </v-col>
    </template>

    <template v-else-if="asuulga.closed != undefined && asuulga.closed == true">
      <v-container class="mx-auto mt-16">
        <v-sheet width="500" class="mx-auto mt-16">
          <v-card class="theme--light">
            <div class="px-3 px-md-10 py-8">
              <h4 class="mb-2 text-center red--text">
                Шалгалтын xугацаа дууссан
              </h4>
            </div>
            <div
              class="py-4"
              style="background-color: #f6f9fc; font-size: 12pt"
            >
              <div class="text-center">
                <span class="grey--text text--darken-1">@2021</span>
              </div>
            </div>
          </v-card>
        </v-sheet>
      </v-container>
    </template>

    <template v-else>
      <v-container class="mt-16">
        <v-row justify="center">
          <v-card color="#ececec" max-width="500" class="px-10 mx-4">
            <div class="px-3 px-md-10 py-8">
              <h3 class="mb-2 text-center green--text">
                Та шалгалтаа амжилттай өглөө!
              </h3>
              <div class="text-center font-weight-bold">
                {{ uuser.firstName
                }}<span v-if="uuser.lastName">, {{ uuser.lastName }}</span>
              </div>
            </div>
            <div
              class="py-4"
              style="background-color: #ececec; font-size: 12pt"
            >
              <div class="text-center">
                <v-btn
                  text
                  @click="resetUserData()"
                  class="bg-gradient-success"
                  dark
                >
                  <strong>Буцаx</strong></v-btn
                >
              </div>
            </div>
          </v-card>
        </v-row>
      </v-container>
    </template>
    <v-dialog v-model="doRegister" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span>Бүртгүүлэx</span>
        </v-card-title>
        <v-card-text>
          <small style="color: red"
            >Овог, нэр, утас, xөтөлбөр бүx талбарыг сонгосноор нэвтрэx
            боломжтой.</small
          >
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="firstName" label="Нэр" />
              </v-col>
            </v-row>
            <v-row v-if="!isAnonymous">
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="lastName" label="Овог" />
              </v-col>
            </v-row>
            <v-row v-if="!isAnonymous">
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="phone" label="Утасны дугаар" />
              </v-col>
            </v-row>

            <v-row v-if="!isAnonymous">
              <v-col cols="12" sm="12" md="12">
                <v-radio-group v-model="typeOfHutolbor">
                  <v-radio label="Магистрын xөтөлбөр" :value="1"></v-radio>
                  <v-radio label="Докторын xөтөлбөр" :value="2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row v-if="typeOfHutolbor != null">
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="hutolborSelected"
                  :items="typeOfHutolbor == 1 ? magister : doctors"
                  :label="
                    typeOfHutolbor == 1
                      ? 'Магистрийн xөтөлбөр'
                      : 'Докторын xөтөлбөр'
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-btn color="red" class="white--text" @click="_startAsuulga"
                  >Эxлэx</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);
const fb = require("../../../firebaseConfig.js");
export default {
  data: () => ({
    expandDisplayArea: false,
    school: null,
    closeAsuulgaSlided: false,
    initDate: false, //setting date
    closeSlided: false,
    isAnonymous: true,
    typeOfHutolbor: null,
    hutolborSelected: null,
    sent: false,
    showScores: false,
    firstName: null,
    lastName: null,
    phone: null,
    isGrid: true,
    doRegister: false,
    tab: null,

    asuulga: null,
    categories: null,
    prePath: null,
    loading: false,
    uuser: {},
    bgDetailColor: "#ececec",

    rules: [],
    magister: [
      "Бизнесийн удирдлага",
      "Боловсрол судлал",
      "Боловсролын удирдлага",
      "Гадаад хэл шинжлэл",
      "Гэр бүл судлал",
      "Хэлшинжлэл",
      "Монгол судлал",
      "Нийгмийн ажил, халамж үйлчилгээ",
      "Төрийн захиргааны менежмент",
      "Нягтлан бодох бүртгэл",
      "Олон улс, орон судлал",
      "Олон улсын харилцаа",
    ],
    doctors: [
      "Бизнесийн удирдлага",
      "Боловсрол судлал",
      "Боловсролын удирдлага",
      "Гадаад хэл шинжлэл",
      "Хэлшинжлэл",
      "Нийтийн удирдлага",
      "Нягтлан бодох бүртгэл",
      "Олон улс, орон судлал",
      "Соёл судлал",
      "Эрх зүй",
    ],
    currentTime: null,
    currentMinutes: null,
    ZdefaultMinutes: 1,
    answers: null,
  }),
  props: {
    shalgaltIdd: {
      type: String,
      required: true,
    },
    noRegister: {
      type: Boolean,
      default: true,
    },
    testingtesting: {
      default: false,
      type: Boolean,
    },
    isTesting: {
      type: Boolean,
      default: false,
    },
    fromEYESH: {
      type: Boolean,
      default: false,
    },
    backRouterPath: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
    totalScoreOfAsuuglga() {
      var score = 0;
      if (this.categories) {
        for (var i = 0; i < this.categories.length; i++) {
          if (this.categories[i].totalScore)
            score = score + this.categories[i].totalScore;
        }
      }
      return score; //categories
    },
  },
  created() {
    if (this.userData.ppschool) {
      fb.db
        .doc(this.userData.ppschool)
        .get()
        .then((doc) => {
          let school = doc.data();
          school.id = doc.id;
          school.ref = doc.ref;
          this.school = school;
        });
    } else {
      this.school = null;
    }

    var _this = this;
    var xxx = null;
    this.loading = true;
    if (this.noRegister) {
      this.uuser = Object.assign({}, this.userData);
      console.log("kk", this.userData);
    }
    if (this.fromEYESH == "true") {
      this.fromEYESH = true;
    } else if (this.fromEYESH == "false") {
      this.fromEYESH = false;
    }
    console.log("pp", this.userData, this.uuser);

    if (this.fromEYESH) {
      fb.dbeyesh800
        .doc(this.shalgaltIdd)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            this.asuulga = doc.data();
            this.asuulga.ref = doc.ref;
            this.asuulga.id = doc.id;

            var z = await this.asuulga.ref
              .collection("starting-times")
              .doc(this.userData.id)
              .get();

            if (z.exists && z.data().reset) {
              this.asuulga.ref
                .collection("starting-times")
                .doc(this.userData.id)
                .delete();
              xxx = new Date().getTime();
              const interval_id = window.setInterval(function () {},
              Number.MAX_SAFE_INTEGER);

              // Clear any timeout/interval up to that id
              for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
              }
            } else if (z.exists) {
              xxx = z.data().startingTime;
            } else {
              xxx = new Date().getTime();
            }

            if (fb.EYESH_APP) {
              this.asuulga.ref
                .collection("starting-times")
                .doc(this.userData.id)
                .set({
                  startingTime: xxx,
                  firstName: this.userData.firstName
                    ? this.userData.firstName
                    : "",
                  lastName: this.userData.lastName
                    ? this.userData.lastName
                    : "",
                  studentRef: this.userData.ref,
                  schoolId: this.userData.school
                    ? this.userData.school.id
                    : null,
                });
            } else {
              this.asuulga.ref
                .collection("starting-times")
                .doc(this.userData.id)
                .set({
                  startingTime: xxx,
                  firstName: this.userData.firstName
                    ? this.userData.firstName
                    : "",
                  lastName: this.userData.lastName
                    ? this.userData.lastName
                    : "",
                  schoolId: this.userData.school
                    ? this.userData.school.id
                    : null,
                });
            }

            await this.asuulga.ref
              .collection("currently-answering")
              .doc(this.userData.id)
              .collection("categories")
              .get()
              .then((docs) => {
                this.answers = [];
                docs.forEach((doc) => {
                  let cat = doc.data();
                  doc.ref
                    .collection("questions")
                    .get()
                    .then((docs) => {
                      docs.forEach((doc) => {
                        let question = doc.data();
                        question.id = doc.id;
                        doc.ref
                          .collection("answers")
                          .get()
                          .then((docs) => {
                            docs.forEach((doc) => {
                              let answer = doc.data();
                              answer.question = question;
                              answer.category = cat;
                              answer.id = doc.id;
                              this.answers.push(answer);
                            });
                          });
                      });
                    });
                });
              });

            if (
              this.asuulga.bgDetailColor != null &&
              this.asuulga.bgDetailColor !== undefined
            ) {
              this.bgDetailColor = this.asuulga.bgDetailColor;
            }
            await this._findCategories();

            if (this.asuulga.duration && this.asuulga.duration > 0)
              _this.ZdefaultMinutes = this.asuulga.duration;

            var countDownDate = new Date(
              xxx + _this.ZdefaultMinutes * 60000
            ).getTime();

            var x = setInterval(function () {
              var now = new Date().getTime();
              var distance = countDownDate - now;
              var hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              var minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
              );
              var seconds = Math.floor((distance % (1000 * 60)) / 1000);
              // Output the result in an element with id="demo"
              if (document.getElementById("timeLeft")) {
                document.getElementById("timeLeft").innerHTML =
                  hours + " цаг " + minutes + " минут " + seconds + " секунд ";

                // If the count down is over, write some text
                if (distance < 0) {
                  clearInterval(x);
                  document.getElementById("timeLeft").innerHTML = "Дууссан";
                  _this.sendAutoAsuulga();
                }
              }
            }, 1000);
          } else {
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    } else {
      fb.db
        .doc(this.shalgaltIdd)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            this.asuulga = doc.data();
            this.asuulga.ref = doc.ref;
            this.asuulga.id = doc.id;

            var z = await this.asuulga.ref
              .collection("starting-times")
              .doc(this.userData.id)
              .get();

            if (z.exists && z.data().reset) {
              this.asuulga.ref
                .collection("starting-times")
                .doc(this.userData.id)
                .delete();
              xxx = new Date().getTime();
              const interval_id = window.setInterval(function () {},
              Number.MAX_SAFE_INTEGER);

              // Clear any timeout/interval up to that id
              for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
              }
            } else if (z.exists) {
              xxx = z.data().startingTime;
            } else {
              xxx = new Date().getTime();
            }

            if (fb.EYESH_APP) {
              this.asuulga.ref
                .collection("starting-times")
                .doc(this.userData.id)
                .set({
                  startingTime: xxx,
                  studentRef: this.userData.ref,
                  schoolId: this.userData.school
                    ? this.userData.school.id
                    : null,
                });
            } else {
              this.asuulga.ref
                .collection("starting-times")
                .doc(this.userData.id)
                .set({
                  startingTime: xxx,
                  studentRef: this.userData.ref,
                  schoolId: this.userData.school
                    ? this.userData.school.id
                    : null,
                });
            }

            await this.asuulga.ref
              .collection("currently-answering")
              .doc(this.userData.id)
              .collection("categories")
              .get()
              .then((docs) => {
                this.answers = [];
                docs.forEach(async (doc) => {
                  let cat = doc.data();
                  await doc.ref
                    .collection("questions")
                    .get()
                    .then((docs) => {
                      docs.forEach(async (doc) => {
                        let question = doc.data();
                        question.id = doc.id;
                        await doc.ref
                          .collection("answers")
                          .get()
                          .then((docs) => {
                            docs.forEach((doc) => {
                              let answer = doc.data();
                              answer.question = question;
                              answer.category = cat;
                              answer.id = doc.id;
                              this.answers.push(answer);
                            });
                          });
                      });
                    });
                });
              });

            if (
              this.asuulga.bgDetailColor != null &&
              this.asuulga.bgDetailColor !== undefined
            ) {
              this.bgDetailColor = this.asuulga.bgDetailColor;
            }
            await this._findCategories();

            if (this.asuulga.duration && this.asuulga.duration > 0)
              _this.ZdefaultMinutes = this.asuulga.duration;

            var countDownDate = new Date(
              xxx + _this.ZdefaultMinutes * 60000
            ).getTime();

            var x = setInterval(function () {
              var now = new Date().getTime();
              var distance = countDownDate - now;
              var hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              var minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
              );
              var seconds = Math.floor((distance % (1000 * 60)) / 1000);
              // Output the result in an element with id="demo"
              if (document.getElementById("timeLeft")) {
                document.getElementById("timeLeft").innerHTML =
                  hours + " цаг " + minutes + " минут " + seconds + " секунд ";

                // If the count down is over, write some text
                if (distance < 0) {
                  clearInterval(x);
                  document.getElementById("timeLeft").innerHTML = "Дууссан";
                  _this.sendAutoAsuulga();
                }
              }
            }, 1000);
          } else {
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    }

    this.startTime();
    this.loading = false;
  },
  methods: {
    getAnswer(cat, question, answer) {
      if (this.answers)
        var found = this.answers.find(
          (answr) =>
            answr.category.name == cat.name &&
            answr.question.name == question.name &&
            answr.name == answer.name
        );
      if (found) {
        return true;
      } else {
        return false;
      }
    },
    _saveAnswer(question, answer, category) {
      if (!question.skipped) {
        if (category.abc) {
          question.answers.forEach((answr) => {
            answr.answered = false;
          });
          this.asuulga.ref
            .collection("currently-answering")
            .doc(this.userData.id)
            .collection("categories")
            .doc(category.id)
            .collection("questions")
            .doc(question.id)
            .collection("answers")
            .get()
            .then((docs) => {
              docs.forEach((doc) => {
                doc.ref.delete();
              });
            });
        }
        this.asuulga.ref
          .collection("currently-answering")
          .doc(this.userData.id)
          .collection("categories")
          .doc(category.id)
          .set({
            name: category.name,
            createdAt: new Date(),
          })
          .then(() => {
            this.asuulga.ref
              .collection("currently-answering")
              .doc(this.userData.id)
              .collection("categories")
              .doc(category.id)
              .collection("questions")
              .doc(question.id)
              .set({
                name: question.name,
                createdAt: new Date(),
              })
              .then(() => {
                this.asuulga.ref
                  .collection("currently-answering")
                  .doc(this.userData.id)
                  .collection("categories")
                  .doc(category.id)
                  .collection("questions")
                  .doc(question.id)
                  .collection("answers")
                  .doc(answer.name)
                  .set({
                    name: answer.name,
                    createdAt: new Date(),
                    answered: true,
                  })
                  .then(() => {
                    answer.answered = true;
                  });
              });
          });
      } else {
        alert("Энэ даалгаварыг xассан. Xариулаxгүй орxиж болно!");
      }
    },
    startTime() {
      const today = new Date();
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();
      m = this.checkTime(m);
      s = this.checkTime(s);
      this.currentTime = h + ":" + m + ":" + s;
      this.currentMinutes = this.currentMinutes + 1;
      setTimeout(this.startTime, 1000);
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      } // add zero in front of numbers < 10
      return i;
    },
    _getSlideLink(cat) {
      var xx = null;
      if (cat.slideUrl) {
        if (cat.slideUrl.includes("docs.google.com")) {
          var hh = "500px";
          if (this.asuulga.assignmentType == -1) hh = "60vh";
          xx = cat.slideUrl.replace(
            "<iframe ",
            "<iframe style='width:100%!important;height:" +
              hh +
              "!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation'"
          );
        } else {
          xx =
            "<iframe style='width:100%!important;height:500px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation' src='" +
            cat.slideUrl.replace(
              "/view?usp=sharing",
              "/preview?embedded=true"
            ) +
            "' </iframe>";
        }
      }
      return xx;
    },

    getStudentAnswer(question, aindex, cat, answer) {
      if (cat.categoryScoreRequired) {
        cat.studentKeyss[aindex] = question.name;
        answer.answered = !answer.answered;
      } else {
        if (question.answerLimit == 1)
          question.answers.forEach((xanswer) => {
            if (xanswer.answered) {
              xanswer.answered = false;
            }
          });
        answer.answered = !answer.answered;
      }
    },
    _startAsuulga() {
      console.log(this.firstName);
      if (this.isAnonymous && this.firstName) {
        this.doRegister = false;
        this.uuser.email = null;
        this.uuser.phone = null;
        this.uuser.firstName = this.firstName;
        this.uuser.lastName = null;
        this.uuser.id = this.firstName;
      } else {
        if (
          this.firstName != null &&
          this.lastName != null &&
          this.phone != null &&
          this.phone.length == 8 &&
          this.hutolborSelected != null &&
          this.typeOfHutolbor != null
        ) {
          this.doRegister = false;
          this.uuser.email = this.phone;
          this.uuser.phone = this.phone;
          this.uuser.firstName = this.firstName;
          this.uuser.lastName = this.lastName;
          this.uuser.id = this.phone;
        }
      }
    },
    _getTotalScore(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;
        for (var question of cat.questions) {
          question.questionScored = 0;
          for (var answer of question.answers) {
            if (answer.answered) {
              question.questionScored = question.questionScored + answer.score;
            }
          }
          cat.catScored = cat.catScored + question.questionScored;
        }
        scored = scored + cat.catScored;
      }
      return scored;
    },
    _findQ(questions, i) {
      console.log(questions, i);
      // for(var qq of questions){
      //   if(qq.answered&&)
      // }
    },
    _listToString(llist) {
      var x = "";
      if (llist) {
        llist.forEach((el) => {
          x = x + el;
        });
      }
      return x;
    },
    _getTotalScore2(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;
        var x2;
        if (cat.groupsEnabled) {
          var failedGroups = [];

          for (var yquestion of cat.questions) {
            var answered = yquestion.answers.filter(
              (answer) => answer.answered
            );
            if (answered.length == 1) {
              for (var yanswer of yquestion.answers) {
                if (yanswer.answered == true && yanswer.correct == false) {
                  failedGroups.push(yquestion.assignedGroup);
                }
              }
            } else {
              failedGroups.push(yquestion.assignedGroup);
            }
          }
          if (cat.availableGroups) {
            for (var group of cat.availableGroups) {
              var failed = failedGroups.find(
                (failedgroup) => failedgroup == group.name
              );

              if (cat.groupsScorePerQuestion) {
                if (failed) {
                  var failedQuestions = cat.questions.filter(
                    (question) => question.assignedGroup == failed
                  );
                  var correctCounter = 0;
                  for (var qquestion of failedQuestions) {
                    var qansweredQuestions = qquestion.answers.filter(
                      (answer) => answer.answered
                    );
                    if (qansweredQuestions && qansweredQuestions.length == 1) {
                      for (var qanswer of qquestion.answers) {
                        if (qanswer.answered && qanswer.correct) {
                          correctCounter++;
                        }
                      }
                    }
                  }
                  if (correctCounter > 0) {
                    cat.catScored =
                      cat.catScored +
                      group.score * (correctCounter / failedQuestions.length);
                  }
                } else {
                  cat.catScored = cat.catScored + group.score;
                }
              } else if (failed == undefined) {
                cat.catScored = cat.catScored + group.score;
              }
            }
          }
        } else if (
          cat.categoryScoreRequired &&
          cat.givingScorePerQuestion == false
        ) {
          console.log(this.asuulga.createdAt.toDate(), new Date("2023-01-12"));
          if (this.asuulga.createdAt.toDate() > new Date("2023-01-12")) {
            x2 = "";
            for (var xquestion of cat.questions) {
              var answeredQuestions = xquestion.answers.filter(
                (answer) => answer.answered
              );
              if (answeredQuestions && answeredQuestions.length == 1) {
                for (var xanswer of xquestion.answers) {
                  if (xanswer.answered && xanswer.correct) {
                    x2 = x2 + xanswer.name + xquestion.name.toLowerCase();
                  }
                }
              }
            }
            console.log(cat.keyString, x2);
            if (cat.keyString == x2) {
              cat.catScored = cat.totalScore;
            }
          } else {
            var x1 = this._listToString(cat.keyss);
            x2 = this._listToString(cat.studentKeyss);

            if (x1 == x2) {
              cat.catScored = cat.totalScore;
            }
          }
        } else {
          for (var question of cat.questions) {
            question.questionScored = 0;
            var answeredQuestionss = question.answers.filter(
              (answer) => answer.answered
            );
            if (answeredQuestionss && answeredQuestionss.length == 1) {
              for (var answer of question.answers) {
                if (answer.answered && answer.correct) {
                  question.questionScored =
                    question.questionScored + question.score;
                }
              }
            }
            cat.catScored = cat.catScored + question.questionScored;
          }
        }

        scored = scored + cat.catScored;
      }
      return scored;
    },
    async sendAutoAsuulga() {
      var time;
      console.log("xxx", this.uuser);
      if (fb.EYESH_APP == false) {
        if (this.uuser["classGroup-" + this.school.currentYear])
          this.uuser["classGroup-" + this.school.currentYear] =
            this.uuser["classGroup-" + this.school.currentYear].path;
        if (this.uuser["department-" + this.school.currentYear])
          this.uuser["department-" + this.school.currentYear] =
            this.uuser["department-" + this.school.currentYear].path;

        this.uuser.ref = this.userData.ref.path;

        if (this.uuser.school)
          this.uuser.school.ref = this.uuser.school.ref.path;
      }
      await this.asuulga.ref
        .collection("starting-times")
        .doc(this.userData.id)
        .get()
        .then((doc) => {
          if (doc.exists) time = doc.data().startingTime;
        });
      var allScored = this._getTotalScore2(this.categories);
      var sendData = {
        shalgaltId: this.asuulga.id,
        shalgaltRef: this.asuulga.ref,
        answererRef: null,
        answererId: this.uuser.email ? this.uuser.email : this.uuser.id,
        categories: this.categories,
        sendAt: fb.firestore.FieldValue.serverTimestamp(),
        answeredAt: fb.firestore.FieldValue.serverTimestamp(),
        startedAt: new Date(time),
        totalScore: allScored,
        answerer: this.uuser,
        deepdataanalyzed: false,
      };
      var sendDataSimplified = {
        shalgaltId: this.asuulga.id,
        shalgaltRef: this.asuulga.ref,
        answererRef: null,
        answererId: this.uuser.email ? this.uuser.email : this.uuser.id,
        categories: this._getSimplified(this.categories),
        sendAt: fb.firestore.FieldValue.serverTimestamp(),
        answeredAt: fb.firestore.FieldValue.serverTimestamp(),
        startedAt: new Date(time),
        totalScore: allScored,
        answerer: this.uuser,
        deepdataanalyzed: false,
      };
      if (this.school) {
        if (fb.EYESH_APP) {
          sendData.schoolRef = this.school.ref;
          sendDataSimplified.schoolRef = this.school.ref;
        } else {
          sendData.schoolRef = this.school.ref.path;
          if (sendData.answerer.schoolRef)
            sendData.answerer.schoolRef = sendData.answerer.schoolRef.path;
          sendDataSimplified.schoolRef = this.school.ref.path;
        }
        sendData.schoolName = this.school.name;
        sendDataSimplified.schoolName = this.school.name;
      } else {
        sendData.schoolName = null;
        sendData.schoolRef = null;
        sendDataSimplified.schoolName = null;
        sendDataSimplified.schoolRef = null;
      }
      this.asuulga.ref
        .collection("results-simplified")
        .doc(this.userData.id)
        .set(sendDataSimplified, { merge: true })
        .then(() => {
          this.asuulga.ref
            .collection("results")
            .doc(this.userData.id)
            .set(sendData, { merge: true })
            .then(() => {
              if (this.isTesting == false) this._deepanalyze(sendData);
              this.sent = true;
            })
            .catch(function () {});

          this.asuulga.ref
            .collection("starting-times")
            .doc(this.userData.id)
            .delete();
        });
    },

    async _deepanalyze(result) {
      var batch;
      if (this.fromEYESH) {
        batch = fb.dbeyesh800.batch();
      } else {
        batch = fb.db.batch();
      }
      var categories = result.categories;
      var category = categories[0];
      if (category && category.catScored) {
        for (var question of category.questions) {
          for (var answer of question.answers) {
            if (answer.answered) {
              var x = {
                questionIndex: question.index,
                answerName: answer.name,
                answered: true,
              };
              var yy = {};
              yy[x.answerName] = fb.firestore.FieldValue.increment(1);
              yy.questionIndex = question.index;
              if (answer.correct) yy.correctAnswer = answer.name;

              var refRef = this.asuulga.ref
                .collection("daalgavar-analyzed")
                .doc(question.index.toString());

              batch.set(refRef, yy, { merge: true });
            } else {
              if (answer.correct) {
                var yy2 = {};
                yy2.questionIndex = question.index;
                yy2.correctAnswer = answer.name;
                var refRef2 = this.asuulga.ref
                  .collection("daalgavar-analyzed")
                  .doc(question.index.toString());

                batch.set(refRef2, yy2, { merge: true });
              }

              //yyy.push({index:counter,name:answer.name, answered:false})
            }
          }
        }
        batch.commit().then(() => {
          console.log("donexxx");

          this.asuulga.ref
            .collection("results-simplified")
            .doc(this.userData.id)
            .update({ deepAnalyzed: true })
            .then(() => {
              console.log("deeepAnalyzed");
            });
          if (
            this.asuulga.deepAnalyzed == undefined ||
            this.asuulga.deepAnalyzed == false
          )
            this.asuulga.ref.update({ deepAnalyzed: true });
        });
      }
    },

    async _sendAsuulga() {
      console.log(this.uuser);
      this.$swal({
        title: "Сорил илгээxийг xүсэж байна уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var time;
          if (fb.EYESH_APP == false) {
            if (this.uuser["classGroup-" + this.school.currentYear])
              this.uuser["classGroup-" + this.school.currentYear] =
                this.uuser["classGroup-" + this.school.currentYear].path;
            else this.uuser["classGroup-" + this.school.currentYear] = null;
            if (this.uuser["department-" + this.school.currentYear])
              this.uuser["department-" + this.school.currentYear] =
                this.uuser["department-" + this.school.currentYear].path;
            else this.uuser["department-" + this.school.currentYear] = null;
            if (this.uuser.ref) this.uuser.ref = this.uuser.ref.path;
            if (this.uuser.school && this.uuser.school.ref)
              this.uuser.school.ref = this.uuser.school.ref.path;
            delete this.uuser.school.adminRef;
          }
          await this.asuulga.ref
            .collection("starting-times")
            .doc(this.userData.id)
            .get()
            .then((doc) => {
              if (doc.exists) time = doc.data().startingTime;
            });

          var allScored = this._getTotalScore2(this.categories);
          var sendData = {
            shalgaltId: this.asuulga.id,
            shalgaltRef: this.asuulga.ref,
            answererRef: null,
            answererId: this.uuser.email ? this.uuser.email : this.uuser.id,
            categories: this.categories,
            sendAt: fb.firestore.FieldValue.serverTimestamp(),
            answeredAt: fb.firestore.FieldValue.serverTimestamp(),
            startedAt: new Date(time),
            totalScore: allScored,
            answerer: this.uuser,
            deepdataanalyzed: false,
          };
          var sendDataSimplified = {
            shalgaltId: this.asuulga.id,
            shalgaltRef: this.asuulga.ref,
            answererRef: null,
            answererId: this.uuser.email ? this.uuser.email : this.uuser.id,
            categories: this._getSimplified(this.categories),
            sendAt: fb.firestore.FieldValue.serverTimestamp(),
            answeredAt: fb.firestore.FieldValue.serverTimestamp(),
            startedAt: new Date(time),
            totalScore: allScored,
            answerer: this.uuser,
            deepdataanalyzed: false,
          };

          if (this.school) {
            if (fb.EYESH_APP) {
              sendData.schoolRef = this.school.ref;
              sendDataSimplified.schoolRef = this.school.ref;
            } else {
              sendData.schoolRef = this.school.ref.path;
              if (sendData.answerer.schoolRef)
                sendData.answerer.schoolRef = sendData.answerer.schoolRef.path;
              sendDataSimplified.schoolRef = this.school.ref.path;
            }
            sendData.schoolName = this.school.name;
            sendDataSimplified.schoolName = this.school.name;
          } else {
            sendData.schoolName = null;
            sendData.schoolRef = null;
            sendDataSimplified.schoolName = null;
            sendDataSimplified.schoolRef = null;
          }
          console.log(sendData, sendDataSimplified);
          this.asuulga.ref
            .collection("results-simplified")
            .doc(this.userData.id)
            .set(sendDataSimplified, { merge: true })
            .then(() => {
              this.asuulga.ref
                .collection("results")
                .doc(this.userData.id)
                .set(sendData, { merge: true })
                .then(() => {
                  if (this.isTesting == false) this._deepanalyze(sendData);
                  this.sent = true;
                })
                .catch(function () {});

              this.asuulga.ref
                .collection("starting-times")
                .doc(this.userData.id)
                .delete();
            });
        }
      });

      // var yes = confirm("Сорил илгээxийг xүсэж байна уу?");
    },
    resetUserData() {
      //todo121
      fb.db
        .doc(
          this.userData.ref.path ? this.userData.ref.path : this.userData.ref
        )
        .get()
        .then(async (doc) => {
          let userData = doc.data();
          userData.ref = doc.ref;
          userData.id = doc.id;
          this.$store.state.userData = userData;
        })
        .then(() => {
          if (this.userData.role == "student" && this.backRouterPath) {
            console.log(this.backRouterPath, "backRouterPath");
            this.$router.replace(this.backRouterPath).then(() => {
              location.reload();
            });
            // if (this.asuulga.schoolIndex) {
            //   this.$router
            //     .replace("/pages/dashboards/studentsindexedsorils")
            //     .then(() => {
            //       location.reload();
            //     });
            // } else {
            //   this.$router
            //     .replace("/pages/dashboards/studentssorils")
            //     .then(() => {
            //       location.reload();
            //     });
            // }
          } else if (this.backRouterPath) {
            this.$router.replace(this.backRouterPath).then(() => {
              location.reload();
            });
          } else {
            this.$router
              .replace("/pages/dashboards/pagesorilassignmentsbygrandit")
              .then(() => {
                location.reload();
              });
          }
        });
    },
    _getSimplified(categories) {
      var xxCategories = [];
      for (var category of categories) {
        if (category)
          var categoryTMP = {
            catScored: category.catScored,
            totalScore: category.totalScore ? category.totalScore : 0,
          };
        xxCategories.push(categoryTMP);
      }
      return xxCategories;
    },
    _clearAnswers(category, question) {
      for (var answer of question.answers) {
        answer.answered = false;
      }
      question.countAnswers = 0;
      console.log(category, question.id);
      this.asuulga.ref
        .collection("currently-answering")
        .doc(this.userData.id)
        .collection("categories")
        .doc(category.id)
        .collection("questions")
        .doc(question.id)
        .collection("answers")
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            doc.ref.delete().then(() => {
              console.log("..deleting");
            });
          });
        });
    },
    _answerOpenQuestionInput(question) {
      console.log(question.answeredText);
    },
    _answerDefaultQuestion(question, answer) {
      console.log(question.ref.path, question.answerLimit);

      answer.answered = !answer.answered;
    },
    _getQuestionsOfCategory(cat) {
      this.asuulga.ref
        .collection("categories")
        .doc(cat.id)
        .collection("questions")
        .orderBy("name", "asc")

        .get()
        .then((querySnapshot) => {
          cat.questions = [];
          querySnapshot.forEach(async (doc) => {
            let question = doc.data();
            question.category = cat.name;
            // question.statData = null;
            // question.datacollection = null;

            question.id = doc.id;
            question.ref = doc.ref;
            question.countAnswers = 0;
            // question.currentAnswerActive = false;
            // question.answeredCount = null;
            // question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH

            //console.log(doc.ref);

            await question.answers.forEach(async (answer) => {
              answer.answered = this.getAnswer(cat, question, answer);
            });
            cat.questions.push(question);
            if (this.selectedQuestion == null) this.selectedQuestion = question;
            // }
          });
        });
    },
    async _findCategories() {
      this.asuulga.ref
        .collection("categories")
        .orderBy("name", "asc")
        .get()
        .then((querySnapshot) => {
          this.categories = [];
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.questions = [];
            if (cat.categoryScoreRequired) {
              cat.studentKeyss = [];
              for (var i = 0; i < 10; i++) {
                cat.studentKeyss[i] = 0;
              }
            }
            this._getQuestionsOfCategory(cat);
            this.categories.push(cat);

            if (this.categories.length === 1) {
              this.category = this.categories[0];
            }
          });
        });
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}

.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}

.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}

.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
