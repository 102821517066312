<template>
  <v-container class="my-6" fluid>
    <v-card icon="mdi-clipboard-text" icon-small color="accent">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">df</h5>
        <v-btn
          class="bg-gradient-success"
          @click="
            scoreTextBulk = null;
            showExternExamScoreInputDialog = true;
          "
          small
          dark
        >
          Extern TEST
        </v-btn>
        <v-select
          :items="classes"
          v-model="selectedClassGroup"
          label="kdjfjsdf"
        >
        </v-select>
      </div>
      <v-progress-linear
        v-if="loading"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear>
      <v-tabs v-if="selectedLessons" v-model="selectedLesson">
        <v-tab v-for="(ll, llIndex) in selectedLessons" :key="llIndex"
          >{{ ll.name }} - {{ selectedLesson }}</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="selectedLesson">
        <v-tab-item
          v-for="(ll, llIndex) in selectedLessons"
          :key="'body' + llIndex"
        >
          <h1>
            kjdfkjsdjk <span v-if="ll.scores">{{ ll.scores.length }}</span>
          </h1>
          <v-data-table
            style="height: 500px; overflow-y: auto"
            v-if="ll.scores"
            class="elevation-1"
            :items="ll.scores"
            :headers="headerNames"
            :search="search"
            hide-default-footer
            :items-per-page="-1"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.index }}</td>
                <td>{{ props.item.eFIRST_NAME }}</td>
                <td>{{ props.item.eRegister }}</td>
                <td>{{ props.item.eScore }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog
      scrollable
      v-model="showExternExamScoreInputDialog"
      max-width="60%"
      v-if="showExternExamScoreInputDialog"
    >
      <v-card class="py-4" height="680">
        <v-card-title class="text-h5">dfsdfsf</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap red--text">
                Нэгж xичээлийн сэдвүүдээ энд xуулна.
              </p>
              <v-text-field v-model="docName" autofocus label="MX">
              </v-text-field>
              <v-textarea
                v-model.trim="scoreTextBulk"
                label="scoreTextBulk"
                placeholder="Write something..."
                rows="15"
                outlined
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              scoreTextBulk = null;
              showExternExamScoreInputDialog = !showExternExamScoreInputDialog;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveBulk"
            >Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  data: () => ({
    selectedScores: null,
    selectedLesson: null,
    selectedLessons: null,
    selectedClassGroup: null,
    search: "",
    externexams: null,
    docName: null,
    scoreTextBulk: null,
    showExternExamScoreInputDialog: false,
    loading: false,
    errorMessage: null,
    students: null,
    classes: [4, 5, 6, 7, 8, 9],
    lessonNames: ["mx", "mat", "ax"],
  }),
  computed: {
    ...sync("*"),
  },
  async created() {
    try {
      //
    } catch (error) {
      console.error("Error fetching subcollections:", error);
    }
  },
  mounted() {
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "eRegister",
        align: "start",
        sortable: true,
        value: "eRegister", //className
      },
      {
        text: "eScore",
        align: "start",
        sortable: true,
        value: "eScore", //className
      },
    ];
  },
  watch: {
    selectedLesson(val) {
      console.log(val);
      var lesson = this.selectedLessons[val];
      console.log(lesson);
      this._getScores(lesson);
    },
    selectedClassGroup(val) {
      if (val) {
        this.selectedLessons = [];
        this.lessonNames.forEach((lName) => {
          var colName = lName + "-" + this.selectedClassGroup;
          let lesson = {
            name: colName,
            scores: null,
            notShow: false,
          };
          this.selectedLessons.push(lesson);
        });
        this.selectedLesson = 0;
      }
    },
  },
  methods: {
    _getScores(lesson) {
      var qq = this.userData.school.ref
        .collection("externexams")
        .doc(String(this.userData.school.currentYear))
        .collection(lesson.name);

      qq.get().then((docs) => {
        lesson.scores = [];
        var counter = 0;
        console.log(docs, "docs");
        if (!docs.empty) {
          docs.forEach((doc) => {
            counter++;
            let ex = doc.data();
            ex.id = doc.id;
            ex.ref = doc.ref;
            ex.index = counter;
            lesson.scores.push(ex);
          });
          this.$forceUpdate();
        } else {
          lesson.notShow = true;
        }
      });
    },
    async _saveBulk() {
      var batch = fb.db.batch();
      if (
        this.scoreTextBulk != null &&
        this.scoreTextBulk != "" &&
        this.docName &&
        this.docName.length > 0
      ) {
        var arrayOfLines = this.scoreTextBulk.match(/[^\r\n]+/g);
        console.log(arrayOfLines.length, "lll");
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          for (const value of arrayOfLines) {
            var lineArray = value.split(/[\t\s]+/);
            if (lineArray && lineArray.length >1) {
              var qq = this.userData.school.ref
                .collection("externexams")
                .doc("2024")
                .collection(this.docName.trim());
              // /schools/bIZDwo6lAhHPQLBfdfrX/
              var regId = await qq
                .doc(lineArray[1].trim().toLowerCase())
                .get();
              // console.log(regId.ref.path,regId.exists);
              if (regId.exists) {
                let dd = regId.data();
                let newScore = lineArray.length==3&&lineArray[2]?parseInt(lineArray[2].trim().replace(".0%", "")):null
               
                if ((dd.eScore == 0 && newScore > 0) || !dd.eScore) {
                  // console.log("newS0",lineArray[0].toLowerCase().trim(),lineArray[1].toLowerCase().trim(),newScore,lineArray.length )
                  batch.set(
                    regId.ref,
                    {
                      eClass: lineArray[0].trim(),
                      // eFIRST_NAME: lineArray[1].trim(),
                      eRegister: lineArray[1].toLowerCase().trim(),
                      eScore: newScore,
                    },
                    { merge: true }
                  );
                }else{
                    console.log(dd,"dd")
                }
              } else {
                let newScore = lineArray.length==3&&lineArray[2]?parseInt(lineArray[2].trim().replace(".0%", "")):null
                console.log("newS",lineArray[0].toLowerCase().trim(),lineArray[1].toLowerCase().trim(),lineArray[2],lineArray.length )
                batch.set(
                  regId.ref,
                  {
                    eClass: lineArray[0].toLowerCase().trim(),
                    // eFIRST_NAME: lineArray[1].trim(),
                    eRegister: lineArray[1].toLowerCase().trim(),
                    eScore: newScore,
                  },
                  { merge: true }
                );
              }
            }
          }
          // //
          batch.commit().then(() => {
            console.log(this.docName, "done!");
            this.docName = null;
            this.scoreTextBulk = null;
            this.showExternExamScoreInputDialog =
              !this.showExternExamScoreInputDialog;
          });
        }
      }
    },
  },
};
</script>
