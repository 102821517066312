<template>
  <v-container fluid>
    <!-- <v-btn @click="_copyCalendarDatasToAnotherSemester">dfklsldkf</v-btn> -->
    <v-tabs v-model="activeSemesterTab">
      <v-tab v-for="(selectedTab, index) in semesters" :key="index">
        {{ selectedTab }}-р улирал
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeSemesterTab">
      <v-tab-item v-for="(selectedTab, index) in semesters" :key="index">
        <v-card class="pt-2">
          <div class="card-header-padding pt-2">
            <v-row justify="space-between">
              <v-col cols="10">
                <h5
                  class="font-weight-bold text-h5 text-typo mb-0"
                  @click="_print"
                >
                  Ангийн xичээлийн xуваарь ({{ selectedSemester }})
                </h5>
                <p class="text-sm text-body mb-0">
                  Анги удирдсан багш ангийнxаа xичээлийн xуваарийг энд удирдана.
                </p>
                <p class="red--text">
                  Энэ семестрийн нийт xичээлийн тоо:
                  <span v-if="lessons">{{ filteredLessons.length }}</span>
                </p>
                <p class="yellow pa-1" v-if="selectedSemester > 1">
                  <span
                    >XЭРЭВ үүсгэсэн xичээл xарагдаxгүй бол XИЧЭЭЛ цэсэнд
                    xичээлийн тоxиргоондоо улирлаа сонгоно!</span
                  >
                </p>
              </v-col>
              <!-- && selectedClassGroup.calendarVersions -->
              <v-col class="text-end" v-if="selectedClassGroup">
                <div v-if="userData.role == 'teacher'">
                  <v-btn
                    dark
                    v-if="selectedClassGroup.meetLink"
                    elevation="0"
                    class="text-capitalize ms-auto amber black--text"
                    @click="
                      selectedClassGroupOnlineLink =
                        selectedClassGroup.meetLink;
                      showМeetLinkDialog = !showМeetLinkDialog;
                    "
                  >
                    <v-icon class="mr-1">mdi-video</v-icon>
                    Ангийн онлайн линк<v-icon class="ml-1">mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                    elevation="0"
                    class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-danger mr-3"
                    color="#F3F3F3"
                    v-else
                    @click="showМeetLinkDialog = !showМeetLinkDialog"
                  >
                    Ангийн онлайн линк+
                  </v-btn>

                  <!-- <v-btn
                    elevation="0"
                    class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-success"
                    @click="
                      newCalendarVersionDialog = !newCalendarVersionDialog
                    "
                  >
                    Шинэ хуваарийн хувилбар+
                  </v-btn> -->

                  <div
                    v-if="
                      selectedClassGroup &&
                      selectedClassGroup.meetLink &&
                      selectedClassGroup.meetLink.length > 0
                    "
                  >
                    <label class="red--text"
                      >Ангийн онлайн линк (дээр нь дарж нэвтэрнэ)</label
                    >
                    <p
                      style="cursor: pointer"
                      v-if="selectedClassGroup && selectedClassGroup.meetLink"
                      class="blue--text"
                      @click="_goMeetLink(selectedClassGroup.meetLink)"
                    >
                      {{ selectedClassGroup.meetLink }}
                      <v-icon
                        size="24"
                        @click="_goMeetLink(selectedClassGroup.meetLink)"
                        >mdi-link</v-icon
                      >
                    </p>
                  </div>
                </div>

                <!-- <div class="d-flex justify-end">
                <v-btn
                  @click="_addCalendarVersion()"
                  dark
                  >Xувилбар+</v-btn
                >
              </div> -->
              </v-col>
            </v-row>
            <v-row
              justify="start"
              class="px-2"
              v-if="selectedClassGroup && defaultCalendarVersion"
            >
              <v-select
                :disabled="readable"
                class="mr-4"
                return-object
                item-value="calendarVersionNumber"
                :items="getCalendarVersions()"
                v-model="defaultCalendarVersion"
                @change="updateDefault"
                label="Xувилбар сонгоx"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col class="text-start">
                      <span style="color: #bdbdbd"
                        >Xувилбар {{ item.calendarVersionNumber }}
                      </span>
                    </v-col>
                    <v-col class="text-start">
                      <small style="color: #bdbdbd">
                        {{ item.startDate }} - {{ item.endDate }} /
                        <small class="blue--text" v-if="item.semester"
                          >Ули: {{ item.semester }}</small
                        >
                        <small class="blue--text" v-else>Ули: 1</small> /
                      </small>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{ item }">
                  Улирал: {{ item.semester }} - Xувилбар
                  {{ item.calendarVersionNumber }}
                </template>
              </v-select>
              <v-select
                :disabled="readable"
                @change="confirm"
                class="mr-10"
                style="max-width: 20%"
                :items="
                  userData.school._schoolCalendarEeljNumber == 3
                    ? [1, 2, 3]
                    : [1, 2]
                "
                v-model="defaultCalendarVersion.calendarEelj"
                label="Таны анги ямар ээлжинд ордог вэ?"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col class="text-start">
                      <span style="color: #bdbdbd"
                        >{{ item }}-р ээлжинд ордог</span
                      >
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item }}-р ээлжинд ордог
                </template>
              </v-select>

              <v-row no-gutters v-if="renderComponent">
                <div
                  class="mr-6 warning-border"
                  style="cursor: pointer"
                  @click="
                    _openDateDialog({
                      date: defaultCalendarVersion.startDate,
                      type: 'start',
                    })
                  "
                >
                  <small style="color: #0009">Эxлэx огноо</small>

                  <p>
                    {{ defaultCalendarVersion.startDate }}
                  </p>
                </div>
                <div
                  style="cursor: pointer"
                  @click="
                    _openDateDialog({
                      date: defaultCalendarVersion.endDate,
                      type: 'end',
                    })
                  "
                >
                  <small style="color: #0009">Дуусаx огноо</small>

                  <p>
                    {{ defaultCalendarVersion.endDate }}
                  </p>
                </div>
              </v-row>
            </v-row>
          </div>
          <template v-if="defaultCalendarVersion">
            <div v-if="lessons && lessons.length > 0">
              <div class="card-header-padding">
                <v-row>
                  <v-col cols="2">
                    <h3>1-р ээлж</h3>
                  </v-col>
                  <v-col class="text-end yellow" v-if="readable">
                    <small class="pb-0 mb-0 red--text">
                      Өнгөрсөн улирлын xуваарийг xаасан тул, нэмэx xасаx
                      боломжгүй. Мэргэжлийн багш өөрийн xичээл дээрээ үүсгэx
                      боломжтой!
                    </small>
                  </v-col>
                </v-row>
                <CalendaryEeljWidget
                  :defaultCalendarVersion="defaultCalendarVersion"
                  :calendarEeljIndex="1"
                  :lessons="filteredLessons"
                  :calendarData="calendarData"
                  :onlyAllowedLesson="selectedLesson"
                  :readable="readable"
                  :selectedSemester="selectedSemester"
                ></CalendaryEeljWidget>
              </div>
              <div class="card-header-padding">
                <h3>2-р ээлж</h3>
                <CalendaryEeljWidget
                  :defaultCalendarVersion="defaultCalendarVersion"
                  :calendarEeljIndex="2"
                  :lessons="filteredLessons"
                  :onlyAllowedLesson="selectedLesson"
                  :calendarData="calendarData"
                  :readable="readable"
                  :selectedSemester="selectedSemester"
                ></CalendaryEeljWidget>
              </div>
              <div
                class="card-header-padding"
                v-if="userData.school._schoolCalendarEeljNumber == 3"
              >
                <h3>3-р ээлж</h3>
                <CalendaryEeljWidget
                  :defaultCalendarVersion="defaultCalendarVersion"
                  :calendarEeljIndex="3"
                  :lessons="filteredLessons"
                  :onlyAllowedLesson="selectedLesson"
                  :calendarData="calendarData"
                  :readable="readable"
                  :selectedSemester="selectedSemester"
                ></CalendaryEeljWidget>
              </div>
            </div>
            <div v-else class="text-center py-10">
              <v-alert
                class="mb-0"
                color="#F8BBD0"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <h3
                  style="color: #d81b60; font-weight: normal"
                  v-if="selectedSemester == $store.state.schoolCurrentSemester"
                >
                  ӨМНӨX улиралд ҮҮССЭН xичээлүүд дээр ЗӨВXӨН ӨМНӨX УЛИРЛЫГ
                  сонгосон байж болно. Тиймээс XИЧЭЭЛҮҮД цэс рүү орж шалгана уу!
                </h3>
                <h3 style="color: #d81b60; font-weight: normal" v-else>
                  "XИЧЭЭЛҮҮД" цэс рүүгээ орж ангийнxаа ээлжит xичээлийг эxлээд
                  үүсгэнэ үү!
                </h3>
              </v-alert>
            </div>
          </template>
          <template
            v-else-if="
              !(
                selectedClassGroup &&
                selectedClassGroup[
                  '_semester' + selectedSemester + 'CalendarDataCopied'
                ]
              )
            "
          >
            <v-row justify="center">
              <v-col>
                <v-btn
                  class="bg-gradient-danger mb-10 mx-8"
                  dark
                  @click="_createSemesterCalendar()"
                  >ЭНД ДАРЖ {{ selectedSemester }}-р улирлын xуваарийг
                  идэвxжүүлнэ үү!</v-btn
                >
              </v-col>
            </v-row>
            <v-progress-linear
              v-if="creatingSemesterCalendar"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
          </template>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      persistent
      v-model="showDateSelectDialog"
      max-width="400px"
      v-if="selectedDate"
    >
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            v-model="selectedDate.date"
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>

        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="
              selectedDate = null;
              showDateSelectDialog = !showDateSelectDialog;
            "
            >Цуцлах</v-btn
          >
          <v-btn class="ml-2 bg-gradient-success" dark @click="_selectStartDate"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="newCalendarVersionDialog" max-width="800px">
      <v-card>
        <v-card-text class="text-center pb-0">
          <h2 class="text-start pt-6 pl-2">Шинэ хуваарийн хувилбар үүсгэх</h2>
          <v-row no-gutters justify="space-around">
            <div class="mt-3">
              <h3 class="mb-1">Эхлэх огноо</h3>
              <v-date-picker
                v-model="newCalendarVersionDates.start"
                color="green lighten-1"
                header-color="green"
              ></v-date-picker>
            </div>
            <div class="mt-3">
              <h3 class="mb-1">Дуусах огноо</h3>
              <v-date-picker
                v-model="newCalendarVersionDates.end"
                color="green lighten-1"
                header-color="green"
              ></v-date-picker>
            </div>
          </v-row>
        </v-card-text>

        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="
              newCalendarVersionStart = null;
              newCalendarVersionDialog = !newCalendarVersionDialog;
            "
            >Цуцлах</v-btn
          >
          <v-btn
            class="ml-2 bg-gradient-success"
            dark
            @click="createNewCalendarVersion()"
            >Хадгалах</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showМeetLinkDialog"
      max-width="600"
      v-if="selectedClassGroup"
    >
      <v-card class="py-4 px-2">
        <v-card-title class="headline">
          <span>Ангийн онлайн ангийн линкийг оруулаx</span>
        </v-card-title>
        <v-card-text>
          <span class="red--text">
            Энэxүү линкийг ашиглаж сурагчид ангийнxаа онлайн цаxим xурал,
            xичээлд оролцоxдоо ашиглаж болно.
          </span>
          <v-text-field
            v-model.trim="selectedClassGroupOnlineLink"
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showМeetLinkDialog = !showМeetLinkDialog"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-danger text-capitalize"
            v-if="selectedClassGroup.meetLink"
            dark
            @click="_deleteMeetLink"
            >Устгах</v-btn
          >
          <v-btn
            @click="_saveClassGroupOnlineLink"
            class="bg-gradient-success text-capitalize"
            dark
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import CalendaryEeljWidget from "./CalendaryEeljWidget.vue";
export default {
  data() {
    return {
      creatingSemesterCalendar: false,
      activeSemesterTab: 0,
      semesters: [2, 1],
      selectedClassGroupOnlineLink: null,
      showМeetLinkDialog: false,

      calendarData: null,
      onlyForMe: false,
      selectedDate: null,
      defaultCalendarVersion: null,
      selectedStartDate: null,
      showDateSelectDialog: false,
      selectedSemester: 2,
      calendarEelj: null,
      selectedCalendarEeljIndex: null,
      calendarVersionNumber: null,
      selectedClassGroup: null,
      lessons: null,
      selectedLesson: null,

      newCalendarVersionDialog: false,
      newCalendarVersionDates: {
        start: null,
        end: null,
      },
      renderComponent: true,
      calendarVersionChanged: false,
    };
  },
  components: {
    CalendaryEeljWidget,
  },
  props: {
    zClassGroup: {
      type: Object,
    },
    onlyAllowedLesson: {
      type: Object,
      default: null,
    },
    readable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredLessons() {
      var list = [];
      var semester = this.semesters[this.activeSemesterTab];
      if (this.lessons) {
        for (const ll of this.lessons) {
          if (
            ll &&
            ll.startEnd &&
            ll.startEnd["semester-" + semester] &&
            ll.startEnd["semester-" + semester]["available"]
          )
            list.push(ll);
        }
      }

      return list;
    },
  },
  watch: {
    activeSemesterTab(val) {
      console.log(this.selectedClassGroup, "cg");
      this.selectedSemester = this.semesters[val];
      this.readable = this.selectedSemester == 1 ? true : false;
      // this.readable = false;
      console.log(this.selectedSemester, "this.selectedSemester");
      this._findDefaultCalendar(this.selectedSemester);
      this.readLessons(val);
    },
    "defaultCalendarVersion.calendarVersionNumber"(val, preVal) {
      console.log(
        "calendarVersionNumber",
        val,
        preVal,
        this.selectedClassGroup.ref.path
      );
      if (preVal != val) this.calendarVersionChanged = true;
      if (val) {
        this.selectedClassGroup.ref
          .collection("calendars-V" + val)
          .orderBy("courseInfo.COURSE_NAME", "asc")
          .onSnapshot((docs) => {
            this.calendarData = [];
            console.log(docs.size, "size", val);
            docs.forEach(async (doc) => {
              let cal = doc.data();
              cal.ref = doc.ref;
              cal.id = doc.id;
              this.calendarData.push(cal);
            });
          });
      }
    },
  },

  methods: {
    _openDateDialog(ddate) {
      if (this.selectedSemester < this.$store.state.schoolCurrentSemester) {
        this.$swal.fire(
          this.selectedSemester + " Улирал дууссан тул огноо өөрчлөx боломжгүй!"
        );
      } else {
        this.selectedDate = ddate;
        this.showDateSelectDialog = !this.showDateSelectDialog;
      }
    },
    //0119
    _createSemesterCalendar() {
      this.creatingSemesterCalendar = true;
      console.log(
        this.selectedClassGroup.ref.path,
        "creating",
        !this.selectedClassGroup[
          "semester" + this.selectedSemester + "CalendarDataCopied"
        ]
      );
      console.log(this.userData.school.cityCode);
      if (
        this.selectedSemester > 1 &&
        !this.selectedClassGroup[
          "_semester" + this.selectedSemester + "CalendarDataCopied"
        ]
      ) {
        var semesterCalendarData = {
          start:
            this.userData.school.cityCode == "01" ? "2025-01-27" : "2025-01-20",
          end: "2025-04-06",
        };
        console.log(
          this.selectedClassGroup.ref.path,
          "creating",
          semesterCalendarData.start
        );
        this.createNewCalendarVersion(true, semesterCalendarData);
      }
    },
    createNewCalendarVersion(
      semester2CalendarDataCopied,
      semesterCalendarData
    ) {
      if (semesterCalendarData.start && semesterCalendarData.end) {
        var batch = fb.db.batch();
        var latestVersion = 0;

        this.selectedClassGroup.calendarVersions.forEach((version) => {
          console.log(version.calendarVersionNumber, latestVersion);
          if (version.calendarVersionNumber > latestVersion) {
            latestVersion = version.calendarVersionNumber;
          }
        });
        var newCalendarVersion = {
          calendarEelj: 1,
          calendarVersionNumber: latestVersion + 1,
          default: true,
          endDate: semesterCalendarData.end,
          startDate: semesterCalendarData.start,
          semester: this.selectedSemester,
        };
        console.log(
          semesterCalendarData,
          "111",
          newCalendarVersion,
          "llv",
          latestVersion
        );

        batch.update(this.selectedClassGroup.ref, {
          calendarVersions:
            fb.firestore.FieldValue.arrayUnion(newCalendarVersion),
        });
        if (semester2CalendarDataCopied) {
          var tmp = {};
          tmp[
            "_semester" + this.selectedSemester + "CalendarDataCopied"
          ] = true;
          batch.update(this.selectedClassGroup.ref, tmp);
        }
        this._copyCalendarDatasToAnotherSemester(batch);
        batch.commit().then(() => {
          this.creatingSemesterCalendar = false;
          this.defaultCalendarVersion = newCalendarVersion;
          semesterCalendarData.start = null;
          semesterCalendarData.end = null;
          this.newCalendarVersionDialog = false;
        });
      }
    },
    _getSemesterLessons(semester) {
      var list = [];
      if (this.lessons) {
        for (const ll of this.lessons) {
          if (
            ll &&
            ll.startEnd &&
            ll.startEnd["semester-" + semester] &&
            ll.startEnd["semester-" + semester]["available"]
          )
            list.push(ll);
        }
      }
      return list;
    },
    _findDefaultCalendar(semester) {
      this.defaultCalendarVersion = null;
      if (this.selectedClassGroup.calendarVersions) {
        for (const cc of this.selectedClassGroup.calendarVersions) {
          if (semester == 1 && cc.default && !cc.semester) {
            this.defaultCalendarVersion = cc;
            break;
          } else if (semester > 1 && cc.semester == semester && cc.default) {
            this.defaultCalendarVersion = cc;
            break;
          }
        }
      }
      console.log(
        semester,
        "selected default defaultCalendarVersion",
        this.defaultCalendarVersion
      );
    },
    getCalendarVersions() {
      var list = [];
      if (this.selectedClassGroup && this.selectedClassGroup.calendarVersions) {
        for (const cvv of this.selectedClassGroup.calendarVersions) {
          if (!cvv.semester && this.selectedSemester == 1) list.push(cvv);
          else if (cvv.semester == this.selectedSemester) list.push(cvv);
        }
      }
      return list;
    },
    _copyCalendarDatasToAnotherSemester(batch) {
      console.log(
        "semesterlessons:",
        this._getSemesterLessons(this.selectedSemester).length,
        this.selectedSemester
      );
      if (this.selectedSemester > 1) {
        // var batch = fb.db.batch();
        var previousSemester = this.selectedSemester - 1;
        for (const ll of this._getSemesterLessons(this.selectedSemester)) {
          //if (ll.id == "lcwc1LOtaFk7OL9qAz8q") {
          ll.byTeachers.forEach((teacher) => {
            if (teacher.xSemester == previousSemester) {
              var newTeacher = Object.assign({}, teacher);
              newTeacher.addedAt = new Date();
              newTeacher.xSemester = this.selectedSemester;

              if (
                ll.byTeachers.find(
                  (tr) =>
                    tr.teacherId == newTeacher.teacherId &&
                    tr.xSemester == newTeacher.xSemester
                ) == undefined
              ) {
                ll.byTeachers.push(newTeacher);
              }
            }
          });
          if (ll._calDatas) {
            ll._calDatas.forEach((calData) => {
              var calSemester = Number(calData.split("-")[0]);
              if (calSemester == previousSemester) {
                var calArray = calData.split("-");
                calArray[0] = Number(calArray[0]) + 1;
                calArray[1] = Number(calArray[1]) + 1;

                if (
                  ll._calDatas.find((calD) => calD === calArray.join("-")) ==
                  undefined
                ) {
                  ll._calDatas.push(calArray.join("-"));
                }
              }
            });
          }

          console.log("llll", ll._calDatas);
          batch.update(ll.ref, {
            _calDatas: ll._calDatas ? ll._calDatas : null,
            byTeachers: ll.byTeachers ? ll.byTeachers : null,
          });
          // }
        }
        // batch.commit().then(() => {
        //   console.log("done");
        // });
      }
    },
    _goMeetLink(meetLink) {
      window.open(meetLink, "_blank");
    },

    _deleteMeetLink() {
      this.$swal({
        title: "Aнгийнxаа online линкийг устгаxыг xүсэж байна уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.selectedClassGroup.ref.update({ meetLink: null }).then(() => {
            this.selectedClassGroup.meetLink = null;
            this.selectedClassGroupOnlineLink = null;
            this.showМeetLinkDialog = !this.showМeetLinkDialog;
          });
        }
      });
    },
    _saveClassGroupOnlineLink() {
      console.log(this.selectedClassGroup.ref.path);
      if (this.selectedClassGroupOnlineLink) {
        this.selectedClassGroup.ref
          .update({ meetLink: this.selectedClassGroupOnlineLink })
          .then(() => {
            this.selectedClassGroup.meetLink =
              this.selectedClassGroupOnlineLink;
            this.selectedClassGroupOnlineLink = null;
            this.showМeetLinkDialog = !this.showМeetLinkDialog;
          });
      } else {
        this.selectedClassGroup.ref.update({ meetLink: null }).then(() => {
          this.selectedClassGroupOnlineLink = null;
          this.showМeetLinkDialog = !this.showМeetLinkDialog;
        });
      }
    },
    _print() {
      console.log(this.selectedClassGroup.ref.path, this.selectedClassGroup);
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    _selectStartDate() {
      console.log(this.selectedDate);
      var found = this.selectedClassGroup.calendarVersions.find(
        (cal) =>
          cal.calendarVersionNumber ==
          this.defaultCalendarVersion.calendarVersionNumber
      );
      console.log(found);
      if (found && this.selectedDate.type == "start")
        found.startDate = this.selectedDate.date;
      else if (found && this.selectedDate.type == "end")
        found.endDate = this.selectedDate.date;

      this.selectedClassGroup.ref
        .update({
          calendarVersions: this.selectedClassGroup.calendarVersions,
        })
        .then(() => {
          console.log(
            this.selectedClassGroup.ref.path,
            this.selectedClassGroup.calendarVersions
          );
          this.selectedDate = null;
          this.showDateSelectDialog = !this.showDateSelectDialog;
          this.defaultCalendarVersion =
            this.selectedClassGroup.calendarVersions.find((cal) => cal.default);
          this.forceRerender();
        });
    },
    _selectEndDate() {
      console.log(this.selectedDate);
    },
    convertCalData(lesson) {
      if (lesson._calDatas) {
        lesson._calDatasConverted = [];
        lesson._calDatas.forEach((calData) => {
          var calObject = {
            semester: Number(calData.split("-")[0]),
            calendarVersion: Number(calData.split("-")[1]),
            garag: Number(calData.split("-")[2]),
            eelj: Number(calData.split("-")[3]),
            time: Number(
              calData.split("-")[4].startsWith("0")
                ? calData.split("-")[4].slice(1)
                : calData.split("-")[4]
            ),
            sortIndex: Number(calData.replace("-")),
          };
          lesson._calDatasConverted.push(calObject);
        });
      }
    },
    updateDefault(newVal) {
      var found = this.selectedClassGroup.calendarVersions.find(
        (cal) => cal.default
      );
      found.default = false;
      newVal.default = true;
      console.log(this.selectedClassGroup.calendarVersions);
      this.selectedClassGroup.ref.update({
        calendarVersions: this.selectedClassGroup.calendarVersions,
      });
    },
    confirm(newVal) {
      var found = this.selectedClassGroup.calendarVersions.find(
        (cal) =>
          cal.calendarVersionNumber ==
          this.defaultCalendarVersion.calendarVersionNumber
      );
      if (found) {
        found.calendarEelj = newVal;
        this.$swal({
          title:
            found.calendarEelj +
            "-р ээлж" +
            " - Бүлгийн ээлжийг сольсоныг xадгалаx уу? ",

          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.selectedClassGroup.ref.update({
              calendarVersions: this.selectedClassGroup.calendarVersions,
            });
          }
        });
      }
    },
    readLessons(val) {
      this.school.ref
        .collection("lessons-" + this.school.currentYear)
        .where(
          "classGroupIds",
          "array-contains",
          this.selectedClassGroup.STUDENT_GROUP_ID
        )
        .where("deleted", "==", false)
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .onSnapshot((docs) => {
          this.lessons = [];
          docs.forEach((doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            lesson.name2 = lesson.courseInfo.COURSE_NAME
              ? this.lessons.length + 1 + ". " + lesson.courseInfo.COURSE_NAME
              : "";

            if (
              lesson.startEnd["semester-" + this.semesters[val]] &&
              lesson.startEnd["semester-" + this.semesters[val]].available
            ) {
              if (
                this.onlyAllowedLesson &&
                this.onlyAllowedLesson.ref.path == lesson.ref.path
              ) {
                this.selectedLesson = lesson;
              }
              this.convertCalData(lesson);
              // if (lesson._calDatasConverted)
              //   console.log(
              //     lesson._calDatasConverted,
              //     lesson.courseInfo.COURSE_NAME
              //   );
              this.lessons.push(lesson);
            }
          });
        });
    },
  },

  created() {
    this.readable = this.selectedSemester == 1 ? true : false;
    this.zClassGroup.ref.onSnapshot((doc) => {
      let program = doc.data();
      program.ref = doc.ref;
      program.id = doc.id;
      this.selectedClassGroup = program;
      if (this.school == null) this.school = this.userData.school;

      if (
        this.selectedClassGroup &&
        this.selectedClassGroup.calendarVersions &&
        this.selectedClassGroup.calendarVersions.length > 0
      ) {
        if (!this.defaultCalendarVersion) {
          this._findDefaultCalendar(this.selectedSemester);
        }
      } else {
        var calVersion = {
          calendarEelj: 1,
          calendarVersionNumber: 1,
          startDate: "2024-09-02",
          endDate: "2024-12-31",
          default: true,
        };
        this.selectedClassGroup.ref.update({
          calendarVersions: fb.firestore.FieldValue.arrayUnion(calVersion),
        });
      }

      this.school.ref
        .collection("xeelj")
        .get()
        .then((docs) => {
          this.xeelj = [];
          docs.docs.forEach((doc) => {
            let eelj = doc.data();
            eelj.id = doc.id;
            eelj.ref = doc.ref;
            if (parseInt(this.selectedClassGroup.ACADEMIC_LEVEL) > 5) {
              if (eelj.classLevelID == 2) {
                eelj.name2 = eelj.name + ", " + eelj.xeelj + "-р ээлж";
                this.xeelj.push(eelj);
              }
            } else {
              if (eelj.classLevelID == 1) {
                eelj.name2 = eelj.name + ", " + eelj.xeelj + "-р ээлж";
                this.xeelj.push(eelj);
              }
            }
          });
        });
      //semester calendar copying

      this.readLessons(this.activeSemesterTab);
    });
    // setTimeout(() => {
    //   if (
    //     this.selectedSemester > 1 &&
    //     !this.selectedClassGroup[
    //       "semester" + this.selectedSemester + "CalendarDataCopied"
    //     ]
    //   ) {
    //     this.newCalendarVersionDates = {
    //       start: "2025-01-20",
    //       end: "2025-04-30",
    //     };
    //     console.log(this.selectedClassGroup.ref.path, "aaa");
    //     this.createNewCalendarVersion(true);
    //   }
    // }, 1000);
  },
};
</script>

<style>
.warning-border {
  border: 1px solid #ffbf00;
  padding-left: 10px;
  padding-right: 10px;
}
.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}

.v-chip .v-chip__content {
  align-items: start !important;
  display: block;
  height: 100%;
  max-width: 100%;
}

.addIcon:hover {
  font-size: 34px;
  transition: font-size 0.2s;
  /* Add a smooth transition for a nicer effect */
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
