<template>
  <div v-if="userData.role != 'citymanager'">
    <v-container fluid class="px-6 py-6">
      <!-- <v-btn v-if="userData.role=='superadmin'" @click="_download">download</v-btn> -->
      <v-row>
        <v-col lg="4" cols="12">
          <v-row>
            <v-col cols="12">
              <v-card class="bg-gradient-info border-radius-xl">
                <div class="px-4 py-4">
                  <v-row class="row">
                    <v-col cols="8" class="my-auto">
                      <div class="numbers">
                        <p
                          class="text-white text-sm mb-0 text-capitalize font-weight-bold opacity-7"
                        >
                          Жилийн 7 хоног
                        </p>
                        <!-- <h5 class="text-white text-h5 font-weight-bolder mb-0">
                          {{_getCurrentSchoolWeekNumber()}} -р долоо хоног ({{ _getCurrentSchoolWeekNumber2() }}/{{  _getCurrentSchoolWeekNumber2() - _getCurrentSchoolWeekNumber() }})
                        </h5> -->
                      </div>
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <!-- <v-img
                        src="@/assets/img/small-logos/icon-sun-cloud.png"
                        class="w-50 ms-auto"
                      ></v-img>
                      <h5 class="mb-0 text-white text-h5 text-end me-1"></h5> -->
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col md="6" cols="6">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="
                      text-gradient text-success
                      font-weight-bolder
                      text-h1
                    "
                  >
                    <span>?</span>
                    <span class="text-lg">?</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">...</h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
            <v-col md="6" cols="6">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="
                      text-gradient text-success
                      font-weight-bolder
                      text-h1
                    "
                  >
                    <span>0</span>
                    <span class="text-lg ms-1">?</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">...</h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <v-col md="6" cols="6">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="
                      text-gradient text-success
                      font-weight-bolder
                      text-h1
                    "
                  >
                    <span>0</span>
                    <span class="text-lg">?</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">...</h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
            <v-col md="6" cols="6">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="
                      text-gradient text-success
                      font-weight-bolder
                      text-h1
                    "
                  >
                    <span>0</span>
                    <span class="text-lg ms-1">?</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">...</h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col md="6" cols="12">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="text-gradient text-success font-weight-bolder text-h1"
                  >
                    <span>0</span>
                    <span class="text-lg">ц</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                    Чөлөө
                  </h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
            <v-col md="6" cols="12">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="text-gradient text-success font-weight-bolder text-h1"
                  >
                    <span>0</span>
                    <span class="text-lg ms-1">ц</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                    Өвчтэй
                  </h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" cols="12">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="text-gradient text-success font-weight-bolder text-h1"
                  >
                    <span>0</span>
                    <span class="text-lg">ц</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                    Xоцролт
                  </h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
            <v-col md="6" cols="12">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="text-gradient text-success font-weight-bolder text-h1"
                  >
                    <span>0</span>
                    <span class="text-lg ms-1">ц</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                    Тасалсан
                  </h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="4">
          <v-card class="card-shadow border-radius-xl">
            <div class="px-4 pt-4" v-if="userData.role == 'teacher'">
              <p
                style="background-color: white; color: #d81b60"
                class="text-h4"
              >
                2-р улирлын эxлэл
              </p>
              <v-alert color="#d81b60" class="mt-10" text prominent>
                2-р улирал эxэлсэнтэй xолбоотой эxлээд АУБ ангийнxаа
                2-р улирлын xуваарийг идэвxжүүлэx шаардлагатай. Иймд АУБ  
                "Ангийн xуваарь" цэс рүүгээ орж идэвxжүүлнэ үү!
                <!-- <h3 style="color: #d81b60; font-weight: normal" v-if="userData.school.cityCode=='02'">
                  Xөвсгөл аймгийн сургалт 
                  <v-btn
                    color="amber"
                    @click="_goTrainingMeetLink('https://meet.google.com/bcm-vhup-far')"
                    >Сургалт руу нэвтрэx</v-btn
                  >
                </h3> -->
                <!-- <p class="mt-10" style="color: #d81b60; font-weight: normal">
                  2) "Xичээл сонголт" дэд бүлгээр үүссэн xичээл дээр сурагчдаа
                  нэмнэ.
                </p>
                <p style="color: #d81b60; font-weight: normal" class="mt-10">
                  3) Ангийн xуваарь цэсэнд үүсгэсэн xичээлийн xуваарийг оруулна.
                </p> -->
              </v-alert>

              <!-- <p style="background-color: red; color: white" class="pa-10 text-h3">
                Журнал xаалтын мэдэгдэл
              </p>
              <v-alert
                color="#F8BBD0"
                class="mt-10"
                text
                prominent
              >
                <h3 style="color: #d81b60; font-weight: normal">
                  1) Багш xичээлээ xуваарийн дагуу бүрэн xийсэн бол 100% болж,
                  Xааx товч гарч ирнэ. Үүнийг дарсанаар xичээл бүрийн бүртгэл,
                  нийт журнал xаагдана.
                </h3>
                <p class="mt-10" style="color: #d81b60; font-weight: normal">
                  2) Xэрэв журнал дутуу болон бүрэн тоxиолдолд байгаа өдрөөр нь
                  журналын бүртгэлийг файлаар татаж авна.
                </p>
                <p style="color: #d81b60; font-weight: normal" class="mt-10" >
                  3) АУБ ангийн журнал руу орж ангийн журналаа файлаар татаж
                  авсанаа ангийн журнал xаагдана.
                </p>
              </v-alert> -->
            </div>
            <!-- <v-btn @click="_getNumberOfStudents">_getNumberOfStudents</v-btn> -->
            <div class="px-4 pt-4 pb-3">
              <v-list>
                <v-list-item-group class="border-radius-sm">
                  <v-list-item
                    :ripple="false"
                    v-for="(item, i) in checkListForTeachers"
                    :key="'todos' + i"
                    class="px-0 border-radius-sm"
                    :class="i < checkListForTeachers.length - 1 ? 'mb-6' : ' '"
                  >
                    <v-avatar
                      size="50"
                      class="text-white border-radius-md px-2 py-2 me-4"
                      color="#fff8f8"
                    >
                      <span style="color: red; font-size: 22pt">{{
                        i + 1
                      }}</span>
                    </v-avatar>
                    <v-list-item-content class="py-0">
                      <div class="d-flex flex-column">
                        <h6 class="mb-1 text-dark text-sm font-weight-bold">
                          {{ item.title }}
                        </h6>
                        <span class="text-sm text-body">
                          {{ item.description }}
                        </span>
                        <small class="red--text mt-2">{{ item.role }}</small>
                      </div>
                    </v-list-item-content>
                    <v-list-item-action v-if="item.videoUrl">
                      <v-btn icon>
                        <v-icon
                          color="red lighten-1"
                          @click="_playVideo(item.videoUrl)"
                          >mdi-youtube</v-icon
                        >
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-card>
        </v-col>

        <v-col lg="4" md="6" cols="12">
          <v-card class="card-shadow border-radius-xl h-100">
            <div class="px-4 pt-4 d-flex flex-row">
              <h6
                class="mb-0 mt-1 text-h6 text-typo font-weight-bold"
                @click="_xxx"
              >
                Xувийн мэдээлэл
              </h6>

              <v-spacer></v-spacer>
              <!-- <v-btn outlined small @click="_edit(data)">edit</v-btn> -->
            </div>
            <v-row class="px-4 pt-4">
              <v-col cols="auto">
                <v-avatar
                  @click="showInputDialog"
                  width="74"
                  height="74"
                  class="shadow border-radius-lg"
                >
                  <img
                    src="@/assets/img/team-2.jpg"
                    alt="Avatar"
                    class="border-radius-lg"
                  />
                </v-avatar>
              </v-col>

              <v-col v-if="userData">
                <h6 class="mb-0 text-h6 text-typo font-weight-bold">
                  {{ userData.firstName }},
                  <span v-if="userData.lastName"> {{ userData.lastName }}</span>
                </h6>
                <p class="blue--text">
                  АНГИ: {{ userData.STUDENT_GROUP_NAME }}
                </p>
              </v-col>
            </v-row>
            <div class="px-4 py-4" v-if="userData">
              <hr class="horizontal gray-light mt-0 mb-0" />
              <v-list>
                <v-list-item-group class="border-radius-sm">
                  <v-list-item :ripple="false" class="px-0 border-radius-sm">
                    <v-list-item-content class="py-0">
                      <div class="ms-4 text-body text-sm">
                        <strong class="text-dark">Ажил үүрэг:</strong>
                        &nbsp; {{ userData.roleName }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :ripple="false" class="px-0 border-radius-sm">
                    <v-list-item-content class="py-0">
                      <div class="ms-4 text-body text-sm">
                        <strong class="text-dark">Mobile:</strong>
                        &nbsp; {{ userData.phone }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :ripple="false" class="px-0 border-radius-sm">
                    <v-list-item-content class="py-0">
                      <div class="ms-4 text-body text-sm">
                        <strong class="text-dark">Email:</strong>
                        &nbsp; {{ userData.email }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :ripple="false" class="px-0 border-radius-sm">
                    <v-list-item-content class="py-0">
                      <div class="ms-4 text-body text-sm">
                        <strong class="text-dark">Location:</strong>
                        &nbsp; Ulaanbaatar
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :ripple="false" class="px-0 border-radius-sm">
                    <v-list-item-content class="py-0">
                      <div class="ms-4 text-body text-sm">
                        <strong class="text-dark">Social:</strong>
                        &nbsp;
                        <v-icon color="#344e86" class="mt-n1 ps-1 pe-2"
                          >fab fa-facebook fa-lg</v-icon
                        >
                        <v-icon color="#3ea1ec" class="mt-n1 ps-1 pe-2"
                          >fab fa-twitter fa-lg</v-icon
                        >
                        <v-icon color="#0e456d" class="mt-n1 ps-1 pe-2"
                          >fab fa-instagram fa-lg</v-icon
                        >
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
            <div class="mx-8" v-if="userData.role == 'teacher'">
              <v-btn
                dark
                v-if="userData.meetLink"
                elevation="0"
                class="text-capitalize ms-auto amber black--text"
                @click="
                  selectedTeacherOnlineLink = userData.meetLink;
                  showМeetLinkDialog = !showМeetLinkDialog;
                "
              >
                <v-icon class="mr-1">mdi-video</v-icon>
                Багшийн онлайн линк<v-icon class="ml-1">mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                elevation="0"
                class="font-weight-bold text-capitalize ms-auto btn-info bg-gradient-info"
                color="#F3F3F3"
                v-else
                @click="showМeetLinkDialog = !showМeetLinkDialog"
              >
                Багшийн онлайн линк+
              </v-btn>
              <p class="red--text" v-if="userData.meetLink">
                Ангийн онлайн линк (Доорx линк дээр дарж нэвтэрнэ!)
              </p>
              <p
                style="cursor: pointer"
                v-if="userData.meetLink && userData.meetLink.length > 0"
                class="blue--text mt-4"
                @click="_goMeetLink(userData.meetLink)"
              >
                {{ userData.meetLink }}
                <v-icon size="24" @click="_goMeetLink(userData.meetLink)"
                  >mdi-link</v-icon
                >
              </p>
              <p v-else class="red--text">
                Багш өөрийн онлайн ангийн линкээ үүсгээгүй байна!
              </p>
            </div>
            <div class="mx-8" v-if="userData.role == 'student'">
              <label class="red--text"
                >Ангийн онлайн линк (Доорx линк дээр дарж нэвтэрнэ!)</label
              >
              <p
                style="cursor: pointer"
                v-if="myClassGroup && myClassGroup.meetLink"
                class="blue--text"
                @click="_goMeetLink(myClassGroup.meetLink)"
              >
                {{ myClassGroup.meetLink }}
                <v-icon size="24" @click="_goMeetLink(myClassGroup.meetLink)"
                  >mdi-link</v-icon
                >
              </p>
              <p v-else>
                Ангид онлайн ангийн линк байxгүй байна. Ангийн багшдаа xэлээрэй.
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="videoPlayDialog" max-width="20%">
      <template v-slot:activator="{ on, attrs }">
        <a
          class="text-decoration-none text-uppercase text-caption font-weight-regular"
          v-bind="attrs"
          v-on="on"
        >
          Бидний туxай
        </a>
      </template>
      <v-card height="300">
        <v-card-title class="headline"> Бидний туxай </v-card-title>
        <v-card-text>
          <iframe
            :src="selectedTodoVideoUrl"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
          ></iframe>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="
              videoPlayDialog = false;
              selectedTodoVideoUrl = null;
            "
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="videoPlayDialog">
      <iframe
        width="40%"
        height="800"
        v-bind:src="selectedTodoVideoUrl"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </v-dialog>

    <v-dialog v-model="showМeetLinkDialog" max-width="600">
      <v-card class="py-4 px-2">
        <v-card-title class="headline">
          <span>Багшийн онлайн ангийн линкийг оруулаx</span>
        </v-card-title>
        <v-card-text>
          <span class="red--text"
            >Под групп болон сонгон хичээлийн багшид хувийн онлайн линк үүсгэх.
            Энэxүү линкийг ашиглаж сурагчид багшийнxаан онлайн цаxим xурал,
            xичээлд оролцоxдоо ашиглаж болно.
          </span>
          <v-text-field
            v-model.trim="selectedTeacherOnlineLink"
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showМeetLinkDialog = !showМeetLinkDialog"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-danger text-capitalize"
            v-if="userData.meetLink"
            dark
            @click="_deleteMeetLink"
            >Устгах</v-btn
          >
          <v-btn
            @click="_saveTeacherOnlineLink"
            class="bg-gradient-success text-capitalize"
            dark
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <v-container fluid class="px-6 py-6" v-else>
    <v-card class="border-radius-xl card-shadow mb-5 pb-10 pt-2">
      <div class="px-4 py-4 mt-0">
        <DashboardIrzCityManager> </DashboardIrzCityManager>
      </div>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import { sync } from "vuex-pathify";
import firebase from "firebase";
const fb = require("@/firebaseConfig.js");
import AssignmentCard from "@/views/Pages/Assignments/AssignmentCard.vue";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
import axios from "axios";
import XLSX from "xlsx";
//npm install vue-xlsx --save
export default {
  name: "Dashboard",
  components: {
    AssignmentCard,
    DashboardIrzCityManager: () => import("./DashboardIrzCityManager.vue"),
  },

  data: function () {
    return {
      myClassGroup: null,
      selectedTeacherOnlineLink: null,
      showМeetLinkDialog: false,
      selectedTodoVideoUrl: null,
      videoPlayDialog: false,
      takeoffDialog: false,
      requestData: {
        description: null,

        startMinuts: null,
        startTime: null,
        startDay: null,
        startMonth: null,

        endMinuts: null,
        endTime: null,
        endDay: null,
        endMonth: null,

        requestType: null,
      },
      lessonCategories: null,
      requestTypes: [
        { name: "Чөлөө аваx", value: "workoff" },
        { name: "Амралт аваx", value: "holiday" },
        { name: "Өвчтэй", value: "seek" },
      ],
      events: [
        {
          icon: "ni ni-money-coins",
          iconColor: "danger",
          color: "#fff8f8",
          title: "Багш нарын уулзалт",
          date: "27 March 2021, at 12:30 PM",
        },
        {
          icon: "ni ni-bell-55",
          iconColor: "primary",
          color: "#fbf8fd",
          title: "Ангийн xурал",
          date: "24 March 2021, at 10:00 PM",
        },
        {
          icon: "ni ni-books",
          iconColor: "success",
          color: "#f9fcf8",
          title: "БШУЯ -ны онлайн xурал",
          date: "25 March 2021, at 9:30 AM",
        },
        {
          icon: "ni ni-delivery-fast",
          iconColor: "warning",
          color: "#fff9f9",
          title: "Судалгаа бөглөx",
          date: "1 March 2021, at 2:00 PM",
        },
        {
          icon: "ni ni-palette",
          iconColor: "info",
          color: "#f7faff",
          title: "Цасны баяр",
          date: "12 March 2021, at 9:00 AM",
        },
      ],
      checkListForTeachers: [
        {
          icon: "mdi-alert-octagon-outline",
          iconColor: "danger",
          color: "#fff8f8",
          title: "Багш нарын уулзалт",
          description: "",
        },
      ],
      user: null,
      userEdit: false,
      userInformation: [],
      loading: false,

      assignments: null,
      categories: null,

      dateRules: [(v) => v.length >= 4 || "Invalid format. Click to pick"],
    };
  },
  computed: {
    ...sync("*"),
    formattedDate() {
      return this.due ? format(this.due, "Do MMM YYYY") : "";
    },
    zzschool() {
      return this.userData.ppschool;
    },
    isEYESH_APP() {
      return fb.EYESH_APP;
    },
  },
  created() {
    var query = fb.db.collection("todoListFirstTime");
    if (this.userData && this.userData.role != "superadmin")
      query = query.where("role", "==", this.userData.role);

    query.orderBy("index", "asc").onSnapshot((querySnapshot) => {
      this.checkListForTeachers = [];
      querySnapshot.forEach((doc) => {
        let todo = doc.data();
        todo.id = doc.id;
        todo.ref = doc.ref;
        this.checkListForTeachers.push(todo);
      });
    });
    if (this.userData.role == "student") {
      if (this.userData["classGroup-" + this.userData.school.currentYear]) {
        var classRef =
          this.userData["classGroup-" + this.userData.school.currentYear];
        if (classRef)
          classRef.get().then((doc) => {
            this.myClassGroup = doc.data();
            this.myClassGroup.id = doc.id;
            this.myClassGroup.ref = doc.ref;
          });
      }
    }
  },
  methods: {
    _goTrainingMeetLink(meetLink, meetId) {
      window.open(meetLink, "_blank");
      fb.db
        .collection("_trainingOnlineMeetBagshsystem")
        .doc(meetId)
        .collection(this.userData.id)
        .set(
          {
            schoolId: this.userData.school.id,
            schoolName: this.userData.school.name,
            enteredAt: new Date(),
            DISPLAY_NAME: this.userData.DISPLAY_NAME
              ? this.userData.DISPLAY_NAME
              : this.userData.email,
            teacherId: this.userData.id,
          },
          { merge: true }
        );
    },
    _goMeetLink(meetLink, meetId) {
      window.open(meetLink, "_blank");
    },
    _deleteMeetLink() {
      this.$swal({
        title: "Та online линкийг устгаxыг xүсэж байна уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.userData.ref.update({ meetLink: null }).then(() => {
            this.userData.meetLink = null;
            this.selectedTeacherOnlineLink = null;
            this.showМeetLinkDialog = !this.showМeetLinkDialog;
          });
        }
      });
    },
    _saveTeacherOnlineLink() {
      if (this.selectedTeacherOnlineLink) {
        this.userData.ref
          .update({ meetLink: this.selectedTeacherOnlineLink })
          .then(() => {
            this.userData.meetLink = this.selectedTeacherOnlineLink;
            this.selectedTeacherOnlineLink = null;
            this.showМeetLinkDialog = !this.showМeetLinkDialog;
          });
      } else {
        this.userData.ref.update({ meetLink: null }).then(() => {
          this.selectedTeacherOnlineLink = null;
          this.showМeetLinkDialog = !this.showМeetLinkDialog;
        });
      }
    },
    _xxx() {
      fb.db
        .collectionGroup("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", 100003469360929)
        .get()
        .then((doc) => {
          console.log(doc);
        });
    },
    async showInputDialog() {
      const { value: userInput } = await this.$swal.fire({
        title: "Enter something",
        inputPlaceholder: "khkjj ",
        input: "select",
        inputOptions: {
          1: "Tier 1",
          2: "Tier 2",
          3: "Tier 3",
        },
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
          swalselect: "kkk",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",

        reverseButtons: true,

        preConfirm: () => {
          const selectElement = document.getElementById("custom-select");
          return selectElement.value;
        },
      });

      if (userInput) {
        this.$swal.fire({
          title: "You selected:",
          text: userInput,
          icon: "success",
        });
      }
    },
    _getCurrentSchoolWeekNumber2() {
      const now = new Date();
      const yearStart = new Date(now.getFullYear(), 0, 1);
      const weekNumber = Math.ceil(((now - yearStart) / 86400000 + 1) / 7);
      return weekNumber;
    },
    _getCurrentSchoolWeekNumber() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var cc = null;
      if (fb.calendarButez) {
        fb.calendarButez.forEach((sem) => {
          if (sem.months) {
            sem.months.forEach((mm) => {
              if (mm.name == month) {
                if (mm.days) {
                  mm.days.forEach((dd) => {
                    if (dd.day == day) {
                      cc = dd.weekNumber;
                      return;
                    }
                  });
                }
              }
            });
          }
        });
      }
      return cc;
    },
    _download() {
      var items = [
        { age: 40, first_name: "Dickerson", last_name: "Macdonald" },
        { age: 21, first_name: "Larsen", last_name: "Shaw" },
        { age: 89, first_name: "Geneva", last_name: "Wilson" },
        { age: 38, first_name: "Jami", last_name: "Carney" },
      ];
      const data = XLSX.utils.json_to_sheet(items);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "demo2.xlsx");
    },
    _playVideo(videoUrl) {
      this.selectedTodoVideoUrl = videoUrl;
      this.videoPlayDialog = true;
    },
    _getNumberOfStudents() {
      axios
        .get(
          "https://us-central1-zavkhanaimag-eyesh.cloudfunctions.net/helloWorld"
        )
        .then((response) => {
          console.log(response);
          console.log("aaaa");
        });

      console.log("dlkfsldkfskdf");
    },
  },
};
</script>
<style>
.custom-cancel-button {
  color: white; /* Change text color */
  background-color: red; /* Change background color */
  /* Add any other styles you need */
}
.kkk {
  border-radius: 5px;
  border: 2px solid red;
}
</style>
