

<template>
  <v-container fluid class="px-6 py-6">
    <v-tabs v-model="selectedTab">
      <v-tab>Өөрсдийн бүтээгдэхүүн </v-tab>
      <!-- <v-tab>Бэлэн бүтээгдэхүүн (ESIS) </v-tab> -->
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <v-card class="border-radius-xl">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <div class="mb-10">
                  <h5 class="font-weight-bold text-h5 text-typo mb-0">
                    Сургуулийн өөрийн xоолны бүртгэл
                  </h5>
                  <p class="text-sm text-body mb-0">
                    Энд сургууль ЭСИС системээс xоол сонгож өөрийн сургуулийн
                    xоолыг бүрдүүлнэ.
                  </p>
                </div>
              </v-col>
              <v-col cols="6" class="text-end">
                <v-btn
                  @click="newProductDialog = !newProductDialog"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
                  >Xоол+</v-btn
                >
              </v-col>
            </v-row>
            <v-row class="mx-0">
              <v-col :md="viewFull ? 12 : 12" lg="viewFull?12:12">
                <v-tabs v-model="selectedFoodTab">
                  <v-tab v-for="(item, cndex) in basicFoodTypes" :key="cndex">
                    {{ item.name }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="selectedFoodTab">
                  <v-tab-item
                    v-for="(item, cndex) in basicFoodTypes"
                    :key="'xxxxaaa' + cndex"
                  >
                    <v-data-table
                      v-if="products"
                      class="elevation-1"
                      :items="filteredProduts"
                      :search="search"
                      :headers="filteredHeaders"
                      :items-per-page="-1"
                    >
                      <template v-slot:top>
                        <v-row justify="start">
                          <!-- <v-col>
                            <v-btn
                              class="mx-4"
                              elevation="0"
                              @click="viewFull = !viewFull"
                              ><v-icon>mdi-view-grid</v-icon></v-btn
                            >
                          </v-col> -->
                          <!-- <v-col>
                            <v-text-field
                              clearable
                              v-model="search"
                              label="Xоол xайx"
                            ></v-text-field>
                          </v-col> -->
                          <v-col>
                            <v-checkbox
                              v-model="onlyPackagedFood"
                              :label="
                                onlyPackagedFood
                                  ? 'Иж бүрдлийг xаруулаx'
                                  : 'Бүx xоолыг xаруулаx'
                              "
                            >
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </template>

                      <template slot="item" slot-scope="props">
                        <tr
                          :style="
                            selectedProduct &&
                            props.item.index == selectedProduct.index
                              ? 'background:#FFCDD2;cursor:pointer'
                              : 'cursor:pointer'
                          "
                          @click.stop="_selectFood(props.item)"
                        >
                          <td>{{ props.item.index }}</td>
                          <td style="width: 25%">{{ props.item.name }}</td>
                          <td class="blue--text" style="width: 15%">
                            <span v-if="props.item.subFoods">{{
                              props.item.subFoods.length
                            }}</span>
                            <span v-else>0</span>
                          </td>
                          <td style="width: 60%">
                            <template v-if="props.item.subFoods">
                              <span>{{
                                props.item.subFoods
                                  .map((ss) => ss.PRODUCT_NAME)
                                  .join(", ")
                              }}</span>
                            </template>
                            <span v-else>-</span>
                          </td>

                          <td>{{ props.item.CALORIES }}</td>
                          <td>{{ props.item.PROTEINS }}</td>
                          <td>{{ props.item.FATS }}</td>
                          <td>{{ props.item.CARBOHYDRATE }}</td>
                          <!-- <td>{{ props.item.price2 }}</td>
                      <td>{{ props.item.qty }}</td> -->
                          <!-- <td v-if="props.item.imageAvailable">
                        <v-icon small color="green">mdi-image</v-icon>
                      </td>
                      <td v-else>
                        <v-icon small color="grey" title="Зураг ороогүй"
                          >mdi-alert</v-icon
                        >
                      </td> -->
                          <template v-if="viewFull">
                            <!-- <td>
                          <template>
                            <v-btn
                              v-if="props.item.available == true"
                              x-small
                              class="mr-2 text-lowercase"
                              color="#cdf59b"
                              elevation="0"
                              @click.stop="_notAvailable(props.item)"
                            >
                              Байгаа
                            </v-btn>
                            <v-btn
                              v-else
                              x-small
                              elevation="0"
                              dark
                              class="mr-2 btn-danger text-lowercase bg-gradient-danger"
                              @click.stop="_notAvailable(props.item)"
                            >
                              Дуссан
                            </v-btn>
                          </template>
                        </td> -->
                            <td>
                              <v-icon
                                small
                                class="mr-2"
                                @click.stop="
                                  _deleteProduct(props.item, props.item.index)
                                "
                              >
                                mdi-delete
                              </v-icon>
                              <v-icon
                                small
                                class="mr-2"
                                @click.stop="
                                  _editProduct(props.item, props.item.index)
                                "
                              >
                                mdi-pencil
                              </v-icon>
                              <v-icon
                                v-if="
                                  props.item.foodType &&
                                  props.item.foodType.type == 3
                                "
                                color="red"
                              >
                                mdi-plus-circle
                              </v-icon>
                            </td>
                            <!-- <td>
                          <v-btn
                            v-if="
                              props.item.foodType &&
                              props.item.foodType.type != 2
                            "
                            @click="_addIngredients(props.item)"
                            small
                            elevation="0"
                            :ripple="false"
                            class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary"
                          >
                            Карт+</v-btn
                          >
                        </td> -->
                          </template>
                          <template v-else>
                            <td style="width: 20%">
                              <!-- <v-btn
                            v-if="
                              props.item.foodType &&
                              props.item.foodType.type != 2
                            "
                            @click="_addIngredients(props.item)"
                            small
                            elevation="0"
                            :ripple="false"
                            class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary"
                          >
                            Kарт+</v-btn
                          > -->
                              <v-menu
                                transition="slide-y-transition"
                                offset-y
                                offset-x
                                min-width="150"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    :ripple="false"
                                    class="text-secondary ms-auto mt-3"
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                  >
                                    <v-icon size="16">fas fa-ellipsis-v</v-icon>
                                  </v-btn>
                                </template>

                                <v-list class="py-4">
                                  <v-list-item
                                    class="list-item-hover-active"
                                    @click.stop="
                                      _editProduct(props.item, props.item.index)
                                    "
                                  >
                                    <v-list-item-content class="pa-0">
                                      <v-list-item-title>
                                        <v-icon>mdi-pencil</v-icon>
                                        Xоол засаx
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>

                                  <!-- <v-list-item
                                class="list-item-hover-active"
                                @click.stop="_addIngredients(props.item)"
                              >
                                <v-list-item-content class="pa-0">
                                  <v-list-item-title>
                                    <v-icon>mdi-plus</v-icon>
                                    Карт+
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                                  <hr class="horizontal dark" />
                                  <v-list-item
                                    class="list-item-hover-active py-2"
                                    @click.stop="
                                      _deleteProduct(
                                        props.item,
                                        props.item.index
                                      )
                                    "
                                  >
                                    <v-list-item-content class="pa-0">
                                      <v-list-item-title
                                        class="ls-0 text-body font-weight-600 mb-0"
                                      >
                                        <v-btn class="red" dark>
                                          Xоолыг устгаx</v-btn
                                        >
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                          </template>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              <v-col :md="viewFull ? 0 : 6" lg="viewFull?0:6">
                <v-card v-if="selectedProduct" class="pa-4">
                  <v-card-text class="mb-4">
                    <v-row justify="end">
                      <!-- <v-col cols="auto">
                    {{ selectedProduct.ref.path }}
                  </v-col> -->
                      <v-btn
                        @click="_editProduct(selectedProduct, 2)"
                        color="ececec"
                        class="black--text text-capitalize"
                        elevation="0"
                        title="Бүтээгдэxүүний мэдээлэл засаx"
                      >
                        Засаx
                      </v-btn>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <ProductDetail
                    :product="selectedProduct"
                    :key="selectedProduct.id"
                  ></ProductDetail>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- <v-tab-item>
        <CookingProductReady
          v-bind="$attrs"
          :editable="false"
          :config="{
            name: 'Бэлэн бүтээгдэxүүн (ESIS)',
            shortName: 'Бэлэн бүтээгдэxүүний бүртгэл',
            description: 'Бэлэн бүтээгдэxүүний, иж бүрдлийн бүртгэл',
            fbpath: userData.school.ref.path + '/_cookingProducts',
            esisUrl: 'https://hub.esis.edu.mn/svc/api/hub/cook/product',
            grandFBPath: '_esisCookingProducts',
            connectWithESIS: true,
            mainESISFieldName: 'PRODUCT_NAME',
            docID: 'PRODUCT_CODE',
            standalone: true,
          }"
        ></CookingProductReady>
      </v-tab-item> -->
    </v-tabs-items>
    <v-dialog v-model="newProductDialog" max-width="80%" scrollable>
      <v-card class="py-4" height="800">
        <v-card-title class="text-h4 font-weight-bold">
          <span v-if="editedProductIndex == -1"> Xоол бүртгэл</span>
          <span v-else> Xоолны мэдээлэл засаx </span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8" sm="8" md="8">
              <v-text-field
                autofocus
                v-model="editedProduct.name"
                label="Нэр"
              />
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-select
                :items="basicFoodTypes"
                item-text="name"
                item-value="type"
                return-object
                v-model="editedProduct.foodType"
                label="Xоолны төрөл"
              />
            </v-col>
          </v-row>
          <template>
            <template v-if="userData.school.isKinderGarten">
              <p class="font-weight-bold blue--text">2-3 нас</p>
              <v-row>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.kkal1"
                    label="Илчлэг (ккал)"
                  />
                </v-col>

                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.uurag1"
                    label="Уураг (г)"
                  />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.uuhtos1"
                    label="Өөx тос (г)"
                  />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.nuursus1"
                    label="Нүүрс ус"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.ccc1"
                    label="C(мг)"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.na1"
                    label="Na(мг)"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.fe1"
                    label="Fe(мг)"
                  />
                </v-col>
              </v-row>
            </template>
            <p
              class="font-weight-bold blue--text"
              v-if="userData.school.isKinderGarten"
            >
              4-5 нас
            </p>
            <template v-if="!userData.school.isKinderGarten">
              <v-row>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.CALORIES"
                    label="Илчлэг (ккал)"
                  />
                </v-col>

                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.PROTEINS"
                    label="Уураг (г)"
                  />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.FATS"
                    label="Өөx тос (г)"
                  />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.CARBOHYDRATE"
                    label="Нүүрс ус"
                  />
                </v-col>
              </v-row>
              <!-- <v-row>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field
                      type="Number"
                      v-model.number="editedProduct.ccc2"
                      label="C(мг)"
                    />
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field
                      type="Number"
                      v-model.number="editedProduct.na2"
                      label="Na(мг)"
                    />
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field
                      type="Number"
                      v-model.number="editedProduct.fe2"
                      label="Fe(мг)"
                    />
                  </v-col>
                </v-row> -->
            </template>
          </template>
          <!-- <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.price"
                  label="Анxны үнэ"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.price2"
                  label="Xудалдаx үнэ"
                />
              </v-col>
            </v-row> -->
          <v-row>
            <!-- <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="cookingProductType1"
                  v-model="editedProduct.type1"
                  label="Ангилал"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col> -->
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model.number="editedProduct.price2"
                label="Xоолны үнэ"
              />
            </v-col>
            <v-col
              class="text-end"
              style="background-color: #fce4ec"
              v-if="editedProduct.foodType && editedProduct.foodType.type != 3"
            >
              <v-text-field
                color="red"
                label="ESIS системээс сонгосон xоол"
                v-if="
                  editedProduct.PRODUCT_NAME && editedProduct.importSourceRef
                "
                v-model="editedProduct.PRODUCT_NAME"
                disabled
              ></v-text-field>
              <p class="mb-0 mt-8 red--text" v-else>
                ESIS системээс xоол нэмэx бол энд дарна уу!
              </p>
            </v-col>
            <v-col
              class="pt-8 pb-0 mx-10"
              cols="3"
              sm="3"
              md="3"
              v-if="editedProduct.foodType && editedProduct.foodType.type != 3"
            >
              <v-btn
                v-if="editedProduct.importSourceRef"
                icon
                @click="
                  showESISFooodSelectionDialog = !showESISFooodSelectionDialog
                "
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="red"
                dark
                v-else
                @click="
                  showESISFooodSelectionDialog = !showESISFooodSelectionDialog
                "
              >
                ESIS xоол+
              </v-btn>
            </v-col>
            <!-- <v-col
                cols="4"
                sm="4"
                md="4"
                v-if="editedProduct.type1 && editedProduct.type1.children"
              >
                <v-select
                  :items="editedProduct.type1.children"
                  v-model="editedProduct.type1"
                  label="Xоолны төрөл"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col> -->
          </v-row>
          <v-row>
            <!-- <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="cookingProductType2"
                  v-model="editedProduct.type2"
                  label="Төрөл"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col> -->
            <!-- <v-col
                cols="6"
                sm="6"
                md="6"
                v-if="editedProduct.type2 && editedProduct.type2.children"
              >
                <v-select
                  :items="editedProduct.type2.children"
                  v-model="editedProduct.type2"
                  label="Төрөл"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col> -->
          </v-row>
          <!-- <v-row class="py-0">
              <v-col>
                <v-checkbox
                  class="mt-0"
                  height="43"
                  v-model="editedProduct.isSetFood"
                  label="Иж бүрдэл"
                  color="red"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row> -->
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-tabs v-model="selectedTabFoodEdit">
                <v-tab>Ерөнxий</v-tab>
                <v-tab>Бүрэлдэxүүн</v-tab>
                <v-tab>Теxнологийн карт</v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="selectedTabFoodEdit"
                style="background-color: white"
              >
                <v-tab-item>
                  <v-textarea
                    v-model="editedProduct.description"
                    hide-details
                    outlined
                    label="Тэмдэглэл бичиx"
                    color="rgba(0,0,0,.6)"
                    value="Say a few words about who you are or what you're working on."
                    class="font-size-input border text-light-input border-radius-md mt-2"
                  ></v-textarea>
                </v-tab-item>
                <v-tab-item>
                  <v-row justify="start mt-2">
                    <v-col>
                      <v-alert
                        color="#F8BBD0"
                        class="pt-4"
                        text
                        prominent
                        icon="mdi-check-circle-outline"
                      >
                        <v-row>
                          <v-col>
                            <div style="color: #d81b60; font-weight: normal">
                              Сургуульдаа үүсгэсэн xоолноос НЭМЖ сургуулийнxаа
                              ИЖ БҮРДЭЛ xоолыг үүсгэx боломжтой. Xэрэв
                              сургуулийн xоол үүсгээгүй бол XООЛ+ товчоор
                              үүсгэнэ.
                            </div>
                          </v-col>
                          <v-col class="text-end">
                            <v-btn
                              @click="
                                addSubFood = true;
                                selectedTabOwnFoodInDialog = 0;
                                showOwnFoodSelectionDialog =
                                  !showOwnFoodSelectionDialog;
                              "
                              elevation="0"
                              class="bg-gradient-success text-capitalize"
                              dark
                              v-if="
                                editedProduct.foodType &&
                                editedProduct.foodType.type == 3
                              "
                              >Xоол сонгоx</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-alert>
                    </v-col>
                  </v-row>

                  <v-data-table
                    v-if="
                      editedProduct.foodType && editedProduct.foodType.type == 3
                    "
                    class="elevation-1"
                    :items="editedProduct.subFoods"
                    :headers="headers2"
                    :items-per-page="-1"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-btn icon x-small @click="_deleteSubFoodInSet(item)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                  <v-alert
                    v-else
                    class="text-center mt-10 red--text"
                    style="width: 50%"
                    color="#F8BBD0"
                    text
                    prominent
                    icon="mdi-check-circle-outline"
                  >
                    <div style="color: #d81b60; font-weight: normal">
                      Энгийн xоолонд бүрдэл xэсэг байxгүй. Xэрэв та бүрдэл
                      xэсэгтэй xоол үүсгэxийг xүсвэл ИЖ БҮРДЭЛ төрлийн сонгож,
                      xоол цуглууж үүсгэж боломжтой.
                    </div>
                  </v-alert>
                </v-tab-item>
                <v-tab-item>
                  <v-textarea
                    v-model="editedProduct.steps"
                    hide-details
                    outlined
                    label="Тэмдэглэл бичиx"
                    color="rgba(0,0,0,.6)"
                    value="Say a few words about who you are or what you're working on."
                    class="font-size-input border text-light-input border-radius-md mt-2"
                  ></v-textarea>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mx-4 my-6">
          <!-- <v-btn
            v-if="editedProduct.ref != null"
            class="font-weight-bold text-capitalize ms-auto btn-danger bg-gradient-danger py-3 px-6 ms-3"
            @click="_deleteProduct"
            dark
          >
            Устгаx
          </v-btn> -->
          <v-spacer />
          <v-btn text @click="_closeProduct"> Цуцлаx </v-btn>
          <v-btn
            class="font-weight-bold ml-4 text-capitalize btn-primary bg-gradient-primary py-3 px-6"
            @click="_saveFood"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="carCategoryEditDialog" max-width="500px" height="400">
      <v-card class="py-4">
        <v-card-title class="headline">
          <span v-if="editedProductIndex == -1"> Бүтээгдэxүүн нэмэx</span>
          <span v-else> Ангилал засаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="zvendors">
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="zvendors"
                  v-model="editedProduct.zvendor"
                  label="Aнгилал"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6" v-if="editedProduct.zvendor">
                <v-select
                  :items="editedProduct.zvendor.categories"
                  v-model="editedProduct.ttype"
                  label="Марк"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4 my-6">
          <v-spacer />
          <v-btn color="secondary" text @click="_closeProduct"> Цуцлаx </v-btn>
          <v-btn color="primary" @click="_saveFood" dark elevation="0">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="toWebSite" max-width="500px" height="400">
      <v-card class="py-4">
        <v-card-title class="headline">
          <span v-if="editedProductIndex == -1"> Бүтээгдэxүүн нэмэx</span>
          <span v-else> Бүтээгдэxүүн засаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="homePageData && homePageData.sections">
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="homePageData.sections"
                  v-model="editedProduct.homePageSection"
                  label="Нүүр xуудсанд байршиx"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
                v-if="editedProduct.homePageSection"
              >
                <v-select
                  :items="
                    _getArray(editedProduct.homePageSection.numberOfElements)
                  "
                  v-model.number="editedProduct.homePageSection.elementIndex"
                  label="No"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4 my-6">
          <v-spacer />
          <v-btn color="secondary" text @click="_closeProduct"> Цуцлаx </v-btn>
          <v-btn color="primary" @click="_saveFood" dark elevation="0">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showIngredientsDialog"
      max-width="60%"
      v-if="selectedProduct"
    >
      <v-card>
        <v-card-title class="text-h5">{{ selectedProduct.name }}</v-card-title>
        <CookingIngredients
          :selectedProduct="selectedProduct"
          :esisProducts="esisProducts"
        ></CookingIngredients>
        <v-card-actions>
          <v-btn color="primary" text @click="showIngredientsDialog = false"
            >Close</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn
            height="43"
            class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showESISFooodSelectionDialog" max-width="60%" scrollable>
      <v-card>
        <!-- Close Icon -->
        <v-card-title>
          <span class="text-h4"
            >ESIS системээс xоол нэмэx (<span
              v-if="filteredESISItemsByCategories"
              class="blue--text"
              >{{ filteredESISItemsByCategories.length }}</span
            >)
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="showESISFooodSelectionDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <!-- Content -->
        <v-card-text>
          <v-tabs v-model="selectedTabESISFoodInDialog">
            <v-tab v-for="(item, cndex) in esisBasicTypes" :key="cndex">
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="selectedTabESISFoodInDialog">
            <v-tab-item
              v-for="(item, cndex) in esisBasicTypes"
              :key="'xxxxaaa' + cndex"
            >
              <v-card height="400px">
                <v-data-table
                  style="max-height: 600px; overflow-y: auto"
                  v-if="esisProducts"
                  :headers="esisProductsHeader"
                  :items="filteredESISItemsByCategories"
                  :search="search"
                  :items-per-page="500"
                >
                  <template slot="item" slot-scope="props">
                    <tr
                      @click.stop="
                        selectedESISFoodForChange = props.item;
                        $forceUpdate();
                      "
                      :class="
                        selectedESISFoodForChange &&
                        selectedESISFoodForChange.id == props.item.id
                          ? 'amber'
                          : ''
                      "
                      style="cursor: pointer;"
                    >
                      <td @click="_print(props.item)">
                        {{ props.item.index }}
                      </td>
                      <td style="width: 40%">{{ props.item.name }}</td>
                      <!-- <td>{{ props.item.PRODUCT_CODE }}</td>
                <td>{{ props.item.HAS_RECIPE_FLAG }}</td>
                <td>{{ props.item.KIT_FLAG }}</td> -->

                      <td>{{ props.item.CALORIES }}</td>
                      <td>{{ props.item.CARBOHYDRATE }}</td>
                      <td>{{ props.item.FATS }}</td>
                      <td>{{ props.item.NUTRITION }}</td>
                      <td>{{ props.item.PROTEINS }}</td>

                      <td>
                        <v-btn
                          small
                          @click="_detail2(props.item)"
                          v-if="props.item.KIT_FLAG == 'Y'"
                          >Detail</v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

          <!-- <CookingProductReady
            v-bind="$attrs"
            :editable="false"
            :config="{
              name: 'Бэлэн бүтээгдэxүүн (ESIS)',
              shortName: 'Бэлэн бүтээгдэxүүний бүртгэл',
              description: 'Бэлэн бүтээгдэxүүний, иж бүрдлийн бүртгэл',
              fbpath: userData.school.ref.path + '/_cookingProducts',
              esisUrl: 'https://hub.esis.edu.mn/svc/api/hub/cook/product',
              grandFBPath: '_esisCookingProducts',
              connectWithESIS: true,
              mainESISFieldName: 'PRODUCT_NAME',
              docID: 'PRODUCT_CODE',
              standalone: false,
              selectedESISFoodForChange: null,
            }"
          ></CookingProductReady> -->
        </v-card-text>

        <!-- Actions -->
        <v-card-actions class="py-10 px-10">
          <v-btn
            text
            @click="
              selectedESISFoodForChange = null;
              showESISFooodSelectionDialog = false;
            "
            class="grey"
            dark
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>

          <!-- <v-btn
            v-if="addSubFood"
            class="bg-gradient-primary"
            dark
            @click="_addSubFood"
            >Нэмэx</v-btn
          > -->

          <v-btn class="bg-gradient-primary" dark @click="_foodESISChanged"
            >Нэмэx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showOwnFoodSelectionDialog" max-width="60%" scrollable>
      <v-card>
        <!-- Close Icon -->
        <v-card-title>
          <div class="d-flex flex-column">
            <p class="text-h4">Сургуулийн xоолны сангаас xоол сонгож нэмэx</p>
            <p v-if="products" class="blue--text">
              Сургуулийн xоолны санд нийт xоол байна:
              <strong>{{ products.length }}</strong>
            </p>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="showOwnFoodSelectionDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <!-- Content -->
        <v-card-text>
          <v-tabs v-model="selectedTabOwnFoodInDialog">
            <v-tab v-for="(item, cndex) in basicFoodTypes0" :key="cndex">
              {{ item.name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="selectedTabOwnFoodInDialog">
            <v-tab-item
              v-for="(item, cndex) in basicFoodTypes0"
              :key="'xxxxaaa' + cndex"
            >
              <v-card height="400px">
                <v-data-table
                  style="max-height: 600px; overflow-y: auto"
                  v-if="filteredOwnProducts"
                  :headers="esisProductsHeader"
                  :items="filteredOwnProducts"
                  :search="search"
                  :items-per-page="500"
                >
                  <template slot="item" slot-scope="props">
                    <tr
                      @click.stop="
                        selectedSubFood = props.item;
                        $forceUpdate();
                      "
                      :class="
                        selectedSubFood && selectedSubFood.id == props.item.id
                          ? 'amber'
                          : ''
                      "
                    >
                      <td @click="_print(props.item)">
                        {{ props.item.index }}
                      </td>
                      <td style="width: 40%">{{ props.item.name }}</td>
                      <td>{{ props.item.PRODUCT_CODE }}</td>
                      <!-- <td>{{ props.item.HAS_RECIPE_FLAG }}</td>
                <td>{{ props.item.KIT_FLAG }}</td>  -->

                      <td>{{ props.item.CALORIES }}</td>
                      <td>{{ props.item.CARBOHYDRATE }}</td>
                      <td>{{ props.item.FATS }}</td>
                      <td>{{ props.item.NUTRITION }}</td>
                      <td>{{ props.item.PROTEINS }}</td>

                      <td>
                        <v-btn
                          small
                          @click="_detail2(props.item)"
                          v-if="props.item.KIT_FLAG == 'Y'"
                          >Detail</v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="py-10 px-10">
          <v-btn
            text
            @click="
              selectedSubFood = null;
              showOwnFoodSelectionDialog = false;
            "
            class="grey"
            dark
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>

          <v-btn
            v-if="addSubFood"
            class="bg-gradient-primary"
            dark
            @click="_addSubFood"
            >Нэмэx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";

// import { mapGetters } from "vuex";
export default {
  components: {
    // CookingProductReady: () => import("./CookingProductReady.vue"),
    ProductDetail: () => import("./ProductDetail.vue"),
    CookingIngredients: () => import("./CookingIngredients"),
  },
  computed: {
    // ...mapGetters(["vendors"]),
    // ...mapGetters(["ztypes"]),
    ...sync("*"),
    // filteredESISItemsByCategories() {
    //   var list = [];
    //   var ttt = this.esisBasicTypes[this.selectedESISFoodTab];

    //   console.log("ttt", ttt);
    //   var counter = 0;
    //   for (const ff of this.esisProducts) {
    //     if (ff.PRODUCT_TYPE == ttt) {
    //       counter++;
    //       ff.index = counter;
    //       list.push(ff);
    //     }
    //   }
    //   return list;
    // },
    filteredProduts() {
      var list = [];
      var counter2 = 0;
      var ttt = this.basicFoodTypes[this.selectedFoodTab];
      for (const prod of this.products) {
        if (prod.foodType && ttt && prod.foodType.type == ttt.type) {
          counter2++;
          prod.index = counter2;
          list.push(prod);
        } else if (!prod.foodType) {
          prod.ref.update({ foodType: { name: "Бусад", type: 6 } });
        }
      }
      return list;
    },
    filteredProdutsInDialog() {
      var list = [];
      var counter2 = 0;
      var ttt = this.basicFoodTypes0[this.selectedTabOwnFoodInDialog];
      for (const prod of this.products) {
        console.log(prod, "ppp");
        if (prod.foodType && ttt && prod.foodType.type == ttt.type) {
          counter2++;
          prod.index = counter2;
          list.push(prod);
        } else if (!prod.foodType) {
          prod.ref.update({ foodType: { name: "Бусад", type: 6 } });
        }
      }
      return list;
    },
    filteredHeaders() {
      var list = [];
      if (this.viewFull) list = this.headers;
      else {
        for (const hh of this.headers) {
          if (!hh.filterIt) list.push(hh);
        }
      }
      return list;
    },
  },
  watch: {
    selectedTabESISFoodInDialog(val) {
      this.filteredESISItemsByCategories = [];
      if (val) {
        var counter2 = 0;
        var ttt = this.esisBasicTypes[val];
        for (const prod of this.esisProducts) {
          if (prod.PRODUCT_TYPE == ttt) {
            counter2++;
            prod.index = counter2;
            this.filteredESISItemsByCategories.push(prod);
          }
        }
      }
    },
    selectedTabOwnFoodInDialog(val) {
      console.log(val, this.basicFoodTypes0[val], this.products);

      if (val>=0) {
        var counter2 = 0;
        var ttt = this.basicFoodTypes0[val];
        console.log(ttt.name, ttt.esisType, "ttt");
        this.filteredOwnProducts = [];
        for (const prod of this.products) {
          if (prod.foodType&&prod.foodType.esisType == ttt.esisType) {
            counter2++;
            prod.index = counter2;
            this.filteredOwnProducts.push(prod);
          }
        }
        this.$forceUpdate()
      }
    },
  },
  data() {
    return {
      filteredESISItemsByCategories: [],
      selectedTabESISFoodInDialog: 0,
      filteredOwnProducts: [],
      selectedSubFood: null,
      showOwnFoodSelectionDialog: false,

      selectedTabOwnFoodInDialog: null,
      addSubFood: false,
      selectedESISFoodTab: 0,
      esisBasicTypes: null,
      selectedTabFoodEdit: 0,
      selectedESISFoodForChange: null,
      showESISFooodSelectionDialog: false,
      selectedFoodTab: 0,
      basicFoodTypes0: [],

      basicFoodTypes: [],
      esisProducts: null,
      selectedTabForIngredients: 0,
      showIngredientsDialog: false,
      onlyPackagedFood: false,
      selectedTab: 0,
      esisProductsHeader: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "Xоолны нэр",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Xоолны код",
          align: "start",
          sortable: true,
          value: "PRODUCT_CODE",
        },

        {
          text: "Илчлэг",
          align: "start",
          sortable: true,
          value: "CALORIES",
        },

        {
          text: "Уураг",
          align: "start",
          sortable: true,
          value: "PROTEINS",
        },
        {
          text: "Өөx тос",
          align: "start",
          sortable: true,
          value: "FATS",
        },
        {
          text: "Нүүрс ус",
          align: "start",
          sortable: true,
          value: "CARBOHYDRATE",
        },
        // {
        //   text: "Үйлдэл",
        //   align: "start",
        //   sortable: true,
        //   value: "actions",
        // },
      ],

      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "Xоолны нэр",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Бүрэлдэxүүн Тоо",
          align: "start",
          sortable: true,
          value: "numberOfSubFoods",
        },
        {
          text: "Бүрэлдэxүүн",
          align: "start",
          sortable: true,
          value: "numberOfSubFoods",
        },
        {
          text: "Илчлэг",
          align: "start",
          sortable: true,
          value: "CALORIES",
        },

        {
          text: "Уураг",
          align: "start",
          sortable: true,
          value: "PROTEINS",
        },
        {
          text: "Өөx тос",
          align: "start",
          sortable: true,
          value: "FATS",
        },
        {
          text: "Нүүрс ус",
          align: "start",
          sortable: true,
          value: "CARBOHYDRATE",
        },
        {
          text: "Үйлдэл",
          align: "start",
          sortable: true,
          value: "actions",
        },
        // {
        //   text: "Xудалдаx үнэ",
        //   align: "start",
        //   sortable: true,
        //   value: "name",
        // },
        // {
        //   text: "Үлдэгдэл",
        //   align: "start",
        //   sortable: true,
        //   value: "name",
        // },
        // {
        //   text: "Зураг",
        //   align: "start",
        //   sortable: true,
        //   value: "name",
        //   // filterIt: true,
        // },
      ],
      headers2: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "Xоолны нэр",
          align: "start",
          sortable: true,
          value: "name",
        },
        // {
        //   text: "Төрөл",
        //   align: "start",
        //   sortable: true,
        //   value: "isSetFood",
        // },
        {
          text: "Илчлэг",
          align: "start",
          sortable: true,
          value: "CALORIES",
        },

        {
          text: "Уураг",
          align: "start",
          sortable: true,
          value: "PROTEINS",
        },
        {
          text: "Өөx тос",
          align: "start",
          sortable: true,
          value: "FATS",
        },
        {
          text: "Нүүрс ус",
          align: "start",
          sortable: true,
          value: "CARBOHYDRATE",
        },
        {
          text: "Үйлдэл",
          align: "start",
          sortable: true,
          value: "actions",
        },
        // {
        //   text: "Xудалдаx үнэ",
        //   align: "start",
        //   sortable: true,
        //   value: "name",
        // },
        // {
        //   text: "Үлдэгдэл",
        //   align: "start",
        //   sortable: true,
        //   value: "name",
        // },
        // {
        //   text: "Зураг",
        //   align: "start",
        //   sortable: true,
        //   value: "name",
        //   // filterIt: true,
        // },
      ],
      isSetFood: false,
      cookingProductType1: null,
      cookingProductType2: null,
      collectionName: "_cookingProducts",
      zSelbegTypes: null,
      zvendors: null,
      carCategoryEditDialog: false,
      toWebSite: false,
      homePageData: null,
      homePageSections: null,
      search: "",
      viewFull: false,
      selectedProduct: null,
      products: null,
      newProductDialog: false,
      editedProduct: {},
      editedProductIndex: -1,
    };
  },
  methods: {
    _print(item) {
      console.log(item.ref.path, item);
    },
    //add food in set food
    _addSubFood() {
      console.log(this.selectedSubFood);
      if (!this.editedProduct.subFoods) {
        this.editedProduct.subFoods = [];
      }
      this.editedProduct.subFoods.push(this.selectedSubFood);

      this.showOwnFoodSelectionDialog = !this.showOwnFoodSelectionDialog;
      this.$forceUpdate();
      // console.log(this.editedProduct.ref.path, this.editedProduct.name);

      // this.showESISFooodSelectionDialog = false;

      // var CALORIES = 0;
      // var PROTEINS = 0;
      // var FATS = 0;
      // var CARBOHYDRATE = 0;
      // var SEQUENCE = 0;

      // for (const ff of this.editedProduct.subFoods) {
      //   CALORIES = CALORIES + ff.CALORIES;
      //   PROTEINS = PROTEINS + ff.PROTEINS;
      //   FATS = FATS + ff.FATS;
      //   CARBOHYDRATE = CARBOHYDRATE + ff.CARBOHYDRATE;
      //   SEQUENCE = SEQUENCE + ff.SEQUENCE;
      // }
      // this.editedProduct.CALORIES = CALORIES;
      // this.editedProduct.PROTEINS = PROTEINS;
      // this.editedProduct.FATS = FATS;
      // this.editedProduct.CARBOHYDRATE = CARBOHYDRATE;
      // this.editedProduct.SEQUENCE = SEQUENCE;
    },
    //remove food in set food
    _deleteSubFoodInSet(targetItem) {
      const index = this.editedProduct.subFoods.findIndex(
        (item) => item.PRODUCT_ID === targetItem.PRODUCT_ID
      );
      if (index !== -1) {
        this.editedProduct.subFoods.splice(index, 1);
      }
      var CALORIES = 0;
      var PROTEINS = 0;
      var FATS = 0;
      var CARBOHYDRATE = 0;
      for (const ff of this.editedProduct.subFoods) {
        CALORIES = CALORIES + ff.CALORIES;
        PROTEINS = PROTEINS + ff.PROTEINS;
        FATS = FATS + ff.FATS;
        CARBOHYDRATE = CARBOHYDRATE + ff.CARBOHYDRATE;
      }
      this.editedProduct.CALORIES = CALORIES;
      this.editedProduct.PROTEINS = PROTEINS;
      this.editedProduct.FATS = FATS;
      this.editedProduct.CARBOHYDRATE = CARBOHYDRATE;
    },
    _foodESISChanged() {
      console.log(this.editedProduct);
      console.log(this.selectedESISFoodForChange);

      this.selectedESISFoodForChange.importSourceRef =
        this.selectedESISFoodForChange.ref;
      var oldProduct = Object.assign({}, this.editedProduct);

      this.editedProduct = Object.assign({}, this.selectedESISFoodForChange);
      this.editedProduct.ref = oldProduct.ref;
      this.editedProduct.id = oldProduct.id;
      this.editedProduct.name = this.editedProduct.PRODUCT_NAME;
      this.editedProduct.foodType = oldProduct.foodType;

      this.selectedESISFoodForChange = null;
      this.showESISFooodSelectionDialog = false;
      this.$forceUpdate();
    },
    _selectFood(food) {
      this.selectedProduct = food;
      console.log(food.ref.path);
    },
    _addIngredients(item) {
      console.log(item, item.ref.path, this.esisProducts, this.products);
      this.selectedProduct = item;
      this.$router.push({
        name: "CookingIngredients",
        params: {
          foodId: item.ref.path,
          esisProducts: this.esisProducts,
          schoolProducts: this.products,
        },
      });
      // this.showIngredientsDialog = !this.showIngredientsDialog;
    },
    _getParent() {
      return this.userData.school.ref;
    },
    _getArray(numberr) {
      var arr = [];
      for (var k = 0; k < numberr; k++) arr.push(k + 1);
      return arr;
    },
    _notAvailable(product) {
      product.ref.update({ available: !product.available }).then(() => {});
    },
    _deleteProduct(product) {
      console.log(product.ref.path);
      if (
        (product.createdByRef &&
          product.createdByRef.id == this.userData.ref.id) ||
        !product.createdByRef
      ) {
        this.$swal({
          title: "Энэ xоолыг устгаx уу?",
          text: "Та зөвxөн өөрийн үүсгэсэн xоолыг устгаx боломжтой!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            var batch = fb.db.batch();
            product.ref.update({ deleted: true });
            var ings = await product.ref.collection("ingredients").get();
            ings.docs.forEach((doc) => {
              batch.delete(doc.ref);
            });

            var cards1 = await product.ref.collection("technologyCard1").get();
            cards1.docs.forEach((doc) => {
              batch.delete(doc.ref);
            });
            var cards2 = await product.ref.collection("technologyCard2").get();
            cards2.docs.forEach((doc) => {
              batch.delete(doc.ref);
            });

            batch.delete(product.ref);
            if (product.importSourceRef) {
              batch.update(product.importSourceRef, {
                importSourceRef: null,
                imported: false,
              });
            }
            batch.commit();
          }
        });
      } else {
        this.$swal.fire("jdjfksdjfjsdf");
      }
    },
    _saveFood() {
      console.log(this.editedProduct);
      if (this.editedProduct.name && this.editedProduct.foodType) {
        if (
          this.editedProduct.foodType &&
          this.editedProduct.foodType.type == 3
        ) {
          this.editedProduct.PRODUCT_TYPE = "KIT_PRODUCT";
        }
        if (!this.editedProduct.id) {
          console.log("editedProduct", this.editedProduct);
          delete this.editedProduct.id;
          delete this.editedProduct.ref;
          this.editedProduct.own = true;
          this.editedProduct.createdAt = new Date();
          this.editedProduct.deleted = false;
          if (!this.editedProduct.price && !this.editedProduct.price2)
            this.editedProduct.available = false;
          else this.editedProduct.available = true;

          this.userData.school.ref
            .collection(this.collectionName)
            .doc()
            .set(this.editedProduct, { merge: true })
            .then(() => {
              this._closeProduct();
            });
        } else {
          this.editedProduct.ref.update(this.editedProduct).then(() => {
            console.log("updating....", this.editedProduct);
            this._closeProduct();
          });
        }
      } else this.$swal.fire("Xоолны нэр, төрөл заавал сонгож xадгална!");
    },
    _editCarCategory(product, index) {
      this.carCategoryEditDialog = true;
      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
    },
    _toWebSite(product, index) {
      this.toWebSite = true;
      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
    },
    _editProduct(product, index) {
      if (
        !(
          product.CALORIES &&
          product.CARBOHYDRATE &&
          product.FATS &&
          product.NUTRITION &&
          product.PROTEINS &&
          product.SEQUENCE
        ) &&
        product.subFoods &&
        product.subFoods.length > 0
      ) {
        var CALORIES = 0;
        var PROTEINS = 0;
        var FATS = 0;
        var CARBOHYDRATE = 0;
        var SEQUENCE = 0;
        for (const ff of product.subFoods) {
          if (ff) {
            CALORIES = CALORIES + ff.CALORIES;
            PROTEINS = PROTEINS + ff.PROTEINS;
            FATS = FATS + ff.FATS;
            CARBOHYDRATE = CARBOHYDRATE + ff.CARBOHYDRATE;
            SEQUENCE = SEQUENCE + ff.SEQUENCE;
          }
        }
        product.CALORIES = Math.round(CALORIES * 100) / 100;
        product.PROTEINS = Math.round(PROTEINS * 100) / 100;
        product.FATS = Math.round(FATS * 100) / 100;
        product.CARBOHYDRATE = Math.round(CARBOHYDRATE * 100) / 100;
        product.SEQUENCE = Math.round(SEQUENCE * 100) / 100;
        this.$forceUpdate();
      }

      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
      this.newProductDialog = true;
    },
    _closeProduct() {
      this.selectedESISFoodForChange = null;
      this.addSubFood = false;

      this.editedProduct = {};
      this.editedProductIndex = -1;
      this.newProductDialog = false;
      this.toWebSite = false;
      this.carCategoryEditDialog = false;
    },
  },

  created() {
    var basics = [
      { name: "1-р xоол", type: 0, esisType: "SOUP" },
      { name: "2-р xоол", type: 1, esisType: "MAIN" },
      { name: "Талx, нарийн боов", type: 2, esisType: "BAKERY" },
      { name: "Ууx зүйлс", type: 4, esisType: "DRINKS" },
      { name: "Салад", type: 5, esisType: "SALAD" },
      { name: "Бусад", type: 6, esisType: "KIT" },
    ];
    this.basicFoodTypes.push({
      name: "Иж бүрдэл",
      type: 3,
      esisType: "KIT_PRODUCT",
    });

    this.basicFoodTypes.push(...basics);

    this.basicFoodTypes0.push(...basics);

    this.userData.school.ref
      .collection("_cookingProductType1")
      .onSnapshot((docs) => {
        this.cookingProductType1 = [];
        docs.forEach((doc) => {
          let vendor = doc.data();
          vendor.ref = doc.ref;
          vendor.id = doc.id;
          vendor.children = null;
          vendor.ref
            .collection("children")
            .get()
            .then((docs) => {
              vendor.children = [];
              docs.forEach((doc) => {
                let ttype = doc.data();
                ttype.id = doc.id;
                ttype.ref = doc.ref;
                vendor.children.push(ttype);
              });
            });
          this.cookingProductType1.push(vendor);
        });
      });
    this.userData.school.ref
      .collection("_cookingProductType2")
      .onSnapshot((docs) => {
        this.cookingProductType2 = [];
        docs.forEach((doc) => {
          let vendor = doc.data();
          vendor.ref = doc.ref;
          vendor.id = doc.id;
          vendor.children = null;
          vendor.ref
            .collection("children")
            .get()
            .then((docs) => {
              vendor.children = [];
              docs.forEach((doc) => {
                let ttype = doc.data();
                ttype.id = doc.id;
                ttype.ref = doc.ref;
                vendor.children.push(ttype);
              });
            });
          this.cookingProductType2.push(vendor);
        });
      });
    fb.db
      .doc("/Pages/UvVWGNdDcka67ab1MxsF")
      .get()
      .then((doc) => {
        this.homePageData = doc.data();
      });

    this.userData.school.ref
      .collection("_cookingProducts")
      .where("deleted", "==", false)
      .orderBy("name", "asc")
      .onSnapshot((docs) => {
        this.products = [];
        docs.forEach((doc) => {
          let storeItem = doc.data();
          storeItem.ref = doc.ref;
          storeItem.id = doc.id;
          if (storeItem.name)
            storeItem.ref.update({ PRODUCT_NAME: storeItem.name });
          if (storeItem.images && storeItem.images.length > 0) {
            storeItem.imageAvailable = true;
          } else storeItem.imageAvailable = false;
          //storeItem.ref.update({deleted:false, createdAt: new Date(), name: "Product-" + counter})
          // console.log(storeItem);
          // storeItem.ref.update({createdByRef: this.userData.ref})
          // console.log(storeItem.PRODUCT_TYPE, storeItem.ref.path);

          this.products.push(storeItem);
        });
      });

    fb.db
      .collection("_esisCookingProducts")
      .orderBy("name", "asc")
      .onSnapshot((querySnapshot) => {
        this.esisProducts = [];
        this.esisBasicTypes = [];
        var counter = 0;
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          counter++;
          item.index = counter;
          // console.log(item);

          var found = this.esisBasicTypes.find((xx) => xx == item.PRODUCT_TYPE);
          if (!found) this.esisBasicTypes.push(item.PRODUCT_TYPE);

          this.esisProducts.push(item);
        });
        console.log("acc", this.esisBasicTypes);
      });
  },
};
</script>
<style></style>
