<template>
  <v-container class="mx-auto" style="max-width: 1500px">
    <v-card class="border-radius-xl card-shadow pt-4 pb-10 mb-5 px-4">
      <div class="py-4 mt-2">
        <v-row no-gutters>
          <v-col cols="9">
            <h2 class="text-typo">Багшийн үнэлгээ2 баталгаажуулалт</h2>
            <p class="text-body">
              Бүртгэлтэй багшийн тоо
              <span class="blue--text" v-if="teachers">{{
                teachers.length
              }}</span>
            </p>
          </v-col>
          <v-col cols="3">
            <v-select
              style="background-color: #f2e2fa"
              :items="[
                {
                  yearId: 1,
                  name: preText + ' xичээлийн жил, 1-р xагас жил',
                },
                {
                  yearId: 2,
                  name: preText + ' xичээлийн жил, 2-р xагас жил',
                },
              ]"
              v-model="selectedHalfYear"
              label="Улирал"
              return-object
              item-text="name"
              item-value="yearId"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
      <v-data-table
        :headers="headerNames"
        :items="teachers"
        hide-details
        class="teacherMonitoringTable"
        hide-default-footer
        :items-per-page="-1"
        v-if="teachers"
      >
        <template slot="item" slot-scope="props">
          <tr style="cursor: pointer" v-if="renderComponent">
            <td @click="print(props.item)">{{ props.index + 1 }}</td>
            <td style="width: 16%" class="px-0 blue--text pl-2">
              {{ props.item["DISPLAY_NAME"] }},
            </td>
            <td>
              {{ props.item.lessonNumber }}
            </td>
            <td>
              {{ getAverageScore(props.item) }}
            </td>
            <td style="color: green">
              {{
                _getConfirmationProcent(props.item) >= 100 ||
                _getConfirmationProcent(props.item) == 0
                  ? _getConfirmationProcent(props.item) > 100
                    ? 100
                    : _getConfirmationProcent(props.item)
                  : _getConfirmationProcent(props.item).toFixed(1)
              }}%
            </td>
            <td style="color: #db9943">
              {{
                props.item[
                  "year" + selectedHalfYear.yearId + "UnelgeeScores"
                ] &&
                props.item["year" + selectedHalfYear.yearId + "UnelgeeScores"]
                  .inProgressNumber
                  ? props.item[
                      "year" + selectedHalfYear.yearId + "UnelgeeScores"
                    ].inProgressNumber
                  : 0
              }}
            </td>
            <td class="red--text">
              {{
                props.item[
                  "year" + selectedHalfYear.yearId + "UnelgeeScores"
                ] &&
                props.item["year" + selectedHalfYear.yearId + "UnelgeeScores"]
                  .rejectedNumber
                  ? props.item[
                      "year" + selectedHalfYear.yearId + "UnelgeeScores"
                    ].rejectedNumber
                  : 0
              }}
            </td>
            <td style="color: #800080">
              {{
                props.item[
                  "year" + selectedHalfYear.yearId + "UnelgeeScores"
                ] &&
                props.item["year" + selectedHalfYear.yearId + "UnelgeeScores"]
                  .savedNumber
                  ? props.item[
                      "year" + selectedHalfYear.yearId + "UnelgeeScores"
                    ].savedNumber
                  : 0
              }}
            </td>
            <td>
              <v-btn
                x-small
                dark
                class="bg-gradient-success text-capitalize"
                @click="showTeacherUnelgee2Dialog(props.item)"
                v-if="_getConfirmationProcent(props.item) == 100"
                >Бүгд баталгаажсан</v-btn
              >
              <v-btn
                v-else
                class="bg-gradient-danger text-capitalize"
                x-small
                dark
                @click="showTeacherUnelgee2Dialog(props.item)"
                >Баталгаажуулаx</v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-progress-linear v-else indeterminate color="red"></v-progress-linear>
      <v-dialog
        style="background-color: white"
        v-model="showTeacherUnelgee2"
        fullscreen
        v-if="selectedTeacher && lessons"
      >
        <template v-slot:default>
          <v-card>
            <v-card-title>
              <h3 class="text-typo">{{ selectedTeacher.DISPLAY_NAME }}</h3>
              <v-spacer></v-spacer>
              <v-btn icon @click="showTeacherUnelgee2 = false">
                <span class="red--text">Буцаx ></span>
              </v-btn>
            </v-card-title>
            <Unelgee25ConfirmationsEmbed
              v-if="renderComponent"
              :selectedTeacher="selectedTeacher"
              :teacherLessons="lessons"
            >
            </Unelgee25ConfirmationsEmbed>
          </v-card>
        </template>
      </v-dialog>
    </v-card>
  </v-container>
</template>
<script>
// const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
import Unelgee25ConfirmationsEmbed from "./Unelgee25ConfirmationsEmbed.vue";
export default {
  name: "smart-home",
  components: {
    Unelgee25ConfirmationsEmbed,
  },
  computed: {
    ...sync("*"),
  },
  data() {
    return {
      selectedTeacher: null,
      lessons: null,
      showTeacherUnelgee2: false,
      selectedHalfYear: null,
      renderComponent: true,
      teachers: null,
      headerNames: [
        {
          text: "No",
          align: "start",
          value: "index",
          width: "1%",
        },
        {
          text: "Багшийн нэр",
          value: "DISPLAY_NAME",
        },
        {
          text: "Xичээлийн тоо",
          value: "lessons.length",
        },
        {
          text: " Гүйцэтгэл ",
          value: "",
        },
        {
          text: "Баталгаажсан Хувь",
          value: "confirmedPercent",
        },
        {
          text: "Хянагдаж байгаа",
          value: "inProgressNumberAll",
        },
        {
          text: "Цуцлагдсан",
          value: "rejectedNumberAll",
        },
        {
          text: "Бүртгэсэн",
          value: "sentNumberAll",
        },
      ],
      loading: false,
    };
  },
  created() {
    this.preText =
      this.userData.school.currentYear +
      "-" +
      (this.userData.school.currentYear + 1);
    this.selectedHalfYear = {
      yearId: 1,
      name: this.preText + " xичээлийн жил, 1-р xагас жил",
    };
  },
  watch: {
    selectedHalfYear() {
      if (this.userData.school) this._setupp();
    },
  },
  methods: {
    async showTeacherUnelgee2Dialog(teacher) {
      var query = await this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("deleted", "==", false)
        .where("teacherRefs", "array-contains", teacher.ref)
        .orderBy("courseInfo.COURSE_NAME", "asc");

      this.loading = true;
      query.onSnapshot((docs) => {
        this.lessons = [];
        docs.forEach(async (doc) => {
          let lesson = doc.data();
          lesson.id = doc.id;
          lesson.ref = doc.ref;

          if (
            this.userData.school &&
            this.userData.school.id == "bIZDwo6lAhHPQLBfdfrX"
          ) {
            //1230
            if (
              !lesson.deleted &&
              !(
                lesson.courseInfo.SUBJECT_AREA_ID == 18 ||
                lesson.courseInfo.SUBJECT_AREA_ID == 233
              )
            ) {
              this.lessons.push(lesson);
            }
          } else if (!lesson.deleted) {
            this.lessons.push(lesson);
          }
        });
        this.loading = false;
      });

      this.selectedTeacher = teacher;
      this.selectedTeacher.school = this.userData.school;
      this.showTeacherUnelgee2 = !this.showTeacherUnelgee2;
      this.forceRerender();
    },
    print(teacher) {
      console.log(teacher);
    },
    _getConfirmationProcent(teacher) {
      var xxx = teacher["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
        ? teacher["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .approvedNumber
        : 0;
      var yyy = teacher["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
        ? teacher["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .totalLessonStudentsNumber
        : 0;
      if (xxx && yyy) return (xxx / yyy) * 100;
      return 0;
    },
    getAverageScore(teacher) {
      return teacher["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] &&
        teacher["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
          .scoreAverage
        ? teacher["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .scoreAverage
        : 0;
    },
    _setupp() {
      this.loading = true;
      this.userData.school.ref
        .collection("teachers")
        .where("role", "==", "teacher")
        .where("deleted", "==", false)
        .orderBy("FIRST_NAME", "asc")
        .get()
        .then((docs) => {
          this.teachers = [];
          docs.forEach(async (doc) => {
            let teacher = doc.data();
            teacher.id = doc.id;
            teacher.ref = doc.ref;

            if (!teacher.moved) {
              await this.userData.school.ref
                .collection(
                  "unelgee2-" + this.userData.school.currentYear + "-teachers"
                )
                .doc(String(teacher.id))
                .onSnapshot((doc) => {
                  if (doc.exists) {
                    let teacherTMP = doc.data();
                    teacher[
                      "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                    ] = {};
                    if (
                      teacherTMP[
                        "year" +
                          this.selectedHalfYear.yearId +
                          "UnelgeeScores-" +
                          this.userData.school.currentYear
                      ]
                    ) {
                      teacher[
                        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                      ].totalLessonStudentsNumber = 0;

                      teacher[
                        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                      ].approvedNumber = 0;
                      teacher[
                        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                      ].inProgressNumber = 0;
                      teacher[
                        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                      ].rejectedNumber = 0;
                      teacher[
                        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                      ].savedNumber = 0;
                      teacher[
                        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                      ].scoreAverage = 0;

                      var noStudWorkaround = 0;
                      var divideBy = 0;

                      for (var classId in teacherTMP[
                        "year" +
                          this.selectedHalfYear.yearId +
                          "UnelgeeScores-" +
                          this.userData.school.currentYear
                      ]) {
                        if (
                          typeof teacherTMP[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores-" +
                              this.userData.school.currentYear
                          ][classId] === "object"
                        ) {
                          if (
                            teacherTMP[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores-" +
                                this.userData.school.currentYear
                            ][classId].approvedNumber
                          )
                            teacher[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].approvedNumber =
                              teacher[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores"
                              ].approvedNumber +
                              teacherTMP[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores-" +
                                  this.userData.school.currentYear
                              ][classId].approvedNumber;

                          if (
                            teacherTMP[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores-" +
                                this.userData.school.currentYear
                            ][classId].inProgressNumber
                          )
                            teacher[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].inProgressNumber =
                              teacher[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores"
                              ].inProgressNumber +
                              teacherTMP[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores-" +
                                  this.userData.school.currentYear
                              ][classId].inProgressNumber;

                          if (
                            teacherTMP[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores-" +
                                this.userData.school.currentYear
                            ][classId].rejectedNumber
                          )
                            teacher[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].rejectedNumber =
                              teacher[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores"
                              ].rejectedNumber +
                              teacherTMP[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores-" +
                                  this.userData.school.currentYear
                              ][classId].rejectedNumber;

                          if (
                            teacherTMP[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores-" +
                                this.userData.school.currentYear
                            ][classId].savedNumber
                          )
                            teacher[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].savedNumber =
                              teacher[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores"
                              ].savedNumber +
                              teacherTMP[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores-" +
                                  this.userData.school.currentYear
                              ][classId].savedNumber;

                          if (
                            teacherTMP[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores-" +
                                this.userData.school.currentYear
                            ][classId].scoreAverage
                          ) {
                            teacher[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].scoreAverage =
                              teacher[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores"
                              ].scoreAverage +
                              Number(
                                teacherTMP[
                                  "year" +
                                    this.selectedHalfYear.yearId +
                                    "UnelgeeScores-" +
                                    this.userData.school.currentYear
                                ][classId].scoreAverage
                              ) *
                                teacherTMP[
                                  "year" +
                                    this.selectedHalfYear.yearId +
                                    "UnelgeeScores-" +
                                    this.userData.school.currentYear
                                ][classId].numberStudentsOfUnelgee;
                          }

                          if (
                            teacherTMP[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores-" +
                                this.userData.school.currentYear
                            ] &&
                            teacherTMP[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores-" +
                                this.userData.school.currentYear
                            ][classId] &&
                            teacherTMP[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores-" +
                                this.userData.school.currentYear
                            ][classId].numberStudentsOfUnelgee
                          )
                            divideBy =
                              divideBy +
                              teacherTMP[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores-" +
                                  this.userData.school.currentYear
                              ][classId].numberStudentsOfUnelgee;

                          if (
                            teacherTMP[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores-" +
                                this.userData.school.currentYear
                            ][classId].numberStudentsOfUnelgee
                          ) {
                            teacher[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores"
                            ].totalLessonStudentsNumber =
                              teacher[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores"
                              ].totalLessonStudentsNumber +
                              teacherTMP[
                                "year" +
                                  this.selectedHalfYear.yearId +
                                  "UnelgeeScores-" +
                                  this.userData.school.currentYear
                              ][classId].numberStudentsOfUnelgee;
                            noStudWorkaround++;
                          }
                        }
                      }
                      if (
                        noStudWorkaround >
                        teacherTMP[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores-" +
                            this.userData.school.currentYear
                        ].electiveLessonNumber +
                          teacherTMP[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores-" +
                              this.userData.school.currentYear
                          ].mandatoryLessonNumber
                      ) {
                        noStudWorkaround =
                          teacherTMP[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores-" +
                              this.userData.school.currentYear
                          ].electiveLessonNumber +
                          teacherTMP[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores-" +
                              this.userData.school.currentYear
                          ].mandatoryLessonNumber;
                      }

                      teacher.lessonNumber =
                        (teacherTMP[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores-" +
                            this.userData.school.currentYear
                        ] &&
                        teacherTMP[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores-" +
                            this.userData.school.currentYear
                        ].electiveLessonNumber
                          ? teacherTMP[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores-" +
                                this.userData.school.currentYear
                            ].electiveLessonNumber
                          : 0) +
                        (teacherTMP[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores-" +
                            this.userData.school.currentYear
                        ] &&
                        teacherTMP[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores-" +
                            this.userData.school.currentYear
                        ].mandatoryLessonNumber
                          ? teacherTMP[
                              "year" +
                                this.selectedHalfYear.yearId +
                                "UnelgeeScores-" +
                                this.userData.school.currentYear
                            ].mandatoryLessonNumber
                          : 0);

                      if (divideBy > 1) {
                        divideBy = divideBy * 100;

                        teacher[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores"
                        ].scoreAverage = (
                          (teacher[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores"
                          ].scoreAverage /
                            (divideBy > 0 ? divideBy : 1)) *
                          100
                        ).toFixed(1);
                      }

                      teacher[
                        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                      ].totalLessonStudentsNumber =
                        teacher[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores"
                        ].totalLessonStudentsNumber +
                        (teacherTMP[
                          "year" +
                            this.selectedHalfYear.yearId +
                            "UnelgeeScores-" +
                            this.userData.school.currentYear
                        ].electiveLessonNumber +
                          teacherTMP[
                            "year" +
                              this.selectedHalfYear.yearId +
                              "UnelgeeScores-" +
                              this.userData.school.currentYear
                          ].mandatoryLessonNumber -
                          noStudWorkaround) *
                          20; //workaround
                    }
                    this.forceRerender();
                  } else {
                    teacher[
                      "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                    ] = {};
                  }
                });

              this.teachers.push(teacher);
            }
          });
          this.loading = false;
        });
    },
    _print(item) {
      console.log(item.ref.path);
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
  },
};
</script>
<style>
.teacherMonitoringTable td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 32px !important;
  padding: 0px 0px 0px 2px;
}

.teacherMonitoringTable th {
  border: 1px solid #dddddd;
  text-align: center;
  height: 32px !important;
  padding: 0 !important;
  padding-left: 5px !important;
}
</style>
