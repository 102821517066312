<template>
  <v-container
    class="my-6 mx-auto"
    style="max-width: 100% !important; background-color: white !important"
  >
    <v-row class="mx-2">
      <v-col>
        <h2 v-if="days" class="text-typo">
          <span v-if="!selectedMonth">  {{ selectedSemester }}-р улирлын нийт xичээллэx өдөр: </span>
          <span v-else>  {{ selectedMonth.name }}-р сарын нийт xичээллэx өдөр: </span>
          <span class="red--text">{{ days.length }}</span>
        </h2>
      </v-col>
      <v-col class="text-end">
        <v-btn color="green" dark @click="_download()" :disabled="loading">
          Файл татаx
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="px-4 py-5">
      <v-row justify="space-between">
        <v-col cols="4">
          <v-select
            :items="calendarButez.map((ss) => ss.session)"
            v-model="selectedSemester"
            label="Улирал сонгоx"
          >
          </v-select>
        </v-col>
        <!-- <v-col>
          <h2 class="mx-4">
            {{ todayDateYear }}/{{ todayDateMonth }}/{{ todayDateDay }}
          </h2>
        </v-col> -->
        <v-col v-if="selectedSession" cols="3" class="text-end">
          <v-select
            clearable
                 style="background-color: #F8BBD0;"
            :items="selectedMonths"
            v-model="selectedMonth"
            return-object
            item-text="name"
            label="Сар сонгоx"
          >
          </v-select>
        </v-col>
      </v-row>
      <template v-if="selectedMonth">
        <v-data-table
          class="irzOverviewTable"
          v-if="students && loading == false"
          :headers="headers"
          :items="students"
          hide-default-header
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th class="text-start px-1">No</th>
                <th class="pl-2">Нэр</th>
                <th>Ирсэн%</th>
                <th>Ирсэн</th>
                <th>Тасалсан</th>
                <th>Өвчтэй</th>
                <th>Чөлөөтэй</th>

                <th
                  v-for="(day, dayIndex) in days"
                  :key="
                    'header' +
                    $attrs.currentSelectedSemester +
                    'days' +
                    dayIndex +
                    selectedMonth.name +
                    day
                  "
                  class="text-center px-0 blue--text"
                  style="
                    padding-left: 4px !important;
                    padding-right: 2px !important;
                  "
                >
                  {{ selectedMonth.name }}/{{ day }}
                </th>
              </tr>
              <tr style="background-color: #bbdefb">
                <td colspan="2"></td>
                <td>{{ _calculateSumme("total%") }}%</td>
                <td>{{ _calculateSumme("presentNumber") }}</td>
                <td>{{ _calculateSumme("absentNumber") }}</td>
                <td>{{ _calculateSumme("sickNumber") }}</td>
                <td>{{ _calculateSumme("excusedNumber") }}</td>
              </tr>
            </thead>
          </template>
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-center px-1" style="width: 1%">
                {{ props.index + 1 }}
              </td>
              <td class="pl-2" style="width: 30%">
                {{ props.item["FIRST_NAME"] }},
                <span style="color: #a3a3a3">{{
                  props.item["LAST_NAME"]
                }}</span>
              </td>
              <td>
                {{
                  ((_getPresentDays(props.item) / days.length) * 100).toFixed(0)
                }}
              </td>
              <td style="background-color: #f9fff2">
                <strong
                  v-if="
                    props.item.absentNumber > 0 ||
                    props.item.sickNumber > 0 ||
                    props.item.excusedNumber
                  "
                  >{{
                    days.length -
                    props.item.absentNumber -
                    props.item.sickNumber -
                    props.item.excusedNumber
                  }}</strong
                >
                <span v-else style="color: grey">{{
                  days.length -
                  props.item.absentNumber -
                  props.item.sickNumber -
                  props.item.excusedNumber
                }}</span>
              </td>

              <td
                class="text-center"
                v-if="props.item.absentNumber > 0"
                style="width: 5%; background-color: red; color: white"
              >
                <span>{{ props.item.absentNumber }}</span>
              </td>
              <td v-else></td>

              <td
                class="text-center"
                v-if="props.item.sickNumber > 0"
                style="width: 5%; background-color: #ffa500; color: white"
              >
                <span>{{ props.item.sickNumber }}</span>
              </td>
              <td v-else></td>

              <td
                class="text-center"
                v-if="props.item.excusedNumber > 0"
                style="width: 5%; background-color: yellow"
              >
                <span>{{ props.item.excusedNumber }}</span>
              </td>
              <td v-else></td>

              <td
                v-for="(day, dayIndex) in days"
                :key="
                  'body' +
                  $attrs.currentSelectedSemester +
                  'days' +
                  dayIndex +
                  selectedMonth.name +
                  day
                "
                class="text-center px-1 text-lowercase"
                :style="
                  getStudentAttendance(props.item, day) == 'И'
                    ? 'background:#f9fff2; color: grey !important'
                    : getStudentAttendance(props.item, day) == 'Тодорxойгүй' //Тодорxойгүй
                    ? 'background:#bbb'
                    : getStudentAttendance(props.item, day) == 'Xоцорсон' //Xоцорсон
                    ? 'background:#87B3FC'
                    : getStudentAttendance(props.item, day) == 'Чөлөөтэй' //Чөлөөтэй
                    ? 'background:yellow; color:black!important'
                    : getStudentAttendance(props.item, day) == 'Өвчтэй' //Өвчтэй
                    ? 'background:#FFA500; color:white'
                    : getStudentAttendance(props.item, day) == 'Тасалсан' //Тасалсан
                    ? 'background:red; color:white'
                    : ''
                "
              >
                {{ _getChar(getStudentAttendance(props.item, day)) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
      <h1
        v-else-if="(!students || students.length == 0) && loading == false"
        class="text-center"
      >
        Сурагчгүй
      </h1>
      <!-- <v-progress-linear v-else indeterminate color="blue"></v-progress-linear> -->
      <template v-else>
        <v-data-table
          class="irzOverviewTable"
          v-if="students && loading == false"
          :headers="headers"
          :items="students"
          hide-default-header
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th class="text-start px-1">No</th>
                <th class="pl-2">Нэр</th>
                <th>Ирсэн</th>
                <th>Тасалсан</th>
                <th>Өвчтэй</th>
                <th>Чөлөөтэй</th>
                <th>Ирсэн%</th>
              </tr>
              <tr style="background-color: #bbdefb">
                <td colspan="2"></td>
                <td>{{ _calculateSumme("presentNumber") }}</td>
                <td>{{ _calculateSumme("absentNumber") }}</td>
                <td>{{ _calculateSumme("sickNumber") }}</td>
                <td>{{ _calculateSumme("excusedNumber") }}</td>
                <td>{{ _calculateSumme("total%") }}%</td>
              </tr>
            </thead>
          </template>
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-center px-1" style="width: 1%">
                {{ props.index + 1 }}
              </td>
              <td class="pl-2" style="width: 30%">
                {{ props.item["FIRST_NAME"] }},
                <span style="color: #a3a3a3">{{
                  props.item["LAST_NAME"]
                }}</span>
              </td>
              <td style="background-color: #f9fff2; width: 5%">
                <strong
                  v-if="
                    props.item.absentNumber > 0 ||
                    props.item.sickNumber > 0 ||
                    props.item.excusedNumber
                  "
                  >{{ _getPresentDays(props.item) }}</strong
                >
                <span v-else style="color: grey">{{
                  _getPresentDays(props.item)
                }}</span>
              </td>
              <td
                class="text-center"
                v-if="props.item.absentNumber > 0"
                style="width: 5%; background-color: red; color: white"
              >
                <span>{{ props.item.absentNumber }}</span>
              </td>
              <td v-else></td>

              <td
                class="text-center"
                v-if="props.item.sickNumber > 0"
                style="width: 5%; background-color: #ffa500; color: white"
              >
                <span>{{ props.item.sickNumber }}</span>
              </td>
              <td v-else></td>

              <td
                class="text-center"
                v-if="props.item.excusedNumber > 0"
                style="width: 5%; background-color: yellow"
              >
                <span>{{ props.item.excusedNumber }}</span>
              </td>
              <td v-else></td>
              <td>
                {{
                  ((_getPresentDays(props.item) / days.length) * 100).toFixed(
                    0
                  )
                }}%
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import XLSX from "xlsx";
Vue.use(VueSweetalert2);
// const fb = require("@/firebaseConfig.js");

export default {
  data: () => ({
    selectedMonths: null,
    selectedSession: null,
    selectedSemester: null,
    allAttendances: null,
    startSemesterMonth: null,
    endSemesterMonth: null,
    startSemesterDay: null,
    endSemesterDay: null,

    todayDateYear: null,
    todayDateMonth: null,
    todayDateDay: null,
    loading: true,
    months: [1, 2, 3, 4, 5, 6, 9, 10, 11, 12],
    days: null,
    students: null,
    selectedYear: null,
    selectedMonth: null,
    headers: [
      {
        text: "No",
      },
      {
        text: "Name",
      },
    ],
    noStudents: false,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    calendarButez() {
      return this.$store.state.calendarButez2;
    },
  },
  props: {
    currentClassGroup: {
      required: true,
      type: Object,
    },
  },
  watch: {
    selectedSemester(val) {
      console.log("selectedSemester", val);
      this.selectedSession = this.calendarButez.find((ss) => ss.session == val);
      console.log("sess", this.selectedSession);
      if (this.selectedSession && this.selectedSession.months) {
        this.selectedMonths = [];
        for (const mm of this.selectedSession.months) {
          this.selectedMonths.push(mm);
        }
      }
      if (!this.selectedMonth) {
        this._calculateAllWorkingDays(this.selectedSession);
        this.getAttendances(this.selectedSession.year, null);
        setTimeout(() => {
          if (this.students.length == 0) {
            this.noStudents = true;
          }
        }, 3000);
      }
    },
    selectedYear(val) {
      console.log(val);
    },
    selectedMonth(val) {
      if (val && val.days) {
        this.days = [];
        for (const dd of val.days) {
          dd.month = val.name;
          if (!dd.isHoliday && this._isNotFuture(dd.year, dd.month, dd.day)) {
            this.days.push(dd.day);
          }
        }
        this.getAttendances(this.selectedSession.year, this.selectedMonth.name);
        setTimeout(() => {
          if (this.students.length == 0) {
            this.noStudents = true;
          }
        }, 3000);
      } else {
        this._calculateAllWorkingDays(this.selectedSession);
        this.getAttendances(this.selectedSession.year, null);
        setTimeout(() => {
          if (this.students.length == 0) {
            this.noStudents = true;
          }
        }, 3000);
      }
    },
  },
  methods: {
    _calculateSumme(keyWord) {
      var summe = 0;
      if (keyWord == "presentNumber") {
        this.students.forEach((student) => {
          summe = summe + this._getPresentDays(student);
        });
      } else if (keyWord == "total%") {
        this.students.forEach((student) => {
          summe =
            summe + (this._getPresentDays(student) / this.days.length) * 100;
        });
        summe = (summe / this.students.length).toFixed(0);
      } else {
        this.students.forEach((student) => {
          summe = summe + student[keyWord];
        });
      }
      return summe;
    },
    _download() {
      console.log("dfsdfsf");
      const wb = XLSX.utils.book_new();
      // this.excelLoading = true;
      var excelData = [];

      var row2 = {};
      row2["No"] = "";
      row2["Нэр"] = "";

      row2["Ирсэн"] = this._calculateSumme("presentNumber");
      row2["Тасалсан"] = this._calculateSumme("absentNumber");
      row2["Өвчтэй"] = this._calculateSumme("sickNumber");
      row2["Чөлөөтэй"] = this._calculateSumme("excusedNumber");
      row2["Ирсэн%"] = this._calculateSumme("total%");

      excelData.push(row2);
      this.students.forEach((student, sindex) => {
        var row = {};
        row["No"] = sindex + 1;
        row["Нэр"] = student["FIRST_NAME"] + "." + student["LAST_NAME"][0];

        row["Ирсэн"] = this._getPresentDays(student);
        row["Тасалсан"] = student.absentNumber;
        row["Өвчтэй"] = student.sickNumber;
        row["Чөлөөтэй"] = student.excusedNumber;
        row["Ирсэн%"] = ((row["Ирсэн"] / this.days.length) * 100).toFixed(0);
        excelData.push(row);
      });

      console.log(excelData);
      const data = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, data, "irz");
      this.excelLoading = false;
      var fileName = "irz-"
      if(this.selectedMonth)
        fileName = fileName + this.selectedMonth.name + "р сар"
      else fileName = fileName + this.selectedSemester + "р улирал";
      XLSX.writeFile(wb, fileName + ".xlsx");
    },
    _getPresentDays(student) {
      return (
        this.days.length -
        student.absentNumber -
        student.sickNumber -
        student.excusedNumber
      );
    },
    _calculateAllWorkingDays(session) {
      this.days = [];
      if (session && session.months) {
        for (const mm of session.months) {
          for (const dd of mm.days) {
            dd.month = mm.name;
            if (!dd.isHoliday && this._isNotFuture(dd.year, dd.month, dd.day)) {
              this.days.push(dd.day);
            }
          }
        }
      }
    },
    _isNotFuture(year, month, day) {
      var ok = false;
      var checkingDate = new Date(year, month - 1, day);
      var todayDate = new Date();
      if (checkingDate < todayDate) {
        ok = true;
      } else {
        ok = false;
      }
      return ok;
    },
    _getChar(text) {
      return text.charAt(0);
    },
    _getNumbers(attendances, stat) {
      var xx = attendances.filter((att) => att.status == stat);
      if (xx.length == 0) return null;
      else return xx.length;
    },
    getStudentAttendance(student, day) {
      var attendance = this.allAttendances.find(
        (att) => att.studentId == String(student["PERSON_ID"]) && att.day == day
      );
      if (attendance) {
        return attendance.text;
      } else {
        return "И";
      }
    },
    async getAttendances(year, month) {
      console.log("selectedYear", this.selectedYear, this.selectedMonth);
      this.allAttendances = [];
      this.loading = true;
      var qq = this.currentClassGroup.ref
        .collection("attendances-" + year)
        .where("year", "==", year)
        .where("daily_attendance", "==", true);

      if (month) qq = qq.where("month", "==", month);
      qq.get().then((docs) => {
        docs.forEach((doc) => {
          let attendance = doc.data();
          attendance.ref = doc.ref;
          attendance.id = doc.id;
          // student.attendances.push(attendance)
          // console.log(attendance);
          this.allAttendances.push(attendance);
        });
        for (const student of this.students) {
          student.sickNumber = 0;
          student.absentNumber = 0;
          student.excusedNumber = 0;
          for (const att of this.allAttendances) {
            if (att.studentId == String(student.PERSON_ID)) {
              // if (!student.attendances) student.attendances = [];
              if (att.status == "-2") student.excusedNumber++;
              else if (att.status == "-3") student.sickNumber++;
              else if (att.status == "-4") student.absentNumber++;

              // student.attendances.push(att);
            }
          }
        }
        this.loading = false;
      });
    },
    async getClassStudents(classGroup) {
      var docs = await this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", classGroup.STUDENT_GROUP_ID)
        .orderBy("FIRST_NAME", "asc")
        .get();
      docs.forEach((doc) => {
        let student = doc.data();
        student.id = doc.id;
        student.ref = doc.ref;
        student.attendances = [];
        if (student.moved != true) {
          this.students.push(student);
        }
      });
    },
  },
  async created() {
    console.log(this.selectedSemester, "sss");
    this.todayDateYear = new Date().getFullYear();
    this.todayDateMonth = new Date().getMonth() + 1;
    this.todayDateDay = new Date().getDate();

    this.selectedYear = new Date().getFullYear();

    this.students = [];
    this.loading = true;
    await this.getClassStudents(this.currentClassGroup);
    this.selectedSemester = this.$store.state.schoolCurrentSemester;
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.irzOverviewTable {
  border-collapse: collapse;
}

.irzOverviewTable td {
  border: 1px solid #dddddd;
  text-align: left;
  border: 1px solid #ddd;
  height: 1px !important;
}

.irzOverviewTable th {
  border: 1px solid #dddddd;
  height: 5px !important;
}

.fixed-column {
  position: sticky;
  left: 10px;
  z-index: 1;
  /* background-color: #ececec !important */
  /* Change this to your desired background color */
}
</style>
