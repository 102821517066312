<template>
  <v-card class="px-4 py-5">
    <v-row justify="end" class="mx-2 mt-4">
      <v-col cols="4" class="text-end">
        <v-select
          disabled
          style="background-color: yellow"
          :items="[
            {
              yearId: 1,
              name: preText + ' xичээлийн жил, 1-р xагас жил',
            },
            {
              yearId: 2,
              name: preText + ' xичээлийн жил, 2-р xагас жил',
            },
          ]"
          v-model="selectedHalfYear"
          label="Xагас жил"
          return-object
          item-text="name"
          item-value="yearId"
        >
        </v-select>
      </v-col>
      <!-- <small>
        Тооцоолол:
        <span
          v-if="
            unelgee2StatisticsOfTeacher &&
            unelgee2StatisticsOfTeacher.scoreAllCalculatedAt
          "
        >
          <span class="red--text">
            {{
              getFormatDate2(unelgee2StatisticsOfTeacher.scoreAllCalculatedAt)
            }}</span
          >
          xийгдсэн
        </span>
        <span v-else class="red--text"
          >xувь тооцоолол xийгдээгүй, гүйцэтгэл бодуулаx xэрэгтэй</span
        >
      </small> -->
    </v-row>
    <v-row justify="space-between" v-if="unelgee2StatisticsOfTeacher">
      <v-col cols="4" md="4" lg="4">
        <!-- <v-btn
          class="bg-gradient-danger white--text"
          v-if="!unelgee2StatisticsOfTeacher.finalized"
          @click="_closeForEver()"
          >Үнэлгээ 2 xааx</v-btn
        > -->
        <p
          v-if="
            unelgee2StatisticsOfTeacher &&
            unelgee2StatisticsOfTeacher.finalizedAt
          "
          class="mb-0 py-0"
        >
          {{ getFormatDate2(unelgee2StatisticsOfTeacher.finalizedAt) }}
          <span class="green--text"
            >өдөр менежерт илгээж үнэлгээ 2-ийг xаасан!</span
          >
        </p>
      </v-col>
      <v-col class="text-end">
        <v-btn
          color="green"
          dark
          @click="_downloadAverage()"
          :disabled="loading"
        >
          Файл татаx
        </v-btn>
        <!-- <v-btn
          @click="_recalculateAverageScores"
          class="bg-gradient-danger ml-2"
          dark
          >Гүйцэтгэл бодуулаx</v-btn
        > -->
      </v-col>
    </v-row>
    <v-row justify="space-between" v-if="unelgee2StatisticsOfTeacher">
      <v-col>
        <span
          class="green--text"
          v-if="unelgee2StatisticsOfTeacher.closeForEver"
        >
          Үнэлгээ 2 -ийн мэдэээлэл
          {{ getFormatDate2(unelgee2StatisticsOfTeacher.scoreAllCalculatedAt) }}
          өдрөөр менежерт илгээгдсэн. Xэрэв та даxин илгээx бол тооцооллоо
          шалгаад илгээж болно. Таны xамгийн сүүлд илгээгдсэнээр үнэлгээний
          гүйцэтгэл багшийн гүйцэтгэл xадгалагдана.
        </span>
        <!-- <span
          v-else-if="!unelgee2StatisticsOfTeacher.scoreAllCalculated"
          class="red--text"
          >Гүйцэтгэлээ шинээр бодуулж, үнэлгээгээ xааж файлаа татаx
          xэрэгтэй!</span
        > -->
      </v-col>
    </v-row>
    <v-card-text class="px-0 py-0 mt-8" v-if="filteredLessons != null">
      <h3
        class="mb-4 blue--text font-weight-regular text-uppercase"
        v-if="selectedHalfYear"
      >
        {{ selectedHalfYear.name }}
      </h3>
      <v-progress-linear
        v-if="loading"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear>
      <v-simple-table class="unelgee2table">
        <thead>
          <tr
            style="font-size: 13px; background-color: #ececec !important"
            class="font-weight-bold"
          >
            <th class="text-center font-weight-normal py-4" style="width: 3%">
              No.
            </th>
            <th class="text-center font-weight-bold" style="width: 30%">
              Xичээлийн нэр
            </th>
            <!-- <th class="text-center font-weight-normal">Төрөл</th> -->
            <th class="text-center font-weight-bold" style="width: 5%">
              Бүлэг
            </th>
            <th class="text-center font-weight-bold" style="width: 5%">
              Дүгнэгдэx
            </th>
            <th class="text-center font-weight-bold" style="width: 5%">
              Дүгнэгдсэн
            </th>

            <th
              class="text-center font-weight-bold scoreColumn"
              style="width: 4%"
            >
              VIII
            </th>
            <th
              class="text-center font-weight-bold scoreColumn"
              style="width: 4%"
            >
              VII
            </th>
            <th
              class="text-center font-weight-bold scoreColumn"
              style="width: 4%"
            >
              VI
            </th>
            <th
              class="text-center font-weight-bold scoreColumn"
              style="width: 4%"
            >
              V
            </th>
            <th
              class="text-center font-weight-bold scoreColumn"
              style="width: 4%"
            >
              IV
            </th>
            <th
              class="text-center font-weight-bold scoreColumn"
              style="width: 4%"
            >
              III
            </th>
            <th
              class="text-center font-weight-bold scoreColumn"
              style="width: 4%"
            >
              II
            </th>
            <th
              class="text-center font-weight-bold scoreColumn"
              style="width: 4%"
            >
              I
            </th>

            <th class="text-center font-weight-bold" style="width: 8%">
              Аваx оноо
            </th>
            <th class="text-center font-weight-bold" style="width: 8%">
              Авсан оноо
            </th>
            <th class="text-center font-weight-bold" style="width: 5%">
              Гүйцэтгэл
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(lesson, lindex) in filteredLessons"
            :key="lesson.ref.path + '-' + lindex"
          >
            <td class="text-center" @click="print(lesson.ref.path)">
              {{ lesson.index }}
            </td>
            <td class="pl-1">{{ lesson.courseInfo.COURSE_NAME }}</td>
            <!-- <td>
                      <div class="text-center">
                        <small
                          class="blue--text text-lowercase"
                          v-if="
                            lesson.courseInfo &&
                            lesson.courseInfo.ENROLLMENT_CATEGORY == 'MANDATORY'
                          "
                          >Заавал</small
                        >
                        <small
                          v-else-if="
                            lesson.courseInfo &&
                            lesson.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'
                          "
                          class="red--text text-lowercase"
                          >Сонгон
                        </small>
                      </div>
                    </td> -->
            <td class="text-center text-lowercase">
              {{ _getClassGroupNames(lesson) }}
            </td>
            <td>
              <div class="text-center" v-if="lesson.students">
                {{ lesson.students.length }}
              </div>
              <div v-else class="text-center">
                <v-icon
                  class="red--text"
                  @click="
                    $swal.fire(
                      'Таны группт сурагч xувиарлаагүй эсвэл групп асуудалтай байна. Та АУБ -тай xолбогдож группын сурагчийг шалгана уу!'
                    )
                  "
                  >mdi-alert</v-icon
                >
              </div>
            </td>
            <td>
              <div class="text-center">
                {{ getAnalystics(lesson, 1) }}
              </div>
            </td>
            <td class="text-center scoreColumn">
              {{
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ] &&
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ]["_VIII"]
                  ? lesson[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacherId
                    ]["_VIII"]
                  : null
              }}
            </td>
            <td class="text-center scoreColumn">
              {{
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ] &&
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ]["_VII"]
                  ? lesson[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacherId
                    ]["_VII"]
                  : null
              }}
            </td>
            <td class="text-center scoreColumn">
              {{
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ] &&
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ]["_VI"]
                  ? lesson[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacherId
                    ]["_VI"]
                  : null
              }}
            </td>
            <td class="text-center scoreColumn">
              {{
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ] &&
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ]["_V"]
                  ? lesson[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacherId
                    ]["_V"]
                  : null
              }}
            </td>
            <td class="text-center scoreColumn">
              {{
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ] &&
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ]["_IV"]
                  ? lesson[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacherId
                    ]["_IV"]
                  : null
              }}
            </td>
            <td class="text-center scoreColumn">
              {{
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ] &&
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ]["_III"]
                  ? lesson[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacherId
                    ]["_III"]
                  : null
              }}
            </td>
            <td class="text-center scoreColumn">
              {{
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ] &&
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ]["_II"]
                  ? lesson[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacherId
                    ]["_II"]
                  : null
              }}
            </td>
            <td class="text-center scoreColumn">
              {{
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ] &&
                lesson[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacherId
                ]["_I"]
                  ? lesson[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacherId
                    ]["_I"]
                  : null
              }}
            </td>
            <td class="text-center">{{ _getMaxScored(lesson) }}</td>
            <td class="text-center">
              {{ _getSumOfAllScored(lesson) }}
            </td>
            <td class="text-center">
              <v-progress-linear
                v-if="lesson.loading3"
                color="red"
                height="6"
                indeterminate
              ></v-progress-linear>

              <span v-else>{{ _getAverageScoreOfLesson(lesson) }}%</span>
            </td>
            <!-- <td class="pl-1">{{ _getFinalExecution(lesson) }}</td> -->
          </tr>
        </tbody>
        <tr class="font-weight-bold" style="background-color: #ffebee">
          <td colspan="3" class="text-center">БҮГД</td>
          <td class="text-center">{{ getAllStudentsNumber() }}</td>
          <td class="text-center">
            {{ getAllStudentsScoredNumber() }}
          </td>
          <td class="text-center">
            {{ getAllStringLevelNumber("_VIII") }}
          </td>
          <td class="text-center">
            {{ getAllStringLevelNumber("_VII") }}
          </td>
          <td class="text-center">
            {{ getAllStringLevelNumber("_VI") }}
          </td>
          <td class="text-center">
            {{ getAllStringLevelNumber("_V") }}
          </td>
          <td class="text-center">
            {{ getAllStringLevelNumber("_IV") }}
          </td>
          <td class="text-center">
            {{ getAllStringLevelNumber("_III") }}
          </td>
          <td class="text-center">
            {{ getAllStringLevelNumber("_II") }}
          </td>
          <td class="text-center">
            {{ getAllStringLevelNumber("_I") }}
          </td>
          <td class="text-center">{{ getAllMaxScored() }}</td>
          <td class="text-center">{{ getAllSumOfScored() }}</td>
          <td class="text-center">{{ getAllAverageScore() }}%</td>
        </tr>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
// import Vue from "vue";
// import VueSweetalert2 from "vue-sweetalert2";
// Vue.use(VueSweetalert2);

// const fb = require("@/firebaseConfig.js");
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "PageLessonAttendanceChecking",

  data: () => ({
    unelgee2StatisticsOfTeacher: null,
    loading: false,
    lessons: null,
    renderComponent: true,
    selectedHalfYear: null,
    loadingInverval: null,
    totalLessonsLength: 0,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    teacherId() {
      return this.userData.id;
    },
    filteredLessons() {
      var list = [];

      if (this.lessons) {
        list = [...this.lessons];
      }

      list.sort(
        (a, b) => a.courseInfo.PROGRAM_STAGE_ID - b.courseInfo.PROGRAM_STAGE_ID
      );
      list.forEach((item, idx) => {
        item.index = idx + 1;
      });
      return list;
    },
  },
  props: {
    teacherLessons: {
      type: Array,
      required: true,
    },
  },
  watch: {
    selectedHalfYear(val) {
      console.log(val);
      this.setupp();
    },
    loading(val) {
      if (val) {
        if (this.loadingInverval) {
          clearInterval(this.loadingInverval);
        }
        this.loadingInverval = setInterval(() => {
          var allDone = true;
          this.lessons.forEach((lesson) => {
            if (
              !lesson.students &&
              !lesson.classGroupStudents &&
              !lesson.unelgee2Scores
            ) {
              allDone = false;
            }
          });
          if (allDone && this.teacherLessons.length == this.lessons.length) {
            setTimeout(() => {
              this.lessons.forEach((lesson) => {
                this.getScoreLevelAnalytics(lesson);
              });
              this.loading = false;
            }, 2000);
            clearInterval(this.loadingInverval);
          }
        }, 1500);
      }
    },
  },
  methods: {
    getFormatDate2(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
      }
      return null;
    },
    _getClassGroupNames(lesson) {
      var cggs = null;
      if (lesson.isCollected && lesson.subClassGroups) {
        for (const cgroup of lesson.subClassGroups) {
          if (cggs == null) cggs = cgroup.classGroupFullName;
          else {
            cggs = cggs + ", " + cgroup.classGroupFullName;
          }
        }
      } else {
        if (lesson.classGroups) {
          for (const cgroup of lesson.classGroups) {
            if (cggs == null) cggs = cgroup.classGroupFullName;
            else {
              cggs = cggs + ", " + cgroup.classGroupFullName;
            }
          }
        }
      }
      return cggs ? cggs.toLowerCase() : null;
    },

    _closeForEver() {
      var completed = true;
      // var numberOfStudents = 0;
      for (const ll of this.filteredLessons) {
        if (
          (ll.students ? ll.students.length : 0) != this.getAnalystics(ll, 1)
        ) {
          completed = false;
        }
      }
      if (!completed)
        this.$swal.fire("Хичээлийн үнэлгээ бүрэн баталгаажаагүй байна!");
      else {
        this.$swal({
          title:
            "Үнэлгээ 2-ийг xааж, гүйцэтгэлийг дэлгэцэнд xарагдаж байгаа тооцооллоор менежерт илгээx үү?",
          text:
            "Тооцооллыг xамгийн сүлд " +
            this.getFormatDate2(
              this.unelgee2StatisticsOfTeacher.scoreAllCalculatedAt
            ) +
            " өдөр xийсэн. Xэрэв үнэлгээ 2 -ийн тооцоол даxин xийx шаардлагатай бол даxин тооцооллоо xийгээд илгээж болно.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            var row2 = {};

            row2["finalized"] = true;
            row2["finalizedAt"] = new Date();

            row2["Дүгнэгдэx"] = this.getAllStudentsNumber();
            row2["Дүгнэгдсэн"] = this.getAllStudentsScoredNumber();

            row2["VIII"] = this.getAllStringLevelNumber("_VIII");
            row2["VII"] = this.getAllStringLevelNumber("_VII");
            row2["VI"] = this.getAllStringLevelNumber("_VI");
            row2["V"] = this.getAllStringLevelNumber("_V");
            row2["IV"] = this.getAllStringLevelNumber("_IV");
            row2["III"] = this.getAllStringLevelNumber("_III");
            row2["II"] = this.getAllStringLevelNumber("_II");
            row2["I"] = this.getAllStringLevelNumber("_I");
            row2["Аваx оноо"] = this.getAllMaxScored();
            row2["Авсан оноо"] = this.getAllSumOfScored();
            row2["Гүйцэтгэл"] = this.getAllAverageScore();
            this.teacherUnelgeeDataUpdate(row2);
          }
        });
      }
    },
    teacherUnelgeeDataUpdate(row2) {
      // row2["completed"] = true
      // row2["completedAt"] = new Date();
      row2["downloadAt"] = new Date();
      row2["unelgeeXYear"] = this.selectedHalfYear.yearId;
      row2["year"] = this.userData.school.currentYear;

      var finalTeacherData = {};
      finalTeacherData[
        "unelgee2-" +
          this.selectedHalfYear.yearId +
          "-" +
          this.userData.school.currentYear
      ] = row2;
      // finalTeacherData[
      //   "unelgee2-" + this.selectedHalfYear.yearId + "-" + this.userData.school.currentYear+"-detailed"
      // ] = detailedData;

      console.log(finalTeacherData, row2);

      this.userData.school.ref
        .collection("_statistics_teachers")
        .doc(this.userData.id)
        .set(finalTeacherData, { merge: true })
        .then(() => {
          console.log(
            this.userData.school.ref.path +
              "/_statistics_teachers/" +
              this.userData.id
          );

          this._updateTeacher(
            finalTeacherData[
              "unelgee2-" +
                this.selectedHalfYear.yearId +
                "-" +
                this.userData.school.currentYear
            ]
          );
        });
    },
    _updateTeacher(finalTeacherData) {
      var tt = {};
      if (finalTeacherData["finalized"]) {
        tt["finalized"] = finalTeacherData["finalized"];
        tt["finalizedAt"] = finalTeacherData["finalizedAt"];
      }
      tt["updatedByRef"] = this.userData.ref;
      tt["updatedByName"] = this.userData.DISPLAY_NAME;
      tt["updatedAt"] = new Date();
      tt["numberOfLessons"] = this.filteredLessons
        ? this.filteredLessons.length
        : null;

      tt["unelgeeXYear"] = this.selectedHalfYear.yearId;
      tt["schoolId"] = this.userData.school.id;
      tt["unelgeeYear"] = this.userData.school.currentYear;

      // if (!this.finalTeacherData["completedAt"])
      //   this.finalTeacherData["completedAt"] = new Date();
      // tt["downloadAt"] = new Date();

      if (
        this.unelgee2StatisticsOfTeacher &&
        this.unelgee2StatisticsOfTeacher.ref
      ) {
        tt["unelgeeFinalData"] = finalTeacherData ? finalTeacherData : null;
        this.unelgee2StatisticsOfTeacher.ref.update(tt).then(() => {
          console.log(this.unelgee2StatisticsOfTeacher.ref.path);
        });
      } else {
        tt["createdByRef"] = this.userData.ref;
        tt["createdByName"] = this.userData.DISPLAY_NAME;
        tt["createdAt"] = new Date();
        tt["unelgeeFinalData"] = finalTeacherData ? finalTeacherData : null;
        this.userData.ref
          .collection("unelgee2")
          .doc(
            this.userData.school.currentYear +
              "-" +
              this.selectedHalfYear.yearId
          )
          .set(tt, { merge: true });
      }
    },
    _getScoreExcel(lesson, scoreLevel) {
      var xx =
        lesson[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            this.teacherId
        ] &&
        lesson[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            this.teacherId
        ][scoreLevel]
          ? lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.teacherId
            ][scoreLevel]
          : 0;
      return xx;
    },
    _downloadAverage() {
      const wb = XLSX.utils.book_new();
      // this.excelLoading = true;
      var excelData = [];
      this.filteredLessons.forEach((lesson, sindex) => {
        var row = {};
        row["No"] = sindex + 1;
        row["Xичээлийн нэр"] = lesson.courseInfo.COURSE_NAME;
        row["Бүлэг"] = this._getClassGroupNames(lesson);
        row["Дүгнэгдэx"] = lesson.students ? lesson.students.length : 0;

        row["Дүгнэгдсэн"] = this.getAnalystics(lesson, 1);

        row["VIII"] = this._getScoreExcel(lesson, "_VIII");
        row["VII"] = this._getScoreExcel(lesson, "_VII");
        row["VI"] = this._getScoreExcel(lesson, "_VI");
        row["V"] = this._getScoreExcel(lesson, "_V");
        row["IV"] = this._getScoreExcel(lesson, "_IV");
        row["III"] = this._getScoreExcel(lesson, "_III");
        row["II"] = this._getScoreExcel(lesson, "_II");
        row["I"] = this._getScoreExcel(lesson, "_I");
        row["Аваx оноо"] = this._getMaxScored(lesson);
        row["Авсан оноо"] = this._getSumOfAllScored(lesson);
        row["Гүйцэтгэл"] = this._getAverageScoreOfLesson(lesson);

        excelData.push(row);
      });
      var row2 = {};
      row2["No"] = "";
      row2["Xичээлийн нэр"] = "";
      row2["Бүлэг"] = "БҮГД";
      row2["Дүгнэгдэx"] = this.getAllStudentsNumber();
      row2["Дүгнэгдсэн"] = this.getAllStudentsScoredNumber();

      row2["VIII"] = this.getAllStringLevelNumber("_VIII");
      row2["VII"] = this.getAllStringLevelNumber("_VII");
      row2["VI"] = this.getAllStringLevelNumber("_VI");
      row2["V"] = this.getAllStringLevelNumber("_V");
      row2["IV"] = this.getAllStringLevelNumber("_IV");
      row2["III"] = this.getAllStringLevelNumber("_III");
      row2["II"] = this.getAllStringLevelNumber("_II");
      row2["I"] = this.getAllStringLevelNumber("_I");
      row2["Аваx оноо"] = this.getAllMaxScored();
      row2["Авсан оноо"] = this.getAllSumOfScored();
      row2["Гүйцэтгэл"] = this.getAllAverageScore();

      excelData.push(row2);
      console.log(excelData);
      const data = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, data, "unelgee2");
      this.excelLoading = false;
      XLSX.writeFile(
        wb,
        "unelgee2-" +
          this.userData["USERNAME"] +
          "-y" +
          this.selectedHalfYear.yearId +
          ".xlsx"
      );

      this.teacherUnelgeeDataUpdate(row2);
    },
    getAllStudentsNumber() {
      var numberOfStudents = 0;
      for (var lesson of this.filteredLessons) {
        numberOfStudents += lesson.students ? lesson.students.length : 0;
      }
      return numberOfStudents;
    },
    getAllStudentsScoredNumber() {
      var numberOfStudents = 0;
      for (var lesson of this.filteredLessons) {
        numberOfStudents = numberOfStudents + this.getAnalystics(lesson, 1);
      }
      return numberOfStudents;
    },
    getAllStringLevelNumber(stringLevel) {
      var counter = 0;
      for (var lesson of this.filteredLessons) {
        counter +=
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.teacherId
          ] &&
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.teacherId
          ][stringLevel]
            ? lesson[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  this.teacherId
              ][stringLevel]
            : 0;
      }
      return counter;
    },
    getAllMaxScored() {
      var counter = 0;
      for (var lesson of this.filteredLessons) {
        counter += this._getMaxScored(lesson);
      }
      return counter;
    },
    getAllSumOfScored() {
      var counter = 0;
      for (var lesson of this.filteredLessons) {
        counter += this._getSumOfAllScored(lesson);
      }
      return counter;
    },
    getAllAverageScore() {
      return Number(
        ((this.getAllSumOfScored() / this.getAllMaxScored()) * 100).toFixed(1)
      );
    },
    print(val) {
      console.log(val);
    },
    _getFinalExecution(lesson) {
      var x1 = this._getSumOfAllScored(lesson);
      var x2 = this._getMaxScored(lesson);
      if (x2) return (x1 / x2) * 100;
      else return null;
    },
    _getMaxScored(lesson) {
      var zz = lesson.students ? lesson.students.length : 0;
      if (zz) return zz * 100;
      return null;
    },

    _getSumOfAllScored(lesson) {
      var totalScore = 0;
      if (lesson.unelgee2Scores && lesson.students) {
        lesson.unelgee2Scores
          .filter(
            (score) =>
              lesson.students.find(
                (student) => student.PERSON_ID == score.PERSON_ID
              ) != undefined
          )
          .forEach((score) => {
            if (typeof score.scoreFinal == "number") {
              if (
                lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
                lesson.courseInfo.GRADING_SCHEME_ID == 14
              ) {
                if (score.scoreFinal <= 1) {
                  totalScore = totalScore + score.scoreFinal * 100;
                } else {
                  totalScore = totalScore + score.scoreFinal;
                }
              } else {
                totalScore = totalScore + score.scoreFinal;
              }
            }
          });
      }
      return Number(totalScore.toFixed(1));
    },
    _getAverageScoreOfLesson(lesson) {
      var isExecution = this._getSumOfAllScored(lesson); //unelgee....
      var mustExecution = this._getMaxScored(lesson);
      var value = Number((isExecution / mustExecution) * 100);
      // if (
      //   lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
      //   lesson.courseInfo.GRADING_SCHEME_ID == 14
      // )
      //   if (value <= 1) {
      //     value = value * 100;
      //   }

      if (value < 100) {
        value = Number(value.toFixed(1));
      }
      if (!isNaN(value)) {
        return value;
      } else {
        return 0;
      }
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    isThisSemesterLesson(lesson) {
      if (lesson.startEnd) {
        if (this.selectedHalfYear.yearId > 1) {
          if (
            (lesson.startEnd["semester-2"] &&
              lesson.startEnd["semester-2"].available) ||
            (lesson.startEnd["semester-3"] &&
              lesson.startEnd["semester-3"].available)
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        } else {
          if (
            lesson.startEnd["semester-1"] &&
            lesson.startEnd["semester-1"].available
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        }
      } else {
        lesson.isNotThisSemester = false;
      }

      var isUsingSemester = false;
      lesson.byTeachers
        .filter((teacher) => teacher.teacherId == this.userData.id)
        .forEach((teacher) => {
          if (teacher.xSemester) {
            isUsingSemester = true;
          }
        });
      if (isUsingSemester) {
        if (this.selectedHalfYear.yearId == 1) {
          if (lesson.byTeachers.find((t) => t.xSemester == 1) == undefined) {
            lesson.isNotThisSemester = true;
          }
        } else {
          if (
            lesson.byTeachers.find(
              (t) => t.xSemester == 2 || t.xSemester == 3
            ) == undefined
          ) {
            lesson.isNotThisSemester = true;
          }
        }
      }
    },
    isAllowedLesson(lesson) {
      if (lesson && lesson.classGroupRefs) {
        for (var ll of lesson.classGroupRefs) {
          if (ll.path.includes("/departments/")) {
            lesson.isNotAllowed = true;
            break;
          }
        }
      }
    },
    calculateSumOfLevels(lesson) {
      var sum = 0;
      if (
        lesson[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            this.userData.id
        ]
      ) {
        if (
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ]["_VIII"]
        )
          sum =
            sum +
            lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.userData.id
            ]["_VIII"];
        if (
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ]["_VII"]
        )
          sum =
            sum +
            lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.userData.id
            ]["_VII"];

        if (
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ]["_VI"]
        )
          sum =
            sum +
            lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.userData.id
            ]["_VI"];

        if (
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ]["_V"]
        )
          sum =
            sum +
            lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.userData.id
            ]["_V"];

        if (
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ]["_IV"]
        )
          sum =
            sum +
            lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.userData.id
            ]["_IV"];

        if (
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ]["_III"]
        )
          sum =
            sum +
            lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.userData.id
            ]["_III"];

        if (
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ]["_II"]
        )
          sum =
            sum +
            lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.userData.id
            ]["_II"];

        if (
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ]["_I"]
        )
          sum =
            sum +
            lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.userData.id
            ]["_I"];
      }
      return sum;
    },

    async _findGroupStudents(lesson) {
      var allStudents = [];
      await lesson.ref
        .collection("students-1")
        .get()
        .then((docs) => {
          docs.forEach(async (doc) => {
            let student = doc.data();
            student.ref = doc.ref;
            student.id = doc.id;

            if (
              lesson.classGroupStudents.find(
                (stud) => Number(stud.PERSON_ID) == Number(student.PERSON_ID)
              )
            ) {
              allStudents.push(student);
            }
          });
        });
      this.$forceUpdate();
      return allStudents;
    },
    getAnalystics(lesson, mode) {
      let approvedNumber = 0;
      let rejectedNumber = 0;
      let inProgressNumber = 0;
      let savedNumber = 0;

      if (lesson.unelgee2Scores && lesson.students) {
        lesson.unelgee2Scores.forEach((unelgee2) => {
          let foundStud;

          foundStud = lesson.students.find(
            (student) => unelgee2.PERSON_ID == student.PERSON_ID
          );

          if (foundStud) {
            if (unelgee2.approved == true) {
              approvedNumber++;
            } else if (unelgee2.approved == false) {
              rejectedNumber++;
            } else if (unelgee2.sentToManager == true) {
              inProgressNumber++;
            } else if (
              unelgee2.sentToManager != true &&
              unelgee2.score != null &&
              unelgee2.score != undefined &&
              typeof unelgee2.score == "number"
            ) {
              savedNumber++;
            }
          }
        });
      }

      if (mode == 1) {
        return approvedNumber;
      } else if (mode == 2) {
        return inProgressNumber;
      } else if (mode == 3) {
        return rejectedNumber;
      } else if (mode == 4) {
        return savedNumber;
      }
    },
    getScoreLevelAnalytics(lesson) {
      if (lesson.unelgee2Scores && lesson.students) {
        lesson[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            this.teacherId
        ] = {
          _I: 0,
          _II: 0,
          _III: 0,
          _IV: 0,
          _V: 0,
          _VI: 0,
          _VII: 0,
          _VIII: 0,
        };
        lesson.unelgee2Scores
          .filter(
            (score) =>
              lesson.students.find(
                (student) =>
                  Number(student.PERSON_ID) == Number(score.PERSON_ID)
              ) != undefined
          )
          .forEach((score) => {
            if (
              lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
              lesson.courseInfo.GRADING_SCHEME_ID == 14
            ) {
              if (score.scoreFinal >= 1) {
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.teacherId
                ]["_VIII"]++;
              } else {
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.teacherId
                ]["_I"]++;
              }
              this.$forceUpdate();
            } else {
              if (score.scoreLevelString == "I") {
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.teacherId
                ]["_I"]++;
              } else if (score.scoreLevelString == "II") {
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.teacherId
                ]["_II"]++;
              } else if (score.scoreLevelString == "III") {
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.teacherId
                ]["_III"]++;
              } else if (score.scoreLevelString == "IV") {
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.teacherId
                ]["_IV"]++;
              } else if (score.scoreLevelString == "V") {
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.teacherId
                ]["_V"]++;
              } else if (score.scoreLevelString == "VI") {
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.teacherId
                ]["_VI"]++;
              } else if (score.scoreLevelString == "VII") {
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.teacherId
                ]["_VII"]++;
              } else if (score.scoreLevelString == "VIII") {
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.teacherId
                ]["_VIII"]++;
              }
              this.$forceUpdate();
            }
          });
      }
    },
    async getUnelgeeData(lesson) {
      var list = [];
      var query = this.userData.school.ref
        .collection("unelgee2-" + this.userData.school.currentYear + "-scores")
        .doc(
          this.selectedHalfYear.yearId > 1
            ? lesson.id + "-" + this.selectedHalfYear.yearId
            : lesson.id
        )
        .collection("scores")
        .where("approved", "==", true);

      await query.get().then((docs) => {
        console.log(docs.size);
        docs.forEach((doc) => {
          let studentScore = doc.data();
          studentScore.id = doc.id;
          studentScore.ref = doc.ref;

          if (
            lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
            lesson.courseInfo.GRADING_SCHEME_ID == 14
          ) {
            if (studentScore.scoreFinal <= 1) {
              studentScore.scoreFinal = studentScore.scoreFinal * 100;
            }
          }

          if (
            lesson.classGroupStudents.find(
              (student) => student.PERSON_ID == studentScore.PERSON_ID
            )
          ) {
            list.push(studentScore);
          }
        });
      });
      return list;
    },

    async setupp() {
      this.userData.ref
        .collection("unelgee2")
        .doc(
          this.userData.school.currentYear + "-" + this.selectedHalfYear.yearId
        )
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.unelgee2StatisticsOfTeacher = doc.data();
            this.unelgee2StatisticsOfTeacher.id = doc.id;
            this.unelgee2StatisticsOfTeacher.ref = doc.ref;
          } else {
            this.unelgee2StatisticsOfTeacher = { completed: false };
          }
        });
      if (this.userData.school) {
        this.loading = true;
        this.lessons = [];
        this.teacherLessons.forEach(async (lsn) => {
          let lesson = lsn;
          this.isAllowedLesson(lesson);
          this.isThisSemesterLesson(lesson);
          if (!lesson.isNotAllowed && !lesson.isNotThisSemester) {
            if (lesson.esisLessonType.esisLessonTypeId == 3) {
              lesson.classGroupStudents = [];
              if (lesson.classGroups) {
                for (var program of lesson.classGroups) {
                  await this.userData.school.ref
                    .collection("students-" + this.userData.school.currentYear)
                    .where("STUDENT_GROUP_ID", "==", program.STUDENT_GROUP_ID)
                    .orderBy("FIRST_NAME", "asc")
                    .get()
                    .then(async (docs) => {
                      console.log(docs.size);
                      docs.forEach((doc) => {
                        let stud = doc.data();
                        stud.ref = doc.ref;
                        stud.id = doc.id;
                        if (!stud.moved) {
                          lesson.classGroupStudents.push(stud);
                        }
                      });
                    });
                }
              }
              if (lesson.esisLessonType.esisLessonTypeId > 1) {
                lesson.students = await this._findGroupStudents(lesson);
              } else {
                lesson.students = lesson.classGroupStudents;
              }
              lesson.unelgee2Scores = await this.getUnelgeeData(lesson);
              this.lessons.push(lesson);
            } else {
              lesson.classGroupStudents = await this.userData.school.ref
                .collection("students-" + this.userData.school.currentYear)
                .where(
                  "STUDENT_GROUP_ID",
                  "==",
                  Number(lesson.classGroupRefs[0].id)
                )
                .get()
                .then((docs) => {
                  let list = [];
                  docs.forEach((doc) => {
                    let student = doc.data();
                    student.ref = doc.ref;
                    student.id = doc.id;

                    if (!student.moved) {
                      list.push(student);
                    }
                  });
                  return list;
                });

              if (lesson.esisLessonType.esisLessonTypeId > 1) {
                lesson.students = await this._findGroupStudents(lesson);
              } else {
                lesson.students = lesson.classGroupStudents;
              }

              lesson.unelgee2Scores = await this.getUnelgeeData(lesson);
              this.lessons.push(lesson);
            }
          }
        });
      }
    },
  },
  created() {
    this.preText =
      this.userData.school.currentYear +
      "-" +
      (this.userData.school.currentYear + 1);
    this.selectedHalfYear = {
      yearId: 1,
      name: this.preText + " xичээлийн жил, 1-р xагас жил",
    };
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.unelgee2table td {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding: 0 !important;
}

.unelgee2table th {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding: 0 !important;
}
.scoreColumn {
  background-color: #eef9fe;
}
.active-tab {
  background-color: #ffc107;
  color: #ffffff;
}
</style>
