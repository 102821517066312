<template>
  <v-app>
    <v-container class="my-6 pa-0" fluid>
      <v-tabs v-model="selectedTab">
        <!-- <v-tab>Ангийн үнэлгээ 2</v-tab> -->
        <v-tab
          >Ангийн үнэлгээ 2
          <small
            class="ml-1"
            style="
              padding-left: 2px;
              padding-right: 2px;
              padding-top: 2px;
              padding-bottom: 2px;
              color: white;
              background-color: red;
              font-weight: normal;
              border-radius: 3px;
              font-size: 8pt;
            "
            >New</small
          ></v-tab
        >
        <v-tab
          >Багшийн үнэлгээ 2<small
            class="ml-1"
            style="
              padding-left: 2px;
              padding-right: 2px;
              padding-top: 2px;
              padding-bottom: 2px;
              color: white;
              background-color: red;
              font-weight: normal;
              border-radius: 3px;
              font-size: 8pt;
            "
            >New</small
          ></v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="selectedTab" style="background-color: white!important;">
        <!-- <v-tab-item>
          <Unelgee2ClassSelection></Unelgee2ClassSelection>
        </v-tab-item> -->

        <v-tab-item>
          <!-- <Unelgee22ClassSelection></Unelgee22ClassSelection> -->
          <Unelgee25ClassSelection></Unelgee25ClassSelection>
        </v-tab-item>
        <v-tab-item>
          <!-- <Unelgee22TeachersMonitoring></Unelgee22TeachersMonitoring> -->
          <Unelgee25TeachersMonitoring></Unelgee25TeachersMonitoring>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-app>
</template>

<script>
// import Unelgee2ClassSelection from "./Unelgee2ClassSelection";
// import Unelgee22ClassSelection from "./Unelgee22ClassSelection";
import Unelgee25ClassSelection from "./Unelgee25ClassSelection";
// import Unelgee22TeachersMonitoring from "./Unelgee22TeachersMonitoring";
import Unelgee25TeachersMonitoring from "./Unelgee25TeachersMonitoring";

export default {
  data() {
    return {
      selectedTab: 0,
    };
  },
  components: {
    // Unelgee2ClassSelection,
    // Unelgee22ClassSelection,
    Unelgee25ClassSelection,
    // Unelgee22TeachersMonitoring,
    Unelgee25TeachersMonitoring,
  },
  methods: {},
};
</script>

<style scoped>
/* Your CSS styles go here */
</style>
