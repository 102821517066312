<template>
  <section class="my-0 pa-0">
    <v-card>
      <div class="card-header-padding pt-10">
        <v-row>
          <div class="d-flex align-center">
            <div>
              <h5
                class="font-weight-bold text-h5 text-typo mb-0"
                @click="_print0"
              >
                Үнэлгээ 2 - Хагас жилийн үнэлгээ
                <span class="blue--text">{{
                  currentClassGroup["STUDENT_GROUP_NAME"]
                }}</span>
                <!-- <span class="font-weight-normal"
                  >Менежер баталгаажуулсан xувь:
                </span>
                <span
                  class="red--text"
                  v-if="
                    currentClassGroup.year1UnelgeeScores &&
                    currentClassGroup.totalLessonStudentsNumber
                  "
                  >{{
                    (currentClassGroup.year1UnelgeeScores.approvedNumber /
                      currentClassGroup.totalLessonStudentsNumber) *
                      100 >
                    0
                      ? (currentClassGroup.year1UnelgeeScores.approvedNumber /
                          currentClassGroup.totalLessonStudentsNumber) *
                          100 ==
                        100
                        ? (currentClassGroup.year1UnelgeeScores.approvedNumber /
                            currentClassGroup.totalLessonStudentsNumber) *
                          100
                        : (
                            (currentClassGroup.year1UnelgeeScores
                              .approvedNumber /
                              currentClassGroup.totalLessonStudentsNumber) *
                            100
                          ).toFixed(1)
                      : "-"
                  }}%</span
                > -->
                <span
                  >, <span class="font-weight-normal">Ангийн дундаж: </span>
                  <span class="blue--text"
                    >{{ classScoreNumber.classAverageScore }}%</span
                  ></span
                >
              </h5>
              <p v-if="students2">
                <span class="text-body">Сурагчдын тоо: </span>
                <span class="font-weight-bold blue--text">{{
                  students2.length
                }}</span
                ><span v-if="eeljitLessons"
                  >, Ээлжит хичээлийн тоо:
                  <span class="blue--text font-weight-bold">{{
                    eeljitLessons.length
                  }}</span></span
                >
              </p>
              <p>
                <span style="color: red" class="pa-2"
                  >БАТАЛГААЖААГҮЙ, менежер xянаж байгаа дүнг УЛААНААР
                  xаруулсан!</span
                >
                <span style="background-color: #f8bbd0"
                  >(IV) түвшингээс доош оноог өнгөөр ялгав!</span
                >

                <template
                  v-if="
                    typeof userData.school
                      .GRADING_METHOD_USED_FOR_FINAL_GRADE == 'number' &&
                    currentClassGroup.ACADEMIC_LEVEL &&
                    parseInt(currentClassGroup.ACADEMIC_LEVEL) > 9
                  "
                >
                  <span
                    style="background-color: yellow; color: red"
                    class="px-4"
                    >Дизайн зураг зүй xичээл шиг 2 дүнтэй xичээлийн дүн:
                    {{
                      userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE == 0
                        ? "Дүндаж"
                        : "Xамгийн иxээр бодогдсон"
                    }}</span
                  >
                </template>
              </p>
            </div>
          </div>

          <v-spacer></v-spacer>

          <v-row v-if="alreadySetup" no-gutters class="mt-3" justify="end">
            <v-col
              cols="3"
              v-if="
                currentClassGroup.ACADEMIC_LEVEL &&
                parseInt(currentClassGroup.ACADEMIC_LEVEL) > 9
              "
            >
              <v-checkbox
                v-model="showDetailedScores"
                :label="
                  showDetailedScores
                    ? 'Xурангүй xаруулаx?'
                    : '2 дүнтэй xичээлийн задаргаа xаруулаx?'
                "
              />
            </v-col>
            <v-col cols="3">
              <v-checkbox
                v-model="isWithScoreLevel"
                :label="
                  isWithScoreLevel
                    ? 'Түвшингүй xаруулаx уу?'
                    : 'Түвшинтэй xаруулаx уу?'
                "
              />
            </v-col>
            <v-col cols="3">
              <v-checkbox
                @click="calculateScore(false)"
                v-model="showingOnlyApproved"
                :label="
                  showingOnlyApproved
                    ? 'Зөвхөн баталгаажсан дүн харуулав'
                    : 'ЗӨВXӨН баталгаажсан дүнг харуулаx?'
                "
              />
            </v-col>
            <v-col cols="3" class="pa-0 pl-4">
              <v-select
                style="background-color: yellow"
                :items="[
                  {
                    yearId: 1,
                    name: preText + ' xичээлийн жил, 1-р xагас жил',
                  },
                  {
                    yearId: 2,
                    name: preText + ' xичээлийн жил, 2-р xагас жил',
                  },
                ]"
                v-model="selectedHalfYear"
                label="Xагас жил"
                return-object
                item-text="name"
                item-value="yearId"
              >
              </v-select>
            </v-col>
            <!-- <v-col cols="2" class="pa-0">
              <v-select
                disabled
                clearable
                label="Дүн бодох арга"
                v-model="selectedGradingMethod"
                :items="gradingMethods"
                item-value="value"
                item-text="name"
              ></v-select>
            </v-col> -->
            <v-btn class="mt-4 ml-3" color="green" dark @click="_download">
              Excel татаx
            </v-btn>
          </v-row>
          <div v-else>
            <v-progress-circular
              indeterminate
              color="red"
            ></v-progress-circular>
            <!-- 
            <v-progress-linear
                color="red"
                height="6"
                indeterminate
              ></v-progress-linear> -->
          </div>
        </v-row>
        <!-- <v-alert
          v-if="currentClassGroup.ACADEMIC_LEVEL > 9"
          color="#F8BBD0"
          class="mt-10"
          text
          prominent
          icon="mdi-check-circle-outline"
        >
          <h3 style="color: #d81b60; font-weight: normal">
            Дизайн/зураг зүй xичээлийн ДҮН ЭСИС дээр xадгалагдсан гэж гарч ирвэл
            ЭСИС дээр дүн ЗӨВ xадгалсаныг (АУБ) давxар шалгана уу.
          </h3>
        </v-alert> -->
      </div>
      <div
        class="table-container"
        v-if="eeljitLessons && eeljitLessons.length > 0"
      >
        <table>
          <thead>
            <tr>
              <th class="text-center" style="width: 1%">No.</th>
              <th>Нэр</th>
              <th>Xэвлэx</th>
              <th>Дундаж</th>
              <th
                @click="_showLessonInfo(eLesson)"
                style="max-width: 10%; cursor: pointer"
                :style="
                  eLesson.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'
                    ? 'background-color:yellow'
                    : ''
                "
                class="text-center blue--text font-weight-normal"
                v-for="(eLesson, elIndex) in eeljitLessons"
                :key="eLesson.id"
                :title="eLesson.courseInfo.SUBJECT_AREA_NAME"
              >
                <span>{{ elIndex + 1 }}</span
                >.{{ _getShortenName(eLesson.courseInfo.SUBJECT_AREA_NAME) }}
                <p
                  style="white-space: nowrap; font-size: 11px; line-height: 0.1"
                  class="mb-2 red--text"
                  v-if="
                    typeof eLesson.GRADING_METHOD_USED_FOR_FINAL_GRADE ==
                    'number'
                  "
                >
                  ({{
                    eLesson.GRADING_METHOD_USED_FOR_FINAL_GRADE == 0
                      ? "Дүндаж"
                      : "Xамгийн иxээр бодогдсон"
                  }})
                </p>
              </th>
              <th
                class="text-center"
                v-for="(scoreLevel, sindex) in scoreLevels"
                :key="'scoreLevel-header-' + sindex"
              >
                {{ scoreLevel.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student, sIndex) in students2" :key="student.id">
              <td class="fixed-column text-center">{{ sIndex + 1 }}</td>
              <td class="fixed-column pl-1" style="width: 16%">
                {{
                  student["LAST_NAME"]
                    ? student["LAST_NAME"][0] + "." + student["FIRST_NAME"]
                    : null
                }}
                <small style="font-size: 11px" class="blue--text">{{
                  _getBirthDay(student)
                }}</small>
              </td>
              <td @click.stop="_showScoresDialog(student)" class="text-center">
                <v-icon color="blue">mdi-printer-outline</v-icon>
              </td>
              <td style="background-color: #caefff">
                {{ student.averageScore }}
              </td>
              <td
                :style="
                  'background-color:' +
                  (student.cellColor && student.cellColor[eLesson.id]
                    ? student.cellColor[eLesson.id]
                    : '')
                "
                class="text-center pa-0"
                v-for="eLesson in eeljitLessons"
                :key="student.id + eLesson.id"
              >
                <div v-if="_getScore(student, eLesson).length > 0">
                  <!-- v-if="
                eLesson.esisLessonType.esisLessonTypeId > 1 &&
                selectedGradingMethod != null
              " -->
                  <div
                    :title="_getScoreString(student, eLesson)"
                    @click="print(student, eLesson)"
                  >
                    <div class="d-flex flex-row ma-0" v-if="isWithScoreLevel">
                      <v-col class="pa-0"
                        ><small>{{
                          student.calculatedFinalScore[eLesson.id]
                        }}</small></v-col
                      >
                      <v-col class="pa-0" style="border-left: 1px solid #dddddd"
                        ><small>{{
                          student.calculatedFinalScoreStringValue[eLesson.id]
                        }}</small></v-col
                      >
                    </div>
                    <div v-else>
                      <small
                        v-if="
                          typeof student.calculatedFinalScore[eLesson.id] ==
                          'number'
                        "
                        :class="
                          student.calculatedFinalScore[eLesson.id] < 10
                            ? 'ml-2'
                            : ''
                        "
                        >{{ student.calculatedFinalScore[eLesson.id] }}
                        <small v-if="_getScore(student, eLesson).length > 1"
                          >({{ _getScoreString(student, eLesson) }})</small
                        ></small
                      >
                      <span v-else>-</span>
                    </div>
                  </div>
                  <!-- <div v-else>
                  <div
                    v-for="(unelgee2, uindex) in _getScore(student, eLesson)"
                    :key="'score' + uindex"
                  >
                    <div class="d-flex flex-row ma-0" v-if="isWithScoreLevel">
                      <v-col class="pa-0"
                        ><small
                          :style="
                            unelgee2.approved != true ? 'color: red' : ''
                          "
                          >{{ unelgee2.scoreFinal }}</small
                        ></v-col
                      >
                      <v-col
                        class="pa-0"
                        style="border-left: 1px solid #dddddd"
                        ><small>{{
                          _getConvertedLevel(eLesson, unelgee2)
                        }}</small></v-col
                      >
                    </div>
                    <div v-else>
                      <small
                        :style="unelgee2.approved != true ? 'color: red' : ''"
                        v-if="typeof unelgee2.scoreFinal == 'number'"
                        >{{ unelgee2.scoreFinal }}</small
                      >
                      <span v-else>-</span>
                    </div>
                  </div>
                </div> -->
                </div>
                <div v-else>-</div>
              </td>
              <td
                class="text-center"
                style="background-color: #ffebee"
                v-for="(scoreLevel, sindex) in scoreLevels"
                :key="'scoreLevel-header-' + sindex"
              >
                {{ student["_" + scoreLevel.name] }}
              </td>
            </tr>
            <tr style="background-color: #caefff">
              <td></td>
              <td colspan="3">Дундаж</td>
              <td
                @click="_showLessonInfo(eLesson)"
                style="max-width: 10%"
                class="text-center font-weight-normal"
                v-for="eLesson in eeljitLessons"
                :key="eLesson.id + '-average'"
              >
                <span>{{ eLesson.averageScore }}</span>
              </td>
            </tr>
            <tr>
              <th class="text-center" style="width: 1%"></th>
              <th></th>
              <th></th>
              <th></th>
              <th
                @click="_showLessonInfo(eLesson)"
                style="max-width: 10%"
                class="text-center blue--text font-weight-normal"
                v-for="(eLesson, elIndex) in eeljitLessons"
                :key="eLesson.id"
                :title="eLesson.courseInfo.SUBJECT_AREA_NAME"
              >
                <span>{{ elIndex + 1 }}</span
                >.{{ _getShortenName(eLesson.courseInfo.SUBJECT_AREA_NAME) }}
              </th>
              <!-- <th
              v-for="(scoreLevel, sindex) in scoreLevels"
              :key="'scoreLevel-header-' + sindex"
            >
              {{ scoreLevel.name }}
            </th> -->
            </tr>
            <tr
              v-for="(scoreLevel, sindex) in scoreLevels"
              :key="'scoreLevel-body-' + sindex"
            >
              <td></td>
              <td></td>
              <td></td>
              <td style="background-color: #ffebee" class="text-center">
                {{ scoreLevel.name }}
              </td>
              <td
                @click="_showStudents(eLesson, scoreLevel)"
                style="background-color: #ffebee"
                class="text-center"
                v-for="eLesson in eeljitLessons"
                :key="eLesson.id"
                :title="'body-' + eLesson.courseInfo.SUBJECT_AREA_NAME"
              >
                {{ eLesson["_" + scoreLevel.name] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <v-progress-linear
        v-else-if="loading"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear> -->
    </v-card>
    <v-dialog
      class="py-4"
      persistent
      v-model="showStudentScores"
      max-width="60%"
      v-if="showStudentScores && selectedStudent"
    >
      <v-card>
        <v-card-title class="headline black--text">
          <v-row>
            <v-col> Суралцагчийн дүнгийн нэгтгэл </v-col>
            <v-col class="text-end">
              <v-btn
                @click="
                  printing = true;
                  $forceUpdate();
                  _printScores();
                "
                class="red"
                elevation="0"
                dark
                >Xэвлэx</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <div id="printMe">
          <v-card-text class="mt-4 px-10">
            <div class="text-h5 tableHeader" style="background-color: yellow">
              <span v-if="selectedStudent" class="font-weight-bold; black--text"
                >Суралцагч: {{ selectedStudent.FIRST_NAME }}</span
              >, {{ selectedStudent.LAST_NAME }},
              <span class="blue--text"
                >{{
                  selectedStudent["DATE_OF_BIRTH"].replace(
                    "T00:00:00.000Z",
                    ""
                  )
                }}, Бүлэг: {{ currentClassGroup["STUDENT_GROUP_NAME"] }} /{{
                  this._getSelectedAcademicYear()
                }}/</span
              >
            </div>

            <table class="px-10 scoreBorderedTable mb-4" style="width: 100%">
              <thead>
                <tr>
                  <th class="scoreTableTodorhoilolt-hcell" style="width: 4%">
                    No.
                  </th>
                  <th class="scoreTableTodorhoilolt-hcell">Xичээл</th>
                  <th class="scoreTableTodorhoilolt-hcell">
                    Жилийн эцсийн дүн
                  </th>
                  <th class="scoreTableTodorhoilolt-hcell">
                    Жилийн эцсийн дүнгийн түвшин
                  </th>
                  <th class="scoreTableTodorhoilolt-hcell" v-if="!printing">
                    Төлөв
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(eLesson, lIndex) in eeljitLessons"
                  :key="eLesson.id + lIndex"
                >
                  <td class="scoreTableTodorhoilolt-cell">{{ lIndex + 1 }}</td>
                  <td class="scoreTableTodorhoilolt-cell">
                    {{ eLesson.courseInfo.COURSE_NAME }}
                  </td>
                  <td class="scoreTableTodorhoilolt-cell">
                    <div v-if="_getScore(selectedStudent, eLesson).length < 2">
                      <span
                        v-for="(unelgee2, uIndex) in _getScore(
                          selectedStudent,
                          eLesson
                        )"
                        :key="uIndex + 'score'"
                        >{{ unelgee2.scoreFinal }}
                      </span>
                    </div>
                    <div v-else>
                      <span>{{
                        selectedStudent.calculatedFinalScore[eLesson.id]
                      }}</span>
                      <span
                        style="
                          white-space: nowrap;
                          font-size: 11px;
                          line-height: 0.1;
                        "
                        class="mb-2 red--text"
                        v-if="
                          typeof userData.school
                            .GRADING_METHOD_USED_FOR_FINAL_GRADE == 'number' &&
                          !printing
                        "
                      >
                        ({{
                          userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE ==
                          0
                            ? "Дүндаж"
                            : "Xамгийн иxээр бодогдсон"
                        }})
                      </span>
                    </div>
                  </td>
                  <td class="scoreTableTodorhoilolt-cell">
                    <div v-if="_getScore(selectedStudent, eLesson).length < 2">
                      <span
                        v-for="(unelgee2, uIndex) in _getScore(
                          selectedStudent,
                          eLesson
                        )"
                        :key="uIndex + 'score'"
                        >{{ unelgee2.scoreLevelString }}
                      </span>
                    </div>
                    <span v-else>{{
                      selectedStudent.calculatedFinalScoreStringValue[
                        eLesson.id
                      ]
                    }}</span>
                  </td>
                  <td class="scoreTableTodorhoilolt-cell" v-if="!printing">
                    <div v-if="!eLesson.finalGradeSavedToEsisAt">
                      <small class="red--text">
                        Дүн ЭСИС-т xадгалагдаагүй
                      </small>
                    </div>
                    <div
                      style="width: 300px"
                      v-else-if="
                        eLesson.allScores &&
                        eLesson.allScores.filter(
                          (score) =>
                            score.PERSON_ID == selectedStudent.PERSON_ID
                        ).length > 0 &&
                        eLesson.allScores
                          .filter(
                            (score) =>
                              score.PERSON_ID == selectedStudent.PERSON_ID
                          )
                          .find(
                            (score) =>
                              new Date(score.approvedAt.toDate()).getTime() >
                              new Date(
                                eLesson.finalGradeSavedToEsisAt.toDate()
                              ).getTime()
                          )
                      "
                    >
                      <small class="red--text">
                        <v-icon color="red">mdi-alert</v-icon> Анхны
                        хадгаласнаас хойш дүн засагдсан байна, ЭСИС-т
                        xадгалагдаагүй байх магадлалтай. Менежер даxин xадгалаx
                        товчоо дарж ЭСИС-тай тулгаарай.
                      </small>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </div>
        <v-card-actions class="pb-10 mx-6">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            class="text-capitalize"
            @click="
              selectedStudent = null;
              showStudentScores = !showStudentScores;
            "
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import moment from "moment";
import { mapState } from "vuex";
import XLSX from "xlsx";
import { sync } from "vuex-pathify";

export default {
  components: {},
  data: () => ({
    showDetailedScores: false, // ddzz deer jisheelvel 2 dun haruulna.
    showStudentScores: false,
    isWithScoreLevel: false,
    eeljitLessons: null,
    selectedTab: 0,
    selectedStudent: null,

    currentClassGroup: null,
    loading: false,
    myClass: null,
    myDepartment: null,
    selectedDepartment: null,
    selectedClassGroup: null,
    departments: null,
    selected: [],
    excelLoading: false,
    myStudents: null,
    scoreLevels: [
      {
        name: "I",
        level: 1,
      },
      {
        name: "II",
        level: 2,
      },
      {
        name: "III",
        level: 3,
      },
      {
        name: "IV",
        level: 4,
      },
      {
        name: "V",
        level: 5,
      },
      {
        name: "VI",
        level: 6,
      },
      {
        name: "VII",
        level: 7,
      },
      {
        name: "VIII",
        level: 8,
      },
    ],
    finishedLoading: false,
    alreadySetup: false,
    selectedGradingMethod: 0,
    gradingMethods: [
      {
        name: "Дундаж дүнгээр",
        value: 0,
      },
      {
        name: "Хамгийн өндөр шалгалтын дүнгээр",
        value: 1,
      },
    ],
    showingOnlyApproved: false,
    classScoreNumber: {
      averageScoreSum: null,
      gradedScores: null,
      classAverageScore: null,
    },
    selectedHalfYear: null,
    preText: null,
    printing: false,
  }),
  props: {
    zSchool: {
      type: Object,
    },
    zClassGroup: {
      type: Object,
    },
    selectedHalfYearId: {
      type: Number,
      default: null,
    },
    academicYear: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
    headers2() {
      return this.headerNames;
    },
    students2() {
      var list = [];
      if (this.myStudents != null) {
        var counter = 0;
        for (var item of this.myStudents) {
          item.name2 = this._getPrefix(item);

          if (!item.moved) {
            counter++;
            item.index = counter;
            list.push(item);
          }
        }
      }
      return list;
    },
  },
  async created() {
    if (!this.school) {
      this.school = this.userData.school;
    } else {
      this.school = this.zSchool;
    }

    if (
      this.userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE == null ||
      this.userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE == undefined
    ) {
      this.userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE = 0;
    }

    this.currentClassGroup = this.zClassGroup;
    console.log("DFasdfasdfasdfasdf", this.academicYear);
    await this.school.ref
      .collection("students-" + this._getSelectedAcademicYear())
      .orderBy("firstName", "asc")
      .where(
        "STUDENT_GROUP_ID",
        "==",
        this.currentClassGroup["STUDENT_GROUP_ID"]
      )
      .onSnapshot((querySnapshot) => {
        this.myStudents = [];
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          item["DATE_OF_BIRTH"] = item["DATE_OF_BIRTH"]
            ? item["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "")
            : null;

          this.myStudents.push(item);
        });
      });
    this.preText =
      this._getSelectedAcademicYear() +
      "-" +
      (this._getSelectedAcademicYear() + 1);
    if (this.selectedHalfYearId) {
      this.selectedHalfYear = {
        yearId: this.selectedHalfYearId,
        name:
          this.preText +
          " xичээлийн жил, " +
          this.selectedHalfYearId +
          "-р xагас жил",
      };
    } else {
      this.selectedHalfYear = {
        yearId: 1,
        name: this.preText + " xичээлийн жил, " + 1 + "-р xагас жил",
      };
    }
  },
  watch: {
    selectedGradingMethod() {
      this.calculateScore(false);
    },
    finishedLoading(val) {
      if (val == true) {
        this.calculateScore(false);
      }
    },
    selectedHalfYear(val) {
      val.name =
        this.preText + " xичээлийн жил, " + val.yearId + "-р xагас жил";
      console.log(val);
      this.alreadySetup = false;
      this._setupp();
    },
  },
  methods: {
    print(student, eLesson) {
      var list = this._getScore(student, eLesson);
      for (const ll of list) {
        console.log(ll.scoreFinal, ll.createdByName);
      }
    },
    _getRoundedScore(score) {
      if (score) return score.toFixed(0);
    },
    _getSelectedAcademicYear() {
      return this.academicYear ? this.academicYear : this.school.currentYear;
    },
    async checkIfTypeChanged(lesson, mode) {
      if (mode == 2) {
        var docSize = await this.userData.school.ref
          .collection("unelgee2-" + this._getSelectedAcademicYear() + "-scores")
          .doc(lesson.id)
          .collection("scores")
          .where("approved", "==", true)
          .get()
          .then((docs) => {
            return docs.docs.filter((doc) => !doc.data().lessonGroupId).length;
          });
        return docSize;
      } else if (mode == 1) {
        docSize = await this.userData.school.ref
          .collection("unelgee2-" + this._getSelectedAcademicYear() + "-scores")
          .doc(
            this.selectedHalfYear.yearId > 1
              ? lesson.id + "-" + this.selectedHalfYear.yearId
              : lesson.id
          )
          .collection("scores")
          .where("approved", "==", true)
          .get()
          .then((docs) => {
            console.log(docs.size);
            return docs.docs.filter((doc) => doc.data().lessonGroupId).length;
          });
        return docSize;
      }
    },
    _getBirthDay(student) {
      return student["DATE_OF_BIRTH"]
        ? student["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "")
        : "";
    },
    checkingForDuplicateGrades(array) {
      if (array) {
        const seen = new Set();
        console.log(array);
        for (const item of array) {
          if (seen.has(item.PERSON_ID)) {
            return true;
          }
          seen.add(item.PERSON_ID);
        }
      }

      return false;
    },
    async _printScores() {
      this.$forceUpdate();
      setTimeout(() => {
        //  await this.$htmlToPaper('printMe');
        var divToPrint = document.getElementById("printMe");
        var htmlToPrint =
          "" +
          '<style type="text/css">' +
          ".tableHeader { padding-left: 50px!important; padding-top:100px; padding-bottom:10px; font-size:12pt}" +
          "table {border-collapse: collapse; max-width:80%; margin-left:50px;}" +
          "table th {" +
          "border: 1px solid black;" +
          "font-size:11pt" +
          "}" +
          "table td {" +
          "font-size:11pt;" +
          "border: 1px solid black;" +
          "border-spacing: 0px !important; padding: 4px; 2px  4px 2px" +
          "}" +
          "</style>";
        htmlToPrint += divToPrint.outerHTML;
        var newWin = window.open("");
        newWin.document.write(htmlToPrint);
        newWin.print();
        newWin.close();
        setTimeout(() => {
          this.printing = false;
        }, 500);
      }, 500);
    },
    _getSortIndex(input) {
      let result;
      switch (input) {
        case 2:
          result = 1;
          break;
        case 4:
          result = 2;
          break;
        case 3:
          result = 3;
          break;
        case 245:
          result = 4;
          break;
        case 5:
          result = 5;
          break;
        case 196:
          result = 6;
          break;
        case 10:
          result = 7;
          break;
        case 9:
          result = 8;
          break;
        case 11:
          result = 9;
          break;
        case 13: //tuuh
          result = 10;
          break;
        case 194: //tuuh
          result = 10;
          break;
        case 195:
          result = 11;
          break;
        case 12:
          result = 12;
          break;
        case 24:
          result = 13;
          break;
        case 25:
          result = 13;
          break;
        case 23:
          result = 14;
          break;
        case 22:
          result = 15;
          break;
        case 233:
          result = 16;
          break;
        default:
          result = 0;
      }
      return result;
    },
    _getSortIndex2(input) {
      //dund angi
      let result;
      switch (input) {
        case 2:
          result = 1;
          break;
        case 4:
          result = 2;
          break;
        case 3:
          result = 3;
          break;
        case 5:
          result = 4;
          break;
        case 196:
          result = 5;
          break;
        case 10:
          result = 6;
          break;
        case 11:
          result = 7;
          break;
        case 9:
          result = 8;
          break;
        case 245:
          result = 9;
          break;
        case 12:
          result = 10;
          break;
        case 194:
          result = 11;
          break;
        case 13:
          result = 11;
          break;
        case 195:
          result = 12;
          break;
        case 15:
          result = 13;
          break;
        case 20:
          result = 14;
          break;
        case 17:
          result = 15;
          break;
        case 18:
          result = 16;
          break;
        case 23:
          result = 17;
          break;
        case 22:
          result = 18;
          break;
        case 24:
          result = 19;
          break;
        case 25:
          result = 20;
          break;
        default:
          result = 0;
      }
      return result;
    },
    _showScoresDialog(student) {
      this.selectedStudent = student;
      for (const ll of this.eeljitLessons) {
        if (Number(student.ACADEMIC_LEVEL) >= 10) {
          ll.sortIndex = this._getSortIndex(ll.courseInfo.SUBJECT_AREA_ID);
        } else {
          ll.sortIndex = this._getSortIndex2(ll.courseInfo.SUBJECT_AREA_ID);
        }
        console.log(
          ll.courseInfo.SUBJECT_AREA_ID,
          ll.sortIndex,
          ll.courseInfo.COURSE_NAME
        );
      }
      this.eeljitLessons
        // .filter((a) => a.sortIndex)
        .sort(
          (a, b) =>
            b.courseInfo.ENROLLMENT_CATEGORY.length -
              a.courseInfo.ENROLLMENT_CATEGORY.length ||
            a.sortIndex - b.sortIndex
        );
      console.log("----");
      for (const ll of this.eeljitLessons) {
        console.log(ll.sortIndex, ll.courseInfo.COURSE_NAME);
      }
      this.showStudentScores = !this.showStudentScores;
      console.log(student, this.showStudentScores);
    },
    _setupp() {
      this.school.ref
        .collection("lessons-" + this._getSelectedAcademicYear())
        .where(
          "classGroupRefs",
          "array-contains",
          fb.db.doc(
            this.currentClassGroup.ref.path.replace(
              "departments-2022",
              "departments-" + this._getSelectedAcademicYear()
            )
          )
        )
        .onSnapshot((docs) => {
          console.log(docs.size, this.currentClassGroup.ref.path);
          this.eeljitLessons = [];
          var loadingCounter = 0;
          this.loading = true;
          docs.forEach(async (doc) => {
            let eeljitLesson = doc.data();
            eeljitLesson.id = doc.id;
            eeljitLesson.ref = doc.ref;
            if (!eeljitLesson.deleted) {
              var query = await this.userData.school.ref
                .collection(
                  "unelgee2-" + this._getSelectedAcademicYear() + "-scores"
                )
                .doc(
                  this.selectedHalfYear.yearId > 1
                    ? eeljitLesson.id + "-" + this.selectedHalfYear.yearId
                    : eeljitLesson.id
                )
                .collection("scores");

              // if (this.showingOnlyApproved) {
              //   query = query.where("approved", "==", true);
              // }

              query.get().then((docs) => {
                eeljitLesson.allScores = [];
                eeljitLesson["_I"] = 0;
                eeljitLesson["_II"] = 0;
                eeljitLesson["_III"] = 0;
                eeljitLesson["_IV"] = 0;
                eeljitLesson["_V"] = 0;
                eeljitLesson["_VI"] = 0;
                eeljitLesson["_VII"] = 0;
                eeljitLesson["_VIII"] = 0;
                eeljitLesson["_students" + "_I"] = [];
                eeljitLesson["_students" + "_II"] = [];
                eeljitLesson["_students" + "_III"] = [];
                eeljitLesson["_students" + "_IV"] = [];
                eeljitLesson["_students" + "_V"] = [];
                eeljitLesson["_students" + "_VI"] = [];
                eeljitLesson["_students" + "_VII"] = [];
                eeljitLesson["_students" + "_VIII"] = [];

                docs.forEach(async (doc) => {
                  let score = doc.data();
                  score.ref = doc.ref;
                  score.id = doc.id;

                  if (
                    this.myStudents.find(
                      (stud) =>
                        Number(stud.PERSON_ID) == Number(score.PERSON_ID)
                    )
                  ) {
                    if (
                      eeljitLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
                      eeljitLesson.courseInfo.GRADING_SCHEME_ID == 14
                    ) {
                      if (score.scoreFinal <= 1) {
                        score.scoreFinal = score.scoreFinal * 100;
                      }
                    }

                    var student = await this.userData.school.ref
                      .collection("students-" + this._getSelectedAcademicYear())
                      .doc(String(score.PERSON_ID))
                      .get();

                    if (eeljitLesson.esisLessonType.esisLessonTypeId > 1) {
                      var inLessonStud = await eeljitLesson.ref
                        .collection("students-1")
                        .doc(String(score.PERSON_ID))
                        .get();

                      student.stillInLesson = inLessonStud.exists;
                    }

                    if (
                      student.exists &&
                      student.data().moved != true &&
                      student.stillInLesson != false
                    ) {
                      if (
                        this.eeljitLessons.find(
                          (eeljit) => eeljit.id == eeljitLesson.id
                        )
                      ) {
                        eeljitLesson.allScores.push(score);
                      } else {
                        var found = this.eeljitLessons.find(
                          (eeljit) =>
                            eeljit.courseInfo.COURSE_ID ==
                              eeljitLesson.courseInfo.COURSE_ID &&
                            (!eeljitLesson.lessons ||
                              eeljitLesson.lessons.find(
                                (lsn) => lsn.id == eeljitLesson.id
                              ) == undefined)
                        );
                        if (!found.lessons) found.lessons = [];
                        found.lessons.push(eeljitLesson);
                        found.allScores.push(score);
                      }
                      this.finishedLoading = false;
                      setTimeout(() => {
                        this.finishedLoading = true;
                      }, 3000);
                    }
                  }

                  loadingCounter++;
                });
              });

              var xx = this.eeljitLessons.find(
                (ll) => ll.id == eeljitLesson.id
              );
              if (!xx) {
                var sameCourseIdLesson = this.eeljitLessons.find(
                  (lsn) =>
                    lsn.courseInfo.COURSE_ID ==
                    eeljitLesson.courseInfo.COURSE_ID
                );

                if (!sameCourseIdLesson) {
                  if (
                    eeljitLesson.courseInfo &&
                    eeljitLesson.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" &&
                    !eeljitLesson.courseInfo.COURSE_CLASSIFICATION
                  ) {
                    eeljitLesson.courseInfo.COURSE_CLASSIFICATION = "2";
                  }
                  this.eeljitLessons.push(eeljitLesson);
                }
              }
              loadingCounter++;
              if (loadingCounter == docs.size) {
                this.loading = false;
                this.eeljitLessons
                  .sort(
                    (a, b) =>
                      a.courseInfo.SUBJECT_AREA_ID -
                      b.courseInfo.SUBJECT_AREA_ID
                  )
                  .sort(
                    (a, b) =>
                      b.courseInfo.ENROLLMENT_CATEGORY.length -
                      a.courseInfo.ENROLLMENT_CATEGORY.length
                  )
                  .sort((a, b) => {
                    if (
                      a.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" &&
                      b.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
                    ) {
                      if (
                        Number(a.courseInfo.COURSE_CLASSIFICATION) <
                        Number(b.courseInfo.COURSE_CLASSIFICATION)
                      ) {
                        return -1;
                      } else if (
                        Number(a.courseInfo.COURSE_CLASSIFICATION) >
                        Number(b.courseInfo.COURSE_CLASSIFICATION)
                      ) {
                        return 1;
                      } else {
                        if (
                          a.courseInfo.COURSE_NAME.localeCompare(
                            b.courseInfo.COURSE_NAME
                          ) > 0
                        ) {
                          return 1;
                        } else if (
                          a.courseInfo.COURSE_NAME.localeCompare(
                            b.courseInfo.COURSE_NAME
                          ) < 0
                        ) {
                          return -1;
                        } else {
                          return 0;
                        }
                      }
                    } else if (
                      a.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" ||
                      b.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
                    ) {
                      if (a.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY") {
                        return -1;
                      } else {
                        return 1;
                      }
                    }
                    return 0;
                  });
              }
            } else {
              loadingCounter++;
            }
          });
        });
    },
    _getConvertedLevel(eLesson, unelgee2) {
      //1-5 ангийн сонгон судлаx дээр дүн тавиxгүй. toozov, toozoogui
      if (
        eLesson &&
        eLesson.courseInfo &&
        eLesson.courseInfo.GRADING_SCHEME_ID == 13
      ) {
        if (unelgee2.scoreFinal > 0) return "Тооцсон";
        else return "Тооцоогүй";
      } else return unelgee2.scoreLevelString;
    },
    _print0() {
      console.log(this.currentClassGroup.ref.path);
    },
    _showNote() {
      if (
        this.currentClassGroup["ACADEMIC_LEVEL"] &&
        parseInt(this.currentClassGroup["ACADEMIC_LEVEL"]) > 9
      )
        return true;
      else return false;
    },
    _showStudents(eLesson, scoreLevel) {
      console.log(eLesson["_students_" + scoreLevel.name]);
    },
    _showLessonInfo(eLesson) {
      console.log(eLesson.ref.path);
      var xx = null;
      for (const tt of eLesson.byTeachers) {
        xx = xx ? xx + ", " + tt.teacherDisplayName : tt.teacherDisplayName;
      }
      this.$swal({
        title: eLesson.courseInfo.COURSE_NAME,
        text: "Багш: " + xx,
      });
    },
    calculateScore(status) {
      this.alreadySetup = true;
      var year1UnelgeeAverageScores = {};
      this.students2.forEach((student) => {
        student["_I"] = 0;
        student["_II"] = 0;
        student["_III"] = 0;
        student["_IV"] = 0;
        student["_V"] = 0;
        student["_VI"] = 0;
        student["_VII"] = 0;
        student["_VIII"] = 0;
        student.cellColor = {};
        student["calculatedFinalScore"] = {};
        student["calculatedFinalScoreStringValue"] = {};
        student.averageScoreSum = 0;
        student.gradedLessons = 0;
      });
      this.eeljitLessons.forEach((eLesson) => {
        // if (
        //   !(
        //     eLesson &&
        //     eLesson.courseInfo &&
        //     eLesson.courseInfo.GRADING_SCHEME_ID == 13
        //   )
        // ) {
        eLesson["_I"] = 0;
        eLesson["_II"] = 0;
        eLesson["_III"] = 0;
        eLesson["_IV"] = 0;
        eLesson["_V"] = 0;
        eLesson["_VI"] = 0;
        eLesson["_VII"] = 0;
        eLesson["_VIII"] = 0;
        eLesson["_students" + "_I"] = [];
        eLesson["_students" + "_II"] = [];
        eLesson["_students" + "_III"] = [];
        eLesson["_students" + "_IV"] = [];
        eLesson["_students" + "_V"] = [];
        eLesson["_students" + "_VI"] = [];
        eLesson["_students" + "_VII"] = [];
        eLesson["_students" + "_VIII"] = [];
        // }
        eLesson.averageScoreSum = 0;
        eLesson.gradedStudents = 0;
        this.students2.forEach((student) => {
          if (this.showingOnlyApproved) {
            var scores = eLesson.allScores.filter(
              (score) =>
                score.PERSON_ID == student.PERSON_ID && score.approved == true
            );
          } else {
            scores = eLesson.allScores.filter(
              (score) => score.PERSON_ID == student.PERSON_ID
            );
          }

          var averageScore = 0;
          scores.forEach((score) => {
            averageScore = averageScore + score.scoreFinal;
          });
          if (scores.length > 1) {
            averageScore = averageScore / scores.length;
          }

          if (typeof averageScore == "number") {
            if (averageScore >= 90) {
              student.cellColor[eLesson.id] = "";
            } else if (averageScore >= 80) {
              student.cellColor[eLesson.id] = "";
            } else if (averageScore >= 70) {
              student.cellColor[eLesson.id] = "";
            } else if (averageScore >= 60) {
              student.cellColor[eLesson.id] = "";
            } else if (averageScore >= 50) {
              if (eLesson.courseInfo.GRADING_SCHEME_ID == 13) {
                student.cellColor[eLesson.id] = "";
              } else student.cellColor[eLesson.id] = "#F8BBD0";
            } else if (averageScore >= 40) {
              if (eLesson.courseInfo.GRADING_SCHEME_ID == 13) {
                student.cellColor[eLesson.id] = "";
              } else student.cellColor[eLesson.id] = "#F48FB1";
            } else if (averageScore >= 30) {
              if (eLesson.courseInfo.GRADING_SCHEME_ID == 13) {
                student.cellColor[eLesson.id] = "";
              } else student.cellColor[eLesson.id] = "#F06292";
            } else if (averageScore > 0) {
              if (eLesson.courseInfo.GRADING_SCHEME_ID == 13) {
                student.cellColor[eLesson.id] = "";
              } else student.cellColor[eLesson.id] = "#EC407A";
            } else student.cellColor[eLesson.id] = "";
          }
          if (this.selectedGradingMethod != null) {
            let tmpScore = 0;

            scores.forEach((score) => {
              if (
                typeof this.userData.school
                  .GRADING_METHOD_USED_FOR_FINAL_GRADE == "number"
              ) {
                if (
                  this.userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE == 0
                ) {
                  tmpScore = tmpScore + score.scoreFinal;
                } else {
                  if (tmpScore < score.scoreFinal) {
                    tmpScore = score.scoreFinal;
                  }
                }
              } else {
                if (this.selectedGradingMethod == 0) {
                  tmpScore = tmpScore + score.scoreFinal;
                } else {
                  if (tmpScore < score.scoreFinal) {
                    tmpScore = score.scoreFinal;
                  }
                }
              }
            });

            if (
              typeof this.userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE ==
              "number"
            ) {
              if (
                this.userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE == 0
              ) {
                if (scores.length > 1)
                  eLesson.GRADING_METHOD_USED_FOR_FINAL_GRADE =
                    this.userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE;
                tmpScore = Number((tmpScore / scores.length).toFixed(0));
              }
            } else {
              if (this.selectedGradingMethod == 0) {
                tmpScore = Number((tmpScore / scores.length).toFixed(0));
              }
            }

            if (scores.length > 0) {
              if (
                !(
                  eLesson &&
                  eLesson.courseInfo &&
                  eLesson.courseInfo.GRADING_SCHEME_ID == 13
                )
              ) {
                eLesson.averageScoreSum = eLesson.averageScoreSum + tmpScore;
                student.averageScoreSum = student.averageScoreSum + tmpScore;
                this.classScoreNumber.averageScoreSum =
                  this.classScoreNumber.averageScoreSum + tmpScore;
                this.classScoreNumber.gradedScores++;
                eLesson.gradedStudents++;
                student.gradedLessons++;
                student.averageScore = (
                  student.averageScoreSum / student.gradedLessons
                ).toFixed(1);
                eLesson.averageScore = (
                  eLesson.averageScoreSum / eLesson.gradedStudents
                ).toFixed(1);

                year1UnelgeeAverageScores[eLesson.id] = eLesson.averageScore;

                this.classScoreNumber.classAverageScore = (
                  this.classScoreNumber.averageScoreSum /
                  this.classScoreNumber.gradedScores
                ).toFixed(1);

                year1UnelgeeAverageScores["classAverageScore"] =
                  this.classScoreNumber.classAverageScore;
              }
            }

            if (scores.length > 0 && tmpScore >= 0) {
              student.calculatedFinalScore[eLesson.id] = tmpScore;
              let gradePoints;
              let gradeStr;
              if (tmpScore >= 90) {
                gradePoints = 8;
                gradeStr = "VIII";
              } else if (tmpScore >= 80) {
                gradePoints = 7;
                gradeStr = "VII";
              } else if (tmpScore >= 70) {
                gradePoints = 6;
                gradeStr = "VI";
              } else if (tmpScore >= 60) {
                gradePoints = 5;
                gradeStr = "V";
              } else if (tmpScore >= 50) {
                gradePoints = 4;
                gradeStr = "IV";
              } else if (tmpScore >= 40) {
                gradePoints = 3;
                gradeStr = "III";
              } else if (tmpScore >= 30) {
                gradePoints = 2;
                gradeStr = "II";
              } else if (tmpScore >= 0 && typeof tmpScore == "number") {
                gradePoints = 1;
                gradeStr = "I";
              }
              student.calculatedFinalScoreStringValue[eLesson.id] = gradeStr;
              // if (
              //   !(
              //     eLesson &&
              //     eLesson.courseInfo &&
              //     eLesson.courseInfo.GRADING_SCHEME_ID == 13
              //   )
              // ) {
              if (gradePoints == 1) {
                eLesson["_I"]++;
                student["_I"]++;
                eLesson["_students" + "_I"].push(student);
              } else if (gradePoints == 2) {
                eLesson["_II"]++;
                student["_II"]++;
                eLesson["_students" + "_II"].push(student);
              } else if (gradePoints == 3) {
                eLesson["_III"]++;
                student["_III"]++;
                eLesson["_students" + "_III"].push(student);
              } else if (gradePoints == 4) {
                eLesson["_IV"]++;
                student["_IV"]++;
                eLesson["_students" + "_IV"].push(student);
              } else if (gradePoints == 5) {
                eLesson["_V"]++;
                student["_V"]++;
                eLesson["_students" + "_V"].push(student);
              } else if (gradePoints == 6) {
                eLesson["_VI"]++;
                student["_VI"]++;
                eLesson["_students" + "_VII"].push(student);
              } else if (gradePoints == 7) {
                eLesson["_VII"]++;
                student["_VII"]++;
                eLesson["_students" + "_VII"].push(student);
              } else if (gradePoints == 8) {
                eLesson["_VIII"]++;
                student["_VIII"]++;
                eLesson["_students" + "_VIII"].push(student);
              }
              // }
            } else {
              student.calculatedFinalScore[eLesson.id] = "-";
              student.calculatedFinalScoreStringValue[eLesson.id] = "-";
            }
          } else {
            scores.forEach((score) => {
              var allowed = false;
              if (status) {
                if (this.showingOnlyApproved) {
                  if (score.approved == true) {
                    allowed = true;
                  }
                } else {
                  allowed = true;
                }
              } else {
                allowed = true;
              }
              if (allowed) {
                if (score.scoreLevelNumber == 1) {
                  eLesson["_I"]++;
                  student["_I"]++;
                  eLesson["_students" + "_I"].push(student);
                } else if (score.scoreLevelNumber == 2) {
                  eLesson["_II"]++;
                  student["_II"]++;
                  eLesson["_students" + "_II"].push(student);
                } else if (score.scoreLevelNumber == 3) {
                  eLesson["_III"]++;
                  student["_III"]++;
                  eLesson["_students" + "_III"].push(student);
                } else if (score.scoreLevelNumber == 4) {
                  eLesson["_IV"]++;
                  student["_IV"]++;
                  eLesson["_students" + "_IV"].push(student);
                } else if (score.scoreLevelNumber == 5) {
                  eLesson["_V"]++;
                  student["_V"]++;
                  eLesson["_students" + "_V"].push(student);
                } else if (score.scoreLevelNumber == 6) {
                  eLesson["_VI"]++;
                  student["_VI"]++;
                  eLesson["_students" + "_VII"].push(student);
                } else if (score.scoreLevelNumber == 7) {
                  eLesson["_VII"]++;
                  student["_VII"]++;
                  eLesson["_students" + "_VII"].push(student);
                } else if (score.scoreLevelNumber == 8) {
                  eLesson["_VIII"]++;
                  student["_VIII"]++;
                  eLesson["_students" + "_VIII"].push(student);
                }
              }
            });
          }
        });
      });
      this.currentClassGroup.ref.update({
        year1UnelgeeAverageScores: year1UnelgeeAverageScores,
      });
    },
    _getScoreString(student, eLesson) {
      var scoreString = "";
      var sindex = 0;
      for (var score of this._getScore(student, eLesson)) {
        if (sindex + 1 == this._getScore(student, eLesson).length) {
          scoreString = scoreString + score.scoreFinal;
        } else {
          scoreString = scoreString + score.scoreFinal + ", ";
        }
        sindex++;
      }
      return scoreString;
    },
    _getScore(student, eLesson) {
      if (eLesson.allScores) {
        if (this.showingOnlyApproved) {
          var scores = eLesson.allScores.filter(
            (score) =>
              score.PERSON_ID == student.PERSON_ID && score.approved == true
          );
        } else {
          scores = eLesson.allScores.filter(
            (score) => score.PERSON_ID == student.PERSON_ID
          );
        }
        return scores;
      } else {
        return [];
      }

      // if (eLesson.allScores.length > 0) {
      //   this.finishedLoading = false;
      //   setTimeout(() => {
      //     this.finishedLoading = true;
      //   }, 2000);
      // }
    },
    print222(student) {
      for (var eLesson of this.eeljitLessons) {
        for (var unelgee2 of this._getScore(student, eLesson)) {
          console.log(eLesson.courseInfo.COURSE_NAME, unelgee2.scoreFinal);
        }
      }
    },
    _showStudentScores(student) {
      for (var eLesson of this.eeljitLessons) {
        for (var unelgee2 of this._getScore(student, eLesson)) {
          console.log(eLesson.courseInfo.COURSE_NAME, unelgee2.scoreFinal);
        }
      }
    },
    _getShortenName(text) {
      const words = text.split(/\s+/);
      if (words.length == 1) {
        return words[0].substring(0, 3).toUpperCase();
      } else {
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase();
        });
        const capitalizedText = capitalizedWords.join("");
        return capitalizedText;
      }
    },
    excelHeaderName(lesson) {
      return lesson.courseInfo.COURSE_NAME;
    },
    _download() {
      // this.$swal.fire("Дүнгүүд баталгаажуулаад дуусахаар татаx боломжтой!");
      const wb = XLSX.utils.book_new();
      this.excelLoading = true;
      var excelData = [];
      this.calculateScore(true);
      this.students2.forEach((student, sindex) => {
        var row = {};
        row["No"] = sindex + 1;
        row["Нэр"] = student["FIRST_NAME"];
        row["Овог"] = student["LAST_NAME"];
        this.eeljitLessons.forEach((eLesson) => {
          row["Дундаж"] = student.averageScore;
          var studentScores = this._getScore(student, eLesson);
          var scoreString = "";
          var scoreLevelString = "";
          if (studentScores.length > 0) {
            if (this.selectedGradingMethod != null) {
              if (typeof student.calculatedFinalScore[eLesson.id] == "number") {
                scoreString = student.calculatedFinalScore[eLesson.id];
                if (
                  eLesson &&
                  eLesson.courseInfo &&
                  eLesson.courseInfo.GRADING_SCHEME_ID == 13
                ) {
                  if (student.calculatedFinalScore[eLesson.id] > 0)
                    scoreLevelString = "Тооцсон";
                  else scoreLevelString = "Тооцоогүй";
                } else
                  scoreLevelString =
                    student.calculatedFinalScoreStringValue[eLesson.id];
              } else {
                scoreString = "";
                scoreLevelString = "";
              }
            } else {
              studentScores.forEach((score, sindex) => {
                if (this.showingOnlyApproved) {
                  if (score.approved == true) {
                    if (sindex + 1 == studentScores.length) {
                      scoreString = scoreString + score.scoreFinal;
                    } else {
                      scoreString = scoreString + score.scoreFinal + ", ";
                    }
                  } else {
                    if (sindex + 1 == studentScores.length) {
                      scoreString = scoreString + "";
                    } else {
                      scoreString = scoreString + "" + ", ";
                    }
                  }
                } else {
                  if (sindex + 1 == studentScores.length) {
                    scoreString = scoreString + score.scoreFinal;
                  } else {
                    scoreString = scoreString + score.scoreFinal + ", ";
                  }
                }
              });
            }
          } else {
            scoreString = "";
            scoreLevelString = "";
          }
          row[this.excelHeaderName(eLesson)] = scoreString;
          if (this.isWithScoreLevel)
            row[this.excelHeaderName(eLesson)] = scoreLevelString;
        });
        this.scoreLevels.forEach((scoreLevel) => {
          row[scoreLevel.name] = student["_" + scoreLevel.name];
        });
        excelData.push(row);
      });

      var row2 = {};
      row2["No"] = "";
      row2["Нэр"] = "";
      row2["Овог"] = "Дундаж";
      row2["Дундаж"] = "";
      this.eeljitLessons.forEach((eLesson) => {
        row2[this.excelHeaderName(eLesson)] = eLesson.averageScore;
      });
      excelData.push(row2);

      this.scoreLevels.forEach((scoreLevel) => {
        var row = {};
        row["No"] = "";
        row["Нэр"] = "";
        row["Овог"] = scoreLevel.name;
        this.eeljitLessons.forEach((eLesson) => {
          row[this.excelHeaderName(eLesson)] = eLesson["_" + scoreLevel.name];
        });
        excelData.push(row);
      });

      const data = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, data, "Дүнгүүд");
      this.excelLoading = false;
      XLSX.writeFile(
        wb,
        "unelgee2-" +
          this.currentClassGroup["STUDENT_GROUP_NAME"] +
          "-" +
          this.selectedHalfYear.yearId +
          "-р xагас жил" +
          ".xlsx"
      );
      var tmp = {};
      tmp[
        "downloadClassScoreTable-" +
          this.preText +
          "-" +
          this.selectedHalfYear.yearId
      ] = new Date();
      tmp["downloadClassScoreTableByRef"] = this.userData.ref;
      this.currentClassGroup.ref.update(tmp);
      if (this.userData.role == "teacher") {
        this.userData.school.ref
          .collection(
            "teachersExecutionOverview-" + this.userData.school.currentYear
          )
          .doc(String(this.userData.id))
          .update(tmp);
      }

      this.calculateScore(false);
    },
    _print(item) {
      console.log(item.ref.path);
    },
    _getPrefix(item) {
      var name = null;
      if (item.prefixName) {
        if (item.lastName && item.lastName.length >= item.prefixName)
          name =
            item.firstName + ". " + item.lastName.substring(0, item.prefixName);
        else name = item.firstName;
      } else {
        if (item.lastName && item.lastName.length > 0)
          name = item.firstName + ". " + item.lastName[0];
        else name = item.firstName;
      }
      return name;
    },
    _detail() {},
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
<style>
.table-container {
  overflow-x: auto;
  max-width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 1px 4px;
  text-align: left;
  border: 1px solid #ddd;
  /* Change this to your desired border style */
}

.fixed-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
  /* Change this to your desired background color */
}
.scoreBorderedTable {
  border-collapse: collapse;
}
.scoreTableTodorhoilolt-hcell {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
  font-weight: bold !important;
}
.scoreTableTodorhoilolt-cell {
  border: 1px solid black;
  text-align: left;
  border-spacing: 0px !important;
  padding: 0px;
  padding-left: 10px;
  cursor: pointer;
}
</style>
