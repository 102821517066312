<template>
  <v-container class="my-0 mx-auto">
    <v-card class="py-6">
      <div class="card-header-padding pb-0">
        <v-row class="mb-0">
          <v-col md="6" lg="6" cols="6">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Жижүүр багшийн хувиарлалт
              </h5>
              <p class="text-sm text-body mb-0">
                Хэрэв танай сургууль жижүүр багшаар өдрийн ирц бүртгүүлдэг бол
                энд жижүүр багшийг удирдана.
              </p>
            </div>
          </v-col>
          <v-col md="6" lg="6" cols="6">
            <v-row justify="end">
              <v-col class="pa-0 mr-2">
                <v-select :items="_getSemesters()" v-model="currentSelectedSemester" return-object item-text="session"
                  item-value="session" label="Улирал сонгоx">
                </v-select>
              </v-col>
              <v-col class="pa-0 mr-2" v-if="currentSelectedSemester">
                <v-select style="background-color: #f8bbd0" :items="currentSelectedSemester.months"
                  v-model="currentSelectedMonth" return-object item-text="name" item-value="name" label="Сар сонгох">
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <p class="red--text">
          Харгалзах нүдэнд дарсанаар жижүүр багш тухайн өдөрт өдрийн ирц бүртгэх
          боломжтой. Буцаах бол дахин дарна. Өнгөтэй нүд нь тухайн багш тухайн
          өдөр жижүүр багш гэсэн үг.
        </p>
      </div>
      <div class="card-header-padding pt-0">
        <v-simple-table class="bborder">
          <template v-slot:default>
            <thead>
              <tr>
                <th style="
                    border-right: 0px !important;
                    border-left: 1px solid #bbb;
                  ">
                  No
                </th>
                <th>Нэр</th>
                <th v-for="dd in accessLinks" :key="currentSelectedMonth.name + dd.day"
                  class="text-center px-0 blue--text" style="width: 2%">
                  {{ currentSelectedMonth.name }}/{{ dd.day }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(teacher, lindex) in teachers" :key="teacher.id + lindex">
                <td style="
                    border-right: 0px !important;
                    border-left: 1px solid #bbb;
                    width: 1%;
                  ">
                  {{ lindex + 1 }}
                </td>
                <td style="width: 10%" @click="_print(teacher)" :data-title2="teacher['FIRST_NAME'] + ', ' + teacher['LAST_NAME']
                  ">
                  {{ teacher.DISPLAY_NAME }}
                </td>
                <template v-for="day in accessLinks">
                  <td :key="teacher.id + lindex + day.id" class="text-center borderCell px-1"
                    @click="_clickedCell(day, teacher)" v-if="_isContained(day, teacher)"
                    style="background: #f5f591; cursor: pointer; width: 2%" :data-title2="day.year + '-' + currentSelectedMonth.name + '-' + day.day
                      ">
                    <span>+</span>
                  </td>
                  <td class="text-center px-0 mx-0" :key="teacher.id + lindex + day.day" v-else
                    @click="_clickedCell(day, teacher)" :data-title2="day.year + '-' + currentSelectedMonth.name + '-' + day.day
                      " style="cursor: pointer; width: 1px">
                    -
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      currentSelectedMonth: null,
      currentSelectedSemester: null,
      accessLinks: null,
      teachers: null,
    };
  },
  watch: {
    currentSelectedSemester() {
      var cc = new Date().getMonth() + 1;
      this.currentSelectedMonth = null;
      if (this.currentSelectedSemester && this.currentSelectedSemester.months) {
        var dd = this.currentSelectedSemester.months.find(
          (mm) => mm.name == cc
        );
        if (dd) this.currentSelectedMonth = dd;
        else this.currentSelectedMonth = null;
      }
    },
    currentSelectedMonth() {
      if (this.currentSelectedMonth && this.currentSelectedMonth.days) {
        this.accessLinks = [];
        this.currentSelectedMonth.days.forEach((dd) => {
          this.accessLinks.push(dd);
        });
      }
    },
  },
  methods: {
    _print(teacher) {
      console.log(teacher.ref.path);
    },
    _getSemesters() {
      return this.$store.state.calendarButez2;
    },
    _isContained(day, teacher) {
      if (teacher.accessLinks) {
        for (const jijuur of teacher.accessLinks) {
          if (
            day.year + "-" + this.currentSelectedMonth.name + "-" + day.day ==
            jijuur.id
          ) {
            return true;
          }
        }
      }
      return false;
    },
    _clickedCell(day, teacher) {
      var added = this._isContained(day, teacher);
      var xx = day.year + "-" + this.currentSelectedMonth.name + "-" + day.day;
      if (added) {
        this.userData.school.ref
          .collection("_settings/classIrz/employees")
          .doc(teacher.id)
          .collection("jijuur")
          .doc(xx)
          .delete();
      } else {
        this.userData.school.ref
          .collection("_settings/classIrz/employees")
          .doc(teacher.id)
          .collection("jijuur")
          .doc(xx)
          .set(
            {
              createdAt: new Date(),
            },
            { merge: true }
          );
      }
    },
    async _setupp() {
      this.userData.school.ref
        .collection("teachers")
        .orderBy("FIRST_NAME", "asc")
        .onSnapshot((querySnapshot) => {
          this.teachers = [];
          querySnapshot.forEach((doc) => {
            let teacher = doc.data();
            teacher.id = doc.id;
            teacher.ref = doc.ref;
            teacher.accessLinks = null;
            if (!teacher.moved) {
              this.userData.school.ref
                .collection("_settings/classIrz/employees")
                .doc(teacher.id)
                .collection("jijuur")
                .onSnapshot((querySnapshot) => {
                  teacher.accessLinks = [];
                  querySnapshot.forEach((doc) => {
                    let jijuur = doc.data();
                    jijuur.id = doc.id;
                    jijuur.ref = doc.ref;
                    teacher.accessLinks.push(jijuur);
                  });
                });
              this.teachers.push(teacher);
            }
          });
        });
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },

  created() {
    var ss = this._getSemesters().find((xx) => xx.session == this.$store.state.schoolCurrentSemester);
    if (ss) this.currentSelectedSemester = ss;
    if (this.userData.school.jijuurIrzWith) {
      console.log(this.userData.school.jijuurIrzWith);
    } else if (this.userData.school.jijuurIrzWith == undefined) {
      this.$swal({
        title: "Танай сургуульд жижүүр багш ИРЦ бүртгэдэг үү?",
        text: "",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.userData.school.ref.update({ jijuurIrzWith: true });
          console.log("noooo");
        } else {
          this.userData.school.ref.update({ jijuurIrzWith: false });
        }
      });
    }
    this._setupp();
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #2196f3;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #2196f3;
  -webkit-box-shadow: 0px 0px 4px #2196f3;
  box-shadow: 0px 0px 4px #2196f3;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
