<template>
  <v-card class="px-0 mt-0" height="450">
    <template>
      <div class="px-10 pt-4 d-flex flex-row ma-0">
        <span>
          <span class="blue--text">Нийт сурагчийн тооxxx: </span
          >{{ totalNumberOfStudents }}, 
          <span class="blue--text">Бүх Дундаж:</span>
          {{ lessonAverageScore }},
          <span class="blue--text"> Батлагдсан дундаж:</span>
          {{ approvedAverageScore }}
        </span>
        <v-spacer></v-spacer>
        <v-col cols="3" class="py-0 mr-4">
          <v-select
            dense
            v-model="selectedGradingMethod"
            label="Бүлэг сэдвийн үнэлгээ (Үнэлгээ-I)"
            item-text="name"
            item-value="value"
            :items="gradingMethods"
          >
          </v-select>
        </v-col>

        <v-btn
          class="blue white--text mr-4"
          small
          @click="useGradingMethodAll()"
          >Үнэлгээ 1 дүн ашиглах</v-btn
        >
      </div>
      <v-simple-table
        v-if="attendances && allScores"
        class="px-10 pt-2 pb-2 scoreTables"
        style="height: 100%; overflow-y: auto"
      >
        <tbody>
          <tr style="background-color: #ececec" class="mt-2 pt-2">
            <th class="font-weight-bold black--text text-center">No.</th>
            <th class="font-weight-bold black--text pl-2">Нэр</th>
            <th class="font-weight-bold black--text pl-2">Бүлэг</th>
            <th
              class="font-weight-bold black--text text-center"
              v-if="!$attrs.simpleCalculationModel"
            >
              Ирц (10%)
              <v-btn
                dark
                x-small
                color="red"
                elevation="0"
                @click="_readAttendances"
                >Ирц татах</v-btn
              >
            </th>
            <th class="font-weight-bold black--text text-center">
              <div class="d-flex flex-row ma-0">
                <v-col
                  cols="4"
                  style="
                    border-right: 2px solid #dddddd;
                    background-color: #c9daf8 !important;
                  "
                  class="pa-0"
                  >Дундаж</v-col
                >
                <v-col cols="8" class="pa-0">Үнэлгээ - I</v-col>
              </div>
            </th>
            <th class="font-weight-bold black--text text-center">Дүн (%)</th>
            <th class="font-weight-bold black--text text-center px-2">
              Түвшин
            </th>
            <!-- <th class="font-weight-bold black--text text-center">Тайлбар</th> -->
            <th class="font-weight-bold black--text text-center">Төлөв</th>
            <th class="font-weight-bold black--text text-center px-2">
              Үйлдэл
            </th>
          </tr>
          <tr
            v-for="(item, ii) in filteredStudents"
            :key="ii + '-' + item.index"
          >
            <template v-if="item.separator">
              <td
                colspan="8"
                class="pl-2 blue--text"
                style="background-color: #ececec; height: 20px !important"
              >
                <!-- {{ item.teacherDisplayName }}   -->
                {{ item.name }} ({{ item.numberOfStudents }})
              </td>
            </template>

            <template style="width: 100%" v-else>
              <td class="text-center" @click="_print(item)" style="width: 1%">
                {{ item.index }}
              </td>
              <td
                class="px-2"
                style="max-width: 40%; cursor: pointer"
                @click="_deleteItem(item)"
              >
                {{ item["FIRST_NAME"]
                }}<span class="grey--text"
                  >,
                  {{ item["LAST_NAME"] }}
                </span>
              </td>
              <td class="text-center">
                <span class="blue--text">
                  {{ item["STUDENT_GROUP_NAME"] }}</span
                >
              </td>
              <td class="text-center" v-if="!$attrs.simpleCalculationModel">
                {{ getStudentAttendances(item) }}
              </td>
              <td class="text-center">
                <div class="ma-0 d-flex flex-row" style="height: 100%">
                  <v-col
                    cols="4"
                    @click="
                      useGradingMethod({ score: item.averageScore }, item)
                    "
                    class="pa-0 pt-1"
                    title="Дүн ашиглах"
                    style="border-right: 2px solid #dddddd"
                    :style="
                      (item.scoreState != true && item.sentToManager != true
                        ? 'cursor: pointer;'
                        : '') +
                      (selectedGradingMethod == 0
                        ? 'background-color: #fdd270;'
                        : 'background-color: #c9daf8;')
                    "
                  >
                    {{ getUnelgee1Scores(item) }}
                  </v-col>
                  <v-col
                    class="pa-0 pt-1"
                    @click="useGradingMethod(score, item)"
                    v-for="(score, sindex) in item.unelgee1Scores"
                    :key="'score' + sindex + score.score"
                    :style="
                      (item.scoreState != true && item.sentToManager != true
                        ? 'cursor: pointer;'
                        : '') +
                      ((selectedGradingMethod == 1 &&
                        score.score == item.highestScore) ||
                      (selectedGradingMethod == 2 &&
                        score.score == item.latestScore)
                        ? 'background-color: #fdd270;'
                        : '' +
                          (sindex + 1 == item.unelgee1Scores.length
                            ? ''
                            : 'border-right: 2px solid #dddddd'))
                    "
                    :class="
                      sindex + 1 == item.unelgee1Scores.length ? '' : 'px-1'
                    "
                    >{{ score.score }}</v-col
                  >
                </div>
              </td>
              <td style="max-width: 15%; background: #ffebee">
                <div
                  class="text-center"
                  v-if="item.scoreState == true || item.sentToManager == true"
                >
                  {{ item.score }}
                </div>
                <input
                  v-else
                  @keyup.enter="focusNextField"
                  outlined
                  class="text-center pl-3 score-bordered-input"
                  :id="ii"
                  type="number"
                  v-model.number="item.score"
                  style="width: 100%"
                  :max="100"
                  :min="0"
                  @click="
                    item.score != null &&
                    item.score != undefined &&
                    typeof item.score == 'number'
                      ? (previousValue = item.score)
                      : (previousValue = null)
                  "
                  @keyup="checkValue(item)"
                  @change="
                    calScoreLevel(item);
                    item.modifiedScore = true;
                  "
                />
              </td>
              <td class="text-center">
                <span v-if="item.scoreFinal == 0" class="red--text">{{
                  _getCorrectScoreString(item)
                }}</span>
                <span v-else class="green--text">{{
                  _getCorrectScoreString(item)
                }}</span>
              </td>
              <!-- <td style="width: 25%">
              <input
                class="text-center"
                :id="item.id + 'description'"
                v-model.trim="item.description"
                style="width: 100%"
              />
            </td> -->
              <td class="text-center">
                <span
                  text
                  v-if="item.scoreState != -1"
                  x-small
                  :class="
                    item.scoreState == true
                      ? 'green--text'
                      : item.scoreState == false
                      ? 'red--text'
                      : item.sentToManager == true
                      ? 'orange--text'
                      : 'blue--text'
                  "
                  class="ml-2 mr-3"
                  style="text-transform: none"
                  v-text="
                    item.scoreState == true
                      ? 'Баталгаажсан'
                      : item.scoreState == false
                      ? 'Цуцлагдсан'
                      : item.sentToManager == true
                      ? 'Хянагдаж байгаа'
                      : 'Бүртгэсэн'
                  "
                >
                </span>
                <span
                  class="px-2 red--text text-center"
                  v-else-if="
                    (item.score == null || item.score == undefined) &&
                    typeof item.score != 'number'
                  "
                  >Дүнгүй</span
                >
              </td>
              <td class="text-center">
                <v-btn
                  @click="_clearScore(item)"
                  text
                  v-if="
                    item.scoreState != -1 &&
                    item.scoreState != true &&
                    item.sentToManager != true
                  "
                  x-small
                  class="white--text ml-2 mr-3 bg-gradient-danger"
                  style="text-transform: none"
                >
                  Устгах
                </v-btn>
              </td>
            </template>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else class="mt-16 pt-16">
        <v-progress-linear
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
      </div>
      <v-dialog v-model="dialogDelete" max-width="600px" v-if="editedItem">
        <v-card class="card-shadow card-padding border-radius-xl py-10">
          <v-card-title
            class="pt-0 text-h4 text-typo justify-censentToManagerter"
            >Группт байдаггүй сурагч уу?</v-card-title
          >
          <v-card-text class="text-h5 mt-6">
            <span class="font-weight-bold text-uppercase"
              >{{ editedItem["FIRST_NAME"] }},</span
            >
            {{ editedItem["LAST_NAME"] }}
          </v-card-text>
          <v-card-text>
            <span style="color: red">
              Энэxүү үйлдлийг xийсэнээр таны ангийн сурагч группээс
              хасагдана.</span
            >
          </v-card-text>
          <!-- <v-card-text>
            Та энэ сурагчийг
            <span class="font-weight-bold" style="color: red"
              >аль ангид сурдагийг мэдэx бол</span
            >
            сонгоно уу. Мэдэxгүй бол сонгоxгүй байж болно.
          </v-card-text> -->
          <v-card-actions class="pb-0 mt-4">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDelete"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
              >Цуцлаx</v-btn
            >
            <v-btn
              @click="_moveStudent(editedItem)"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
              >Манай группын сурагч биш</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
export default {
  components: {},
  data: () => ({
    studentsStatuses: null,
    alertText: null,
    showAlert: false,
    selectedEeljTsag: null,
    selectedLessonDayReport: null,
    attends: null,
    selectedStudent: null,
    selectedXXDay: null,
    selectedReasons: null,
    forParents: false,
    forStudent: false,
    forClassTeacher: false,
    forManager: false,
    xlessonStudentNotes: null,
    noteAdded: null,
    dialogAddNote: false,

    query: null,
    bodyNames: null,
    endOfDay: 30,
    collectionName: "attendances",
    docName: "attendance",
    lessonAverageScore: 0,
    approvedAverageScore: 0,
    currentDays: null,
    teachedTopics: null,
    curriculumDialog: null,
    selectedSubtopic: null,
    // selectedHomeworks: null,
    newDaalgavarName: null,
    previousSelectedDaalgavar: null,
    headerNames: [
      {
        text: "No.",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
    ],
    attendances: null,
    loading: false,
    allScores: null,
    previousValue: null,
    editedItem: null,
    dialogDelete: false,
    totalNumberOfStudents: null,
    alreadyCollectedGroup: null,
    selectedGradingMethod: 2,
    gradingMethods: [
      {
        name: "Дундаж дүнгээр",
        value: 0,
      },
      {
        name: "Хамгийн өндөр шалгалтын дүнгээр",
        value: 1,
      },
      {
        name: "Сүүлийн шалгалтын дүнгээр",
        value: 2,
      },
    ],
  }),
  mixins: [myfunction],
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    _getFilteredGroups() {
      // $attrs.lesson.lessonGroups
      var list = [];
      for (const lg of this.$attrs.lesson.lessonGroups) {
        if (
          this.$attrs.currentSelectedExam.lessonGroup &&
          lg.id == this.$attrs.currentSelectedExam.lessonGroup.id
        ) {
          list.push(lg);
        } else if (!this.$attrs.currentSelectedExam.lessonGroup) {
          list.push(lg);
        }
      }
      return list;
    },
    filteredStudents() {
      var list = [];
      var prevGroupIndex;
      if (this.$attrs.students != null) {
        var counter = 0;
        for (var stud of this.$attrs.students) {
          if (this.$attrs.lesson.esisLessonType.esisLessonTypeId > 1) {
            if (stud.lessonGroup && stud.lessonGroup.groupIndex) {
              if (
                !prevGroupIndex ||
                prevGroupIndex != stud.lessonGroup.groupIndex
              ) {
                list.push({
                  numberOfStudents: stud.lessonGroup.students.length,
                  index: stud.lessonGroup.groupIndex,
                  name: stud.lessonGroup.name,
                  separator: true,
                  // teacherDisplayName:
                  // stud.lessonGroup.teacher.teacherDisplayName,
                });
                this.setNumberOfStudents(
                  stud.lessonGroup.students.length,
                  true,
                  stud.lessonGroup
                );

                counter = 0;
              }
              prevGroupIndex = stud.lessonGroup.groupIndex;
            }
          } else {
            this.setNumberOfStudents(this.$attrs.students.length, false);
          }
          counter++;
          stud.index = counter;
          if (stud.changedScore == undefined) {
            stud.changedScore = false;
          }

          this.getStudentScoreState(stud);
          list.push(stud);
        }
      }
      return list;
    },
  },
  async created() {
    this.totalNumberOfStudents = 0;
    this.alreadyCollectedGroup = [];
    var classGroup =
      await this.$attrs.lesson.classGroups[0].classGroupRef.get();
    this.$attrs.lesson.ref
      .collection("executions")
      .where("semester", "in", [1, 2])
      .where("studentsPresent", "<", classGroup.data().numberOfStudents)
      .get()
      .then((docs) => {
        this.attendances = [];
        this.loading = true;
        if (this.$attrs.lesson.esisLessonType.esisLessonTypeId > 1) {
          this.$attrs.students = this.$attrs.students.sort(
            (a, b) => a.lessonGroup.groupIndex - b.lessonGroup.groupIndex
          );
        }
        docs.forEach(async (doc) => {
          let execution = doc.data();
          execution.ref = doc.ref;
          execution.id = doc.id;
          await this.$attrs.lesson.classGroups[0].classGroupRef
            .collection("attendances-" + this.userData.school.currentYear)
            .where("year", "==", new Date().getFullYear())
            .where("month", "==", execution.month)
            .where("day", "==", execution.day)
            .where("xeelj", "==", execution.xeelj)
            .where("daily_attendance", "==", false)
            .get()
            .then((docs) => {
              docs.forEach((doc) => {
                let att = doc.data();
                att.ref = doc.ref;
                att.id = doc.id;
                // console.log(att)
                this.attendances.push(att);
              });
            });
        });
        this.loading = false;
        console.log(this.attendances);
      });

    var tmp = {};
    tmp["numberOfStudents-" + this.userData.id] = this.$attrs.students.length;
    if (
      this.$attrs.lesson["numberOfStudents-" + this.userData.id] == undefined ||
      tmp["numberOfStudents-" + this.userData.id] !=
        this.$attrs.lesson["numberOfStudents-" + this.userData.id]
    ) {
      this.$attrs.lesson.ref.update(tmp);
    }
    this.allScores = this.$attrs.allScores;
    this.calculateAverageScore();
  },
  methods: {
    focusNextField(event) {
      document.getElementById(String(Number(event.srcElement.id) + 1)).focus();
    },
    _getCorrectScoreString(item) {
      if (
        this.$attrs.lesson &&
        this.$attrs.lesson.courseInfo &&
        (this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        if (item.scoreFinal > 0) return "Тооцсон";
        //return this.$attrs.lesson.courseInfo.GRADING_SCHEME_NAME;
        else if (item.scoreFinal == 0) return "Тооцоогүй";
        else return null;
      } else return item.scoreLevelString;
    },
    _getBD(student) {
      return student["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "");
    },
    useGradingMethodAll() {
      this.$swal({
        title: "Үнэлгээ1 -ийн дүнг Үнэлгээ 2-т хуулах уу?",
        text: "Та сонгосон Үнэлгээ 1-ийнхээ дүнг Үнэлгээ 2-ийн баганад бүх сурагч дээр хуулах боломжтой!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$attrs.students.forEach((student) => {
            if (this.selectedGradingMethod == 0) {
              if (student.averageScore)
                this.useGradingMethod({ score: student.averageScore }, student);
            } else if (this.selectedGradingMethod == 1) {
              if (student.highestScoreExam)
                this.useGradingMethod(student.highestScoreExam, student);
            } else if (this.selectedGradingMethod == 2) {
              if (student.latestScoreExam)
                this.useGradingMethod(student.latestScoreExam, student);
            }
          });
        }
      });
    },
    setNumberOfStudents(value, status, lessonGroup) {
      if (status) {
        if (lessonGroup) {
          if (
            this.alreadyCollectedGroup.find((lg) => lg == lessonGroup.id) ==
            undefined
          ) {
            this.totalNumberOfStudents = this.totalNumberOfStudents + value;
            this.alreadyCollectedGroup.push(lessonGroup.id);
          }
        } else {
          this.totalNumberOfStudents = this.totalNumberOfStudents + value;
        }
      } else {
        this.totalNumberOfStudents = value;
      }
    },
    _deleteItem(item) {
      if (
        this.$attrs.lesson.esisLessonType.esisLessonTypeId > 1 &&
        item.lessonGroup.allStudentsSelected != true
      ) {
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    _moveStudent(item) {
      this.$swal({
        title:
          "Энэ группээс " +
          item["FIRST_NAME"] +
          ", " +
          item["LAST_NAME"] +
          " сурагчийг хасах уу?",
        text: "<Тийм> гэснээр сурагч группээс хасагдана. Буцааж нэмэх бол АУБ нэмнэ!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (item.ref.path.includes("lesson-groups")) {
            var studIndex = this.$attrs.students.findIndex(
              (ss) => ss.PERSON_ID == item.PERSON_ID
            );
            item.ref.delete().then(() => {
              this.editedItem = Object.assign({}, this.defaultItem);
              if (studIndex > -1) this.$attrs.students.splice(studIndex, 1);
              this.dialogDelete = false;
            });
          }
        }
      });
    },
    useGradingMethod(exam, item) {
      if (item.scoreState != true && item.sentToManager != true)
        if (
          exam &&
          exam.score != null &&
          exam.score != undefined &&
          typeof exam.score == "number"
        ) {
          item.score = Number(exam.score);
          item.scoreFinal = Number(exam.score);
          item.scoreBasedOnExam = exam;
          if (item.score == item.averageScore) {
            item.scoreBasedOnState = 0;
          } else if (item.score == item.highestScore) {
            item.scoreBasedOnState = 1;
          } else if (item.score == item.latestScore) {
            item.scoreBasedOnState = 2;
          } else {
            item.scoreBasedOnState = false;
          }
          console.log(exam);
          item.modifiedScore = true;
          this.calScoreLevel(item);
          this.$forceUpdate();
        }
    },
    _clearScore(score) {
      console.log(score.scoreRef.path);
      score.scoreRef
        .update({
          score: fb.firestore.FieldValue.delete(),
          scoreFinal: fb.firestore.FieldValue.delete(),
          scoreLevelNumber: fb.firestore.FieldValue.delete(),
          scoreLevelString: fb.firestore.FieldValue.delete(),
          sentToManager: fb.firestore.FieldValue.delete(),
          approved: fb.firestore.FieldValue.delete(),
        })
        .then(() => {
          if (score.sentToManager != undefined) delete score.sentToManager;
          delete score.score;
          delete score.scoreFinal;
          delete score.scoreLevelNumber;
          delete score.scoreLevelString;
          delete score.approved;
          score.scoreState = -1;

          var deletingScore;
          if (this.$attrs.lesson.esisLessonType.esisLessonTypeId > 1) {
            deletingScore = this.allScores.find(
              (dscore) =>
                dscore.PERSON_ID == score.PERSON_ID &&
                dscore.lessonGroupId == score.lessonGroup.id
            );
          } else {
            deletingScore = this.allScores.find(
              (dscore) => dscore.PERSON_ID == score.PERSON_ID
            );
          }
          if (deletingScore) {
            console.log(deletingScore);
            deletingScore.deleted = true;
          }
          this.calculateAverageScore();
          this.$forceUpdate();
        });
    },
    getUnelgee1Scores(student) {
      var scores;
      if (this.$attrs.lesson.esisLessonType.esisLessonTypeId > 1) {
        scores = this.$attrs.unelgee1Scores.filter(
          (score) =>
            score.PERSON_ID == student.PERSON_ID &&
            score.courseInfo.COURSE_ID ==
              this.$attrs.lesson.courseInfo.COURSE_ID &&
            score.lessonGroupId == student.lessonGroup.id
        );
      } else {
        scores = this.$attrs.unelgee1Scores.filter(
          (score) =>
            score.PERSON_ID == student.PERSON_ID &&
            score.courseInfo.COURSE_ID ==
              this.$attrs.lesson.courseInfo.COURSE_ID
        );
      }

      scores = scores.sort((a, b) => a.examDate - b.examDate);
      if (scores.length > 0) {
        var highestScore = null;
        var averageScore = 0;
        var latestScore = null;
        scores.forEach((score) => {
          if (
            (highestScore && highestScore.score < score.score) ||
            highestScore == null
          ) {
            highestScore = score;
          }
          if (
            (latestScore && latestScore.examDate < score.examDate) ||
            latestScore == null
          ) {
            latestScore = score;
          }
          averageScore = averageScore + score.score;
        });

        averageScore = Number((averageScore / scores.length).toFixed(0));
        student.highestScore = highestScore.score;
        student.highestScoreExam = highestScore;
        student.latestScore = latestScore.score;
        student.latestScoreExam = latestScore;
        student.unelgee1Scores = scores;
        student.averageScore = averageScore;

        return averageScore;
      } else {
        return "-";
      }
    },
    checkValue(item) {
      if (this.previousValue) {
        if (item.score > 100) {
          item.score = this.previousValue;
        } else {
          if (
            String(item.score) != String(this.previousValue)[0] &&
            item.score != null &&
            item.score != undefined &&
            typeof item.score == "number"
          )
            this.previousValue = item.score;
        }
      } else {
        if (item.score > 100) {
          item.score = 100;
          this.previousValue = 100;
        }
      }
      this.calculateAverageScore();
    },
    calculateAverageScore() {
      var lessonAverageSum = 0;
      var lessonAverageGradedScoreCount = 0;
      this.lessonAverageScore = 0;

      var approvedAverageSum = 0;
      var approvedAverageGradedScoreCount = 0;
      this.approvedAverageScore = 0;
      for (var student of this.filteredStudents) {
        if (student.score != undefined && typeof student.score == "number") {
          lessonAverageSum += student.score;
          lessonAverageGradedScoreCount++;
          if (student.approved) {
            approvedAverageSum += student.score;
            approvedAverageGradedScoreCount++;
          }
        }
      }
      if (approvedAverageSum > 0 && approvedAverageGradedScoreCount > 0)
        this.approvedAverageScore = Number(
          (approvedAverageSum / approvedAverageGradedScoreCount).toFixed(1)
        );
      if (lessonAverageSum > 0 && lessonAverageGradedScoreCount > 0)
        this.lessonAverageScore = Number(
          (lessonAverageSum / lessonAverageGradedScoreCount).toFixed(1)
        );
      if (this.lessonAverageScore != "NaN") {
        var tmp = {};
        tmp["year1UnelgeeScores-" + this.userData.id] =
          this.$attrs.lesson["year1UnelgeeScores-" + this.userData.id];
        tmp["year1UnelgeeScores-" + this.userData.id].allScoreAverage =
          this.lessonAverageScore;
        tmp["year1UnelgeeScores-" + this.userData.id].scoreAverage =
          this.approvedAverageScore;
        this.$attrs.lesson.ref.set(tmp, { merge: true });
      } else {
        this.lessonAverageScore = "-";
      }
    },
    getStudentScoreState(student) {
      if (this.allScores) {
        var score;
        if (this.$attrs.lesson.esisLessonType.esisLessonTypeId > 1) {
          score = this.allScores.find(
            (score) =>
              score.PERSON_ID == student.PERSON_ID &&
              score.lessonGroupId == student.lessonGroup.id &&
              score.deleted != true
          );
        } else {
          score = this.allScores.find(
            (score) =>
              score.PERSON_ID == student.PERSON_ID && score.deleted != true
          );
        }
        if (
          score &&
          score.score != null &&
          score.score != undefined &&
          typeof score.score == "number"
        ) {
          student.score = score.score;
          student.scoreFinal = score.score;
          student.scoreRef = score.ref;
          student.scoreLevelString = score.scoreLevelString;
          if (score.approved != undefined) student.approved = score.approved;
          if (student.sentToManager != undefined)
            student.sentToManager = score.sentToManager;
          if (score.approved == true) {
            student.scoreState = true;
          } else if (score.approved == false) {
            student.scoreState = false;
          } else {
            if (score.sentToManager) {
              student.sentToManager = true;
            } else {
              student.sentToManager = false;
            }
            student.scoreState = null;
          }
        } else {
          student.scoreState = -1;
        }
      }
    },
    async _readAttendances() {
      await this.$attrs.lesson.classGroups[0].classGroupRef
        .collection("attendances-" + this.userData.school.currentYear)
        .where("year", "==", new Date().getFullYear())
        .where("month", "<=", 12)
        .where("daily_attendance", "==", false)
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let att = doc.data();
            att.ref = doc.ref;
            att.id = doc.id;
            console.log(att);
            this.attendances.push(att);
          });
        });

      // var classGroup =
      // await this.$attrs.lesson.classGroups[0].classGroupRef.get();
      // this.$attrs.lesson.ref
      //   .collection("executions")
      //   .where("semester", "in", [1, 2])
      //   .where("studentsPresent", "<", classGroup.data().numberOfStudents)
      //   .get()
      //   .then((docs) => {
      //     this.attendances = [];
      //     this.loading = true;
      //     docs.forEach(async (doc) => {
      //       let execution = doc.data();
      //       execution.ref = doc.ref;
      //       execution.id = doc.id;
      //       await this.$attrs.lesson.classGroups[0].classGroupRef
      //         .collection("attendances-" + this.userData.school.currentYear)
      //         .where("year", "==", new Date().getFullYear())
      //         .where("month", "==", execution.month)
      //         .where("day", "==", execution.day)
      //         .where("xeelj", "==", execution.xeelj)
      //         .where("daily_attendance", "==", false)
      //         .get()
      //         .then((docs) => {
      //           docs.forEach((doc) => {
      //             let att = doc.data();
      //             att.ref = doc.ref;
      //             att.id = doc.id;
      //             // console.log(att)
      //             this.attendances.push(att);
      //           });
      //         });
      //     });
      //     this.loading = false;
      //     console.log(this.attendances);
      //   });
    },
    getStudentAttendances(student) {
      if (this.attendances)
        var studentAtt = this.attendances.filter(
          (att) => att.studentId == student["PERSON_ID"]
        );
      var absent = 0;
      var sick = 0;
      var excused = 0;
      if (studentAtt) {
        studentAtt.forEach((att) => {
          if (att.status == -4) {
            absent++;
          } else if (att.status == -3) {
            sick++;
          } else if (att.status == -2) {
            excused++;
          }
        });
      }
      console.log(sick, excused);
      return absent;
    },
    calScoreLevel(item) {
      item.scoreFinal = null;
      if (!this.$attrs.simpleCalculationModel) {
        item.scoreFinal =
          (item.score != null &&
          item.score != undefined &&
          typeof item.score == "number"
            ? item.score
            : 0) + this.getStudentAttendances(item);
        if (item.scoreFinal > 100 - this.getStudentAttendances(item)) {
          this.$swal.fire("100>>");
          return item.score;
        }
      }
      //if simple
      else {
        item.scoreFinal =
          item.score != null &&
          item.score != undefined &&
          typeof item.score == "number"
            ? item.score
            : 0;
        if (item.scoreFinal > 100) {
          this.$swal.fire("100>>");
          return item.score;
        }
      }
      let gradePoints;
      let gradeStr;
      let tmpScore = item.scoreFinal;
      if (tmpScore >= 90) {
        gradePoints = 8;
        gradeStr = "VIII";
      } else if (tmpScore >= 80) {
        gradePoints = 7;
        gradeStr = "VII";
      } else if (tmpScore >= 70) {
        gradePoints = 6;
        gradeStr = "VI";
      } else if (tmpScore >= 60) {
        gradePoints = 5;
        gradeStr = "V";
      } else if (tmpScore >= 50) {
        gradePoints = 4;
        gradeStr = "IV";
      } else if (tmpScore >= 40) {
        gradePoints = 3;
        gradeStr = "III";
      } else if (tmpScore >= 30) {
        gradePoints = 2;
        gradeStr = "II";
      } else if (tmpScore >= 0 && typeof tmpScore == "number") {
        gradePoints = 1;
        gradeStr = "I";
      } else if (
        tmpScore == null ||
        tmpScore == undefined ||
        typeof tmpScore != "number"
      ) {
        gradePoints = null;
        gradeStr = null;
      }
      item.changedScore = true;
      item.scoreLevelNumber = gradePoints;
      item.scoreLevelString = gradeStr;
      this.previousValue = null;
      // console.log(this.$attrs.lesson);
      if (
        item.score > 0 &&
        (this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        item.scoreLevelString = "Тооцов";
      } else if (
        item.score == 0 &&
        (this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        item.scoreLevelString = "Тооцоогүй";
      }
      this.$forceUpdate();
    },
    _print(item) {
      console.log("xxx", item.ref.path, item.scoreRef.path);
    },
  },
};
</script>
<style>
.inProgressButton {
  color: #ffbf00 !important;
}

.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 35px !important;
  padding: 0 !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}

.score-bordered-input .v-input__control {
  border: 1px solid #000;
  /* Customize border style here */
  border-radius: 4px;
  /* Optional: Add border radius */
}
</style>
