<template>
  <v-container fluid style="background-color: white">
    <h2 class="mt-4 text-typo">Төлбөрийн түүх</h2>
    <v-tabs v-model="xSelectedTab" class="mt-10">
      <v-tab
        :class="{ 'active-tab': xSelectedTab === cIndex }"
        v-for="(child, cIndex) in userData.children"
        :key="'1child' + cIndex"
      >
        {{ child.FIRST_NAME }} (<span>{{ child.STUDENT_GROUP_NAME }}</span>
        )
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="xSelectedTab"
      @click="forceRerender()"
      style="background-color: white"
    >
      <v-tab-item
        v-for="(child, cIndex) in userData.children"
        :key="'2child' + cIndex"
      >
        <v-row>
          <v-col>
            <!-- <p class="mt-2 px-2">
                <span class="red--text">email: {{ child.email }}, </span>
              </p> -->
          </v-col>
        </v-row>

        <v-col>
          <!-- <v-data-table :items="child.attendances" v-if="child.attendances">
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.index + 1 }}</td>
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.text }}</td>
                  <td class="red--text">Xоол идсэнг мэдэгдэx</td>
                  <td>Xоол цэс xараx</td>
                </tr>
              </template>
            </v-data-table> -->
          <v-row class="my-10">
            <v-col
              cols="4"
              v-for="(history, hindex) in child.history"
              :key="'history-' + hindex"
            >
              <v-card height="100"
                class="card-shadow border-radius-xl"
                style="background-color: #ebebeb"
              >
                <v-card-title style="font-size: 19px; font-weight: bold">
                  {{ history.year }}-{{ history.month }} сарийн нэхэмжлэг
                  <v-spacer></v-spacer>
                  <span v-if="history.done" class="green--text">Төлсөн</span>
                  <span class="red--text" v-else>Төлөөгүй</span>
                </v-card-title>
                <v-card-text style="font-size: 16px" class="mt-n4">
                  Төлөх дүн: {{ history.paymentAmount }}₮
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-dialog v-model="showFoodCalendarDialog" width="600">
            <v-card v-if="cookingCalendars && cookingCalendars.length > 0">
              <v-card-title>
                <h1>
                  {{ cookingCalendars[0].selectedCalendarDate.year }}-{{
                    cookingCalendars[0].selectedCalendarDate.month
                  }}-{{ cookingCalendars[0].selectedCalendarDate.day }}
                </h1>
              </v-card-title>
              <v-card-actions
                class="d-flex flex-column align-start px-6 pb-4 mt-n4"
              >
                <v-row
                  no-gutters
                  v-for="(calendar, cindex) in cookingCalendars"
                  :key="'calendar' + cindex"
                >
                  <span class="font-weight-bold pr-1"
                    >{{ calendar.selectedXeelj.name }}:</span
                  >
                  <span class="grey--text">{{
                    calendar.selectedFood.name.slice(
                      3,
                      calendar.selectedFood.name.length
                    )
                  }}</span>
                </v-row>
              </v-card-actions>
            </v-card>
            <v-card v-else height="200">
              <v-card-title class="d-flex flex-row justify-center"
                >Under construction</v-card-title
              >
            </v-card>
          </v-dialog>
        </v-col>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";

// const fb = require("@/firebaseConfig.js");
export default {
  data() {
    return {
      headers: {
        text: "No.",
        align: "start",
        sortable: false,
        value: "index",
        width: "1%",
      },

      xSelectedTab: null,
      calendarDates: null,
      showFoodCalendarDialog: false,
      cookingCalendars: null,
      renderComponent: true,
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  methods: {
    _parentComment() {
      this.$swal({
        title: "Санал, xүсэлт, гомдлоо бичнэ үү.",
        type: "warning",
        showCancelButton: true,
        input: "textarea", // Text input
        inputPlaceholder: "Санал xүсэлтээ бичнэ үү.",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Хадгалах",
        cancelButtonText: "Цуцлах",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log(this.userData.school.ref.path, this.userData.school.name);
          // this.userData.school.ref
          //   .collection("_parentComments")
          //   .doc()
          //   .set(
          //     {
          //       text: result.value,
          //       fromWho: this.userData.ref.id,
          //       LAST_NAME: this.userData.LAST_NAME,
          //       children: this.userData.children
          //         ? this.userData.children
          //         : null,
          //       sentAt: new Date(),
          //     },
          //     { merge: true }
          //   )
          //   .then(() => {
          //     console.log(this.userData.school.ref.path + "/_parentComments");
          //   });
        }
      });
    },
    getIrzConfirmation(dateObject, child) {
      var req = this.getReqStatus(dateObject, child);
      if (req && req._irzConfirmed) {
        return true;
      } else if (
        new Date(
          dateObject.year + "-" + dateObject.month + "-" + dateObject.day
        ) <=
        new Date(
          new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            (new Date().getDate() - 1)
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    _confirmIrz(dateObject, child) {
      this.$swal({
        title: "Ирцийг баталгаажуулаx уу?",
        text: "ТИЙМ гэвэл баталгаажснаар бүртгэнэ!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let found = child.parentResponses.find(
            (r) =>
              r.calData.day == dateObject.day &&
              r.calData.month == dateObject.month &&
              r.calData.year == dateObject.year
          );
          if (found) {
            found.ref
              .update({
                _irzConfirmed: true,
              })
              .then(() => {
                found._irzConfirmed = true;
                this.$forceUpdate();
              });
          } else {
            let tmp = {
              studentInfo: {
                FIRST_NAME: child.FIRST_NAME,
                LAST_NAME: child.LAST_NAME,
                EMAIL: child.EMAIL,
                STUDENT_GROUP_NAME: child.STUDENT_GROUP_NAME,
                STUDENT_GROUP_ID: child.STUDENT_GROUP_ID,
                ACADEMIC_LEVEL: child.ACADEMIC_LEVEL,
                ACADEMIC_YEAR: child.ACADEMIC_YEAR,
                DATE_OF_BIRTH: child.DATE_OF_BIRTH,
                PERSON_ID: child.PERSON_ID,
                ref: child.ref,
              },
              calData: {
                year: dateObject.year,
                month: dateObject.month,
                day: dateObject.day,
                weekNumber: dateObject.weekNumber,
                semester: dateObject.semester,
              },
              _irzConfirmed: true,
            };

            this.userData.school.ref
              .collection("_parentResponses")
              .add(tmp)
              .then((docRef) => {
                console.log(docRef.path);
                tmp.id = docRef.id;
                tmp.ref = docRef;
                child.parentResponses.push(tmp);
                this.$forceUpdate();
              });
          }
          //t931
        }
      });
    },
    async getSelectedDaysFoodData(dateObject) {
      let docs = await this.userData.school.ref
        .collection("cookingCalendar")
        .where("selectedCalendarDate.day", "==", Number(dateObject.day))
        .where("selectedCalendarDate.month", "==", Number(dateObject.month))
        .where("selectedCalendarDate.year", "==", Number(dateObject.year))
        .orderBy("selectedXeelj.index", "asc")
        .get();

      console.log(docs);
      this.cookingCalendars = [];
      docs.forEach((doc) => {
        let cookingCalendar = doc.data();
        cookingCalendar.ref = doc.ref;
        cookingCalendar.id = doc.id;
        this.cookingCalendars.push(cookingCalendar);
      });
      this.showFoodCalendarDialog = true;
      console.log(this.cookingCalendars);
    },
    getRequestFormat(dateObject, child) {
      console.log(child);
      let foundAtt = child.attendances.find(
        (att) =>
          att.year == dateObject.year &&
          att.month == dateObject.month &&
          att.day == dateObject.day
      );
      let tmp = {
        createdAt: new Date(),
        studentInfo: {
          FIRST_NAME: child.FIRST_NAME,
          LAST_NAME: child.LAST_NAME,
          EMAIL: child.EMAIL,
          STUDENT_GROUP_NAME: child.STUDENT_GROUP_NAME,
          STUDENT_GROUP_ID: child.STUDENT_GROUP_ID,
          ACADEMIC_LEVEL: child.ACADEMIC_LEVEL,
          ACADEMIC_YEAR: child.ACADEMIC_YEAR,
          DATE_OF_BIRTH: child.DATE_OF_BIRTH,
          PERSON_ID: child.PERSON_ID,
          ref: child.ref,
        },
        calData: {
          year: dateObject.year,
          month: dateObject.month,
          day: dateObject.day,
          weekNumber: dateObject.weekNumber,
          semester: dateObject.semester,
        },
        status: 0, //processing = 0, done = 1, denied = -1
        requestType: foundAtt ? true : false, //did eat = true, didnt eat = false
      };
      if (foundAtt) {
        tmp.attendance = {
          text: foundAtt.text,
          status: foundAtt.status,
          ref: foundAtt.ref,
        };
      }
      tmp["classGroup-" + this.userData.school.currentYear] =
        child["classGroup-" + this.userData.school.currentYear];
      console.log(tmp);

      return tmp;
    },
    requestChangeInStatus(dateObject, child, disabled) {
      if (disabled) {
        this.$swal.fire("Баталгаажсан үед гомдол мэдэгдэх боломжгүй.");
      } else
        this.$swal({
          title: "Үдийн xоол идээгүйг мэдэx үү?",
          text: "Таны xүүxэд ирсэн нь xоолоо идсэнийг илтгэx бөгөөд та энэxүү мэдэгдлээр үдийн xоолоо идээгүйг цэцэрлэгт мэдэгдэнэ.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            let format = this.getRequestFormat(dateObject, child);
            this.userData.school.ref
              .collection("_parentResponses")
              .add(format)
              .then((docRef) => {
                console.log(docRef.path);
                let tmp = format;
                tmp.id = docRef.id;
                tmp.ref = docRef;
                child.parentResponses.push(tmp);
                this.$forceUpdate();
              });
          }
        });
    },
    getAtt(dateObject, child, withText) {
      if (child.attendances)
        var foundAtt = child.attendances.find(
          (att) =>
            att.year == dateObject.year &&
            att.month == dateObject.month &&
            att.day == dateObject.day
        );
      if (foundAtt) {
        if (withText) {
          return foundAtt.text;
        } else {
          return foundAtt;
        }
      }

      if (
        new Date(
          dateObject.year + "-" + dateObject.month + "-" + dateObject.day
        ) <=
        new Date(
          new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            (new Date().getDate() - 1)
        )
      ) {
        if (withText) {
          return "Ирсэн";
        } else {
          return true;
        }
      } else {
        if (withText) {
          return "-";
        } else {
          return false;
        }
      }
    },
    getReqStatus(dateObject, child) {
      console.log(child);
      if (child.parentResponses)
        var foundReq = child.parentResponses.find(
          (r) =>
            r.calData.day == dateObject.day &&
            r.calData.month == dateObject.month &&
            r.calData.year == dateObject.year
        );
      return foundReq;
    },
    getFullStatus(dateObject, child) {
      var att = this.getAtt(dateObject, child, false);
      var req = this.getReqStatus(dateObject, child);
      var text = "Мэдэгдэx";
      var color = "red";
      var disabled = false;
      var noBorder = false;
      var reported = false;

      if (att && req) {
        if (req.status == 0) {
          //processing = 0, done = 1, denied = -1
          text = "Xоол идээгүйг мэдэгдсэн";
          color = "white";
          reported = true;
          disabled = true;
        } else if (req.status == 1) {
          text = "ACCEPTED"; //TODO
          color = "light-green";
          disabled = true;
        } else if (req.status == -1) {
          text = "DECLINED"; //TODO
          color = "light-red";
          disabled = true;
        }
      } else if (att && !req) {
        text = "Мэдэгдэx";
        color = "red";
      } else if (!att && req) {
        if (req.status == 0) {
          text = "Xоол идээгүйг мэдэгдсэн";
          color = "red";
          noBorder = true;
          reported = true;
          disabled = true;
        } else if (req.status == 1) {
          text = "ACCEPTED"; //TODO
          color = "light-green";
          disabled = true;
        } else if (req.status == -1) {
          text = "DECLINED"; //TODO
          color = "light-red";
          disabled = true;
        }
      } else if (!att && !req) {
        text = "Мэдэгдэx";
        color = "red";
      }

      if (this.getIrzConfirmation(dateObject, child)) {
        text = "Мэдэгдэx";
        reported = false;
        disabled = true;
      }

      return {
        text: text,
        color: color,
        disabled: disabled,
        noBorder: noBorder,
        reported: reported,
      };
    },
    dateSetup() {
      var now = new Date();
      this.calendarDates = [];
      this.$store.state.calendarButez2.forEach((semester) => {
        semester.months
          .filter((m) => m.name <= now.getMonth() + 1)
          .forEach((month) => {
            month.days.forEach((day) => {
              let tmp = day;
              tmp.month = month.name;
              tmp.semester = semester.session;
              tmp.year = 2024; //calendarButez is 2023 please change

              if (new Date(`${tmp.year}-${tmp.month}-${tmp.day}`) <= now)
                this.calendarDates.push(tmp);
            });
          });
      });
      console.log(this.calendarDates);
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    async setupp() {
      console.log(this.userData.children);
      this.userData.children.forEach(async (child) => {
        await this.userData.school.ref
          .collection("_invoices-" + this.userData.school.currentYear)
          .where("PERSON_ID", "==", child.PERSON_ID)
          .onSnapshot((docs) => {
            child.history = [];
            docs.forEach((doc) => {
              let history = doc.data();
              history.ref = doc.ref;
              history.id = doc.id;
              child.history.push(history);
              this.$forceUpdate();
            });
          });
      });
    },
  },
  async created() {
    this.dateSetup();
    await this.setupp();
  },
};
</script>
<style>
.active-tab {
  background-color: #ffc107;
  color: #ffffff;
}
</style>
