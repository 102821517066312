<template>
  <v-container fluid style="background-color: white">
    <h3 class="mt-4">Xичээлүүд</h3>
    <v-tabs v-model="xSelectedTab" class="mt-10">
      <v-tab
        :class="{ 'active-tab': xSelectedTab === cIndex }"
        v-for="(child, cIndex) in userData.children"
        :key="'1child' + cIndex"
      >
        {{ child.FIRST_NAME }} (<span>{{ child.STUDENT_GROUP_NAME }}</span>
        )
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="xSelectedTab" style="background-color: white">
      <v-tab-item
        v-for="(child, cIndex) in userData.children"
        :key="'2child' + cIndex"
      >
      <StudentsLessonTable :selectedStudent="child"></StudentsLessonTable>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import StudentsLessonTable from "@/views/Pages/Students/StudentsLesson.vue"
// const fb = require("@/firebaseConfig.js");
export default {
  data() {
    return {
      xSelectedTab: null,
    };
  },
  components:{
    StudentsLessonTable
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  methods: {},
  async created() {
    if (this.userData.children) {
      for (const student of this.userData.children) {
        student.school = this.userData.school;
        console.log(student, "sss");
      }
    }
  },
};
</script>
<style>
.active-tab {
  background-color: #ffc107;
  color: #ffffff;
}
</style>
