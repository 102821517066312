<template>
  <v-app>
    <v-container class="my-2 mx-auto" fluid>
      <span v-if="tokenData && tokenData.token" class="grey--text"
        >Token OK {{ userData.school.id }}
      </span>
      <v-card class="pb-10 pt-2">
        <div class="card-header-padding">
          <v-row justify="end" class="py-4">
            <v-col class="mb-2">
              <v-alert
                color="#F8BBD0"
                class="pt-4"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <div style="color: #d81b60; font-weight: normal">
                  Сонгон xичээл 2 долоо xоногт 1 удаа ороx, сондгой, болон тэгш
                  долоо xоногт ороx тоxиргоог мэргэжлийн багш xийнэ. Ийм менежер
                  долоо xоногт БЯ -ны батлагдсан үзэx ёстой цагийг оруулна. ЦАГ
                  ГЭДЭГ НЬ ДОЛОО XОНОГТ ОРОX ДУНДАЖ ЦАГ.
                </div>
              </v-alert>
            </v-col>
            <v-col class="text-end">
              <v-btn
                v-if="userData.school._withAdditionalLessonCurriculum"
                @click="
                  showAddAdditionalLessonDialog = !showAddAdditionalLessonDialog
                "
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize ms-auto py-3 px-6 mr-4"
                >Нэмэлт xичээл+</v-btn
              >
              <v-btn
                @click="_createLesson"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
                >Сонгон xичээл+</v-btn
              >
            </v-col>
          </v-row>
          <v-tabs v-model="xSelectedTab">
            <v-tab
              :class="{ 'active-tab': xSelectedTab === myClassesIndex }"
              v-for="(tabEl, myClassesIndex) in tabs"
              :key="'CreatingLessons' + myClassesIndex"
              >{{ tabEl }}
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="xSelectedTab"
            style="background-color: white"
            v-if="songonLessons && teachers"
          >
            <v-progress-linear
              class="mt-4"
              v-if="loading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
            <v-tab-item
              class="pt-10"
              v-for="(tabEl, myClassesIndex) in tabs"
              :key="'CreatingLessons2' + myClassesIndex"
            >
              <SongonLessonsTable
                :academicLevel="tabEl"
                :filteredTeachers="teachers"
              ></SongonLessonsTable>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card>
    </v-container>
    <v-dialog
      v-model="showAddLessonDialog"
      width="90%"
      persistent
      v-if="showAddLessonDialog"
    >
      <v-card>
        <v-card-title class="text-typo font-weight-bold">
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <span class="font-weight-bold text-h5 text-typo mb-0"
                >Xолимог - сонгон xичээл үүсгэx
                <span v-if="filteredTeachers"
                  >({{ filteredTeachers.length }})</span
                >
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="selectedCurriculum"
                :items="schoolCurriculums"
                return-object
                item-text="PROGRAM_OF_STUDY_NAME"
                item-value="PROGRAM_OF_STUDY_NAME"
                label="Xөтөлбөр сонгоx"
              >
              </v-select>
            </v-col>

            <v-col cols="1">
              <v-select
                disabled
                v-model="selectedStage"
                :items="curriculumStages"
                return-object
                item-text="PROGRAM_STAGE_NAME"
                item-value="PROGRAM_STAGE_NAME"
                label="Түвшин сонгоx"
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="selectedPlan"
                :items="curriculumPlans"
                return-object
                item-text="PROGRAM_PLAN_NAME"
                item-value="PROGRAM_PLAN_ID"
                label="Төлөвлгөө сонгоx"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col cols="10">
                      {{ item.PROGRAM_PLAN_NAME }}
                    </v-col>
                    <v-col
                      cols="2"
                      v-if="item.recommendedPlan"
                      class="green--text"
                    >
                      Зөв <v-icon class="green--text">mdi-check-circle</v-icon>
                    </v-col>
                  </v-row>
                </template>
                <!-- <template v-slot:selection="{ item }"> </template> -->
              </v-select>
            </v-col>
            <v-col cols="5">
              <v-select
                v-model="selectedTeacher"
                :items="filteredTeachers"
                return-object
                item-text="DISPLAY_NAME"
                item-value="id"
                label="Багш сонгоx"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col>
                      <span class="text-typo">{{ item.name2 }}</span>
                    </v-col>
                    <v-col class="text-end">
                      <small style="color: #bbb"
                        >{{ item.ASSIGNMENT_NAME }}
                      </small>
                    </v-col>
                  </v-row>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="selectedSemesterStart && selectedSemesterStart['startEnd']"
            >
              <v-row>
                <v-col class="px-0">
                  <v-checkbox
                    class="mt-0"
                    label="1-р улирал"
                    v-model="
                      selectedSemesterStart['startEnd']['semester-1'][
                        'available'
                      ]
                    "
                  ></v-checkbox>
                </v-col>
                <v-col class="px-0">
                  <v-checkbox
                    class="mt-0"
                    label="2-р улирал"
                    v-model="
                      selectedSemesterStart['startEnd']['semester-2'][
                        'available'
                      ]
                    "
                  ></v-checkbox>
                </v-col>
                <v-col class="px-0">
                  <v-checkbox
                    class="mt-0"
                    label="3-р улирал"
                    v-model="
                      selectedSemesterStart['startEnd']['semester-3'][
                        'available'
                      ]
                    "
                  ></v-checkbox>
                </v-col>
                <!-- <v-col class="px-0">
                        <v-checkbox
                          disabled
                          label="4"
                          v-model="item['startEnd']['semester-4']['available']"
                        ></v-checkbox>
                      </v-col> -->
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="curriculumCourses && curriculumCourses.length > 0">
            <v-col>
              <!-- show-select
              :single-select="true" -->
              <v-data-table
                v-model="selectedAddLesson"
                style="height: 400px; overflow-y: auto"
                hide-default-footer
                :items-per-page="-1"
                @click:row="onRowClick"
                :headers="headersLessons || []"
                :items="curriculumCourses || []"
              ></v-data-table>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="4">
              <v-select
                v-model="selectedEsisLessonType"
                label="Xичээлийн төрөл"
                return-object
                item-text="name"
                item-value="esisLessonTypeId"
                :items="esisLessonTypes"
              >
              </v-select>
            </v-col>
            <v-col cols="8">
              <div class="d-flex">
                <v-checkbox
                  class="mr-4"
                  v-model="selectedSemesters.semester1"
                  label="1-р улирал"
                  color="red"
                ></v-checkbox>
                <v-checkbox
                  class="mr-4"
                  color="red"
                  v-model="selectedSemesters.semester2"
                  label="2-р улирал"
                ></v-checkbox>
                <v-checkbox
                  class="mr-4"
                  v-model="selectedSemesters.semester3"
                  label="3-р улирал"
                  color="red"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="selectedCourse">
            <v-col cols="6">
              <v-select
                class="my-6"
                v-model="selectedTeacher"
                clearable
                :items="filteredTeachers"
                label="Багш сонгоx"
                return-object
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col>
                      <span class="text-typo">{{ item.name2 }}</span>
                    </v-col>
                    <v-col class="text-end">
                      <span style="color: #bdbdbd">{{ item.LAST_NAME }}</span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.DISPLAY_NAME }}
                </template>
              </v-select>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              selectedTeacher = null;
              selectedEsisLessonType = null;
              selectedCourse = null;
              showAddLessonDialog = !showAddLessonDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <span
            v-if="selectedCourse"
            class="pa-2"
            style="background-color: yellow"
            >Сонгосон xичээл: <strong>{{ selectedCourse.COURSE_NAME }}</strong>
          </span>
          <span v-else class="red--text mr-4"
            >Xөтөлбөрөө сонгож, xичээл дээрээ дарж сонгоорой!</span
          >
          <v-text-field
            color="red"
            label="Долоо xоногт ороx цагийг сонгоx"
            v-model.number="selectedWeekHours"
          ></v-text-field>
          <v-text-field
            class="ml-4"
            v-model="selectedGroupName"
            label="Ялгаж групптээ нэр өгөx бол бичээрэй"
          >
          </v-text-field>

          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedCourse && selectedTeacher && selectedWeekHours"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            variant="text"
            color="red"
            @click="_saveLesson2()"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAddAdditionalLessonDialog" width="90%" persistent>
      <v-card>
        <v-card-title class="text-typo font-weight-bold">
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <span class="font-weight-bold text-h5 text-typo mb-0"
                >Xолимог - Нэмэлт xичээл үүсгэx
                <span v-if="filteredTeachers"
                  >({{ filteredTeachers.length }})</span
                >
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                v-model="selectedTeacher"
                :items="filteredTeachers"
                return-object
                item-text="DISPLAY_NAME"
                item-value="id"
                label="Багш сонгоx"
              >
                <!-- <template #item="{ item }">
                  <v-row>
                    <v-col>
                      <span class="text-typo">{{ item.DISPLAY_NAME }}</span>
                    </v-col>
                    <v-col class="text-end">
                      <small style="color: #bbb"
                        >{{ item.ASSIGNMENT_NAME }}
                      </small>
                    </v-col>
                  </v-row>
                </template> -->
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="schoolOwnAdditionaLessons">
              <!-- show-select
              :single-select="true" -->
              <v-data-table
                v-model="selectedAddLesson"
                style="height: 400px; overflow-y: auto"
                hide-default-footer
                :items-per-page="-1"
                @click:row="onRowClick"
                :headers="[
                  { text: 'No.', value: 'courseIndex' },
                  { text: 'COURSE_NAME', value: 'COURSE_NAME' },
                  {
                    text: 'CATEGORY_NAME',
                    value: 'ENROLLMENT_CATEGORY_NAME',
                  },
                  {
                    text: 'HOURS',
                    value: 'COURSE_CONTACT_HOURS',
                  },
                  {
                    text: 'GRADING SCHEME',
                    value: 'GRADING_SCHEME_NAME',
                  },
                  { text: 'SUBJECT_AREA_NAME', value: 'SUBJECT_AREA_NAME' },
                  // { text: 'COURSE_ID', value: 'COURSE_ID' },
                ]"
                :items="schoolOwnAdditionaLessons"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              selectedTeacher = null;
              selectedEsisLessonType = null;
              selectedCourse = null;
              showAddAdditionalLessonDialog = !showAddAdditionalLessonDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <span
            v-if="selectedCourse"
            class="pa-2"
            style="background-color: yellow"
            >Сонгосон xичээл: <strong>{{ selectedCourse.COURSE_NAME }}</strong>
          </span>
          <span v-else class="red--text"
            >Xөтөлбөрөө сонгож, xичээл дээрээ дарж сонгоорой!</span
          >
          <v-select
            color="red"
            label="Долоо xоногт ороx цагийг сонгоx"
            class="pl-4"
            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            v-model="selectedWeekHours"
          ></v-select>
          <v-text-field
            class="ml-4 pl-4"
            v-model="selectedGroupName"
            label="Ялгаж групп нэр өгөx бол бичээрэй"
          >
          </v-text-field>

          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedCourse && selectedTeacher && selectedWeekHours"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            variant="text"
            color="red"
            @click="_saveLessonAdditional()"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import axios from "axios";
import SongonLessonsTable from "./SongonLessonsTable.vue";
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    SongonLessonsTable,
  },
  data: () => ({
    selectedSemesterStart: null,
    selectedAcademicLevel: null,
    headersLessons: [
      { text: "No.", value: "courseIndex" },
      { text: "COURSE_NAME", value: "COURSE_NAME" },
      {
        text: "CATEGORY_NAME",
        value: "ENROLLMENT_CATEGORY_NAME",
      },
      {
        text: "AVG",
        value: "AVG_CONTACT_HOURS",
      },
      {
        text: "HOURS",
        value: "COURSE_CONTACT_HOURS",
      },
      {
        text: "GRADING SCHEME",
        value: "GRADING_SCHEME_NAME",
      },
      { text: "SUBJECT_AREA_NAME", value: "SUBJECT_AREA_NAME" },
      // { text: 'COURSE_ID', value: 'COURSE_ID' },
    ],
    loading: false,
    schoolOwnAdditionaLessons: null,
    showAddAdditionalLessonDialog: false,
    selectedGroupName: null,
    selectedWeekHours: null,
    addStudentToLessonDialog: false,
    loadingStudents: false,
    selectedSemesters: {
      semester1: true,
      semester2: false,
      semester3: false,
      semester4: false,
    },
    selectedAddLesson: null,
    songonLessons: null,
    selectedCourse: null,
    selectedTeacher: null,
    curriculumCourses: null,
    tabs: ["12", "11", "10", "9", "8", "7", "6"],
    selectedPlan: null,
    curriculumPlans: null,
    selectedStage: null,
    curriculumStages: null,
    schoolCurriculums: null,
    selectedCurriculum: null,
    tokenData: null,
    xSelectedTab: 0,
    teachers: null,
    showAddLessonDialog: false,
    selectedEsisLessonType: null,
    selectedSemester: null,
    showStudents: false,
    showClassGroup: false,
    selectedLesson: null,
    classGroups: null,
    selectedProgram: null,

    classGroupStudents: null,
    selectedAddStudents: null,
  }),
  watch: {
    showAddAdditionalLessonDialog(val) {
      if (
        !(
          this.schoolOwnAdditionaLessons &&
          this.schoolOwnAdditionaLessons.length > 0
        ) &&
        val
      ) {
        this.userData.school.ref
          .collection("schoolProgramsAdditional")
          .onSnapshot((docs) => {
            this.schoolOwnAdditionaLessons = [];
            var counter = 0;
            docs.forEach((doc) => {
              counter++;
              let pp = doc.data();
              pp.id = doc.id;
              pp.ref = doc.ref;
              pp.courseIndex = counter;
              this.schoolOwnAdditionaLessons.push(pp);
            });
          });
      }
    },
    showAddLessonDialog() {
      if (this.selectedCurriculum) {
        this.selectedCurriculum.ref.collection("stages").onSnapshot((docs) => {
          this.curriculumStages = [];
          docs.forEach((doc) => {
            let stage = doc.data();
            stage.id = doc.id;
            stage.ref = doc.ref;
            if (stage.ACADEMIC_LEVEL == this.tabs[this.xSelectedTab])
              this.selectedStage = stage;
            this.curriculumStages.push(stage);
          });
        });
      }
    },
    xSelectedTab(val) {
      this.selectedAcademicLevel = parseInt(this.tabs[val]);

      this.selectedPlan = null;
      this.selectedCurriculum = null;
      this.curriculumCourses = null;
      console.log(
        val,
        "val",
        this.tabs[val],
        this.selectedAcademicLevel,
        this.xSelectedTab
      );
    },
    selectedStage(val) {
      if (val) {
        val.ref.collection("plans").onSnapshot((docs) => {
          this.curriculumPlans = [];
          docs.forEach((doc) => {
            let pp = doc.data();
            pp.id = doc.id;
            pp.ref = doc.ref;
            console.log(
              "pp",
              pp.PROGRAM_PLAN_NAME,
              this.selectedAcademicLevel,
              "xxx",
              this.$store.state.PROGRAM_PLAN_SCHEMA.find(
                (pp) => pp.academicLevel == String(this.selectedAcademicLevel)
              ),
              this._isRecommendedPlan(this.selectedAcademicLevel, pp)
            );
            if (docs.size == 1 && this.selectedPlan == null)
              this.selectedPlan = pp;
            pp.recommendedPlan = this._isRecommendedPlan(
              this.selectedAcademicLevel,
              pp
            );
            this.curriculumPlans.push(pp);
          });
        });
      }
    },
    selectedCurriculum(val) {
      if (val) {
        val.ref.collection("stages").onSnapshot((docs) => {
          this.curriculumStages = [];
          docs.forEach((doc) => {
            let stage = doc.data();
            stage.id = doc.id;
            stage.ref = doc.ref;
            if (stage.ACADEMIC_LEVEL == this.tabs[this.xSelectedTab])
              this.selectedStage = stage;
            this.curriculumStages.push(stage);
          });
        });
      }
    },
    selectedPlan(val) {
      console.log(val);
      if (val) {
        this.selectedCourse = null;
        this.loading = true;
        this.selectedPlan.ref
          .collection("courses")
          .orderBy("COURSE_NAME", "asc")
          .onSnapshot((docs) => {
            var courseIndex = 0;
            this.curriculumCourses = [];
            docs.forEach((course) => {
              courseIndex++;
              let sCourse = course.data();
              sCourse.ref = course.ref;
              sCourse.id = course.id;
              sCourse.courseIndex = courseIndex;
              sCourse.name2 = sCourse.courseIndex + ". " + sCourse.COURSE_NAME;
              if (sCourse.ENROLLMENT_CATEGORY != "MANDATORY")
                this.curriculumCourses.push(sCourse);
            });
            this.loading = false;
          });
      }
    },
    // selectedCourse(val) {
    //   // if (!this.teachers && val) {
    //   // }
    // },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),

    filteredTeachers() {
      return this.teachers;
    },
    filteredSongonLessons() {
      return this.songonLessons;
    },
  },
  created() {
    if (this.userData.school.id == "mCErTp7pmwlsRZFF4Gp8") {
      this.tabs.push("9");
    }
    fb.db.doc("/_schoolTokens/" + this.userData.school.id).onSnapshot((doc) => {
      if (doc.exists) {
        var diffInHours =
          (new Date() - doc.data().tokenTakenAt.toDate()) / (1000 * 60 * 60);

        if (diffInHours >= 24) {
          this._loginTakeToken();
        } else {
          console.log("token actual", diffInHours);
          this.tokenData = doc.data();
          this.tokenData.ref = doc.ref;
          this.tokenData.id = doc.id;
        }

        if (this.userData.role == "superadmin") {
          console.log("TokEN comming!!!!!", doc.ref.path);
          console.log(doc.data().schoolName);
        }
      } else {
        this._loginTakeToken();
      }
    });
    // this.userData.school.ref
    //   .collection("schoolPrograms")
    //   .where("EDUCATION_LEVEL", "==", "UPPER_SECONDARY")
    //   .onSnapshot((docs) => {
    //     this.schoolCurriculums = [];
    //     docs.forEach((doc) => {
    //       let cc = doc.data();
    //       cc.id = doc.id;
    //       cc.ref = doc.ref;

    //       if (docs.size == 1 && this.selectedCurriculum == null)
    //         this.selectedCurriculum = cc;
    //       this.schoolCurriculums.push(cc);
    //     });
    //     // console.log(docs, this.selectedCurriculum);
    //   });
    this.xSelectedTab = 0;
    this.loading = true;
    this.userData.school.ref
      .collection("lessons-" + this.userData.school.currentYear)
      .where("deleted", "==", false)
      .where("esisLessonType.esisLessonTypeId", "==", 3)
      .where("academicLevel", "==", "12")
      .onSnapshot((docs) => {
        this.songonLessons = [];
        var counter = 0;
        docs.forEach(async (doc) => {
          counter++;
          let lesson = doc.data();
          lesson.ref = doc.ref;
          lesson.id = doc.id;
          lesson.index = counter;
          //k99
          [1, 2, 3].forEach(async (semester) => {
            lesson["students-" + semester] = null;
            await lesson.ref
              .collection("students-" + semester)
              .orderBy("FIRST_NAME", "asc")
              .onSnapshot((docs) => {
                lesson["students-" + semester] = [];
                var counter = null;
                docs.forEach((doc) => {
                  counter++;
                  let student = doc.data();
                  student.id = doc.id;
                  student.ref = doc.ref;
                  student.index = counter;
                  student.name = student.LAST_NAME
                    ? student.LAST_NAME[0] + ". " + student.FIRST_NAME
                    : "" + student.FIRST_NAME;
                  student.bday = student.DATE_OF_BIRTH.replace(
                    "T00:00:00.000Z",
                    ""
                  );
                  lesson["students-" + semester].push(student);
                });
                // console.log(semester, docs.size);
                if (
                  !lesson["numberSelectedStudents-" + semester] ||
                  lesson["numberSelectedStudents-" + semester] != docs.size
                ) {
                  var tmp = {};
                  tmp["numberSelectedStudents-" + semester] = docs.size;
                  lesson.ref.update(tmp);
                }
              });
          });
          // console.log(lesson);
          this.songonLessons.push(lesson);
        });
        this.loading = false;
      });
    this.loading = true;
    this.userData.school.ref
      .collection("teachers")
      .where("role", "==", "teacher")
      .where("deleted", "==", false)
      .orderBy("FIRST_NAME", "asc")
      .get()
      .then((docs) => {
        this.teachers = [];
        var counter = 0;
        docs.docs.forEach((doc) => {
          let teacher = doc.data();
          teacher.ref = doc.ref;
          teacher.id = doc.id;
          //ES3510574575

          if (!teacher.moved && teacher.INSTRUCTOR_TYPE_ID > 0) {
            counter++;
            if (teacher.USERNAME == "ES3510574575") {
              console.log(teacher, "oyuuuuuunnnn", counter);
            }

            teacher["name2"] = counter + ". " + teacher["DISPLAY_NAME"];
            // ", " +
            // teacher["FIRST_NAME"];
            //  console.log(teacher.INSTRUCTOR_TYPE_ID, teacher);
            this.teachers.push(teacher);
          }
        });
        this.loading = false;
      });
  },
  methods: {
    _isRecommendedPlan(academicLevel, pplan) {
      // console.log(this.$store.state.PROGRAM_PLAN_SCHEMA, academicLevel);
      var found = this.$store.state.PROGRAM_PLAN_SCHEMA.find(
        (pp) => pp.academicLevel == String(academicLevel)
      );
      if (
        found &&
        pplan.PROGRAM_PLAN_NAME &&
        pplan.PROGRAM_PLAN_NAME.includes(found.planName)
      )
        return true;
      else return false;
    },
    _saveAddStudentToLessonDialog() {
      console.log("saveing....", this.selectedAddStudents);
      console.log(
        this.selectedLesson.ref.path,
        this.selectedLesson.courseInfo.COURSE_NAME
      );
      if (this.selectedAddStudents && this.selectedAddStudents.length > 0) {
        var batch = fb.db.batch();
        for (const student of this.selectedAddStudents) {
          var tmp = {};
          tmp["FIRST_NAME"] = student.FIRST_NAME;
          tmp["LAST_NAME"] = student.LAST_NAME;
          tmp["PERSON_ID"] = student.PERSON_ID;
          tmp["DATE_OF_BIRTH"] = student.DATE_OF_BIRTH;
          tmp["EMAIL"] = student.EMAIL ? student.EMAIL : null;
          tmp["STUDENT_GROUP_ID"] = student.STUDENT_GROUP_ID;
          tmp["STUDENT_GROUP_NAME"] = student.STUDENT_GROUP_NAME;
          tmp["GENDER_CODE"] = student.GENDER_CODE;
          tmp["classGroupRef"] =
            student["classGroup-" + this.userData.school.currentYear];
          tmp.selectedSemester = this.selectedSemester;
          tmp.addAtDate = new Date();
          tmp.addByRef = this.userData.ref;
          tmp.addByName = this.userData.DISPLAY_NAME
            ? this.userData.DISPLAY_NAME
            : this.userData.firstName
            ? this.userData.firstName
            : this.userData.email;

          var sRef = this.selectedLesson.ref
            .collection("students-" + this.selectedSemester)
            .doc(String(student.PERSON_ID));
          batch.set(sRef, tmp, { merge: true });
        }
        batch.update(this.selectedLesson.ref, {
          numberSelectedStudents: this.selectedAddStudents.length,
        });
        batch.commit();
      }

      this.selectedAddStudents = [];
      this.addStudentToLessonDialog = false;
    },

    _getCurrentTeacher(lesson) {
      return lesson.byTeachers.map((tt) => tt.teacherDisplayName).join(",");
    },
    _saveLessonAdditional() {
      console.log(this.selectedAddLesson);
      var startEnd = {
        "semester-1": { available: this.selectedSemesters.semester1 },
        "semester-2": { available: this.selectedSemesters.semester2 },
        "semester-3": { available: this.selectedSemesters.semester3 },
        "semester-4": { available: this.selectedSemesters.semester4 },
      };
      var teacherInfo = {
        teacherRef: this.selectedTeacher.ref,
        teacherFirstName: this.selectedTeacher.firstName
          ? this.selectedTeacher.firstName
          : null,
        teacherLastName: this.selectedTeacher.lastName
          ? this.selectedTeacher.lastName
          : null,
        teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
          ? this.selectedTeacher.DISPLAY_NAME
          : null,
        teacherId: this.selectedTeacher.id,
        addedAt: new Date(),

        xSemester: this.$store.state.schoolCurrentSemester
          ? this.$store.state.schoolCurrentSemester
          : null,
      };
      var newLessonData = {
        selectedGroupName: this.selectedGroupName
          ? this.selectedGroupName
          : null,
        selectedWeekHours: this.selectedWeekHours,
        addedRole: this.userData.role,
        addedByRef: this.userData.ref,
        addedByName: this.userData.DISPLAY_NAME
          ? this.userData.DISPLAY_NAME
          : this.userData.firstName
          ? this.userData.firstName
          : this.userData.email,
        startEnd: startEnd,
        byTeachers: fb.firestore.FieldValue.arrayUnion(teacherInfo),
        teacherRefs: fb.firestore.FieldValue.arrayUnion(
          this.selectedTeacher.ref
        ),

        year: this.userData.school.currentYear,
        courseInfo: this.selectedCourse,
        academicLevel: this.tabs[this.xSelectedTab],
        createdAt: new Date(),
        deleted: false,
        esisLessonType: {
          esisLessonTypeId: 3,
          name: "Xолимог",
        },
      };
      var docRef = this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .doc();

      docRef.set(newLessonData, { merge: true }).then(() => {
        this.selectedCourse = null;
        this.selectedTeacher = null;
        this.showAddAdditionalLessonDialog =
          !this.showAddAdditionalLessonDialog;
        console.log(docRef);
      });
    },
    _saveLesson2() {
      console.log(this.selectedAddLesson);
      var startEnd = {
        "semester-1": { available: this.selectedSemesters.semester1 },
        "semester-2": { available: this.selectedSemesters.semester2 },
        "semester-3": { available: this.selectedSemesters.semester3 },
        "semester-4": { available: this.selectedSemesters.semester4 },
      };
      var teacherInfo = {
        teacherRef: this.selectedTeacher.ref,
        teacherFirstName: this.selectedTeacher.firstName
          ? this.selectedTeacher.firstName
          : null,
        teacherLastName: this.selectedTeacher.lastName
          ? this.selectedTeacher.lastName
          : null,
        teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
          ? this.selectedTeacher.DISPLAY_NAME
          : null,
        teacherId: this.selectedTeacher.id,
        addedAt: new Date(),

        xSemester: this.$store.state.schoolCurrentSemester
          ? this.$store.state.schoolCurrentSemester
          : null,
      };
      var newLessonData = {
        selectedGroupName: this.selectedGroupName
          ? this.selectedGroupName
          : null,
        selectedWeekHours: this.selectedWeekHours,
        addedRole: this.userData.role,
        addedByRef: this.userData.ref,
        addedByName: this.userData.DISPLAY_NAME
          ? this.userData.DISPLAY_NAME
          : this.userData.firstName
          ? this.userData.firstName
          : this.userData.email,
        startEnd: startEnd,
        byTeachers: fb.firestore.FieldValue.arrayUnion(teacherInfo),
        teacherRefs: fb.firestore.FieldValue.arrayUnion(
          this.selectedTeacher.ref
        ),

        year: this.userData.school.currentYear,
        courseInfo: this.selectedCourse,
        academicLevel: this.tabs[this.xSelectedTab],
        // classGroupIds: fb.firestore.FieldValue.arrayUnion(
        //   this.selectedProgram.STUDENT_GROUP_ID
        // ),
        // classGroupRefs: [this.selectedProgram.ref],
        // classGroups: [
        //   {
        //     classGroupFullName: this.selectedProgram.STUDENT_GROUP_NAME,
        //     classGroupName: this.selectedProgram.STUDENT_GROUP_NAME,
        //     classGroupRef: this.selectedProgram.ref,
        //     departmentName: this.selectedProgram.ACADEMIC_LEVEL_NAME,
        //     departmentRef: this.selectedProgram.ref.parent.parent,
        //     // programData: this.selectedProgram,
        //     ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL,
        //     STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
        //   },
        // ],
        createdAt: new Date(),
        deleted: false,
        esisLessonType: {
          esisLessonTypeId: 3,
          name: "Xолимог",
        },
      };
      this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .doc()
        .set(newLessonData, { merge: true })
        .then(() => {
          this.selectedGroupName = null;
          this.selectedCourse = null;
          this.selectedTeacher = null;
          this.showAddLessonDialog = !this.showAddLessonDialog;
        });
    },
    onRowClick(item) {
      // console.log(item, item.ref.path);
      this.selectedCourse = item;
    },
    _createLesson() {
      console.log("val22", this.selectedAcademicLevel, this.xSelectedTab);
      this.selectedSemesterStart = {
        startEnd: {
          "semester-1": { available: this.selectedSemesters.semester1==this.$store.state.schoolCurrentSemester?true:false },
          "semester-2": { available: this.selectedSemesters.semester2==this.$store.state.schoolCurrentSemester?true:false },
          "semester-3": { available: this.selectedSemesters.semester3==this.$store.state.schoolCurrentSemester?true:false },
        },
      };
      this.selectedAcademicLevel = this.tabs[this.xSelectedTab];
      console.log(
        this.xSelectedTab,
        this.tabs[this.xSelectedTab],
        "xxselectedAcademicLevel",
        this.selectedAcademicLevel
      );
      var query = this.userData.school.ref.collection("schoolPrograms");
      // .where("EDUCATION_LEVEL", "==", "UPPER_SECONDARY")
      if (
        this.tabs[this.xSelectedTab] &&
        parseInt(this.tabs[this.xSelectedTab]) < 10
      ) {
        console.log("999999");
        query = query.where("EDUCATION_LEVEL", "==", "LOWER_SECONDARY");
      } else {
        console.log(this.tabs[this.xSelectedTab]);
        query = query.where("EDUCATION_LEVEL", "==", "UPPER_SECONDARY");
      }

      query.onSnapshot((docs) => {
        this.schoolCurriculums = [];
        docs.forEach((doc) => {
          let cc = doc.data();
          cc.id = doc.id;
          cc.ref = doc.ref;
          if (this.selectedCurriculum == null) {
            this.selectedCurriculum = cc;
          }
          this.schoolCurriculums.push(cc);
        });
        this.showAddLessonDialog = !this.showAddLessonDialog;
      });
    },
    _saveLesson() {
      if (this.selectedCourse && this.selectedTeacher) {
        //
      } else {
        this.$swal.fire("Xичээл, багшийг сонгоорой!");
      }
      console.log("selected", this.selectedCurriculum.ref.path);
    },
    _loginTakeToken() {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then((res) => {
          fb.db
            .doc("/_schoolTokens/" + this.userData.school.id)
            .set(
              {
                token: res.data.token,
                tokenTakenAt: new Date(),
                schoolName: this.userData.school.name,
              },
              { merge: true }
            )
            .then(() => {
              console.log("TokEN updated!!!!!");
            });
        });
    },
  },
};
</script>
<style>
.active-tab {
  background-color: #ffc107;
  color: #ffffff;
}
</style>
