<template>
  <v-card class="my-4">
    <v-card-text>
      <v-row class="pt-2 pb-4">
        <v-col lg="8" md="8" sm="12">
          <v-alert
            color="#F8BBD0"
            class="pt-4"
            text
            prominent
            icon="mdi-information-slab-circle"
          >
            <h3 style="color: #d81b60; font-weight: normal">
              ҮНЭЛЭX товчийг дарж үнэлгээгээ оруулна.
            </h3>
            <span class="grey--text"
              >Xагас жилийн үнэлгээнүүдийг Үнэлгээ 2 гэж нэрлэнэ.</span
            >
            <!-- <span style="color: #d81b60">
              2-р xагас жилийн үнэлгээ2 дүн ЖИЛИЙН ЭЦСИЙН ДҮН болж
              xадгалагдана!</span
            > -->
          </v-alert>
        </v-col>
        <v-col lg="4" md="4" sm="12">
          <!-- style="background-color: #fef7f9" -->
          <v-select
            disabled
            style="background-color: yellow"
            :items="$store.state.schoolHalfYears"
            v-model="selectedHalfYear"
            label="Xагас жил"
            return-object
            item-text="name"
            item-value="name"
          >
          </v-select>
        </v-col>
      </v-row>
      <h3
        class="mb-4 blue--text font-weight-regular text-uppercase"
        v-if="selectedHalfYear"
      >
        {{ selectedHalfYear.name }}
      </h3>
      <v-progress-linear
        v-if="loading"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear>

      <v-data-table
        class="unelgee2table"
        :headers="headerNames"
        :items="filteredLessons"
        :items-per-page="-1"
        hide-default-footer
        hide-default-header
        item-key="uniqueId"
      >
        <template slot="header">
          <tr style="font-size: 13px; background-color: #ececec !important">
            <th
              class="text-center font-weight-normal py-2"
              v-for="(header, hindex) in headerNames"
              :key="'header' + hindex"
              :style="header.style ? header.style : ''"
              :class="header.class ? header.class : ''"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:item.index="{ item }">
          <div class="text-center py-1">
            {{ item.index }}
            <span
              ><v-icon :class="item.savedToEsis ? 'green--text' : 'red--text'"
                >mdi-check-circle</v-icon
              ></span
            >
          </div>
        </template>
        <template v-slot:item.courseInfo.COURSE_NAME="{ item }">
          <div
            class="pl-2"
            style="white-space: nowrap; cursor: pointer"
            @click="_print2(item)"
          >
            {{ item.courseInfo.COURSE_NAME }}
          </div>
        </template>
        <template v-slot:item.esisLessonType="{ item }">
          <div class="text-center">
            <small
              class="text-lowercase"
              v-if="item.esisLessonType.esisLessonTypeId == 1"
              >Үндсэн</small
            >
            <small
              v-else-if="item.esisLessonType.esisLessonTypeId == 2"
              class="blue--text text-lowercase"
              >Дэд бүлэг
            </small>

            <small
              v-else-if="item.esisLessonType.esisLessonTypeId == 3"
              class="red--text text-lowercase"
              >Xолимог
            </small>

            <!-- <small
              class="blue--text text-lowercase"
              v-if="
                item.courseInfo &&
                item.courseInfo.ENROLLMENT_CATEGORY == 'MANDATORY'
              "
              >Заавал</small
            >
            <small
              v-else-if="
                item.courseInfo &&
                item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'
              "
              class="red--text text-lowercase"
              >Сонгон
            </small> -->
          </div>
        </template>
        <template v-slot:item.classGroups="{ item }">
          <span class="pl-2 blue--text text-uppercase">
            {{ _getClassGroups(item) }}</span
          >
        </template>

        <template v-slot:item.numberOfStudents="{ item }">
          <!-- <div class="text-center" v-if="item['numberOfStudents-' + teacherId]">
            {{ item["numberOfStudents-" + teacherId] }}
          </div> -->

          <div class="text-center" style="line-height: 200%">
            <span>
              {{ _getNumbers(item, "numberStudentsOfUnelgee") }}
            </span>
          </div>
        </template>
        <template v-slot:item.approvedPercentage="{ item }">
          <div
            style="line-height: 200%"
            class="text-center"
            :class="_getConfirmedProcent2(item) == '100%' ? 'green--text' : ''"
          >
            <span>
              {{ _getConfirmedProcent2(item) }}
            </span>
          </div>
        </template>
        <template v-slot:item.approvedNumber="{ item }">
          <div
            class="text-center green--text"
            style="background: #e8f5e9; line-height: 200%"
          >
            <span>
              {{
                _getNumbers(item, "numberStudentsOfUnelgee") != "-" &&
                _getNumbers(item, "approvedNumber") >
                  _getNumbers(item, "numberStudentsOfUnelgee")
                  ? _getNumbers(item, "numberStudentsOfUnelgee")
                  : _getNumbers(item, "approvedNumber")
              }}
            </span>
          </div>
        </template>
        <template v-slot:item.inProgressNumber="{ item }">
          <div
            class="text-center"
            style="color: #000; background-color: #fff4d4; line-height: 200%"
          >
            <span> {{ _getNumbers(item, "inProgressNumber") }} </span>
          </div>
        </template>
        <template v-slot:item.rejectedNumber="{ item }">
          <div
            class="text-center red--text"
            style="background: #ffebee; line-height: 200%"
          >
            <span>
              {{ _getNumbers(item, "rejectedNumber") }}
            </span>
            <!-- {{
              item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId]
                ? item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId].rejectedNumber
                : 0
            }}  
         {{ getAnalystics(item, 3) }}  
            <span v-if="getAnalystics(item, 3) > 0">{{
              getAnalystics(item, 3)
            }}</span>
            <span v-else>-</span> -->
          </div>
        </template>
        <template v-slot:item.savedNumber="{ item }">
          <div
            class="text-center blue--text"
            style="line-height: 200%; background-color: #e8e8ff"
          >
            <span>
              {{ _getNumbers(item, "savedNumber") }}
            </span>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-center">
            <v-progress-linear
              v-if="item.studentLoading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>

            <small
              class="pa-1"
              @click="_doUnelgee2(item)"
              style="cursor: pointer; background-color: #a5d6a7"
              v-if="_getConfirmedProcent2(item) == '100%'"
              >Баталгаажсан</small
            >
            <!-- <small
              class="amber pa-1"
              @click="_doUnelgee2(item)"
              style="cursor: pointer"
              v-else-if="item['sentToAllManager-' + selectedHalfYear.yearId]"
              >Xянагдаж байна</small
            > -->

            <v-btn
              v-else
              dark
              elevation="0"
              x-small
              class="bg-gradient-danger"
              @click="_doUnelgee2(item)"
            >
              үнэлэх
            </v-btn>
            <!-- <v-btn @click="resetScores(item)">reset</v-btn> -->
          </div>
        </template>
        <!-- <template v-slot:item.finalGradeSavedToEsis="{ item }">
          <small class="green--text ml-2">{{
            item.finalGradeSavedToEsis ? "ESIS-д хадгалагдсан" : ""
          }}</small>
          <p
            style="font-size: 11px; line-height: 0.5"
            class="blue--text mb-2 mt-0 ml-2"
          >
            {{ item.finalGradeSavedToEsisAt | formatDate }}
          </p>
        </template>
        <template v-slot:item.finalGradeSavedToEsisAt="{ item }">
          <v-progress-linear
            v-if="!item.unelgee2Scores"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <div
            class="pl-2"
            style="width: 300px"
            v-else-if="
              item.finalGradeSavedToEsisAt &&
              item.unelgee2Scores &&
              item.unelgee2Scores.find(
                (score) =>
                  new Date(score.approvedAt.toDate()).getTime() >
                  new Date(item.finalGradeSavedToEsisAt.toDate()).getTime()
              )
            "
          >
            <small class="red--text">
              <v-icon color="red">mdi-alert</v-icon> Анхны хадгаласнаас хойш дүн
              засагдсан байна, ЭСИС-т xадгалагдаагүй байх магадлалтай. Менежер
              даxин xадгалаx товчоо дарж ЭСИС-тай тулгаарай.
            </small>
          </div>
        </template> -->
      </v-data-table>
    </v-card-text>
    <v-dialog
      v-model="showUnelgeeDialog"
      max-width="80%"
      v-if="selectedLesson"
      persistent
    >
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0 pt-0">
          <v-row class="py-2">
            <v-col>
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                Үнэлгээ 2 - {{ userData.school.currentYear }},
                <span class="blue--text ml-4" v-if="selectedLesson">
                  {{ selectedLesson.courseInfo.COURSE_NAME }}/
                  <span
                    v-if="
                      selectedLesson.classGroups &&
                      selectedLesson.classGroups.length > 0
                    "
                  >
                    {{
                      selectedLesson.classGroups
                        .map((cg) => cg.classGroupFullName)
                        .join(",")
                    }}
                  </span>
                </span>
              </h5>
            </v-col>
            <!-- <v-col class="justify-self-end" cols="auto">
              <v-switch
                disabled
                class="py-0 my-0"
                color="red"
                v-model="simpleCalculationModel"
                :label="
                  simpleCalculationModel
                    ? 'Энгийн'
                    : 'Ахисан (' + simpleCalculationModel + ')'
                "
              ></v-switch>
            </v-col> -->
          </v-row>
          <v-divider></v-divider>
        </div>
        <ScoreInputUnelgee25Dialog
          class="pt-0"
          v-if="selectedLesson && selectedLesson.students"
          v-bind="$attrs"
          :simpleCalculationModel="true"
          :selectedHalfYear="selectedHalfYear"
          :lesson="selectedLesson"
          :externExamsExists2="externExamsExists"
        >
        </ScoreInputUnelgee25Dialog>

        <div v-else class="text-center mt-16">
          <h3>Хичээл сурагчгүй байна. Сурагчаа бүргэнэ үү!</h3>
        </div>
        <v-card-text
          class="mx-10 px-4 text-center"
          style="width: 80%; overflow: hidden"
          v-if="savingUnelgee"
        >
          <p style="display: block !important" class="red--text">
            Дүн хадгалж байна. Түр хүлээнэ үү!
          </p>
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="mx-6 py-10 mt-14" v-else>
          <div
            v-if="
              selectedLesson &&
              selectedLesson.unelgee2Scores &&
              externExamsExists
            "
            class="red--text text-start"
          >
            <!-- <span v-if="checkingExternGrades()">
              ТТШ шалгалт дүнгүй ({{
                selectedLesson.students.filter(
                  (score) =>
                    (score.register || score.register_no) &&
                    (!score.eScore ||
                      (score.eScore && typeof score.eScore.eScore != "number"))
                ).length
              }}):
              {{
                selectedLesson.students
                  .filter(
                    (score) =>
                      (score.register || score.register_no) &&
                      (!score.eScore ||
                        (score.eScore &&
                          typeof score.eScore.eScore != "number"))
                  )
                  .map((score) => score.LAST_NAME[0] + "." + score.FIRST_NAME)
                  .join(", ")
              }}
            </span> -->
            <!-- <br /> -->
            <span v-if="checkingStudRegister()">
              Регистргүй ({{
                selectedLesson.students.filter(
                  (score) => !score.register && !score.register_no
                ).length
              }}):
              {{
                selectedLesson.students
                  .filter((score) => !score.register && !score.register_no)
                  .map((score) => score.LAST_NAME[0] + "." + score.FIRST_NAME)
                  .join(", ")
              }}
            </span>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="grey" class="white--text" @click="_closeUnelgeeDialog">
            Цуцлах
          </v-btn>
          <template v-if="checkAllGradesDone()">
            <v-btn
              @click="_sendUnelgeeToManager"
              class="bg-gradient-danger white--text ml-2 text-capitalize"
            >
              Xадгалаад Менежерт илгээх
              {{ selectedLesson.externalScoresPulled }}
            </v-btn>
            <v-btn
              @click="_saveUnelgee2OfStudents"
              class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
            >
              Хадгалах
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
      <!-- <v-card>
        <v-card-title>
          Dialog Title
          <v-spacer></v-spacer>
          <v-btn icon @click="showUnelgeeDialog = !showUnelgeeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <table style="height: 200px">
            <tbody>
              <tr
                v-for="(student, sIndex) in selectedLesson.students"
                :key="student.id + sIndex"
              >
                <td>{{ student.FIRST_NAME }}</td>
                <td>{{ student.LAST_NAME }}</td>
                <td>{{ student.DATE_OF_BIRTH }}</td>
                <td>---</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showUnelgeeDialog = !showUnelgeeDialog"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card> -->
    </v-dialog>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ScoreInputUnelgee25Dialog from "./ScoreInputUnelgee25Dialog";
const fb = require("@/firebaseConfig.js");
import axios from "axios";
import moment from "moment";

export default {
  components: {
    ScoreInputUnelgee25Dialog,
  },
  data: () => ({
    halfYearDate: null,
    halfYear2Date: null,
    savingUnelgee: false,
    simpleCalculationModel: true,
    loadingInverval: null,
    selectedLesson: null,
    showUnelgeeDialog: false,
    students: null,
    preText: null,
    selectedHalfYear: null,
    lessons: null,
    xSelectedTab: null,
    headerNames: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
      },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "esisLessonType",
      },
      {
        text: "Бүлэг",
        align: "start",
        value: "classGroups",
        sortable: true,
      },
      {
        text: "Суралцагч",
        align: "start",
        value: "numberOfStudents",
        sortable: true,
        width: "2%",
      },
      // {
      //   text: "Дундаж дүн",
      //   align: "start",
      //   value: "scoreAverage",
      //   sortable: true,
      //   width: "2%",
      // },
      {
        text: "Гүйцэтгэл",
        align: "start",
        value: "approvedPercentage",
        sortable: true,
        width: "2%",
      },
      {
        text: "Батлагдсан",
        align: "center",
        value: "approvedNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Xяналтанд",
        align: "center",
        value: "inProgressNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Цуцлагдсан",
        align: "center",
        value: "rejectedNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Бүртгэсэн",
        align: "center",
        value: "savedNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
      // {
      //   style: "background-color: #e8f5e9",
      //   class: "green--text",
      //   text: "Дүнгүүдийг жилийн эцсийн дүнгээр",
      //   align: "start",
      //   value: "finalGradeSavedToEsis",
      // },
      // {
      //   style: "background-color: #e8f5e9",
      //   class: "green--text",
      //   text: "",
      //   align: "start",
      //   value: "finalGradeSavedToEsisAt",
      // },
    ],
    loading: false,
    studentLoading: false,
    // triggerUnelgeeDialog: false,
    totalLessonCounter: null,
    esisToken: null,
    externExamsExists: null,
    externalScoresPulled: false,
  }),
  props: {
    teacherLessons: {
      type: Array,
      required: true,
    },
  },
  watch: {},
  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
    teacherId() {
      return this.userData.id;
    },
    filteredLessons() {
      var list = [];
      if (this.teacherLessons) {
        this.teacherLessons.forEach((lesson) => {
          this.isThisSemesterLesson(lesson);
          if (!lesson.isNotThisSemester) {
            list.push(lesson);
          }
        });
      }

      // if (this.teacherLessons) {
      //   this.teacherLessons.forEach((lesson) => {
      //     if (lesson.esisLessonType.esisLessonTypeId == 3) {
      //       var tmp = Object.assign({}, lesson);
      //       tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] = {
      //         approvedNumber: 0,
      //         inProgressNumber: 0,
      //         rejectedNumber: 0,
      //         savedNumber: 0,
      //         numberStudentsOfUnelgee: 0,
      //         scoreAverage: 0,
      //       };
      //       var divideBy = 0;
      //       for (var program of lesson.classGroups) {
      //         if (
      //           tmp[
      //             "year" +
      //               this.selectedHalfYear.yearId +
      //               "UnelgeeScores-" +
      //               program.classGroupRef.id
      //           ]
      //         ) {
      //           if (
      //             tmp[
      //               "year" +
      //                 this.selectedHalfYear.yearId +
      //                 "UnelgeeScores-" +
      //                 program.classGroupRef.id
      //             ].approvedNumber
      //           )
      //             tmp[
      //               "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      //             ].approvedNumber =
      //               tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
      //                 .approvedNumber +
      //               tmp[
      //                 "year" +
      //                   this.selectedHalfYear.yearId +
      //                   "UnelgeeScores-" +
      //                   program.classGroupRef.id
      //               ].approvedNumber;
      //           if (
      //             tmp[
      //               "year" +
      //                 this.selectedHalfYear.yearId +
      //                 "UnelgeeScores-" +
      //                 program.classGroupRef.id
      //             ].inProgressNumber
      //           )
      //             tmp[
      //               "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      //             ].inProgressNumber =
      //               tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
      //                 .inProgressNumber +
      //               tmp[
      //                 "year" +
      //                   this.selectedHalfYear.yearId +
      //                   "UnelgeeScores-" +
      //                   program.classGroupRef.id
      //               ].inProgressNumber;
      //           if (
      //             tmp[
      //               "year" +
      //                 this.selectedHalfYear.yearId +
      //                 "UnelgeeScores-" +
      //                 program.classGroupRef.id
      //             ].rejectedNumber
      //           )
      //             tmp[
      //               "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      //             ].rejectedNumber =
      //               tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
      //                 .rejectedNumber +
      //               tmp[
      //                 "year" +
      //                   this.selectedHalfYear.yearId +
      //                   "UnelgeeScores-" +
      //                   program.classGroupRef.id
      //               ].rejectedNumber;
      //           if (
      //             tmp[
      //               "year" +
      //                 this.selectedHalfYear.yearId +
      //                 "UnelgeeScores-" +
      //                 program.classGroupRef.id
      //             ].savedNumber
      //           )
      //             tmp[
      //               "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      //             ].savedNumber =
      //               tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
      //                 .savedNumber +
      //               tmp[
      //                 "year" +
      //                   this.selectedHalfYear.yearId +
      //                   "UnelgeeScores-" +
      //                   program.classGroupRef.id
      //               ].savedNumber;
      //           if (
      //             tmp[
      //               "year" +
      //                 this.selectedHalfYear.yearId +
      //                 "UnelgeeScores-" +
      //                 program.classGroupRef.id
      //             ].numberStudentsOfUnelgee
      //           )
      //             tmp[
      //               "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      //             ].numberStudentsOfUnelgee =
      //               tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
      //                 .numberStudentsOfUnelgee +
      //               tmp[
      //                 "year" +
      //                   this.selectedHalfYear.yearId +
      //                   "UnelgeeScores-" +
      //                   program.classGroupRef.id
      //               ].numberStudentsOfUnelgee;
      //           if (
      //             tmp[
      //               "year" +
      //                 this.selectedHalfYear.yearId +
      //                 "UnelgeeScores-" +
      //                 program.classGroupRef.id
      //             ].scoreAverage
      //           ) {
      //             tmp[
      //               "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      //             ].scoreAverage =
      //               tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
      //                 .scoreAverage +
      //               tmp[
      //                 "year" +
      //                   this.selectedHalfYear.yearId +
      //                   "UnelgeeScores-" +
      //                   program.classGroupRef.id
      //               ].scoreAverage;
      //             divideBy++;
      //           }
      //         }
      //       }
      //       tmp[
      //         "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      //       ].scoreAverage = (
      //         tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
      //           .scoreAverage / divideBy
      //       ).toFixed(1);

      //       list.push(tmp);
      //     } else {
      //       if (
      //         lesson[
      //           "year" +
      //             this.selectedHalfYear.yearId +
      //             "UnelgeeScores-" +
      //             lesson.classGroups[0].classGroupRef.id
      //         ]
      //       ) {
      //         lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] =
      //           lesson[
      //             "year" +
      //               this.selectedHalfYear.yearId +
      //               "UnelgeeScores-" +
      //               lesson.classGroups[0].classGroupRef.id
      //           ];
      //       }
      //       list.push(lesson);
      //     }
      //   });
      // }

      list.sort(
        (a, b) => a.courseInfo.PROGRAM_STAGE_ID - b.courseInfo.PROGRAM_STAGE_ID
      );
      var counter = 0;
      list.forEach((item) => {
        counter++;
        item.index = counter;
      });
      return list;
    },
  },

  methods: {
    isThisSemesterLesson(lesson) {
      if (lesson.startEnd) {
        if (this.selectedHalfYear.yearId > 1) {
          if (
            (lesson.startEnd["semester-2"] &&
              lesson.startEnd["semester-2"].available) ||
            (lesson.startEnd["semester-3"] &&
              lesson.startEnd["semester-3"].available)
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        } else {
          if (
            lesson.startEnd["semester-1"] &&
            lesson.startEnd["semester-1"].available
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        }
      } else {
        lesson.isNotThisSemester = false;
      }

      var isUsingSemester = false;
      lesson.byTeachers
        .filter((teacher) => teacher.teacherId == this.userData.id)
        .forEach((teacher) => {
          if (teacher.xSemester) {
            isUsingSemester = true;
          }
        });
      if (isUsingSemester) {
        if (this.selectedHalfYear.yearId == 1) {
          if (lesson.byTeachers.find((t) => t.xSemester == 1) == undefined) {
            lesson.isNotThisSemester = true;
          }
        } else {
          if (
            lesson.byTeachers.find(
              (t) => t.xSemester == 2 || t.xSemester == 3
            ) == undefined
          ) {
            lesson.isNotThisSemester = true;
          }
        }
      }
    },
    async resetScores(lesson) {
      var batch = fb.db.batch();
      lesson.ref.get().then(async (doc) => {
        let llData = doc.data();
        llData.id = doc.id;
        llData.ref = doc.ref;

        console.log(doc.data(), "1");
        console.log(lesson.ref.path, "ff");
        console.log(
          this.userData.school.ref.path + "/unelgee2-2024-scores/" + lesson.id
        );
        console.log(
          this.userData.school.ref.path +
            "/unelgee2-2024-teachers/" +
            this.userData.id
        );

        var teacherStats = await this.userData.school.ref
          .collection("unelgee2-2024-teachers")
          .doc(this.userData.id)
          .get();

        for (const cg of llData.classGroups) {
          // console.log(cg.STUDENT_GROUP_ID, "cgID");
          //1. remove in lesson
          console.log(
            "S1",
            llData["year1UnelgeeScores-100004324684558"],
            llData.ref.path
          );
          llData["year1UnelgeeScores-" + String(cg.STUDENT_GROUP_ID)] = null;
          // console.log(
          //   "S1",
          //   llData["year1UnelgeeScores-" + String(cg.STUDENT_GROUP_ID)],
          //   llData.ref.path
          // );
          batch.update(llData.ref, llData);

          console.log("------------");

          //2. remove in teacherStats
          var tmp = teacherStats.data();
          console.log(
            "S2",
            tmp["year1UnelgeeScores-2024"][
              llData.id + "-" + String(cg.STUDENT_GROUP_ID)
            ],
            teacherStats.ref.path
          );
          delete tmp["year1UnelgeeScores-2024"][
            llData.id + "-" + String(cg.STUDENT_GROUP_ID)
          ];
          console.log(
            "S2",
            tmp["year1UnelgeeScores-2024"][
              llData.id + "-" + String(cg.STUDENT_GROUP_ID)
            ],
            teacherStats.ref.path
          );
          batch.update(teacherStats.ref, tmp);

          console.log("------------");

          //3. remove in class groups
          var cgg = await cg.classGroupRef.get();
          var cgData = cgg.data();

          console.log(
            "S3",
            cgData["year1UnelgeeAverageScores"][llData.id],
            cgg.ref.path
          );
          console.log(
            "S3",
            cgData["year1UnelgeeScores"][llData.id],
            cgg.ref.path
          );

          delete cgData["year1UnelgeeAverageScores"][llData.id];
          delete cgData["year1UnelgeeScores"][llData.id];

          console.log(
            "S3",
            cgData["year1UnelgeeAverageScores"][llData.id],
            cgg.ref.path
          );
          console.log(
            "S3",
            cgData["year1UnelgeeScores"][llData.id],
            cgg.ref.path
          );

          batch.update(cgg.ref, cgData);

          console.log("------------");
        } //end of classGroups
        //4 remove the scores doc
        //schools/bIZDwo6lAhHPQLBfdfrX/unelgee2-2024-scores/fRrpANSqnrB0r5iq6yrN
        await this.userData.school.ref
          .collection("unelgee2-2024-scores")
          .doc(llData.id)
          .collection("scores")
          .get()
          .then((docs) => {
            docs.docs.forEach((doc) => {
              batch.delete(doc.ref);
              // console.log(doc.ref.path)
            });
          });

        console.log(
          "S4",
          this.userData.school.ref
            .collection("unelgee2-2024-scores")
            .doc(llData.id)
            .collection("scores")
        );
        console.log("------------");
        batch.commit().then(() => {
          console.log("done!");
        });
      });
    },
    checkingStudRegister() {
      if (
        this.selectedLesson.students.filter(
          (score) => !score.register && !score.register_no
        ).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkingExternGrades() {
      if (
        this.selectedLesson.students.filter(
          (score) =>
            (score.register || score.register_no) &&
            (!score.eScore ||
              (score.eScore && typeof score.eScore.eScore != "number"))
        ).length > 0
      ) {
        return true;
      } else {
        false;
      }
    },
    checkAllGradesDone() {
      if (
        (this.selectedLesson &&
          this.selectedLesson.unelgee2Scores &&
          this.selectedLesson.students &&
          this.selectedLesson.unelgee2Scores.find(
            (score) => score.approved == false || !score.sentToManager
          )) ||
        (this.selectedLesson.unelgee2Scores &&
          this.selectedLesson.students &&
          this.selectedLesson.unelgee2Scores.length !=
            this.selectedLesson.students.length)
      ) {
        return true;
      }
    },
    _getShortNameCodeForExternExam(lesson) {
      //1229
      var input = lesson.courseInfo.SUBJECT_AREA_ID;
      switch (input) {
        case 2:
          return "mx";
        case 3:
          return "mx";
        case 4:
          return "mx";

        case 5:
          return "mat";
        case 9:
          return "x"; //himi

        case 11:
          return "bio";

        case 12:
          return "gz";

        case 10:
          return "f"; //physics

        case 13:
          return "tuuh"; //tuuh, niigem

        case 18:
          return "tex"; //tuuh, niigem
        case 20:
          return "xj";
        case 24:
          return "ax";

        case 25:
          return "ox";
        case 194:
          return "tuuh"; // niigem
        case 195:
          return "tuuh"; // niigem

        case 196:
          return "mt";

        case 233:
          return "tex"; //ddzz

        default:
          return null;
      }
    },
    //25107
    _isAllowedForExternExams(acdemicLevel, subjectAreaId) {
      if (acdemicLevel == "6" && subjectAreaId == 3) return false; //uran zohiol
      else if (acdemicLevel == "6" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "8" && subjectAreaId == 3)
        return false; //uran zohiol
      else if (acdemicLevel == "8" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "9" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "10" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "11" && subjectAreaId == 3)
        return false; //uran zohiol
      else if (acdemicLevel == "11" && subjectAreaId == 4)
        return false; //undesnii bichig
      else return true;
    },
    async _isWithExternExam(lesson) {
      //25106
      var classGroup = lesson.classGroups[0];
      var allowed = this._isAllowedForExternExams(
        classGroup.ACADEMIC_LEVEL,
        lesson.courseInfo.SUBJECT_AREA_ID
      );

      if (lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY" && allowed) {
        // console.log(this._getShortNameCodeForExternExam(lesson));
        // var classs = lesson.classGroups[0];
        if (this.userData.school.id == "bIZDwo6lAhHPQLBfdfrX" && classGroup) {
          var qq = await this.userData.school.ref
            .collection("externexams")
            .doc(this.userData.school.currentYear.toString())
            .collection(
              this._getShortNameCodeForExternExam(lesson) +
                "-" +
                classGroup.ACADEMIC_LEVEL
            )
            .limit(1)
            .get();
          this.externExamsExists = !qq.empty;
          console.log("AAAA", allowed, this.externExamsExists);
        } else return false;
      } else return false;
    },
    _updateStatisticsDataLocally(lesson, statisticData) {
      lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] =
        statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"];
    },
    _getConfirmedProcent2(item) {
      var xxx = this._getNumbers(item, "numberStudentsOfUnelgee");
      var yyy = this._getNumbers(item, "approvedNumber");
      if (xxx != "-" && yyy != "-") {
        return (yyy / xxx) * 100 > 100
          ? "100%"
          : ((yyy / xxx) * 100).toFixed(0) + "%";
      }
      return "-";
    },
    _getNumbers(lesson, keyword) {
      var sum = null;
      if (lesson.classGroups) {
        for (const cg of lesson.classGroups) {
          if (
            lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                cg.STUDENT_GROUP_ID
            ]
          ) {
            var xx =
              lesson[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  cg.STUDENT_GROUP_ID
              ][keyword];
            sum = sum == null ? xx : sum + xx;
          }
        }
      }
      if (sum == null) return "-";
      else return sum;
    },
    _getClassGroups(lesson) {
      if (lesson.classGroups) {
        return lesson.classGroups.map((cg) => cg.classGroupFullName).join(",");
      }
      return null;
    },
    async _doUnelgee2(lesson) {
      console.log(lesson.ref.path);
      console.log(
        this.userData.school.ref.path + "/unelgee2-2024-scores/" + lesson.id
      );
      console.log(
        this.userData.school.ref.path +
          "/unelgee2-2024-teachers/" +
          this.userData.id
      );
      for (const cg of lesson.classGroups) {
        console.log(cg.classGroupRef.path);
      }
      lesson.studentLoading = true;
      this.selectedLesson = lesson;
      this._isWithExternExam(lesson);
      this.createEeljitLessonToEsis(this.selectedLesson);
      if (this.selectedLesson.esisLessonType.esisLessonTypeId == 1) {
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where(
            "STUDENT_GROUP_ID",
            "==",
            lesson.classGroups[0].STUDENT_GROUP_ID
          )
          .orderBy("FIRST_NAME", "asc")
          .get()
          .then(async (docs) => {
            this.selectedLesson.students = [];
            var counter = 0;
            docs.docs.forEach((doc) => {
              let stud = doc.data();
              stud.id = doc.id;
              stud.ref = doc.ref;
              // console.log(stud.GENDER_CODE);
              // if(lesson.SUBJECT_AREA_ID==18&&stud.GENDER_CODE==)

              if (!stud.moved) {
                counter++;
                stud.index = counter;
                this.selectedLesson.students.push(stud);
              }
            });
            this.selectedLesson.unelgee2Scores = await this.getUnelgeeData(
              lesson
            );
            this.saveStudentNumberToLesson();

            this.showUnelgeeDialog = true;
          });
      } else if (this.selectedLesson.esisLessonType.esisLessonTypeId > 1) {
        var classStudents = [];
        for (var program of this.selectedLesson.classGroups) {
          await this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .where("STUDENT_GROUP_ID", "==", program.STUDENT_GROUP_ID)
            .orderBy("FIRST_NAME", "asc")
            .get()
            .then(async (docs) => {
              docs.forEach((doc) => {
                let stud = doc.data();
                stud.ref = doc.ref;
                stud.id = doc.id;

                if (!stud.moved) {
                  classStudents.push(stud);
                }
              });
            });
        }

        lesson.ref
          .collection("students-" + this.$store.state.runningSemester)
          .orderBy("FIRST_NAME", "asc")
          .get()
          .then(async (docs) => {
            this.selectedLesson.students = [];
            var counter = 0;
            var isFemaleGroup = false;
            docs.docs.forEach((doc) => {
              let stud = doc.data();
              stud.id = doc.id;
              stud.ref = doc.ref;

              console.log(stud, stud.GENDER_CODE);
              if (isFemaleGroup == false && stud.GENDER_CODE == "F") {
                isFemaleGroup = true;
              }

              var found = classStudents.find(
                (s) => Number(s.PERSON_ID) == Number(stud.PERSON_ID)
              );
              if (found) {
                stud.register = found.register;
                stud.register_no = found.register_no;
                counter++;
                stud.index = counter;
                this.selectedLesson.students.push(stud);
              }
            });

            if (
              this.selectedLesson.courseInfo.SUBJECT_AREA_ID == 18 &&
              isFemaleGroup &&
              this.userData.school.id == "bIZDwo6lAhHPQLBfdfrX"
            ) {
              this.externExamsExists = false;
            }

            this.selectedLesson.unelgee2Scores = await this.getUnelgeeData(
              lesson
            );
            this.saveStudentNumberToLesson();

            this.showUnelgeeDialog = true;
          });
      } else {
        this.selectedLesson.unelgee2Scores = await this.getUnelgeeData(lesson);
        this.selectedLesson.students.sort((a, b) =>
          a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
        );
        var counter = 0;
        this.selectedLesson.students.forEach((student) => {
          counter++;
          student.index = counter;
        });
        this.saveStudentNumberToLesson();
        this.showUnelgeeDialog = true;
      }
      // console.log(this.selectedLesson.ref.path);
    },
    saveStudentNumberToLesson() {
      var tmp = {};
      this.selectedLesson.students.forEach((stud) => {
        if (
          !tmp[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              stud.STUDENT_GROUP_ID
          ]
        ) {
          tmp[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              stud.STUDENT_GROUP_ID
          ] = {
            numberStudentsOfUnelgee: 0,
          };
        }
        tmp[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            stud.STUDENT_GROUP_ID
        ].numberStudentsOfUnelgee++;
      });
      this.selectedLesson.ref.set(tmp, { merge: true });
    },
    _getConfirmedProcent(lesson) {
      if (
        lesson.students ||
        (lesson[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            this.userData.id
        ] &&
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ].studentsLength)
      ) {
        var xxx = lesson.students
          ? lesson.students.length
          : lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.userData.id
            ].studentsLength;

        var yyy = this.getAnalystics(lesson, 1);
        return !isNaN(yyy / xxx)
          ? ((yyy / xxx) * 100 == 100 || (yyy / xxx) * 100 == 0
              ? (yyy / xxx) * 100
              : yyy / xxx > 1
              ? 100
              : ((yyy / xxx) * 100).toFixed(2)) + "%"
          : "-";
      } else {
        return "-";
      }
    },
    // getSize() {
    //   var counter = 0
    //   for(var classId in this.allClassGroups) {
    //     counter++
    //   }
    //   return counter
    // },
    _print2(lesson) {
      console.log(
        lesson.courseInfo.SUBJECT_AREA_ID,
        lesson.courseInfo.SUBJECT_AREA_NAME
      );
      console.log(lesson, lesson.ref.path);
    },
    async _doUnelgee() {
      console.log(this.selectedLesson, this.selectedLesson.students);
      this.selectedLesson.students.sort((a, b) =>
        a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
      );

      var query = this.userData.school.ref
        .collection("courses-" + this.userData.school.currentYear)
        .doc(this.selectedLesson.courseInfo.COURSE_ID.toString())
        .collection("unelgee1-" + this.userData.school.currentYear + "-scores");

      if (this.selectedLesson.esisLessonType.esisLessonTypeId > 1) {
        var availableLessonGroups = this.selectedLesson.lessonGroups.map(
          (lg) => lg.groupIndex
        );
        availableLessonGroups = availableLessonGroups.filter(function (
          element
        ) {
          return element !== undefined;
        });
        if (availableLessonGroups && availableLessonGroups.length > 0) {
          query = query.where("lessonGroupIndex", "in", availableLessonGroups);
        }
        this.selectedLesson.students.sort(
          (a, b) => a.lessonGroup.groupIndex - b.lessonGroup.groupIndex
        );
      }
      await query.onSnapshot((querySnapshot) => {
        this.selectedLesson.unelgee1Scores = [];
        var counter = 0;
        if (querySnapshot.size == 0) {
          this.showUnelgeeDialog = !this.showUnelgeeDialog;
        }
        querySnapshot.forEach(async (doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          await item.examRef.get().then((doc) => {
            if (doc.exists && doc.data().deleted != true) {
              item.examDate = new Date(doc.data().examDate);
              item.deleted = false;
            } else {
              item.deleted = true;
            }
          });
          if (item.deleted == false) {
            this.selectedLesson.unelgee1Scores.push(item);
          }
          counter++;
          if (counter == querySnapshot.size) {
            this.showUnelgeeDialog = true;
          }
        });
      });
    },
    forceSave() {
      this.teacherLessons.forEach((lesson) => {
        var tmp = {};
        tmp["analyticsSaved-" + this.selectedHalfYear.yearId] = false;

        lesson.ref.update(tmp);
      });
    },
    getAnalystics(lesson, mode) {
      let approvedNumber = 0;
      let rejectedNumber = 0;
      let inProgressNumber = 0;
      let savedNumber = 0;
      // if (lesson["analyticsSaved-" + this.selectedHalfYear.yearId]) {
      //   approvedNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.userData.id
      //     ].approvedNumber;
      //   rejectedNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.userData.id
      //     ].rejectedNumber;
      //   inProgressNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.userData.id
      //     ].inProgressNumber;
      //   savedNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.userData.id
      //     ].savedNumber;
      // } else {
      if (
        lesson.unelgee2Scores &&
        lesson.unelgee2Scores.length > 0 &&
        lesson.students &&
        lesson.students.length > 0
      ) {
        lesson.unelgee2Scores.forEach((unelgee2) => {
          let foundStud;
          foundStud = lesson.students.find(
            (student) => unelgee2.PERSON_ID == student.PERSON_ID
          );
          if (foundStud) {
            if (unelgee2.approved == true) {
              approvedNumber++;
            } else if (unelgee2.approved == false) {
              rejectedNumber++;
            } else if (unelgee2.sentToManager == true) {
              inProgressNumber++;
            } else if (
              unelgee2.sentToManager != true &&
              unelgee2.score != null &&
              unelgee2.score != undefined &&
              typeof unelgee2.score == "number"
            ) {
              savedNumber++;
            }
          }
        });
      } else if (
        lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
      ) {
        lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"];
        approvedNumber =
          lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .approvedNumber;

        inProgressNumber =
          lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .inProgressNumber;
        rejectedNumber =
          lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .rejectedNumber;
        savedNumber =
          lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .savedNumber;
      }
      // }
      if (mode == 1) {
        return approvedNumber;
      } else if (mode == 2) {
        return inProgressNumber;
      } else if (mode == 3) {
        return rejectedNumber;
      } else if (mode == 4) {
        return savedNumber;
      }
    },
    async getUnelgeeData(lesson) {
      var list = [];
      var query = this.userData.school.ref
        .collection("unelgee2-" + this.userData.school.currentYear + "-scores")
        .doc(
          this.selectedHalfYear.yearId > 1
            ? lesson.id + "-" + this.selectedHalfYear.yearId
            : lesson.id
        )
        .collection("scores");
      // console.log(query.path,"q")
      await query.get().then((docs) => {
        docs.forEach((doc) => {
          let studentScore = doc.data();
          studentScore.id = doc.id;
          studentScore.ref = doc.ref;
          if (
            lesson.students.find(
              (student) => student.PERSON_ID == studentScore.PERSON_ID
            )
          ) {
            list.push(studentScore);
          }
        });
      });
      this.$forceUpdate();
      return list;
    },
    // isAllowedLesson(lesson) {
    //   if (lesson && lesson.classGroupRefs) {
    //     for (var ll of lesson.classGroupRefs) {
    //       if (ll.path.includes("/departments/")) {
    //         lesson.isNotAllowed = true;
    //         break;
    //       }
    //     }
    //   }
    // },

    _closeUnelgeeDialog() {
      this.selectedLesson.studentLoading = false;
      for (var student of this.selectedLesson.students) {
        student.score = null;
        student.scoreFinal = null;
      }
      this.selectedLesson = null;
      this.showUnelgeeDialog = !this.showUnelgeeDialog;
    },

    async handleEsisToken() {
      var tokenDoc = await fb.db
        .doc("/_schoolTokens/" + this.userData.school.id)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            return doc.data();
          } else {
            return null;
          }
        });

      const now = new Date();
      const yesterdayDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
      if (tokenDoc && tokenDoc.tokenTakenAt.toDate() > yesterdayDate) {
        this.esisToken = tokenDoc.token;
      } else {
        try {
          const response = await axios.post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
            {
              username: this.userData.school._esisUserName,
              password: this.userData.school._esisPword,
            }
          );

          if (response.status === 200 && response.data.token) {
            await fb.db
              .doc("/_schoolTokens/" + this.userData.school.id)
              .set(
                {
                  token: response.data.token,
                  tokenTakenAt: new Date(),
                  schoolName: this.userData.school.name,
                },
                { merge: true }
              )
              .then(() => {
                console.log("TokEN updated!!!!!");
                this.esisToken = response.data.token;
              });
          } else {
            console.error(response.data);
          }
        } catch (e) {
          console.error(e.message);
        }
      }
    },

    async createEeljitLessonToEsis(lesson) {
      if (!lesson.savedToEsis) {
        await this.handleEsisToken();
        for (var classGroup of lesson.classGroups) {
          var program = await classGroup.classGroupRef.get().then((doc) => {
            return doc.data();
          });
          const body = {
            lesson: {
              id: lesson.id,
              courseInfo: {
                COURSE_ID: lesson.courseInfo.COURSE_ID,
                SUBJECT_AREA_ID: lesson.courseInfo.SUBJECT_AREA_ID,
                ENROLLMENT_CATEGORY: lesson.courseInfo.ENROLLMENT_CATEGORY,
              },
            },
            program: {
              STUDENT_GROUP_NAME: program.STUDENT_GROUP_NAME,
              ACADEMIC_LEVEL: program.STUDENT_GROUP_ID,
              STUDENT_GROUP_ID: program.ACADEMIC_LEVEL,
              PROGRAM_OF_STUDY_ID: program.PROGRAM_OF_STUDY_ID,
            },
            school: {
              legalEntityId:
                this.userData.school._esis_schoolInfo.legalEntityId,
              academicYear: this.userData.school.currentYear,
            },
            teacherId: lesson.byTeachers[0].teacherId,
            token: this.esisToken,
          };
          const headers = {
            "Content-Type": "application/json",
          };
          console.log(body);

          try {
            const response = await axios.post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/createEeljitLessonEsis",
              body,
              { headers: headers }
            );
            console.log(response);
            if (response.data.res.SUCCESS_CODE === 200) {
              lesson.ref
                .update({
                  savedToEsis: true,
                  savedToEsisAt: new Date(),
                  savedToEsisData: response.data.req,
                })
                .then(() => {
                  console.log(response.data, "success");
                });
            } else {
              lesson.ref
                .update({
                  lessonSavingErrorMessage: response.data.res.RESPONSE_MESSAGE,
                })
                .then(() => {
                  console.log(response.data, "success");
                });
              // this.$forceUpdate();
            }
          } catch (e) {
            console.error(e.message);
          }
        }
      }
    },

    async _sendUnelgeeToManager() {
      if (this.checkIfAllScoresExists() == true) {
        this.$swal({
          title: "Үнэлгээ 2-ийн дүнг Менежерт илгээх үү?",
          text: "Менежерийн баталгаажуулсан, цуцалсан мэдээлэл хяналтын самбарт харагдана!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            var numberOfScoredStudents = 0;
            if (this.selectedHalfYear.yearId > 1) {
              var unelgeeDocumentPath =
                this.userData.school.ref.path +
                "/unelgee2-" +
                this.userData.school.currentYear +
                "-scores/" +
                this.selectedLesson.id +
                "-" +
                this.selectedHalfYear.yearId;
            } else {
              unelgeeDocumentPath =
                this.userData.school.ref.path +
                "/unelgee2-" +
                this.userData.school.currentYear +
                "-scores/" +
                this.selectedLesson.id;
            }
            var unelgeePath = unelgeeDocumentPath + "/scores";

            var batch = fb.db.batch();
            this.savingUnelgee = true;
            var worked = false;

            for (var program of this.selectedLesson.classGroups) {
              var approvedNumber = 0;
              var inProgressNumber = 0;
              var rejectedNumber = 0;
              var savedNumber = 0;
              var scoreAverage = 0;

              var students = this.selectedLesson.students.filter(
                (stud) =>
                  Number(stud.STUDENT_GROUP_ID) ==
                  Number(program.classGroupRef.id)
              );
              for (var student of students) {
                if (
                  student &&
                  student.score != null &&
                  student.score != undefined &&
                  typeof student.score == "number" &&
                  !isNaN(student.score)
                ) {
                  var scoreUnelgee = Object.assign({}, student);
                  delete scoreUnelgee.delete;
                  scoreUnelgee["schoolId"] = this.userData.school.id;
                  scoreUnelgee["schoolName"] = this.userData.school.name;
                  scoreUnelgee["createdByRef"] = this.userData.ref;
                  scoreUnelgee["createdByName"] = this.userData["DISPLAY_NAME"]
                    ? this.userData["DISPLAY_NAME"]
                    : null;
                  scoreUnelgee["lesson"] = null;
                  scoreUnelgee["lessonId"] = this.selectedLesson.id;
                  scoreUnelgee["lessonRef"] = this.selectedLesson.ref;
                  scoreUnelgee["courseInfo"] = this.minimizedCourseInfo(
                    this.selectedLesson.courseInfo
                  ); //minimize
                  if (this.selectedHalfYear.yearId > 1) {
                    scoreUnelgee["semester"] =
                      this.$store.state.runningSemester;
                    scoreUnelgee["year1"] = 2;
                  } else {
                    scoreUnelgee["semester"] =
                      this.$store.state.runningSemester;
                    scoreUnelgee["year1"] = 1;
                  }
                  scoreUnelgee["year"] = this.userData.school.currentYear;

                  scoreUnelgee["createdAt"] = new Date();
                  scoreUnelgee["updatedAt"] = new Date();
                  scoreUnelgee["unelgee2"] = null;
                  scoreUnelgee["savedToEsis"] = false; //12.22

                  if (
                    scoreUnelgee.register_no == undefined ||
                    scoreUnelgee.register
                  ) {
                    delete scoreUnelgee.register;
                    delete scoreUnelgee.register_no;
                  }

                  scoreUnelgee["gradedBy"] = this.minimizedGradedBy(
                    this.userData
                  );

                  if (student["unelgee1"])
                    scoreUnelgee["unelgee1"] = this.minimizedUnelgee1(
                      student["unelgee1"]
                    );

                  if (
                    student.changedScore ||
                    (student.scoreState != true &&
                      student.sentToManager != true)
                  ) {
                    if (
                      scoreUnelgee.score != null &&
                      scoreUnelgee.score != undefined &&
                      typeof student.score == "number"
                    ) {
                      scoreUnelgee["sentToManager"] = true;
                      scoreUnelgee["sentToManagerAt"] = new Date();
                      if (
                        student.modifiedScore &&
                        student.scoreState != null &&
                        student.scoreState != -1
                      ) {
                        delete scoreUnelgee.approved;
                        delete scoreUnelgee.approvedAt;
                        delete scoreUnelgee.approvedByRef;
                        // batch.update(
                        //   fb.db.collection(unelgeePath).doc(student.id),
                        //   {
                        //     approved: fb.firestore.FieldValue.delete(),
                        //     approvedAt: fb.firestore.FieldValue.delete(),
                        //     approvedByRef: fb.firestore.FieldValue.delete(),
                        //   }
                        // );
                      }
                      numberOfScoredStudents++;
                      batch.set(
                        fb.db.collection(unelgeePath).doc(student.id),
                        scoreUnelgee
                      );
                    }
                  }
                  if (scoreUnelgee.approved == true) {
                    if (typeof scoreUnelgee.score == "number")
                      scoreAverage = scoreAverage + scoreUnelgee.score;
                    approvedNumber++;
                    worked = true;
                  } else if (scoreUnelgee.approved == false) {
                    rejectedNumber++;
                    worked = true;
                  } else if (scoreUnelgee.sentToManager == true) {
                    inProgressNumber++;
                    worked = true;
                  } else if (
                    scoreUnelgee.sentToManager != true &&
                    scoreUnelgee.score != null &&
                    scoreUnelgee.score != undefined &&
                    typeof scoreUnelgee.score == "number"
                  ) {
                    worked = true;
                    savedNumber++;
                  }
                }
                if (student.deleted && !student.score) {
                  batch.delete(student.scoreRef);
                  console.log("marked for deletion");
                }
              } // for all scores

              var statisticData = {};

              statisticData = {
                approvedNumber: approvedNumber,
                rejectedNumber: rejectedNumber,
                inProgressNumber: inProgressNumber,
                savedNumber: savedNumber,
                scoreAverage:
                  approvedNumber > 0
                    ? (scoreAverage / approvedNumber).toFixed(2)
                    : null,
                doneByTeacherRef: this.userData.ref,
                doneByTeacherId: this.userData.id,
                numberStudentsOfUnelgee: students ? students.length : 0,
                sentToManager: true,
              };
              this.saveUnelgeeToLesson(statisticData, program, batch);
              this.saveUnelgeeToTeacher(statisticData, program, batch);
              this.saveUnelgeeToProgram(statisticData, program, batch);

              batch.set(
                fb.db.doc(unelgeeDocumentPath),
                {
                  numberOfScoredStudents: numberOfScoredStudents,
                  numberOfStudents: this.selectedLesson.students.length,
                  calculationModel: this.simpleCalculationModel,
                  createdAt: new Date(),
                },
                { merge: true }
              );
            }

            var tmpp = {};
            tmpp["sentToAllManager-" + this.selectedHalfYear.yearId] = worked;
            if (!this.selectedLesson.savedToEsis) tmpp.savedToEsis = false; //12.22
            if (
              !this.selectedLesson[
                "savedAllScoresToEsis-" + this.selectedHalfYear.yearId
              ]
            )
              tmpp[
                "savedAllScoresToEsis-" + this.selectedHalfYear.yearId
              ] = false;
            batch.set(this.selectedLesson.ref, tmpp, { merge: true });

            batch.set(
              fb.db.doc(unelgeeDocumentPath),
              {
                numberOfScoredStudents: numberOfScoredStudents,
                numberOfStudents: this.selectedLesson.students
                  ? this.selectedLesson.students.length
                  : 0,
                calculationModel: this.simpleCalculationModel,
                createdAt: new Date(),
              },
              { merge: true }
            );

            batch.commit().then(async () => {
              // var lesson = this.teacherLessons.find(
              //   (lesson) => lesson.id == this.selectedLesson.id
              // );
              // lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] =
              // statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"];
              await this.createEeljitLessonToEsis(this.selectedLesson);
              this._updateStatisticsDataLocally(
                this.selectedLesson,
                statisticData
              );
              this.$forceUpdate();
              this.savingUnelgee = false;
              for (var student of this.selectedLesson.students) {
                student.score = null;
                student.scoreFinal = null;
              }
              this._closeUnelgeeDialog();
              this.$swal.fire("Амжилттай илгээгдлээ!");
              // this.getLessonScoreAnalytics(this.selectedLesson);
            });
            console.log(numberOfScoredStudents);
            console.log(
              this.selectedLesson.courseInfo.COURSE_ID,
              this.selectedLesson.courseInfo
            );
          }
        });
      } else {
        this.$swal({
          title:
            "Дүн дутуу! Бүх сурагч дээр дүн орсон тохиолдолд илгээх боломжтой!",
          text: "Бүх сурагчийн дүнгийг оруулсаны дараа дахин оролдоно уу.",
          type: "warning",
          showCancelButton: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          confirmButtonText: "Ок",
          reverseButtons: true,
        });
      }
    },
    minimizedGradedBy(userData) {
      var tmp = {
        DISPLAY_NAME: userData.DISPLAY_NAME,
        EMAIL: userData.EMAIL ? userData.EMAIL : null,
        USERNAME: userData.USERNAME,
        FIRST_NAME: userData.FIRST_NAME,
        LAST_NAME: userData.LAST_NAME,
        ref: userData.ref,
        PERSON_ID: userData.PERSON_ID,
      };
      return tmp;
    },
    minimizedCourseInfo(courseInfo) {
      var tmp = {
        COURSE_ID: courseInfo.COURSE_ID,
        COURSE_NAME: courseInfo.COURSE_NAME,
        ENROLLMENT_CATEGORY: courseInfo.ENROLLMENT_CATEGORY,
        GRADING_SCHEME_ID: courseInfo.GRADING_SCHEME_ID,
        PROGRAM_OF_STUDY_ID: courseInfo.PROGRAM_OF_STUDY_ID,
        PROGRAM_ELEMENT_ID: courseInfo.PROGRAM_ELEMENT_ID,
        PROGRAM_STAGE_ID: courseInfo.PROGRAM_STAGE_ID,
        SUBJECT_AREA_ID: courseInfo.SUBJECT_AREA_ID,
      };
      return tmp;
    },
    getScoreLevel(item, mode) {
      let gradePoints;
      let gradeStr;
      let tmpScore = item.score;
      if (tmpScore >= 90) {
        gradePoints = 8;
        gradeStr = "VIII";
      } else if (tmpScore >= 80) {
        gradePoints = 7;
        gradeStr = "VII";
      } else if (tmpScore >= 70) {
        gradePoints = 6;
        gradeStr = "VI";
      } else if (tmpScore >= 60) {
        gradePoints = 5;
        gradeStr = "V";
      } else if (tmpScore >= 50) {
        gradePoints = 4;
        gradeStr = "IV";
      } else if (tmpScore >= 40) {
        gradePoints = 3;
        gradeStr = "III";
      } else if (tmpScore >= 30) {
        gradePoints = 2;
        gradeStr = "II";
      } else if (tmpScore >= 0 && typeof tmpScore == "number") {
        gradePoints = 1;
        gradeStr = "I";
      } else if (
        tmpScore == null ||
        tmpScore == undefined ||
        typeof tmpScore != "number"
      ) {
        gradePoints = null;
        gradeStr = null;
      }

      if (
        item.score > 0 &&
        (this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        item.scoreLevelString = "Тооцов";
        gradePoints = 8;
      } else if (
        item.score == 0 &&
        (this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        item.scoreLevelString = "Тооцоогүй";
        gradePoints = 8;
      }

      if (mode) {
        return gradeStr;
      } else {
        return gradePoints;
      }
    },
    minimizedUnelgee1(unelgee1) {
      var list = [];
      unelgee1.forEach((score) => {
        list.push({
          examRef: score.examRef,
          score: score.score,
          scoreLevelString: score.scoreLevelString,
        });
      });
      return list;
    },
    // minimizedUnelgee1(unelgee1Scores) {
    //   var tmp = [];
    //   unelgee1Scores.forEach((unelgee1) => {
    //     var tmp2 = {
    //       PERSON_ID: unelgee1.PERSON_ID,
    //       courseInfo: this.minimizedCourseInfo(unelgee1.courseInfo),
    //       lessonGroupIndex: unelgee1.lessonGroupIndex
    //         ? unelgee1.lessonGroupIndex
    //         : null,
    //       score: unelgee1.score,
    //       scoreLevelNumber: unelgee1.scoreLevelNumber
    //         ? unelgee1.scoreLevelNumber
    //         : this.getScoreLevel(unelgee1, false),
    //       scoreLevelString: unelgee1.scoreLevelString
    //         ? unelgee1.scoreLevelString
    //         : this.getScoreLevel(unelgee1, true),
    //       year1: unelgee1.year1,
    //       year: unelgee1.year,
    //       deleted: unelgee1.deleted,
    //       examId: unelgee1.examId,
    //       examRef: unelgee1.examRef,
    //       examDate: unelgee1.examDate,
    //       lessonRef: unelgee1.lessonRef,
    //       lessonId: unelgee1.lessonId,
    //       createdByRef: unelgee1.createdByRef,
    //       createdByName: unelgee1.createdByName,
    //       createdAt: unelgee1.createdAt,
    //     };
    //     tmp.push(tmp2);
    //   });
    //   return tmp;
    // },

    saveUnelgeeToTeacher(statisticData, program, batch) {
      var tmp = {};

      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.userData.school.currentYear
      ] = {};
      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.userData.school.currentYear
      ][this.selectedLesson.id + "-" + program.classGroupRef.id] =
        statisticData;

      batch.set(
        this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-teachers"
          )
          .doc(String(this.userData.id)),
        tmp,
        { merge: true }
      );
    },
    saveUnelgeeToProgram(statisticData, program, batch) {
      var tmp = {};
      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] = {};
      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"][
        this.selectedLesson.id
      ] = statisticData;

      batch.set(program.classGroupRef, tmp, { merge: true });
    },
    saveUnelgeeToLesson(statisticData, program, batch) {
      var tmp = {};
      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          program.classGroupRef.id
      ] = statisticData;

      batch.set(this.selectedLesson.ref, tmp, { merge: true });
    },

    async _saveUnelgee2OfStudents() {
      var numberOfScoredStudents = 0;
      if (this.selectedHalfYear.yearId > 1) {
        var unelgeeDocumentPath =
          this.userData.school.ref.path +
          "/unelgee2-" +
          this.userData.school.currentYear +
          "-scores/" +
          this.selectedLesson.id +
          "-" +
          this.selectedHalfYear.yearId;
      } else {
        unelgeeDocumentPath =
          this.userData.school.ref.path +
          "/unelgee2-" +
          this.userData.school.currentYear +
          "-scores/" +
          this.selectedLesson.id;
      }
      var unelgeePath = unelgeeDocumentPath + "/scores";

      console.log(unelgeePath);
      var batch = fb.db.batch();
      this.savingUnelgee = true;

      for (var program of this.selectedLesson.classGroups) {
        var approvedNumber = 0;
        var inProgressNumber = 0;
        var rejectedNumber = 0;
        var savedNumber = 0;
        var scoreAverage = 0;

        var students = this.selectedLesson.students.filter(
          (stud) =>
            Number(stud.STUDENT_GROUP_ID) == Number(program.classGroupRef.id)
        );

        // var worked = false

        for (var student of students) {
          if (
            student &&
            student.score != null &&
            student.score != undefined &&
            typeof student.score == "number" &&
            !isNaN(student.score)
          ) {
            var scoreUnelgee = Object.assign({}, student);

            // scoreUnelgee["lessonGroup"] = null;
            scoreUnelgee["schoolId"] = this.userData.school.id;
            scoreUnelgee["schoolName"] = this.userData.school.name;
            scoreUnelgee["createdByRef"] = this.userData.ref;
            scoreUnelgee["createdByName"] = this.userData["DISPLAY_NAME"]
              ? this.userData["DISPLAY_NAME"]
              : null;
            scoreUnelgee["lesson"] = null;
            scoreUnelgee["lessonId"] = this.selectedLesson.id;
            scoreUnelgee["lessonRef"] = this.selectedLesson.ref;
            scoreUnelgee["courseInfo"] = this.minimizedCourseInfo(
              this.selectedLesson.courseInfo
            ); //minimize
            if (this.selectedHalfYear.yearId > 1) {
              scoreUnelgee["semester"] = this.$store.state.runningSemester;
              scoreUnelgee["year1"] = 2;
            } else {
              scoreUnelgee["semester"] = this.$store.state.runningSemester;
              scoreUnelgee["year1"] = 1;
            }
            scoreUnelgee["year"] = this.userData.school.currentYear;

            scoreUnelgee["createdAt"] = new Date();
            scoreUnelgee["updatedAt"] = new Date();
            scoreUnelgee["unelgee2"] = null;
            // if (student.unelgee1Scores) {
            //   scoreUnelgee["unelgee1Scores"] = this.minimizedUnelgee1(
            //     student.unelgee1Scores
            //   );
            // }
            scoreUnelgee["gradedBy"] = this.minimizedGradedBy(this.userData); //minimize

            if (student["unelgee1"])
              scoreUnelgee["unelgee1"] = this.minimizedUnelgee1(
                student["unelgee1"]
              );

            if (student.modifiedScore) {
              if (
                scoreUnelgee.score != null &&
                scoreUnelgee.score != undefined &&
                typeof scoreUnelgee.score == "number"
              ) {
                if (
                  student.changedScore &&
                  student.scoreState != null &&
                  student.scoreState != -1
                ) {
                  scoreUnelgee["sentToManager"] = false;
                  delete scoreUnelgee.approved;
                  delete scoreUnelgee.approvedAt;
                  delete scoreUnelgee.approvedByRef;
                  // batch.set(
                  //   fb.db.collection(unelgeePath).doc(student.id),
                  //   {
                  //     approved: fb.firestore.FieldValue.delete(),
                  //     approvedAt: fb.firestore.FieldValue.delete(),
                  //     approvedByRef: fb.firestore.FieldValue.delete(),
                  //   },
                  //   { merge: true }
                  // );
                }
                //20251
                if (
                  scoreUnelgee.register_no == undefined ||
                  scoreUnelgee.register
                ) {
                  delete scoreUnelgee.register;
                  delete scoreUnelgee.register_no;
                }

                numberOfScoredStudents++;
                batch.set(
                  fb.db.collection(unelgeePath).doc(student.id),
                  scoreUnelgee
                );
              }

              // batch.set(fb.db.collection(unelgeeStudentPath).doc(docName2), scoreUnelgee,{merge:true})
            }

            if (scoreUnelgee.approved == true) {
              approvedNumber++;
              if (typeof scoreUnelgee.score == "number")
                scoreAverage = scoreAverage + scoreUnelgee.score;
              // worked = true;
            } else if (scoreUnelgee.approved == false) {
              rejectedNumber++;
              // worked = true;
            } else if (scoreUnelgee.sentToManager == true) {
              inProgressNumber++;
              // worked = true;
            } else if (
              scoreUnelgee.sentToManager != true &&
              scoreUnelgee.score != null &&
              scoreUnelgee.score != undefined &&
              typeof scoreUnelgee.score == "number"
            ) {
              // worked = true;
              savedNumber++;
            }
          }

          if (student.deleted && !student.score) {
            batch.delete(student.scoreRef);
          }

          // else if (student.deleted) {
          //   batch.delete(student.deleteScore.ref);
          //   //savedNumber ene teriig oorchloghuu? @khaagii
          // }
        } // for all scores

        var statisticData = {};

        statisticData = {
          approvedNumber: approvedNumber,
          rejectedNumber: rejectedNumber,
          inProgressNumber: inProgressNumber,
          savedNumber: savedNumber,
          scoreAverage:
            approvedNumber > 0
              ? (scoreAverage / approvedNumber).toFixed(2)
              : null,
          doneByTeacherRef: this.userData.ref,
          doneByTeacherId: this.userData.id,
          numberStudentsOfUnelgee: students ? students.length : 0,
        };
        console.log(statisticData, "statitcs");

        this.saveUnelgeeToLesson(statisticData, program, batch);
        this.saveUnelgeeToTeacher(statisticData, program, batch);
        this.saveUnelgeeToProgram(statisticData, program, batch);
      } //loop lessons
      batch.set(
        fb.db.doc(unelgeeDocumentPath),
        {
          numberOfScoredStudents: numberOfScoredStudents,
          numberOfStudents: this.selectedLesson.students.length,
          calculationModel: this.simpleCalculationModel,
          createdAt: new Date(),
        },
        { merge: true }
      );
      batch.commit().then(async () => {
        // this.handleTeacherAndClassGroupAnalytics(); @khaagii avlaa yamar method yum bol
        this.savingUnelgee = false;
        for (var student of this.selectedLesson.students) {
          student.score = null;
          student.scoreFinal = null;
        }
        await this.createEeljitLessonToEsis(this.selectedLesson);
        this._updateStatisticsDataLocally(this.selectedLesson, statisticData);
        this.$forceUpdate();
        this._closeUnelgeeDialog();
        this.$swal.fire("Амжилттай xадгалагдсан!");
        // this.getLessonScoreAnalytics(this.selectedLesson);
      });
      console.log(numberOfScoredStudents);
      console.log(
        this.selectedLesson.courseInfo.COURSE_ID,
        this.selectedLesson.courseInfo
      );
    },
    checkIfAllScoresExists() {
      var status = true;
      for (var student of this.selectedLesson.students) {
        if (
          student.score == null ||
          student.score == undefined ||
          typeof student.score != "number"
        ) {
          status = false;
        }
      }
      return status;
    },
    // async checkIfTypeChanged(lesson, mode) {
    //   if (mode == 2) {
    //     var docSize = await this.userData.school.ref
    //       .collection(
    //         "unelgee2-" + this.userData.school.currentYear + "-scores"
    //       )
    //       .doc(lesson.id)
    //       .collection("scores")
    //       .where("approved", "==", true)
    //       .get()
    //       .then((docs) => {
    //         return docs.docs.filter((doc) => !doc.data().lessonGroupId).length;
    //       });
    //     return docSize;
    //   } else if (mode == 1) {
    //     docSize = await this.userData.school.ref
    //       .collection(
    //         "unelgee2-" + this.userData.school.currentYear + "-scores"
    //       )
    //       .doc(
    //         this.selectedHalfYear.yearId > 1
    //           ? lesson.id + "-" + this.selectedHalfYear.yearId
    //           : lesson.id
    //       )
    //       .collection("scores")
    //       .where("approved", "==", true)
    //       .get()
    //       .then((docs) => {
    //         console.log(docs.size);
    //         return docs.docs.filter((doc) => doc.data().lessonGroupId).length;
    //       });
    //     return docSize;
    //   }
    // },
  },
  created() {
    this.selectedHalfYear = this.$store.state.currentSchoolHalfYear;
    console.log(this.selectedHalfYear, "selectedHalfYear");

    var mandatoryLessonNumber = 0;
    var electiveLessonNumber = 0;
    for (var lesson of this.teacherLessons) {
      if (lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY") {
        mandatoryLessonNumber++;
      } else {
        electiveLessonNumber++;
      }
    }

    var tmp = {};

    tmp[
      "year" +
        this.selectedHalfYear.yearId +
        "UnelgeeScores-" +
        this.userData.school.currentYear
    ] = {};
    tmp[
      "year" +
        this.selectedHalfYear.yearId +
        "UnelgeeScores-" +
        this.userData.school.currentYear
    ].mandatoryLessonNumber = mandatoryLessonNumber;

    tmp[
      "year" +
        this.selectedHalfYear.yearId +
        "UnelgeeScores-" +
        this.userData.school.currentYear
    ].electiveLessonNumber = electiveLessonNumber;

    this.userData.school.ref
      .collection("unelgee2-" + this.userData.school.currentYear + "-teachers")
      .doc(String(this.userData.id))
      .set(tmp, { merge: true });
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        try {
          var date = val.toDate();
        } catch {
          date = val;
        }

        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
